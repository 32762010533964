import { ReactElement } from 'react';

import PuzzleOne from '../puzzles/PuzzleOne';
import PuzzleTwo from '../puzzles/PuzzleTwo';
import PuzzleThree from '../puzzles/PuzzleThree';
import PuzzleFour from '../puzzles/PuzzleFour';
import PuzzleFive from '../puzzles/PuzzleFive';
import PuzzleSix from '../puzzles/PuzzleSix';
import PuzzleSeven from '../puzzles/PuzzleSeven';

import { Puzzle } from '../../types/Puzzle';

type Props = {
  puzzleId: Puzzle;
  finishPuzzle(): void;
};

export default function PuzzleScene(props: Props): ReactElement {
  const { puzzleId, finishPuzzle } = props;

  const getPuzzleById = (): ReactElement => {
    switch (puzzleId) {
      case 1:
        return <PuzzleOne finishPuzzle={finishPuzzle} />;
      case 2:
        return <PuzzleTwo finishPuzzle={finishPuzzle} />;
      case 3:
        return <PuzzleThree finishPuzzle={finishPuzzle} />;
      case 4:
        return <PuzzleFour finishPuzzle={finishPuzzle} />;
      case 5:
        return <PuzzleFive finishPuzzle={finishPuzzle} />;
      case 6:
        return <PuzzleSix finishPuzzle={finishPuzzle} />;
      case 7:
        return <PuzzleSeven finishPuzzle={finishPuzzle} />;
      default:
        return <></>;
    }
  };

  return <>{getPuzzleById()}</>;
}
