import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

import ComponentContainer from './ComponentContainer';
import { ArrowRight, Clock, FriendsSmall, Globe } from '../icons/Icons';
import PlayAndContnueButtons from '../landingpage/PlayAndContinueButtons';
import Button from './Button';
import tracking from '../../services/tracking';

type Props = {
  isTablet: boolean;
  isFinalPage?: boolean;
  imageOneSrc: string;
  imageTwoSrc: string;
  imageOneFallbackSrc: string;
  imageTwoFallbackSrc: string;
  titleOne: string;
  titleTwo: string;
  copyOne: string;
  copyTwo: string;
};

ImagesSection.defaultProps = {
  isTablet: false,
  isFinalPage: false,
};

export default function ImagesSection(props: Props): ReactElement {
  const { t } = useTranslation();
  const {
    isTablet,
    isFinalPage,
    imageOneFallbackSrc,
    imageOneSrc,
    imageTwoFallbackSrc,
    imageTwoSrc,
    copyOne,
    copyTwo,
    titleOne,
    titleTwo,
  } = props;

  const icons = [
    {
      copy: t('landing_screen.image2.icon1_copy'),
      icon: <Clock />,
    },
    {
      copy: t('landing_screen.image2.icon2_copy'),
      icon: <FriendsSmall />,
    },
    {
      copy: t('landing_screen.image2.icon3_copy'),
      icon: <Globe />,
    },
  ];

  const getIcons = icons.map((icon) => {
    return (
      <StyledIconWrapper key={uuidv4()}>
        {icon.icon}
        <StyledIconText>{icon.copy}</StyledIconText>
      </StyledIconWrapper>
    );
  });

  return (
    <ComponentContainer isFinalPage={isFinalPage}>
      <StyledContentContainer>
        <StyledImagesContainer isFinalPage={isFinalPage}>
          <StyledImageColumn>
            <StyledLine />
            <StyledImageTitle> {titleOne}</StyledImageTitle>
            <picture>
              <source srcSet={imageOneSrc} type="image/webp" />
              <source srcSet={imageOneFallbackSrc} type="image/png" />
              <StyledImage src={imageOneFallbackSrc} alt="Image" width={'560'} height={'320'} loading="lazy" />
            </picture>
            <StyledImageText>{copyOne}</StyledImageText>
            {isFinalPage && (
              <Button
                label={t('final_screen.team_button')}
                color={'secondary'}
                buttonIcon={<ArrowRight />}
                onClick={(e) => {
                  e.preventDefault();
                  window.open(t('final_screen.mediathek_link'), '_blank');
                }}
              />
            )}
          </StyledImageColumn>
          <StyledImageColumn>
            <StyledLine />
            <StyledImageTitle>{titleTwo}</StyledImageTitle>
            <picture>
              <source srcSet={imageTwoSrc} type="image/webp" />
              <source srcSet={imageTwoFallbackSrc} type="image/png" />
              <StyledImage src={imageTwoFallbackSrc} alt="Image" width={'560'} height={'320'} loading="lazy" />
            </picture>
            <StyledImageText>{copyTwo}</StyledImageText>
            {isTablet && !isFinalPage && <StyledIconsWrapper>{getIcons}</StyledIconsWrapper>}
            {isFinalPage && (
              <Button
                label={t('final_screen.newsletter_button')}
                color={'secondary'}
                buttonIcon={<ArrowRight />}
                onClick={(e) => {
                  e.preventDefault();
                  tracking.clickNewsletterLink();
                  window.open(t('final_screen.u30_link'), '_blank');
                }}
              />
            )}
          </StyledImageColumn>
        </StyledImagesContainer>
        {isTablet && !isFinalPage && (
          <StyledButtonsWrapper>
            <PlayAndContnueButtons />
          </StyledButtonsWrapper>
        )}
      </StyledContentContainer>
    </ComponentContainer>
  );
}

const StyledContentContainer = styled.div`
  max-width: 118rem;
  margin: 8rem auto 0;
  ${({ theme }) => theme.breakpoints.queries.sm} {
    margin: 0 auto;
  }
`;

const StyledImagesContainer = styled.div<{ isFinalPage?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-content: flex-start;
  margin: 1.6rem 0 0;
  ${({ theme }) => theme.breakpoints.queries.sm} {
    margin: 3rem 1rem 7rem;
    flex-direction: row;
  }
  ${({ theme }) => theme.breakpoints.queries.md} {
    margin: ${({ isFinalPage }) => (isFinalPage ? '5rem auto 10rem' : '15rem 1rem 7rem')};
  }
`;

const StyledImageColumn = styled.div`
  width: 100%;
  padding: 0 0 7.6rem 0;

  ${({ theme }) => theme.breakpoints.queries.sm} {
    width: 50%;
    padding: 0 1.5rem;
  }
  button {
    margin: 3.3rem 0 0;
  }
`;

const StyledLine = styled.hr`
  width: 15rem;
  height: 0.8rem;
  background: ${({ theme }) => theme.palette.global.black};
  padding: 0;
  margin: 0 0 1.5rem 0;
  border: none;
  ${({ theme }) => theme.breakpoints.queries.sm} {
    height: 0.5rem;
    margin: 0 0 1rem 0;
  }
`;

const StyledImageTitle = styled.h3`
  text-transform: uppercase;
  font-size: 2rem;
  line-height: 2.5rem;
  font-weight: 700;
`;

const StyledImageText = styled.p`
  font-size: 1.8rem;
  line-height: 2.8rem;
  margin: 0 auto;
  min-height: 5rem;
`;

const StyledImage = styled.img`
  width: 100%;
  height: auto;
  margin: 2rem 0;
`;

const StyledIconsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1rem 0 0;
`;

const StyledIconWrapper = styled.div`
  display: flex;
  svg {
    margin: 1rem 0;
  }
`;

const StyledIconText = styled.p`
  font-size: 1.8rem;
  line-height: 2.8rem;
  margin: 1.2rem 2rem;
`;

const StyledButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
`;
