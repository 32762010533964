import { ReactElement } from 'react';
import styled from 'styled-components';

import { ArrowLeft, ArrowRight } from '../../components/icons/Icons';

type Props = {
  text: string;
};

export default function DragInfoToastPano(props: Props): ReactElement {
  return (
    <StyledToastContainer>
      <ArrowLeft></ArrowLeft>
      {props.text}
      <ArrowRight></ArrowRight>
    </StyledToastContainer>
  );
}

const StyledToastContainer = styled.div`
  font-size: 1.4rem;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 2rem;
  text-align: center;
  font-size: 2rem;
  color: #fff;
  padding: 0.6rem 1rem;
  display: inline-block;

  svg {
    fill: #fff;
    display: inline-block;
    width: 2rem;
    height: 2rem;
    margin: 0 1rem;
    vertical-align: -0.3rem;
  }
`;
