import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

type Props = {
  headline: string;
};

export default function IntroTitleElement(props: Props): ReactElement {
  const { headline } = props;
  const { t } = useTranslation();

  return (
    <StyledPuzzleTitleContainer>
      <StyledTask>{t('intro_screen.subheadline')}</StyledTask>
      <StyledPuzzleTitle>{headline}</StyledPuzzleTitle>
    </StyledPuzzleTitleContainer>
  );
}

const StyledPuzzleTitleContainer = styled.div`
  position: absolute;
  top: 2rem;
  left: 2rem;
  z-index: 5;

  ${({ theme }) => theme.breakpoints.queries.xl} {
    top: 4rem;
    left: 7rem;
  }
`;

const StyledTask = styled.h3`
  display: inline-block;
  margin: 0 1.5rem 0 0;
  color: white;
  font-size: 1.6rem;
`;

const StyledPuzzleTitle = styled.h2`
  font-size: 3rem;
  font-weight: 900;
  color: white;
  margin: 2rem 0 0;
  text-transform: uppercase;
  position: relative;

  &:after {
    content: '';
    bottom: -2rem;
    left: 0;
    height: 0.7rem;
    width: 15rem;
    position: absolute;
    background-color: white;
  }
`;
