import PersonAvatar1 from '../assets/puzzle6/avatars/Avatar_01.png';
import PersonAvatar2 from '../assets/puzzle6/avatars/Avatar_02.png';
import PersonAvatar3 from '../assets/puzzle6/avatars/Avatar_03.png';
import PersonAvatar4 from '../assets/puzzle6/avatars/Avatar_04.png';
import PersonAvatar5 from '../assets/puzzle6/avatars/Avatar_05.png';
import PersonAvatar6 from '../assets/puzzle6/avatars/Avatar_06.png';
import PersonAvatar7 from '../assets/puzzle6/avatars/Avatar_07.png';
import { PuzzleSixType } from '../types/PuzzleSixDataType';

export const PuzzleSixData: PuzzleSixType[] = [
  { id: '1', url: PersonAvatar5 },
  { id: '2', url: PersonAvatar1 },
  { id: '3', url: PersonAvatar4 },
  { id: '4', url: PersonAvatar7 },
  { id: '5', url: PersonAvatar2 },
  { id: '6', url: PersonAvatar6 },
  { id: '7', url: PersonAvatar3 },
  { id: '8', url: '' },
  { id: '9', url: '' },
  { id: '10', url: '' },
  { id: '11', url: '' },
  { id: '12', url: '' },
  { id: '13', url: '' },
  { id: '14', url: '' },
  { id: '15', url: '' },
  { id: '16', url: '' },
];
