import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { Markup } from 'interweave';
import { getBrowserName } from '../../services/getBrowserName';

type Props = {
  label: string;
};

export default function HeroTitle(props: Props): ReactElement {
  const { label } = props;
  const titleLabel = label;

  return (
    <StyledSolutionTitle browserName={getBrowserName()}>
      <Markup content={titleLabel} />
    </StyledSolutionTitle>
  );
}

const StyledSolutionTitle = styled.h2<{ browserName: 'firefox' | 'other' }>`
  font-size: 6rem;
  line-height: 8rem;
  text-transform: uppercase;
  font-weight: 900;
  color: ${({ theme }) => theme.palette.global.black};
  max-width: 90rem;
  margin: 0;
  ${({ theme }) => theme.breakpoints.queries.sm} {
    font-size: 9rem;
    line-height: 12rem;
  }

  span {
    background-color: ${({ theme }) => theme.palette.global.white};
    box-shadow: ${({ browserName }) =>
      browserName === 'firefox' ? '1rem 0 0 white, 0 0 0 white' : '1rem 0 0 white, -1rem 0 0 white'};
  }
`;
