import { ReactElement } from 'react';
import styled from 'styled-components';
import Lottie from 'lottie-react';

import Matrix1 from '../../assets/puzzle5/matrix/Matrix_1.json';
import Matrix2 from '../../assets/puzzle5/matrix/Matrix_2.json';
import Matrix3 from '../../assets/puzzle5/matrix/Matrix_3.json';
import Matrix4 from '../../assets/puzzle5/matrix/Matrix_4.json';

import MatrixBackground from '../../assets/puzzle5/matrix/background_matrix.jpg';

export function LedMatrix(): ReactElement {
  return (
    <>
      <StyledContainer>
        <StyledBackgroundImage src={MatrixBackground} />
        <StyledLottie animationData={Matrix1} loop={true} left={45.15} top={-5.1} width={84} />
        <StyledLottie animationData={Matrix2} loop={true} left={44.83} top={-5.1} width={120} />
        <StyledLottie animationData={Matrix3} loop={true} left={44.9} top={-5.1} width={86} />
        <StyledLottie animationData={Matrix4} loop={true} left={44.8} top={-5.3} width={120} />
      </StyledContainer>
    </>
  );
}

const StyledContainer = styled.div`
  position: absolute;
  width: 144rem;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const StyledBackgroundImage = styled.img`
  position: absolute;
  width: 100%;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const StyledLottie = styled(Lottie)<{ top: number; left: number; width: number }>`
  position: absolute;
  z-index: 1;
  width: ${({ width }) => `${width}rem`};
  top: ${({ top }) => `${top}rem`};
  left: ${({ left }) => `${left}%`};
  transform: translate(-50%, -50%);
`;
