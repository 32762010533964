import { ReactElement, useEffect, useState } from 'react';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import i18next from 'i18next';

import { NoteBookDataDe, NoteBookDataEn } from '../../data/NoteBookData';
import { Next } from '../icons/Icons';
import { Previous } from '../icons/Icons';
import Book from './Book';

export default function NoteBookSlider(): ReactElement {
  const [currentPage, setCurrentPage] = useState<number>(0);
  const length: number = NoteBookDataDe.length;
  let rightPos: number;

  const locale = i18next.resolvedLanguage.trim().toLowerCase().slice(0, 2);
  const [bookImage, setBookImage] = useState(NoteBookDataDe);

  const isFirstPage = currentPage === 0;
  const isLastPage = currentPage === length - 1;

  const nextPage = (): void => {
    setCurrentPage(isLastPage ? 0 : currentPage + 1);
  };

  const prevPage = (): void => {
    setCurrentPage(isFirstPage ? length : currentPage - 1);
  };

  const getControlsPosition = (controlsVariant: 'prev' | 'next'): number => {
    if (controlsVariant === 'next') {
      rightPos = -3;
    } else {
      rightPos = 64;
    }
    return rightPos;
  };

  useEffect(() => {
    switch (locale) {
      case 'de':
        setBookImage(NoteBookDataDe);
        break;
      case 'en':
        setBookImage(NoteBookDataEn);
        break;
    }
  }, [locale]);

  return (
    <StyledNotebookContainer>
      <StyledNotebookContent>
        <AnimatePresence>
          <motion.div
            key={currentPage}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0, position: 'absolute' }}
            transition={{ delay: 0.1, duration: 0.5 }}
          >
            <Book currentPage={currentPage} bookImage={bookImage} />
          </motion.div>
        </AnimatePresence>
        {currentPage >= 0 && !isLastPage && (
          <StyledPageControlButton rightPos={getControlsPosition('next')}>
            <Next onClick={nextPage} />
          </StyledPageControlButton>
        )}
        {currentPage < length && !isFirstPage && (
          <StyledPageControlButton rightPos={getControlsPosition('prev')}>
            <Previous onClick={prevPage} />
          </StyledPageControlButton>
        )}
      </StyledNotebookContent>
    </StyledNotebookContainer>
  );
}

const StyledNotebookContainer = styled.div`
  position: absolute;
  width: 100%;
  text-align: center;
  z-index: 3;
  margin: 0 auto;
  padding: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const StyledNotebookContent = styled.div`
  width: 71rem;
  height: auto;
  max-height: 51rem;
  margin: 0 auto;
  position: relative;
  background-position: center;
`;

const StyledPageControlButton = styled.div<{ rightPos: number }>`
  position: absolute;
  top: 26rem;
  right: ${({ rightPos }) => `${rightPos}rem`};
  transform: translate(-50%, -50%);
  cursor: pointer;
  svg {
    fill: ${({ theme }) => theme.palette.company.primary};
    transition: fill 0.3s ease-in;
    filter: drop-shadow(1px 1px 6px rgba(0, 0, 0, 0.45));
    :hover {
      fill: ${({ theme }) => theme.palette.company.hover.primary};
    }
  }
`;
