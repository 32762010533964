/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { ReactElement, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import LineTo from 'react-lineto';
import Draggable from 'react-draggable';
import PinAsset from '../../assets/puzzle2/pin.png';

type Coordinates = {
  x: number;
  y: number;
};

export default function DraggablePins(): ReactElement {
  const calcPositions = (pinId: number): Coordinates => {
    const viewportWidth = window.innerWidth;
    const largerEqualThan1440 = viewportWidth >= 1440;
    const largerEqualThan1280 = viewportWidth >= 1280;
    switch (pinId) {
      case 1:
        return largerEqualThan1440 ? { x: 110, y: 450 } : largerEqualThan1280 ? { x: 70, y: 370 } : { x: 60, y: 315 };
      case 2:
        return largerEqualThan1440 ? { x: 150, y: 450 } : largerEqualThan1280 ? { x: 110, y: 370 } : { x: 100, y: 315 };
      case 3:
        return largerEqualThan1440 ? { x: 210, y: 450 } : largerEqualThan1280 ? { x: 170, y: 370 } : { x: 160, y: 315 };
      case 4:
        return largerEqualThan1440 ? { x: 250, y: 450 } : largerEqualThan1280 ? { x: 210, y: 370 } : { x: 200, y: 315 };
      default:
        return { x: 0, y: 0 };
    }
  };

  const [firstPinLeftPosition, setFirstPinLeftPosition] = useState<Coordinates>(calcPositions(1));
  const [firstPinRightPosition, setFirstPinRightPosition] = useState<Coordinates>(calcPositions(2));
  const [secondPinLeftPosition, setSecondPinLeftPosition] = useState<Coordinates>(calcPositions(3));
  const [secondPinRightPosition, setSecondPinRightPosition] = useState<Coordinates>(calcPositions(4));

  const materialBoxBoundary = useRef<HTMLDivElement>(null);

  const [boxBoundaryX, setBoxBoundaryX] = useState<number>(0);
  const [boxBoundaryY, setBoxBoundaryY] = useState<number>(0);

  const handleResize = (): void => {
    if (materialBoxBoundary.current) {
      setBoxBoundaryX(materialBoxBoundary.current.getBoundingClientRect().x);
      setBoxBoundaryY(materialBoxBoundary.current.getBoundingClientRect().y);
    }
    setFirstPinLeftPosition(calcPositions(1));
    setFirstPinRightPosition(calcPositions(2));
    setSecondPinLeftPosition(calcPositions(3));
    setSecondPinRightPosition(calcPositions(4));
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize, false);
  }, []);

  useEffect(() => {
    if (materialBoxBoundary.current) {
      setBoxBoundaryX(materialBoxBoundary.current.getBoundingClientRect().x);
      setBoxBoundaryY(materialBoxBoundary.current.getBoundingClientRect().y);
    }
  }, [materialBoxBoundary]);

  return (
    <StyledPinOverlay ref={materialBoxBoundary} className="PinOverlay">
      <LineTo
        className="pin-line"
        within="PinOverlay"
        borderWidth={3}
        fromAnchor={'center bottom'}
        toAnchor={'center bottom'}
        from="Draggable1-1"
        to="Draggable1-2"
      />
      <LineTo
        className="pin-line"
        within="PinOverlay"
        borderWidth={3}
        fromAnchor={'center bottom'}
        toAnchor={'center bottom'}
        from="Draggable2-1"
        to="Draggable2-2"
      />
      <Draggable
        defaultClassName="firstpinleft"
        position={firstPinLeftPosition}
        onDrag={(e, data) => setFirstPinLeftPosition(data)}
        bounds="parent"
      >
        <StyledPinDraggable className="Draggable1-1">
          <StyledPinImage src={PinAsset} />
        </StyledPinDraggable>
      </Draggable>
      <Draggable
        defaultClassName="firstpinright"
        position={firstPinRightPosition}
        onDrag={(e, data) => setFirstPinRightPosition(data)}
        bounds="parent"
      >
        <StyledPinDraggable className="Draggable1-2">
          <StyledPinImage src={PinAsset} />
        </StyledPinDraggable>
      </Draggable>
      <Draggable
        defaultClassName="secondpinleft"
        position={secondPinLeftPosition}
        onDrag={(e, data) => setSecondPinLeftPosition(data)}
        bounds="parent"
      >
        <StyledPinDraggable className="Draggable2-1">
          <StyledPinImage src={PinAsset} />
        </StyledPinDraggable>
      </Draggable>
      <Draggable
        defaultClassName="secondpinright"
        position={secondPinRightPosition}
        onDrag={(e, data) => setSecondPinRightPosition(data)}
        bounds="parent"
      >
        <StyledPinDraggable className="Draggable2-2">
          <StyledPinImage src={PinAsset} />
        </StyledPinDraggable>
      </Draggable>
    </StyledPinOverlay>
  );
}

const StyledPinOverlay = styled.div`
  width: 100%;
  height: 46rem;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5;
  pointer-events: none;

  & .pin-line {
    margin: -10px 0px 0px 2px;
  }

  & .Draggable1-1 {
    position: ;
  }

  ${({ theme }) => theme.breakpoints.queries.xl} {
    height: 61rem;
  }
`;

const StyledPinDraggable = styled.div`
  width: 3rem;
  height: 4rem;
  position: absolute;
  z-index: 5;
  cursor: grab;
  pointer-events: all;
`;

const StyledPinImage = styled.img`
  width: 100%;
  pointer-events: none;
`;
