import React, { ReactElement } from 'react';
import styled from 'styled-components';

type Props = {
  children: React.ReactNode;
  columns: number;
};

export function CardsGrid(props: Props): ReactElement {
  const { children, columns } = props;
  return (
    <StyledGridContainer>
      <StyledGrid columns={columns}>{children}</StyledGrid>
    </StyledGridContainer>
  );
}

const StyledGrid = styled.div<{ columns: number }>`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(4, 1fr);
  grid-gap: 1rem;
  justify-items: center;
  position: relative;
  top: 0;
  left: 0;
  width: 40rem;
  height: 40rem;
  margin: 0 auto;
  background-color: ${({ theme }) => theme.palette.global.white};
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);

  ${({ theme }) => theme.breakpoints.queries.lg} {
    width: 50rem;
    height: 50rem;
  }

  div {
    padding: 0;
    position: relative;
  }

  div:nth-child(1) {
    div {
      top: 20%;
      left: -80%;
    }
  }

  div:nth-child(2),
  div:nth-child(4) {
    div {
      top: 40%;
      left: -55%;
    }
  }

  div:nth-child(6) {
    div {
      top: 25%;
      left: -80%;
    }
  }

  div:nth-child(7),
  div:nth-child(9) {
    div {
      top: 40%;
      left: -55%;
    }
  }

  div:nth-child(10) {
    div {
      top: 0%;
      right: 50%;
    }
  }

  div:nth-child(11) {
    div {
      top: 30%;
      left: -80%;
    }
  }
  div:nth-child(12),
  div:nth-child(13),
  div:nth-child(14) {
    div {
      top: 40%;
      left: -55%;
    }
  }
  div:nth-child(15) {
    div {
      top: 0%;
      right: 50%;
    }
  }
  div:nth-child(18) {
    div {
      top: 50%;
      left: -140%;
    }
  }
  div:nth-child(19) {
    div {
      top: 50%;
      left: -120%;
    }
  }

  div:nth-child(3),
  div:nth-child(5),
  div:nth-child(8),
  div:nth-child(16),
  div:nth-child(17),
  div:nth-child(20) {
    div {
      display: none;
    }
  }
`;

const StyledGridContainer = styled.div`
  position: relative;
  top: 0;
  left: 0;
`;
