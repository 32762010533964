import { useTranslation } from 'react-i18next';
import PersonAvatar1 from '../assets/puzzle6/avatars/Avatar_01.png';
import PersonAvatar2 from '../assets/puzzle6/avatars/Avatar_02.png';
import PersonAvatar3 from '../assets/puzzle6/avatars/Avatar_03.png';
import PersonAvatar4 from '../assets/puzzle6/avatars/Avatar_04.png';
import PersonAvatar5 from '../assets/puzzle6/avatars/Avatar_05.png';
import PersonAvatar6 from '../assets/puzzle6/avatars/Avatar_06.png';
import PersonAvatar7 from '../assets/puzzle6/avatars/Avatar_07.png';

import Card2 from '../assets/puzzle7/cards/card2.svg';
import Card3 from '../assets/puzzle7/cards/card3.svg';
import Card4 from '../assets/puzzle7/cards/card4.svg';
import Card5 from '../assets/puzzle7/cards/card5.svg';
import Card6 from '../assets/puzzle7/cards/card6.svg';
import Card7 from '../assets/puzzle7/cards/card7.svg';
import Card8 from '../assets/puzzle7/cards/card8.svg';

export const PuzzleData = (): object[] => {
  const { t } = useTranslation();

  return [
    {
      //Puzzle 1
      puzzleTitle: t('puzzle_one.puzzle_title'),
      puzzleSubTitle: t('puzzle_one.puzzle_subtitle'),
      puzzleCode: 'rauf',
      puzzleKeyword: 'violine',
      puzzleKeyword2: 'violin',
      puzzleTitleKeyword: t('puzzle_one.puzzle_title_keyword'),
      puzzleColor: '#d6d2d3',
      solutionModalImage: 'https://via.placeholder.com/300x150',
      hotspotNote: t('puzzle_one.note_title'),
      checkButton: t('puzzle_one.check_button'),
      puzzleSolutionTitle: t('puzzle_one.puzzleSolution_title'),
      puzzleHints: [t('puzzle_one.hint1'), t('puzzle_one.hint2'), t('puzzle_one.hint3')],
      taskSteps: [t('puzzle_one.task_step1'), t('puzzle_one.task_step2')],
    },
    {
      //Puzzle 2
      puzzleTitle: t('puzzle_two.puzzle_title'),
      puzzleSubTitle: t('puzzle_two.puzzle_subtitle'),
      puzzleCode: 'H4',
      puzzleKeyword: 'cello',
      puzzleKeyword2: 'cello',
      puzzleTitleKeyword: t('puzzle_two.puzzle_title_keyword'),
      puzzleColor: '#747572',
      checkButton: t('puzzle_two.check_button'),
      puzzleSolutionTitle: t('puzzle_two.solution_title'),
      puzzleHints: [t('puzzle_two.hint1'), t('puzzle_two.hint2'), t('puzzle_two.hint3')],
      taskSteps: [t('puzzle_two.task_step1')],
    },
    {
      //Puzzle 3
      puzzleTitle: t('puzzle_three.puzzle_title'),
      puzzleSubTitle: t('puzzle_three.puzzle_subtitle'),
      puzzleCode: 'floete',
      puzzleCode2: 'sonata',
      puzzleKeyword: 'mundharmonika',
      puzzleKeyword2: 'mouthorgan',
      puzzleTitleKeyword: t('puzzle_three.puzzle_title_keyword'),
      puzzleColor: '#282322',
      solutionModalImage: 'https://via.placeholder.com/300x150',
      hotspotNote: t('puzzle_one.note_title'),
      checkButton: t('puzzle_three.check_button'),
      puzzleSolutionTitle: t('puzzle_three.solution_title'),
      puzzleHints: [t('puzzle_three.hint1'), t('puzzle_three.hint2'), t('puzzle_three.hint3')],
      taskSteps: [t('puzzle_three.task_step1'), t('puzzle_three.task_step2')],
    },
    {
      //Puzzle 4
      puzzleTitle: t('puzzle_four.puzzle_title'),
      puzzleSubTitle: t('puzzle_four.puzzle_subtitle'),
      puzzleCode: [{ id: '3' }, { id: '2' }, { id: '6' }, { id: '1' }, { id: '5' }, { id: '4' }],
      puzzleKeyword: 'altsaxophon',
      puzzleKeyword2: 'altosaxophone',
      puzzleTitleKeyword: t('puzzle_four.puzzle_title_keyword'),
      puzzleColor: '#F7F5F8',
      solutionModalImage: 'https://via.placeholder.com/300x150',
      checkButton: t('puzzle_four.check_button'),
      puzzleHints: [t('puzzle_four.hint1'), t('puzzle_four.hint2'), t('puzzle_four.hint3')],
      taskSteps: [t('puzzle_four.task_step1')],
      checkButtonError: t('puzzle_four.check_button_error'),
    },
    {
      //Puzzle 5
      puzzleTitle: t('puzzle_five.puzzle_title'),
      puzzleSubTitle: t('puzzle_five.puzzle_subtitle'),
      puzzleCode: ['2287', 'uai4', '1uo6'],
      puzzleKeyword: 'synthesizer',
      puzzleKeyword2: 'synthesizer',
      puzzleTitleKeyword: t('puzzle_five.puzzle_title_keyword'),
      puzzleColor: '#282828',
      puzzleSolutionTitle: t('puzzle_five.solution_title'),
      solutionModalImage: 'https://via.placeholder.com/300x150',
      hotspotNote: t('puzzle_one.note_title'),
      checkButton: t('puzzle_five.check_button'),
      puzzleHints: [t('puzzle_five.hint1'), t('puzzle_five.hint2'), t('puzzle_five.hint3')],
      taskSteps: [t('puzzle_five.task_step1')],
    },
    {
      //Puzzle 6
      puzzleTitle: t('puzzle_six.puzzle_title'),
      puzzleSubTitle: t('puzzle_six.puzzle_subtitle'),
      puzzleCode: [
        { url: PersonAvatar6 },
        { url: PersonAvatar4 },
        { url: '' },
        { url: PersonAvatar2 },
        { url: PersonAvatar1 },
        { url: PersonAvatar3 },
        { url: PersonAvatar7 },
        { url: PersonAvatar5 },
      ],
      puzzleKeyword: 'drumset',
      puzzleKeyword2: 'drumset',
      puzzleTitleKeyword: t('puzzle_six.puzzle_title_keyword'),
      puzzleColor: '#302f29',
      solutionModalImage: 'https://via.placeholder.com/300x150',
      checkButton: t('puzzle_six.check_button'),
      checkButtonError: t('puzzle_six.check_button_error'),
      puzzleHints: [t('puzzle_six.hint1'), t('puzzle_six.hint2'), t('puzzle_six.hint3')],
      taskSteps: [t('puzzle_six.task_step1')],
    },
    {
      //Puzzle 7
      puzzleTitle: t('puzzle_seven.puzzle_title'),
      puzzleSubTitle: t('puzzle_seven.puzzle_subtitle'),
      puzzleCode: [
        { url: '' },
        { url: Card3 },
        { url: '' },
        { url: Card2 },
        { url: '' },
        { url: '' },
        { url: Card8 },
        { url: '' },
        { url: Card4 },
        { url: '' },
        { url: '' },
        { url: Card7 },
        { url: Card5 },
        { url: Card6 },
        { url: '' },
        { url: '' },
        { url: '' },
        { url: '' },
        { url: '' },
        { url: '' },
      ],
      puzzleKeyword: 'kontrabass',
      puzzleKeyword2: 'doublebass',
      puzzleTitleKeyword: t('puzzle_seven.puzzle_title_keyword'),
      puzzleColor: '#E9E3D0',
      solutionModalImage: 'https://via.placeholder.com/300x150',
      hotspotNote: t('puzzle_one.note_title'),
      checkButton: t('puzzle_seven.check_button'),
      checkButtonError: t('puzzle_seven.check_button_error'),
      puzzleHints: [t('puzzle_seven.hint1'), t('puzzle_seven.hint2'), t('puzzle_seven.hint3')],
      taskSteps: [t('puzzle_seven.task_step1')],
    },
  ];
};
