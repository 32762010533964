import React, { ReactElement, ReactNode } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import useContent from '../../hooks/useContent';

import theme from '../../theme/theme';
import { HiddenRoutes } from '../../types/Routes.d';

type Props = {
  children: ReactNode;
};

export default function Body(props: Props): ReactElement {
  const { children } = props;
  const { pathname } = useLocation();

  const puzzleColorOne = useContent('PuzzleData', 'puzzleColor', 1);
  const puzzleColorTwo = useContent('PuzzleData', 'puzzleColor', 2);
  const puzzleColorThree = useContent('PuzzleData', 'puzzleColor', 3);
  const puzzleColorFour = useContent('PuzzleData', 'puzzleColor', 4);
  const puzzleColorFive = useContent('PuzzleData', 'puzzleColor', 5);
  const puzzleColorSix = useContent('PuzzleData', 'puzzleColor', 6);
  const puzzleColorSeven = useContent('PuzzleData', 'puzzleColor', 7);

  const defineBackgroundColor = (path: string): string => {
    switch (path) {
      case HiddenRoutes.INTRO:
        return '#5D8AB1';
      case HiddenRoutes.PUZZLEONE:
        return puzzleColorOne.toString();
      case HiddenRoutes.PUZZLETWO:
        return puzzleColorTwo.toString();
      case HiddenRoutes.PUZZLETHREE:
        return puzzleColorThree.toString();
      case HiddenRoutes.PUZZLEFOUR:
        return puzzleColorFour.toString();
      case HiddenRoutes.PUZZLEFIVE:
        return puzzleColorFive.toString();
      case HiddenRoutes.PUZZLESIX:
        return puzzleColorSix.toString();
      case HiddenRoutes.PUZZLESEVEN:
        return puzzleColorSeven.toString();
      default:
        return theme.palette.global.white;
    }
  };

  return <StyledBodyContainer backgroundColor={defineBackgroundColor(pathname)}>{children}</StyledBodyContainer>;
}

const StyledBodyContainer = styled.div<{ backgroundColor: string }>`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
  background-color: ${({ backgroundColor }) => backgroundColor};
  height: 100%;
`;
