/* eslint-disable react-hooks/exhaustive-deps */
import { ReactElement, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { keyframes } from 'styled-components';

type Props = {
  currentPage: number;
  setButton(): void;
};

export default function StoryMessages(props: Props): ReactElement {
  const { currentPage, setButton } = props;
  const { t } = useTranslation();

  const messageGroup1 = [t('intro_screen.message1'), t('intro_screen.message2'), t('intro_screen.message3')];
  const messageGroup2 = [t('intro_screen.message4'), t('intro_screen.message5'), t('intro_screen.message6')];
  const messageGroup3 = [t('intro_screen.message7'), t('intro_screen.message8')];

  const [displayedMessages1, setDisplayedMessages1] = useState<string[]>([]);
  const [displayedMessages2, setDisplayedMessages2] = useState<string[]>([]);
  const [displayedMessages3, setDisplayedMessages3] = useState<string[]>([]);

  const smartphoneBottomElement = useRef<HTMLDivElement>(null);
  const smartphoneScollingContainer = useRef<HTMLDivElement>(null);
  const [showTypingAnimation, setShowTypingAnimation] = useState<boolean>(true);

  const scrollToBottom = (): void => {
    if (smartphoneScollingContainer.current && smartphoneBottomElement.current) {
      smartphoneScollingContainer.current.scrollTo({
        top: smartphoneBottomElement.current.offsetTop,
        behavior: 'smooth',
      });
    }
  };

  const renderMessagesWithDelay = (
    sourceArray: string[],
    displayArray: string[],
    setDisplayArray: React.Dispatch<React.SetStateAction<string[]>>,
  ): void => {
    for (let i = 0; i < sourceArray.length; i++) {
      setTimeout(function () {
        setDisplayArray((displayArray) => [...displayArray, sourceArray[i]]);
        setTimeout(function () {
          scrollToBottom();
        }, 300);
        if (i == sourceArray.length - 1) {
          setShowTypingAnimation(false);
          if (setButton != undefined) {
            setButton();
          }
        }
      }, 3500 * i + 1000);
    }
  };

  useEffect(() => {
    if (currentPage == 2) {
      renderMessagesWithDelay(messageGroup1, displayedMessages1, setDisplayedMessages1);
    }

    if (currentPage == 3) {
      renderMessagesWithDelay(messageGroup2, displayedMessages2, setDisplayedMessages2);
    }

    if (currentPage == 4) {
      renderMessagesWithDelay(messageGroup3, displayedMessages3, setDisplayedMessages3);
    }
    setShowTypingAnimation(true);
  }, [currentPage]);

  return (
    <>
      <StyledMessagesContainer ref={smartphoneScollingContainer}>
        {currentPage >= 2 && (
          <>
            {displayedMessages1.map((key, index) => (
              <StyledMessage isUserMessage={false} key={index}>
                {key}
              </StyledMessage>
            ))}
          </>
        )}

        {currentPage >= 3 && (
          <>
            <StyledMessage isUserMessage>{t('intro_screen.usermessage1')}</StyledMessage>
            {displayedMessages2.map((key, index) => (
              <StyledMessage isUserMessage={false} key={index}>
                {key}
              </StyledMessage>
            ))}
          </>
        )}

        {currentPage >= 4 && (
          <>
            <StyledMessage isUserMessage>{t('intro_screen.usermessage2')}</StyledMessage>
            {displayedMessages3.map((key, index) => (
              <StyledMessage isUserMessage={false} key={index}>
                {key}
              </StyledMessage>
            ))}
          </>
        )}

        {showTypingAnimation && (
          <StyledTypingBox>
            <StyledTypingDot />
            <StyledTypingDot />
            <StyledTypingDot />
          </StyledTypingBox>
        )}
        <StyledLastElement ref={smartphoneBottomElement} />
      </StyledMessagesContainer>
      <StyledFadeOverlay />
    </>
  );
}

const typing = keyframes`
  0% {
    opacity: 0;
  }
  60% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

const StyledMessagesContainer = styled.div`
  position: relative;
  width: 100%;
  height: 39rem;
  padding: 2rem 3rem 0rem;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  top: 3rem;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const StyledMessage = styled.div<{ isUserMessage: boolean }>`
  width: 100%;
  padding: ${({ isUserMessage }) => (isUserMessage ? '0.5rem 1.6rem' : '1.4rem 1.2rem')};
  background: ${({ isUserMessage }) => (isUserMessage ? '#41CBFF' : '#eff1f2')};
  margin: 0 0 1rem;
  font-size: 1.4rem;
  line-height: 2rem;
  position: relative;

  &:before {
    content: '';
    width: 1rem;
    height: 1rem;
    transform: translateX(-50%) rotate(45deg);
    position: absolute;
    background-color: ${({ isUserMessage }) => (isUserMessage ? '#41CBFF' : '#eff1f2')};
    left: ${({ isUserMessage }) => (isUserMessage ? '100%' : '0')};
    bottom: 1rem;
  }
`;

const StyledTypingBox = styled.div`
  padding: 1.6rem;
  background: #eff1f2;
  width: 6rem;
  display: flex;
  justify-content: space-between;
`;

const StyledLastElement = styled.div`
  width: 100%;
  height: 1rem;
  margin-top: 5rem;
`;

const StyledFadeOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 80%;
  height: 39rem;
  top: 3rem;
  left: 50%;
  transform: translateX(-50%);
  pointer-events: none;
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0) 2%,
    rgba(255, 255, 255, 0) 90%,
    rgba(255, 255, 255, 1) 100%
  );
`;

const StyledTypingDot = styled.div`
  background-color: #232323;
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
  display: inline-block;
  animation: ${typing} 1.5s ease-in-out infinite;

  &:nth-child(0) {
    animation-delay: 0;
  }
  &:nth-child(1) {
    animation-delay: 0.4s;
  }
  &:nth-child(2) {
    animation-delay: 0.8s;
  }
`;
