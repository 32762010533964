import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { NoMobile } from '../icons/Icons';

export default function NoMobileSection(): ReactElement {
  const { t } = useTranslation();

  return (
    <StyledContentContainer>
      <StyledNoMobile />
      <StyledNoMobileNotice>{t('landing_screen.nomobile_copy')}</StyledNoMobileNotice>
    </StyledContentContainer>
  );
}

const StyledContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.palette.company.primary};
  margin: 0 auto;
  padding: 5rem 3.1rem;
  text-align: center;
`;

const StyledNoMobileNotice = styled.p`
  font-size: 1.8rem;
  line-height: 2.8rem;
`;

const StyledNoMobile = styled(NoMobile)`
  margin: 0 auto 1.7rem;
`;
