import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import Smartphone from '../../assets/Smartphone.svg';
import SmartphoneIcons from '../../assets/Smartphone_Icons.svg';
import NuriaPfP from '../../assets/nuria.png';

type Props = {
  messageComponent: React.ReactNode;
  isIntro: boolean;
};

export default function StorySmartphone(props: Props): ReactElement {
  const { messageComponent, isIntro } = props;
  const { t } = useTranslation();

  return (
    <>
      {isIntro && (
        <StyledIntroductionContainer>
          <StyledIntroductionPicture src={NuriaPfP} />
          <StyledIntroductionTitle>{t('hint_system.nuria')}</StyledIntroductionTitle>
          <StyledIntrocutionDivider />
          <StyledIntroductionDesc>{t('intro_screen.nuria_desc')}</StyledIntroductionDesc>
        </StyledIntroductionContainer>
      )}
      <StyledSmartphone>
        {messageComponent}
        {!isIntro && (
          <>
            <StyledProfilePicture src={NuriaPfP} />
            <StyledName>{t('hint_system.nuria')}</StyledName>
            <StyledDivider />
          </>
        )}
        ;
        <StyledPhoneIcons src={SmartphoneIcons} />
      </StyledSmartphone>
    </>
  );
}

const StyledSmartphone = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 24.3rem;
  height: 45.6rem;
  background-image: url('${Smartphone}');
  background-size: contain;
  background-repeat: no-repeat;
`;

const StyledProfilePicture = styled.img`
  position: absolute;
  left: 6rem;
  transform: translateX(-50%);
  top: 4rem;
  width: 4rem;
  border-radius: 50%;
  pointer-events: none;
  transition: all 0.3s;
`;

const StyledName = styled.h3`
  position: absolute;
  left: 11rem;
  transform: translateX(-50%);
  top: 5rem;
  font-size: 1.8rem;
  font-weight: 900;
  pointer-events: none;
  transition: all 0.3s;
`;

const StyledPhoneIcons = styled.img`
  position: absolute;
  right: 3.4rem;
  top: 2rem;
  width: 3.9rem;
  opacity: 1;
  transition: opacity 0.3s;
`;

const StyledDivider = styled.div`
  position: absolute;
  top: 8.7rem;
  width: 67%;
  left: 50%;
  transform: translateX(-50%);
  height: 0.2rem;
  background-color: black;
`;

const StyledIntroductionContainer = styled.div`
  position: absolute;
  height: 30rem;
  width: 25rem;
  left: 50%;
  top: 50%;
  transform: translate(-37rem, -50%);
  background: linear-gradient(to right, #01b9ff40, #01b9ff40), linear-gradient(to right, #00000064, #00000064);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const StyledIntroductionPicture = styled.img`
  width: 12rem;
  margin: 0 0 1.7rem;
  border-radius: 50%;
`;

const StyledIntroductionTitle = styled.h2`
  color: white;
  text-align: center;
  margin: 0 0 0.8rem;
  font-size: 2.4rem;
  font-weight: 600;
`;

const StyledIntrocutionDivider = styled.div`
  width: 10rem;
  height: 0.3rem;
  background-color: white;
  margin: 0 0 2rem;
`;

const StyledIntroductionDesc = styled.p`
  color: white;
  font-size: 1.6rem;
  line-height: 2rem;
  text-align: center;
  margin: 0 0 2rem;
`;
