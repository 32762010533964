const PaletteTheme = {
  global: {
    white: '#ffffff',
    black: '#000000',
  },
  system: {
    success: '#46DC94',
    error: '#E42322',
  },
  company: {
    primary: '#41CBFF', //lightblue
    accentDark: '#D5D5D5', //darkgrey
    accentLight: '#EFEFEF', //lightgrey
    disabled: "#888888", //grey
    hover: {
      primary: '#01B9FF', //darkblue
    },
  }
};

export default PaletteTheme;
