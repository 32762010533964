import { ReactElement } from 'react';
import styled from 'styled-components';

import Info2 from '../../assets/icons/Info2.svg';

type Props = {
  text: string;
};

export default function DragInfoToast(props: Props): ReactElement {
  return <StyledToastContainer>{props.text}</StyledToastContainer>;
}

const StyledToastContainer = styled.div`
  background: rgba(0, 0, 0, 0.5);
  border-radius: 2rem;
  text-align: center;
  font-size: 1.6rem;
  color: #fff;
  padding: 0.6rem 3rem;
  display: inline-block;

  &:before {
    display: inline-block;
    content: '';
    width: 1.6rem;
    height: 1.6rem;
    background-image: url('${Info2}');
    background-size: contain;
    margin: 0 1.4rem 0 0rem;
    vertical-align: -0.2rem;
  }
`;
