import React, { ReactElement } from 'react';
import styled from 'styled-components';

import Logo from '../../assets/logo-elbphilharmonie.svg';
import LogoWhite from '../../assets/logo-elbphilharmonie-white.svg';
import LogoMobile from '../../assets/logo-elbphilharmonie-mobile.svg';
import LanguageToggle from '../interface/LanguageToggle';
import { Elbphilharmonie } from '../../types/FooterLinks.d';

type Props = {
  isSmallTablet: boolean;
  isTopBannerOpen: boolean;
  isFinalPage: boolean;
  isFadingIn: boolean;
};

Navbar.defaultProps = {
  isSmallTablet: false,
  isFinalPage: false,
  isFadingIn: true,
};

export default function Navbar(props: Props): ReactElement {
  const { isSmallTablet, isTopBannerOpen, isFinalPage, isFadingIn } = props;

  return (
    <StyledContainer isTopBannerOpen={isTopBannerOpen} isFinalPage={isFinalPage} isFadingIn={isFadingIn}>
      <StyledLogoContainer href={Elbphilharmonie.HOME} target="_blank">
        <img
          src={isFinalPage ? LogoWhite : isSmallTablet ? Logo : LogoMobile}
          alt="Elbphilharmonie Logo"
          width={'59'}
          height={'59'}
        />
      </StyledLogoContainer>
      <StyledNavlinksContainer>
        <LanguageToggle isWhite={isFinalPage ? true : isSmallTablet ? false : true} />
      </StyledNavlinksContainer>
    </StyledContainer>
  );
}

const StyledContainer = styled.header<{ isTopBannerOpen: boolean; isFinalPage: boolean; isFadingIn: boolean }>`
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: transparent;
  height: 7rem;
  padding: 0 4% 0;
  max-width: 144rem;
  margin: ${({ isTopBannerOpen }) => (isTopBannerOpen ? '4.5rem 0' : '0 auto')};
  background: '#dce6ee';
  opacity: ${({ isFadingIn }) => (isFadingIn ? 1 : 0)};
  transition: opacity 0.3s ease-in 3s;
  ${({ theme }) => theme.breakpoints.queries.sm} {
    height: 10rem;
    position: ${({ isFinalPage }) => (isFinalPage ? 'absolute' : 'sticky')};
    background: ${({ theme, isFinalPage }) => (isFinalPage ? 'transparent' : theme.palette.global.white)};
    padding: 0 4rem 0;
    margin: 0 auto;
    left: 0;
    right: 0;
  }
`;

const StyledLogoContainer = styled.a`
  width: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  justify-self: left;
  margin: auto 0;
  z-index: 2;
`;

const StyledNavlinksContainer = styled.nav`
  position: static;
  padding: 0;
  height: auto;
  width: auto;
  display: block;
`;
