import Person1 from '../../src/assets/puzzle6/avatars/Avatar_01.png';
import Person2 from '../../src/assets/puzzle6/avatars/Avatar_02.png';
import Person3 from '../../src/assets/puzzle6/avatars/Avatar_03.png';
import Person4 from '../../src/assets/puzzle6/avatars/Avatar_04.png';
import Person5 from '../../src/assets/puzzle6/avatars/Avatar_05.png';
import Person6 from '../../src/assets/puzzle6/avatars/Avatar_06.png';
import Person7 from '../../src/assets/puzzle6/avatars/Avatar_07.png';
import { GuestsClipboardType } from '../types/PuzzleSixDataType';

export const GuestsClipboardData: GuestsClipboardType[] = [
  {
    index: 1,
    guests: [
      {
        image: Person7,
        name: 'puzzle_six.clipboard.guest6.name',
        text: 'puzzle_six.clipboard.guest6.text',
      },
      {
        image: Person1,
        name: 'puzzle_six.clipboard.guest2.name',
        text: 'puzzle_six.clipboard.guest2.text',
      },
    ],
  },
  {
    index: 2,
    guests: [
      {
        image: Person2,
        name: 'puzzle_six.clipboard.guest3.name',
        text: 'puzzle_six.clipboard.guest3.text',
      },
    ],
  },
  {
    index: 3,
    guests: [
      {
        image: Person6,
        name: 'puzzle_six.clipboard.guest4.name',
        text: 'puzzle_six.clipboard.guest4.text',
      },
      {
        image: Person4,
        name: 'puzzle_six.clipboard.guest5.name',
        text: 'puzzle_six.clipboard.guest5.text',
      },
    ],
  },
  {
    index: 4,
    guests: [
      {
        image: Person3,
        name: 'puzzle_six.clipboard.guest1.name',
        text: 'puzzle_six.clipboard.guest1.text',
      },
      {
        image: Person5,
        name: 'puzzle_six.clipboard.guest7.name',
        text: 'puzzle_six.clipboard.guest7.text',
      },
    ],
  },
];
