import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import LanguageToggle from '../components/interface/LanguageToggle';
import ElphiLogo from '../assets/elphi_logo.png';
import WarningIcon from '../assets/warning_icon.svg';
import WarningBackground from '../assets/warning_bg.jpg';
import Button from '../components/common/Button';
import { ArrowRight } from '../components/icons/Icons';

export default function MobileWarnScreen(): ReactElement {
  const { t } = useTranslation();

  return (
    <StyledBlockContainer>
      <StyledWarningHeader>
        <StyledLogo src={ElphiLogo} />
        <LanguageToggle isWhite />
      </StyledWarningHeader>

      <StyledWarningIcon src={WarningIcon} />
      <StyledWarningTitle>{t('mobile_warn_screen.title')}</StyledWarningTitle>
      <StyledWarningCopy>{t('mobile_warn_screen.copy')}</StyledWarningCopy>
      <a href="https://www.elbphilharmonie.de">
        <Button
          label={t('mobile_warn_screen.button')}
          iconPosition="right"
          buttonIcon={<ArrowRight />}
          color="primary"
        />
      </a>
    </StyledBlockContainer>
  );
}

const StyledBlockContainer = styled.div`
  width: 100vw;
  height: 100vh;
  background: url('${WarningBackground}');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const StyledWarningHeader = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 8rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0rem 2rem;
`;

const StyledLogo = styled.img`
  width: 3rem;
  margin-left: 0.5rem;
`;

const StyledWarningIcon = styled.img`
  margin: 0 0 1.7rem;
`;

const StyledWarningTitle = styled.h2`
  font-size: 2rem;
  line-height: 2.5rem;
  color: white;
  text-align: center;
  font-weight: 700;
  text-transform: uppercase;
`;

const StyledWarningCopy = styled.p`
  font-size: 1.8rem;
  line-height: 2.8rem;
  text-align: center;
  font-weight: 400;
  color: white;
  width: 32rem;
  margin: 0 0 3rem;

  ${({ theme }) => theme.breakpoints.queries.sm} {
    width: 45rem;
  }
`;
