import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { keyframes } from 'styled-components';

import { Puzzle } from '../../types/Puzzle.d';
import ProgressBar from './ProgressBar';

type Props = {
  puzzleId: Puzzle;
  isPuzzleFinished: boolean;
};

export default function ProgressElement(props: Props): ReactElement {
  const { puzzleId, isPuzzleFinished } = props;
  const { t } = useTranslation();

  return (
    <StyledProgressContainer isPuzzleFinished={isPuzzleFinished}>
      <p>{t('intermission_screen.progress')}</p>
      <StyledProgressBarContainer>
        <ProgressBar isFilled={puzzleId > 1} isCurrent={isPuzzleFinished && puzzleId === Puzzle.ONE} posY={0} />
        <ProgressBar isFilled={puzzleId > 2} isCurrent={isPuzzleFinished && puzzleId === Puzzle.TWO} posY={2} />
        <ProgressBar isFilled={puzzleId > 3} isCurrent={isPuzzleFinished && puzzleId === Puzzle.THREE} posY={0} />
        <ProgressBar isFilled={puzzleId > 4} isCurrent={isPuzzleFinished && puzzleId === Puzzle.FOUR} posY={-2} />
        <ProgressBar isFilled={puzzleId > 5} isCurrent={isPuzzleFinished && puzzleId === Puzzle.FIVE} posY={2} />
        <ProgressBar isFilled={puzzleId > 6} isCurrent={isPuzzleFinished && puzzleId === Puzzle.SIX} posY={-1.5} />
        <ProgressBar isFilled={puzzleId > 7} isCurrent={isPuzzleFinished && puzzleId === Puzzle.SEVEN} posY={0.5} />
      </StyledProgressBarContainer>
    </StyledProgressContainer>
  );
}

const moveUp = keyframes`
  from { transform: translateY(100%); opacity: 0 }
  to { transform: translateY(0); opacity: 1 }
`;

const StyledProgressContainer = styled.div<{ isPuzzleFinished: boolean }>`
  position: absolute;
  bottom: 2rem;
  left: 7rem;
  transform: translateY(100%);
  opacity: 0;
  animation: ${moveUp} 0.5s ease-in-out ${({ isPuzzleFinished }) => (isPuzzleFinished ? '0s' : '12s')} forwards;

  & p {
    font-size: 1.4rem;
    color: white;
  }
`;

const StyledProgressBarContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 6rem 0 5rem;
`;
