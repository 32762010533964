import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';

type Props = {
  guests: { image: string; name: string; text: string }[];
};

export default function GuestsClipboardElement(props: Props): ReactElement {
  const { guests } = props;
  const { t } = useTranslation();
  return (
    <StyledGuestListInfoContainer>
      {guests.map((guest) => {
        return (
          <StyledGuestInfo key={uuidv4()}>
            <StyledGuestPhoto src={guest.image} alt="Guest image" />
            <StyledGuestText>
              <StyledName> {t(guest.name)} </StyledName>
              <StyledDescription> {t(guest.text)}</StyledDescription>
            </StyledGuestText>
          </StyledGuestInfo>
        );
      })}
    </StyledGuestListInfoContainer>
  );
}

const StyledGuestListInfoContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  align-content: center;
  align-items: flex-start;
  padding: 4.8rem 0 0;
`;

const StyledGuestInfo = styled.div`
  display: flex;
  text-align: left;
  padding: 1rem 0 0;
  margin: 0 2rem;
`;

const StyledGuestText = styled.div`
  margin: 0 1rem;
  max-width: 25rem;
`;

const StyledDescription = styled.p`
  font-size: 1.4rem;
`;

const StyledName = styled.p`
  font-size: 1.4rem;
  font-weight: 700;
  padding: 0 0 0.5rem 0;
`;

const StyledGuestPhoto = styled.img`
  width: 4.8rem;
  height: 4.8rem;
  margin: 0 0 0 2rem;
`;
