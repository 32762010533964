import { IntermissionData } from '../data/IntermissionData';
import { LandingData } from '../data/LandingData';
import { PuzzleData } from '../data/PuzzleData';
import { Puzzle } from '../types/Puzzle.d';

export default function useContent(contentType: string, dataId: string, puzzleId?: Puzzle): string | string[] {
  const puzzleIdAsIndex = puzzleId != undefined ? puzzleId - 1 : 0;

  const getDataType = (contentType: string): Array<object> => {
    switch (contentType) {
      case 'IntermissionData':
        return IntermissionData();
      case 'LandingData':
        return LandingData();
      case 'PuzzleData':
        return PuzzleData();
      default:
        return [];
    }
  };

  const getTargetValue = (): string | string[] | undefined => {
    const data = getDataType(contentType);

    if ((contentType === 'PuzzleData' || contentType === 'IntermissionData') && puzzleId) {
      const dataByPuzzle = data[puzzleIdAsIndex];
      if (dataByPuzzle === undefined) {
        return '--Data not found--';
      }

      for (const [key, value] of Object.entries(dataByPuzzle)) {
        if (dataId === key) {
          return value;
        }
      }
    } else {
      for (const [key, value] of Object.entries(data[0])) {
        if (dataId === key) {
          return value;
        }
      }
    }
  };

  const targetValue = getTargetValue();

  return targetValue === undefined ? '--Could not find string value--' : targetValue;
}
