import fagottIcon from '../assets/puzzle3/icons/fagott.svg';
import floeteIcon from '../assets/puzzle3/icons/floete.svg';
import harfeIcon from '../assets/puzzle3/icons/harfe.svg';
import paukeIcon from '../assets/puzzle3/icons/pauke.svg';
import pianoIcon from '../assets/puzzle3/icons/piano.svg';
import posauneIcon from '../assets/puzzle3/icons/posaune.svg';
import violineIcon from '../assets/puzzle3/icons/violine.svg';

import fagottSound from '../assets/puzzle3/audio/fagott.mp3';
import floeteSound from '../assets/puzzle3/audio/flute.mp3';
import harfeSound from '../assets/puzzle3/audio/harp.mp3';
import paukeSound from '../assets/puzzle3/audio/pauke.mp3';
import pianoSound from '../assets/puzzle3/audio/piano.mp3';
import posauneSound from '../assets/puzzle3/audio/posaune.mp3';
import violineSound from '../assets/puzzle3/audio/violin.mp3';
import { PuzzleThreeInstrument } from '../types/PuzzleThreeType';

export const instrumentsArrayEn: PuzzleThreeInstrument[] = [
  {
    index: 0,
    title: 'bassoon',
    letter: 'T',
    icon: fagottIcon,
    sound: fagottSound,
  },
  {
    index: 1,
    title: 'violin',
    letter: 'A',
    icon: violineIcon,
    sound: violineSound,
  },
  {
    index: 2,
    title: 'trombone',
    letter: 'M',
    icon: posauneIcon,
    sound: posauneSound,
  },

  {
    index: 3,
    title: 'piano',
    letter:'S',
    icon: pianoIcon,
    sound: pianoSound,
  },
  {
    index: 4,
    title: 'harp',
    letter: 'N',
    icon: harfeIcon,
    sound: harfeSound,
  },
  {
    index: 5,
    title: 'flute',
    letter: 'O',
    icon: floeteIcon,
    sound: floeteSound,
  },
  {
    index: 6,
    title: 'timpani',
    letter: 'I',
    icon: paukeIcon,
    sound: paukeSound,
  },
];

export const instrumentsArrayDe: PuzzleThreeInstrument[] = [
  {
    index: 0,
    title: 'Fagott',
    letter: 'T',
    icon: fagottIcon,
    sound: fagottSound,
  },
  {
    index: 1,
    title: 'Violine',
    letter: 'E',
    icon: violineIcon,
    sound: violineSound,
  },
  {
    index: 2,
    title: 'Posaune',
    letter: 'M',
    icon: posauneIcon,
    sound: posauneSound,
  },

  {
    index: 3,
    title: 'Klavier',
    letter: 'F',
    icon: pianoIcon,
    sound: pianoSound,
  },
  {
    index: 4,
    title: 'Harfe',
    letter: 'O',
    icon: harfeIcon,
    sound: harfeSound,
  },
  {
    index: 5,
    title: 'Querflöte',
    letter: 'L',
    icon: floeteIcon,
    sound: floeteSound,
  },
  {
    index: 6,
    title: 'Pauke',
    letter: 'I',
    icon: paukeIcon,
    sound: paukeSound,
  },
];
