import { ReactElement, useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { useNavigate } from 'react-router-dom';
import useContent from '../../hooks/useContent';
import tracking from '../../services/tracking';

import { Puzzle } from '../../types/Puzzle.d';
import { HiddenRoutes } from '../../types/Routes.d';
import { ArrowRight } from '../icons/Icons';
import { useTranslation } from 'react-i18next';

type Props = {
  puzzleId: Puzzle;
  onClose(): void;
};

export default function KeywordInput(props: Props): ReactElement {
  const { puzzleId, onClose } = props;
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [keywordInputValue, setKeywordInputValue] = useState<string>('');
  const [isErrorFeedbackActive, setIsErrorFeedbackActive] = useState<boolean>(false);

  const keywordPuzzleOne = useContent('PuzzleData', 'puzzleKeyword', Puzzle.ONE);
  const keyword2PuzzleOne = useContent('PuzzleData', 'puzzleKeyword2', Puzzle.ONE);
  const keywordPuzzleTwo = useContent('PuzzleData', 'puzzleKeyword', Puzzle.TWO);
  const keyword2PuzzleTwo = useContent('PuzzleData', 'puzzleKeyword2', Puzzle.TWO);
  const keywordPuzzleThree = useContent('PuzzleData', 'puzzleKeyword', Puzzle.THREE);
  const keyword2PuzzleThree = useContent('PuzzleData', 'puzzleKeyword2', Puzzle.THREE);
  const keywordPuzzleFour = useContent('PuzzleData', 'puzzleKeyword', Puzzle.FOUR);
  const keyword2PuzzleFour = useContent('PuzzleData', 'puzzleKeyword2', Puzzle.FOUR);
  const keywordPuzzleFive = useContent('PuzzleData', 'puzzleKeyword', Puzzle.FIVE);
  const keyword2PuzzleFive = useContent('PuzzleData', 'puzzleKeyword2', Puzzle.FIVE);
  const keywordPuzzleSix = useContent('PuzzleData', 'puzzleKeyword', Puzzle.SIX);
  const keyword2PuzzleSix = useContent('PuzzleData', 'puzzleKeyword2', Puzzle.SIX);
  const keywordPuzzleSeven = useContent('PuzzleData', 'puzzleKeyword', Puzzle.SEVEN);
  const keyword2PuzzleSeven = useContent('PuzzleData', 'puzzleKeyword2', Puzzle.SEVEN);

  const checkKeyword = (): void => {
    const keywordValue = keywordInputValue.toLowerCase().replace(/\s/g, '');

    switch (keywordValue) {
      case keywordPuzzleOne:
      case keyword2PuzzleOne:
        trackContinue(Puzzle.ONE);
        navigate(HiddenRoutes.PUZZLEONE, { replace: true });
        break;
      case keywordPuzzleTwo:
      case keyword2PuzzleTwo:
        trackContinue(Puzzle.TWO);
        navigate(HiddenRoutes.PUZZLETWO, { replace: true });
        break;
      case keywordPuzzleThree:
      case keyword2PuzzleThree:
        trackContinue(Puzzle.THREE);
        navigate(HiddenRoutes.PUZZLETHREE, { replace: true });
        break;
      case keywordPuzzleFour:
      case keyword2PuzzleFour:
        trackContinue(Puzzle.FOUR);
        navigate(HiddenRoutes.PUZZLEFOUR, { replace: true });
        break;
      case keywordPuzzleFive:
      case keyword2PuzzleFive:
        trackContinue(Puzzle.FIVE);
        navigate(HiddenRoutes.PUZZLEFIVE, { replace: true });
        break;
      case keywordPuzzleSix:
      case keyword2PuzzleSix:
        trackContinue(Puzzle.SIX);
        navigate(HiddenRoutes.PUZZLESIX, { replace: true });
        break;
      case keywordPuzzleSeven:
      case keyword2PuzzleSeven:
        trackContinue(Puzzle.SEVEN);
        navigate(HiddenRoutes.PUZZLESEVEN, { replace: true });
        break;
      default:
        setIsErrorFeedbackActive(true);
        break;
    }
  };

  const trackContinue = (targetPuzzle: Puzzle): void => {
    tracking.puzzleContinue(puzzleId, targetPuzzle);
  };

  useEffect(() => {
    if (isErrorFeedbackActive) {
      setTimeout(function () {
        setIsErrorFeedbackActive(false);
      }, 1000);
    }
  }, [isErrorFeedbackActive]);

  return (
    <StyledInputContainer>
      <StyledCloseButton onClick={() => onClose()} />
      <StyledKeywordDescription>{t('keyword_input_modal.description')}</StyledKeywordDescription>
      <StyledInputFieldContainer>
        <StyledKeywordInputField
          autoFocus
          value={keywordInputValue}
          onChange={(e) => setKeywordInputValue(e.target.value)}
          placeholder={t('keyword_input_modal.placeholder')}
          maxLength={13}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              checkKeyword();
            }
          }}
        />
        <StyledHashtag>#</StyledHashtag>
      </StyledInputFieldContainer>

      <StyledKeywordButton onClick={() => checkKeyword()} isError={isErrorFeedbackActive}>
        <p>
          {isErrorFeedbackActive ? t('keyword_input_modal.button_text_error') : t('keyword_input_modal.button_text')}
        </p>
        <ArrowRight />
      </StyledKeywordButton>
    </StyledInputContainer>
  );
}

const shake = keyframes`
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-0.5rem);
  }
  50% {
    transform: translateX(0.5rem);
  }
  75% {
    transform: translateX(-0.5rem);
  }
  100% {
    transform: translateX(0)
  }
`;

const StyledInputContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  justify-content: space-between;
`;

const StyledKeywordDescription = styled.p`
  width: 100%;
  font-size: 1.6rem;
  line-height: 2.2rem;
  margin: 0 1.5rem 1.5rem 0rem;
`;

const StyledKeywordInputField = styled.input`
  width: 20rem;
  height: 3.8rem;
  position: relative;
  padding: 0 0 0.1rem 2.8rem;
  border-radius: 0;
  font-size: 1.6rem;
  border: 0.2rem solid black;
  font-family: 'DIN Pro', sans-serif;

  &:focus {
    outline: none;
  }
`;

const StyledInputFieldContainer = styled.div`
  position: relative;
`;

const StyledHashtag = styled.p`
  font-size: 1.6rem;
  position: absolute;
  top: 50%;
  left: 1.4rem;
  transform: translateY(-50%);
  color: black;
`;

const StyledKeywordButton = styled.button<{ isError: boolean }>`
  height: 3.8rem;
  border: 0.2rem solid;
  cursor: pointer;
  font-family: 'DIN Pro', sans-serif;
  font-weight: 600;
  padding: 0 1.4rem;
  display: flex;
  align-items: center;
  position: relative;
  transition: ${({ isError }) => (isError ? 'background-color' : 'all')} 0.3s;

  background-color: ${({ isError }) => (isError ? '#E42322' : 'white')};
  color: ${({ isError }) => (isError ? 'white' : '#41cbff')};
  pointer-events: ${({ isError }) => (isError ? 'none' : 'all')};
  border-color: ${({ isError }) => (isError ? '#E42322' : '#41CBFF')};
  animation: ${({ isError }) => (isError ? shake : 'none')} 0.5s ease-in-out;

  p {
    margin: -0.2rem 0 0 0.5rem;
    transition: color 0.3s;
    pointer-events: none;
  }

  svg {
    margin: -0.3rem 0 0 0.5rem;
    display: ${({ isError }) => (isError ? 'none' : 'block')};

    path {
      fill: #41cbff;
    }
  }

  svg path {
    transition: fill 0.3s;
  }

  &:hover {
    background-color: ${({ isError }) => (isError ? '#E42322' : '#41cbff')};

    p {
      color: ${({ isError }) => (isError ? 'white' : 'black')};
    }

    svg path {
      fill: ${({ isError }) => (isError ? 'white' : 'black')};
    }
  }
`;

const StyledCloseButton = styled.div`
  width: 4rem;
  height: 4rem;
  position: absolute;
  top: -1rem;
  right: -1rem;
  cursor: pointer;
  z-index: 1;

  &:before {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    width: 0.2rem;
    height: 1.5rem;
    transform: translate(-50%, -50%) rotate(45deg);
    background-color: black;
    transition: transform 0.3s;
  }

  &:after {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    width: 0.2rem;
    height: 1.5rem;
    transform: translate(-50%, -50%) rotate(-45deg);
    background-color: black;
    transition: transform 0.3s;
  }
`;
