/* eslint-disable react-hooks/exhaustive-deps */
import { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';

import IntermissionTitleElement from '../interface/IntermissionTitleElement';
import ProgressElement from '../interface/ProgressElement';
import Button from '../common/Button';

import { Puzzle } from '../../types/Puzzle.d';
import { HiddenRoutes } from '../../types/Routes.d';

import { ArrowRight } from '../icons/Icons';
import ElphiLogo from '../../assets/Elphi.gif';
import PhoneMessages from '../interface/PhoneMessages';
import useContent from '../../hooks/useContent';
import ContentContainer from '../layout/ContentContainer';
import StorySmartphone from '../interface/StorySmartphone';

import BackgroundPuzzle1 from '../../assets/intermission/1_Lastenaufzug.jpg';
import BackgroundPuzzle2 from '../../assets/intermission/2_Orchesterplan.jpg';
import BackgroundPuzzle3 from '../../assets/intermission/3_Orchesteraufbau.jpg';
import BackgroundPuzzle4 from '../../assets/intermission/4_Garderobe.jpg';
import BackgroundPuzzle5 from '../../assets/intermission/5_Beleuchtung.jpg';
import BackgroundPuzzle6 from '../../assets/intermission/6_Gaeste.jpg';
import BackgroundPuzzle7 from '../../assets/intermission/7_Standort.jpg';

import IntermissionVideo1 from '../../assets/videos/1-Lastenaufzug.mp4';
import IntermissionVideo2 from '../../assets/videos/2-Orchesterplan.mp4';
import IntermissionVideo3 from '../../assets/videos/3-Orchesteraufbau.mp4';
import IntermissionVideo4 from '../../assets/videos/4-Garderobe.mp4';
import IntermissionVideo5 from '../../assets/videos/5-Beleuchtung.mp4';
import IntermissionVideo6 from '../../assets/videos/6-Gaeste.mp4';
import IntermissionVideo7 from '../../assets/videos/7-Standort.mp4';
import { motion, useAnimationControls } from 'framer-motion';

type Props = {
  puzzleId: Puzzle;
  startPuzzle(): void;
  resetIntermission(): void;
  isPuzzleFinished: boolean;
};

export default function IntermissionScene(props: Props): ReactElement {
  const { puzzleId, startPuzzle, resetIntermission, isPuzzleFinished } = props;
  const navigate = useNavigate();
  const { t } = useTranslation();
  const smartphoneControls = useAnimationControls();
  const intermissionScreenControls = useAnimationControls();

  const [reset, triggerReset] = useState<number>(0);
  const [isLoaderVisible, setIsLoaderVisible] = useState<boolean>(false);
  const [isContinueButtonDisabled, setIsContinueButtonDisabled] = useState<boolean>(true);
  const [videoStarted, setVideoStarted] = useState<boolean>(false);
  const [showMessages, setShowMessages] = useState<boolean>(false);

  const intermissionStartMessages = useContent('IntermissionData', 'introMessages', puzzleId) as string[];
  const intermissionFinishMessages = useContent('IntermissionData', 'finishMessages', puzzleId) as string[];

  const puzzleIdAsIndex = puzzleId - 1;

  const puzzleLinks = [
    HiddenRoutes.PUZZLETWO,
    HiddenRoutes.PUZZLETHREE,
    HiddenRoutes.PUZZLEFOUR,
    HiddenRoutes.PUZZLEFIVE,
    HiddenRoutes.PUZZLESIX,
    HiddenRoutes.PUZZLESEVEN,
    HiddenRoutes.COMPLETED,
  ];

  const puzzleBackgrounds = [
    BackgroundPuzzle1,
    BackgroundPuzzle2,
    BackgroundPuzzle3,
    BackgroundPuzzle4,
    BackgroundPuzzle5,
    BackgroundPuzzle6,
    BackgroundPuzzle7,
  ];

  const puzzleVideos = [
    IntermissionVideo1,
    IntermissionVideo2,
    IntermissionVideo3,
    IntermissionVideo4,
    IntermissionVideo5,
    IntermissionVideo6,
    IntermissionVideo7,
  ];

  const resetElements = (): void => {
    setIsContinueButtonDisabled(true);
    setVideoStarted(false);
    setShowMessages(false);
    resetIntermission();
  };

  const movetoPuzzle = (): void => {
    setIsContinueButtonDisabled(true);
    smartphoneControls.start({ y: -20, transition: { duration: 0.5 } });
    setTimeout(function () {
      smartphoneControls.start({ y: 1000, transition: { duration: 1.5 } });
    }, 500);
    setTimeout(function () {
      intermissionScreenControls.start({ opacity: 0, transition: { duration: 0.2 } });
    }, 1800);
    setTimeout(function () {
      startPuzzle();
    }, 2000);
  };

  const moveToNextScreen = (puzzleId: Puzzle): void => {
    setIsLoaderVisible(true);
    setTimeout(function () {
      navigate(puzzleLinks[puzzleId]);
      resetElements();
    }, 1000);
  };

  useEffect(() => {
    if (!isPuzzleFinished) {
      setIsLoaderVisible(true);
      setTimeout(function () {
        triggerReset(reset + 1);
        setIsLoaderVisible(false);
        setTimeout(function () {
          setVideoStarted(true);
          setTimeout(function () {
            setShowMessages(true);
          }, 12000);
        }, 2000);
      }, 3000);
    }
  }, [isPuzzleFinished]);

  return (
    <StyledIntermissionMotionDiv animate={intermissionScreenControls}>
      <StyledBackground src={puzzleBackgrounds[puzzleIdAsIndex]} />
      {!isPuzzleFinished && (
        <StyledVideoContainer>
          {videoStarted && (
            <StyledVideo muted autoPlay key={reset + 10} isPuzzleFinished={isPuzzleFinished}>
              <source src={puzzleVideos[puzzleIdAsIndex]} type="video/mp4" />
            </StyledVideo>
          )}
          <StyledBlackCover isVideoStarted={videoStarted} />
        </StyledVideoContainer>
      )}
      <ContentContainer>
        <StyledIntermissionContainer>
          {!isLoaderVisible && (
            <IntermissionTitleElement key={reset + 100} puzzleId={puzzleId} isPuzzleFinished={isPuzzleFinished} />
          )}
          <StyledMotionDiv animate={smartphoneControls}>
            <StyledCenterElement key={reset + 1000} isPuzzleFinished={isPuzzleFinished}>
              <StorySmartphone
                messageComponent={
                  showMessages || isPuzzleFinished ? (
                    <PhoneMessages
                      key={reset}
                      isIntermission
                      messageArray={isPuzzleFinished ? intermissionFinishMessages : intermissionStartMessages}
                      setButton={() => setIsContinueButtonDisabled(false)}
                    />
                  ) : (
                    <></>
                  )
                }
                isIntro={false}
              />
            </StyledCenterElement>
          </StyledMotionDiv>

          <ProgressElement key={reset + 10000} puzzleId={puzzleId} isPuzzleFinished={isPuzzleFinished} />
          <StyledContinueButtonWrapper
            key={reset + 100000}
            isPuzzleFinished={isPuzzleFinished}
            isDisabled={isContinueButtonDisabled}
            onClick={isPuzzleFinished ? () => moveToNextScreen(puzzleIdAsIndex) : () => movetoPuzzle()}
          >
            <Button
              label={isPuzzleFinished ? t('intermission_screen.button_finish') : t('intermission_screen.button_start')}
              color="primary"
              buttonIcon={<ArrowRight />}
              iconPosition={'right'}
              disabled={isContinueButtonDisabled}
            />
          </StyledContinueButtonWrapper>
        </StyledIntermissionContainer>
      </ContentContainer>
      <StyledFakeLoader isVisible={isLoaderVisible} />
    </StyledIntermissionMotionDiv>
  );
}

const moveUp = keyframes`
  from { transform: translateY(100%); opacity: 0 }
  to { transform: translateY(0); opacity: 1 }
`;

const smartphoneMoveUp = keyframes`
  from { transform: translateY(100rem); opacity: 0 }
  to { transform: translateY(0rem); opacity: 1 }
`;

const blur = keyframes`
  from {filter: blur(0rem)}
  to {filter: blur(1rem)}
`;

const StyledIntermissionMotionDiv = styled(motion.div)`
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
`;

const StyledBackground = styled.img`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`;

const StyledIntermissionContainer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
`;

const StyledContinueButtonWrapper = styled.div<{ isDisabled: boolean; isPuzzleFinished: boolean }>`
  position: absolute;
  right: 7rem;
  bottom: 3rem;
  cursor: pointer;
  pointer-events: ${({ isDisabled }) => (isDisabled ? 'none' : 'all')};
  transform: translateY(100%);
  opacity: 0;
  animation: ${moveUp} 0.5s ease-in-out ${({ isPuzzleFinished }) => (isPuzzleFinished ? '0s' : '12s')} forwards;

  svg {
    margin: 0 0 0 0.5rem;
  }

  svg path {
    fill: black;
  }
`;

const StyledFakeLoader = styled.div<{ isVisible: boolean }>`
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: black;
  z-index: 100;
  opacity: ${({ isVisible }) => (isVisible ? '1' : '0')};
  transition: opacity 1s ease-in-out;
  pointer-events: ${({ isVisible }) => (isVisible ? 'all' : 'none')};

  &:after {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 11rem;
    height: 15rem;
    background-image: url('${ElphiLogo}');
    background-size: 90%;
    background-position: center;
    background-repeat: no-repeat;
  }
`;

const StyledMotionDiv = styled(motion.div)`
  position: absolute;
  width: 1px;
  height: 1px;
  left: 50%;
  top: 50%;
`;

const StyledCenterElement = styled.div<{ isPuzzleFinished: boolean }>`
  position: absolute;
  width: 1px;
  height: 1px;
  left: 50%;
  top: 50%;
  transform: translateY(100rem);
  animation: ${smartphoneMoveUp} 2s ease-in-out ${({ isPuzzleFinished }) => (isPuzzleFinished ? '0s' : '12s')} forwards;
`;

const StyledVideoContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
`;

const StyledBlackCover = styled.div<{ isVideoStarted: boolean }>`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: ${({ isVideoStarted }) => (isVideoStarted ? '0' : '1')};
  background-color: black;
  transition: opacity 3s;
`;

const StyledVideo = styled.video<{ isPuzzleFinished: boolean }>`
  width: auto;
  object-fit: cover;
  width: 100%;
  height: 100%;
  background-color: black;
  animation: ${blur} 1s ease-in-out 9s forwards;
  display: ${({ isPuzzleFinished }) => (isPuzzleFinished ? 'none' : 'block')};
`;
