export function getBrowserName(): 'firefox' | 'other' {
  const userAgent = navigator.userAgent;
  let browserName: 'firefox' | 'other';

  if (userAgent.match(/firefox|fxios/i)) {
    browserName = 'firefox';
  } else {
    browserName = 'other';
  }
  return browserName;
}
