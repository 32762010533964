import { ReactElement, useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Puzzle } from '../../types/Puzzle.d';
import PuzzleOverlay from '../interface/PuzzleOverlay';
import DraggablePins from '../puzzleelements/DraggablePins';
import Button from '../common/Button';

import MapNoGridImageDE from '../../assets/puzzle2/map_de.jpg';
import MapGridImageDE from '../../assets/puzzle2/map_grid_de.jpg';
import MapNoGridImageEN from '../../assets/puzzle2/map_en.jpg';
import MapGridImageEN from '../../assets/puzzle2/map_grid_en.jpg';

import Background from '../../assets/puzzle2/puzzle2_bg.png';
import PuzzleNoteDE from '../../assets/puzzle2/puzzle2_note_de.png';
import PuzzleNoteEN from '../../assets/puzzle2/puzzle2_note_en.png';

import Room1 from '../../assets/puzzle2/room1.jpg';
import Room3 from '../../assets/puzzle2/room3.jpg';
import Room5 from '../../assets/puzzle2/room5.jpg';
import Room6 from '../../assets/puzzle2/room6.jpg';
import Room9 from '../../assets/puzzle2/room9.jpg';
import Room10 from '../../assets/puzzle2/room10.jpg';
import Room15 from '../../assets/puzzle2/room15.jpg';
import Room22 from '../../assets/puzzle2/room22.jpg';
import i18next from 'i18next';
import { ArrowLeft } from '../icons/Icons';
import Visible from '../../assets/icons/Visible.svg';
import Invisible from '../../assets/icons/Invisible.svg';

type Props = {
  finishPuzzle(): void;
};

export default function PuzzleTwo(props: Props): ReactElement {
  const { finishPuzzle } = props;
  const { t } = useTranslation();
  const puzzleId = Puzzle.TWO;

  const [isRoomShown, setIsRoomShown] = useState<boolean>(false);
  const [isGridShown, setIsGridShown] = useState<boolean>(false);
  const [currentRoomImage, setCurrentRoomImage] = useState<string>('');
  const [currentRoomNumber, setCurrentRoomNumber] = useState<string>('');

  const openRoom = (roomImage: string, roomNumber: string): void => {
    setIsRoomShown(true);
    setCurrentRoomImage(roomImage);
    setCurrentRoomNumber(roomNumber);
    const lineElements = Array.from(document.getElementsByClassName('pin-line') as HTMLCollectionOf<HTMLElement>);
    lineElements.forEach(function (element) {
      const pinLine = element;
      pinLine.style.opacity = '0';
    });
  };

  const closeRoom = (): void => {
    setIsRoomShown(false);
    const lineElements = Array.from(document.getElementsByClassName('pin-line') as HTMLCollectionOf<HTMLElement>);
    lineElements.forEach(function (element) {
      const pinLine = element;
      pinLine.style.opacity = '1';
    });
  };

  const roomButtons = [
    { id: 0, posX: 35.7, posY: 60, sizeX: 6, sizeY: 15.9, rotation: 35, room: Room1, number: '1' },
    { id: 1, posX: 26.5, posY: 50.5, sizeX: 6, sizeY: 15.9, rotation: 35, room: Room3, number: '3' },
    { id: 2, posX: 17.3, posY: 41, sizeX: 6, sizeY: 15.9, rotation: 35, room: Room5, number: '5' },
    { id: 3, posX: 26, posY: 7.5, sizeX: 6, sizeY: 15.9, rotation: 4, room: Room6, number: '6' },
    { id: 4, posX: 43, posY: 9.3, sizeX: 11.2, sizeY: 11.7, rotation: 4, room: Room9, number: '9' },
    { id: 5, posX: 54, posY: 10.6, sizeX: 11.2, sizeY: 11.7, rotation: 4, room: Room10, number: '10' },
    { id: 6, posX: 75.5, posY: 13.5, sizeX: 22, sizeY: 26.5, rotation: 4, room: Room15, number: '15' },
    { id: 7, posX: 51.3, posY: 66, sizeX: 8, sizeY: 8, rotation: 34, room: Room22, number: '22' },
  ];

  const generateRoomButtons = roomButtons.map((key) => (
    <StyledRoomButton
      key={key.id}
      posX={key.posX}
      posY={key.posY}
      sizeX={key.sizeX}
      sizeY={key.sizeY}
      rotation={key.rotation}
      onClick={() => openRoom(key.room, key.number)}
    />
  ));

  const cacheImages = (imageArray: string[]): void => {
    imageArray.map((src) => {
      const img = new Image();
      img.src = src;
    });
  };

  useEffect(() => {
    const preRenderImages = [Room1, Room3, Room5, Room6, Room9, Room10, Room15, Room22];

    cacheImages(preRenderImages);
  }, []);

  const renderMap = (): string => {
    if (isGridShown) {
      return i18next.language.slice(0, 2) == 'de' ? MapGridImageDE : MapGridImageEN;
    } else {
      return i18next.language.slice(0, 2) == 'de' ? MapNoGridImageDE : MapNoGridImageEN;
    }
  };

  return (
    <PuzzleOverlay
      puzzleId={puzzleId}
      finishPuzzle={finishPuzzle}
      hideSolutionButton={isRoomShown}
      backgroundImage={Background}
    >
      {isRoomShown && (
        <StyledRoomView>
          <StyledRoomImage src={currentRoomImage} />
          <StyledCloseButtonContainer>
            <Button
              iconPosition="left"
              buttonIcon={<ArrowLeft />}
              color="primary"
              label={t('puzzle_two.back')}
              onClick={() => closeRoom()}
            />
          </StyledCloseButtonContainer>
        </StyledRoomView>
      )}

      <StyledMapContainer isHidden={isRoomShown}>
        <StyledMapImageWrapper>
          <StyledGridToggle
            gridToggleIcon={isGridShown ? Invisible : Visible}
            onClick={() => setIsGridShown(!isGridShown)}
          />
          <StyledMapImage src={renderMap()} />
        </StyledMapImageWrapper>
        {generateRoomButtons}
        <DraggablePins />
      </StyledMapContainer>

      {isRoomShown ? (
        <StyledRoomNumber>
          <b>{t('puzzle_two.floor_name')}</b>
          {t('puzzle_two.room_name')}
          {currentRoomNumber}
        </StyledRoomNumber>
      ) : (
        <StyledPuzzleNote src={i18next.language.slice(0, 2) == 'de' ? PuzzleNoteDE : PuzzleNoteEN} />
      )}
    </PuzzleOverlay>
  );
}

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

const StyledMapContainer = styled.div<{ isHidden: boolean }>`
  left: 30vw;
  top: 50%;
  transform: translateY(-47%);
  position: absolute;
  z-index: 1;
  opacity: ${({ isHidden }) => (isHidden ? '0' : '1')};
  pointer-events: ${({ isHidden }) => (isHidden ? 'none' : 'all')};

  ${({ theme }) => theme.breakpoints.queries.xl} {
    left: 30%;
  }
`;

const StyledMapImageWrapper = styled.div`
  transform: rotate(4deg);
`;

const StyledMapImage = styled.img`
  width: 64rem;

  ${({ theme }) => theme.breakpoints.queries.lg} {
    width: 74rem;
  }

  ${({ theme }) => theme.breakpoints.queries.xl} {
    width: 93rem;
  }
`;

const StyledRoomButton = styled('div')<{ posX: number; posY: number; sizeX: number; sizeY: number; rotation: number }>(
  ({ posX, posY, sizeX, sizeY, rotation }) => ({
    '&': {
      position: 'absolute',
      top: `${posY}%`,
      left: `${posX}%`,
      width: `${sizeX}%`,
      height: `${sizeY}%`,
      transform: `rotate(${rotation}deg)`,
      cursor: 'pointer',
    },
  }),
);

const StyledRoomView = styled.div`
  overflow: hidden;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 30%;
  z-index: 1;
  width: 70rem;
  height: 40rem;
  background-color: black;
  filter: drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.25));

  ${({ theme }) => theme.breakpoints.queries.xl} {
    width: 103rem;
    height: 60rem;
    left: 25%;
  }
`;

const StyledRoomImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0;
  animation: ${fadeIn} 0.3s ease-out forwards;
`;

const StyledCloseButtonContainer = styled.div`
  position: absolute;
  top: 1rem;
  left: 2rem;
  ${({ theme }) => theme.breakpoints.queries.xl} {
    top: 3rem;
    left: 3rem;
  }
`;

const StyledPuzzleNote = styled.img`
  position: absolute;
  left: 2rem;
  top: 11rem;
  width: 25rem;
  z-index: 1;

  ${({ theme }) => theme.breakpoints.queries.xl} {
    left: 7rem;
    top: 14rem;
  }
`;

const StyledGridToggle = styled.div<{ gridToggleIcon: string }>`
  position: absolute;
  width: 2.8rem;
  height: 2.8rem;
  background-color: ${({ theme }) => theme.palette.company.primary};
  transition: background-color 0.3s;
  border-radius: 50%;
  filter: drop-shadow(0.2rem 0.2rem 0.5rem rgba(0, 0, 0, 0.45));
  cursor: pointer;
  top: 6%;
  left: 3%;

  &:after {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    width: 60%;
    height: 60%;
    transform: translate(-50%, -50%);
    background-image: url('${({ gridToggleIcon }) => gridToggleIcon}');
    background-size: 98%;
    background-position: center;
    background-repeat: no-repeat;
  }

  &:hover {
    background-color: ${({ theme }) => theme.palette.company.hover.primary};
  }

  ${({ theme }) => theme.breakpoints.queries.lg} {
    width: 3rem;
    height: 3rem;
  }

  ${({ theme }) => theme.breakpoints.queries.xl} {
    width: 4rem;
    height: 4rem;
  }
`;

const StyledRoomNumber = styled.p`
  position: absolute;
  left: 2rem;
  top: 13rem;
  font-size: 2rem;
  color: white;
  text-transform: uppercase;
  z-index: 1;

  b {
    font-weight: 600;
  }

  ${({ theme }) => theme.breakpoints.queries.xl} {
    left: 7rem;
    top: 16rem;
  }
`;
