import { ReactElement, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { Puzzle } from '../../types/Puzzle.d';

import LanguageToggle from './LanguageToggle';
import KeywordInput from './KeywordInput';
import { useTranslation } from 'react-i18next';
import { Info, Switch } from '../icons/Icons';
import Manual from './Manual';

type Props = {
  puzzleId: Puzzle;
};

export default function PuzzleOptionsContainer(props: Props): ReactElement {
  const { puzzleId } = props;

  const { t } = useTranslation();
  const isIntro = puzzleId == 0;

  const [isKeywordInputOpen, setIsKeywordInputOpen] = useState<boolean>(false);
  const [isManualOpen, setIsManualOpen] = useState<boolean>(isIntro);

  return (
    <StyledOptionsContainer>
      <LanguageToggle isWhite />
      <>
        <StyledOptionText isActive={isKeywordInputOpen} onClick={() => setIsKeywordInputOpen(true)}>
          <Switch />
          <p>{t('puzzle_screen.switch')}</p>
        </StyledOptionText>
        {isKeywordInputOpen && (
          <>
            <StyledCloseOverlay onClick={() => setIsKeywordInputOpen(false)} />
            <StyledKeywordModal>
              <KeywordInput puzzleId={puzzleId} onClose={() => setIsKeywordInputOpen(false)} />
            </StyledKeywordModal>
          </>
        )}
      </>
      <StyledOptionText isActive={false} onClick={() => setIsManualOpen(true)}>
        <Info />
        <p>{t('puzzle_screen.manual')}</p>
      </StyledOptionText>
      {isManualOpen && (
        <>
          <StyledCloseOverlay onClick={() => setIsManualOpen(false)} />
          <StyledManualModal>
            <Manual onClose={() => setIsManualOpen(false)} />
          </StyledManualModal>
        </>
      )}
    </StyledOptionsContainer>
  );
}

const fadeIn = keyframes`
  from { opacity: 0 }
  to { opacity: 0.8 }
`;

const fadePanInKeyword = keyframes`
  from { opacity: 0; transform: translate(-50%, 1rem); }
  to { opacity: 1; transform: translate(-50%, 0); }
`;

const fadePanInManual = keyframes`
  from { opacity: 0; transform: translateY(1rem); }
  to { opacity: 1; transform: translateY(0); }
`;

const StyledOptionsContainer = styled.div`
  position: absolute;
  top: 2rem;
  right: 2rem;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  z-index: 5;

  ${({ theme }) => theme.breakpoints.queries.xl} {
    top: 4rem;
    right: 7rem;
  }
`;

const StyledOptionText = styled.div<{ isActive: boolean }>`
  color: white;
  font-size: 1.6rem;
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  margin: 0 1.5rem;
  text-shadow: 0rem 0rem 1rem #00000088;
  color: ${({ isActive }) => (isActive ? '#41CBFF' : 'white')};

  svg {
    filter: drop-shadow(0rem 0rem 1rem #00000088);
  }

  p {
    margin: 0.3rem 0 0 1rem;
  }

  svg path {
    fill: ${({ isActive }) => (isActive ? '#41CBFF' : 'white')};
  }
`;

const StyledKeywordModal = styled.div`
  position: absolute;
  left: 50%;
  transform: translate(-50%, 1rem);
  opacity: 0;
  top: calc(100% + 2rem);
  width: 38rem;
  background-color: white;
  z-index: 6;
  pointer-events: all;
  padding: 2rem;
  animation: ${fadePanInKeyword} 0.3s ease-in-out 0.3s forwards;

  &:before {
    content: '';
    width: 1.6rem;
    height: 1.6rem;
    transform: translateX(-50%) rotate(45deg);
    position: absolute;
    background-color: white;
    left: 50%;
    top: -0.8rem;
  }
`;

const StyledManualModal = styled.div`
  position: absolute;
  right: 0;
  transform: translateY(1rem);
  opacity: 0;
  top: calc(100% + 2rem);
  width: 62rem;
  height: 40rem;
  background-color: white;
  z-index: 6;
  pointer-events: all;
  padding: 5rem 0rem 0rem 4rem;
  animation: ${fadePanInManual} 0.3s ease-in-out 0.3s forwards;

  &:before {
    content: '';
    width: 1.6rem;
    height: 1.6rem;
    transform: rotate(45deg);
    position: absolute;
    background-color: white;
    left: 49%;
    top: -0.8rem;
  }

  ${({ theme }) => theme.breakpoints.queries.xl} {
    width: 85rem;
    height: 50rem;

    &:before {
      left: 60%;
    }
  }
`;

const StyledCloseOverlay = styled.div`
  position: fixed;
  top: -100vh;
  left: -100vw;
  width: 300vw;
  height: 300vh;
  pointer-events: all;
  z-index: 5;
  opacity: 0;
  background-color: black;
  animation: ${fadeIn} 0.3s ease-in-out forwards;
`;
