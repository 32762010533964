import { ReactElement, useEffect, useState } from 'react';
import { Puzzle } from '../../types/Puzzle.d';
import PuzzleOverlay from '../interface/PuzzleOverlay';

import { Pannellum } from 'pannellum-react';
import DragInfoToast from '../puzzleelements/DragInfoToastPano';
import styled from 'styled-components';
import DragAndDropCards from '../puzzleelements/DragAndDropCards';
import { PuzzleSevenData, PuzzleSevenPanoViewData } from '../../data/PuzzleSevenData';
import { PuzzleSevenPanoViewHotspotType, PuzzleSevenPanoViewType, PuzzleSevenType } from '../../types/PuzzleSevenType';
import Background from '../../assets/puzzle7/background.jpg';
import InfoStickyDe from '../../assets/puzzle7/Notiz_DE.png';
import InfoStickyEn from '../../assets/puzzle7/Notiz_EN.png';
import CardDe from '../../assets/puzzle7/Karte_DE.jpg';
import CardEn from '../../assets/puzzle7/Karte_EN.jpg';
import navigationBackground from '../../assets/puzzle7/Kompass.png';
import useContent from '../../hooks/useContent';
import { getI18n, useTranslation } from 'react-i18next';
import EyeIcon from '../../assets/icons/EyeW.svg';
import ArrowLeft from '../../assets/icons/ArrowLeftW.svg';
import Close from '../../assets/icons/Close.svg';

type Props = {
  finishPuzzle(): void;
};

export default function PuzzleSeven(props: Props): ReactElement {
  const { finishPuzzle } = props;
  const puzzleId = Puzzle.SEVEN;
  const [panoramaView, setPanoramaView] = useState<PuzzleSevenPanoViewType>();
  const [dndItems, setDndItems] = useState(PuzzleSevenData);
  const [isDndSolutionCorrect, setIsDndSolutionCorrect] = useState(false);
  const [infoSticky, setInfoSticky] = useState(InfoStickyDe);
  const [card, setCard] = useState(CardDe);
  const [hideSolutionButton, setHideSolutionButton] = useState(false);
  const [isFinished, setIsFinished] = useState(false);
  const { t } = useTranslation();

  const navigationButtons = PuzzleSevenPanoViewData();

  useEffect(() => {
    switch (getI18n().language.trim().toLowerCase().slice(0, 2)) {
      case 'de':
        setInfoSticky(InfoStickyDe);
        setCard(CardDe);
        break;
      default:
        setInfoSticky(InfoStickyEn);
        setCard(CardEn);
        break;
    }

    setPanoramaView(undefined);
  }, [t]);

  useEffect(() => {
    setHideSolutionButton(panoramaView ? true : false);
  }, [panoramaView]);

  const toggleLookAround = (): void => {
    if (panoramaView) {
      setPanoramaView(undefined);
    } else {
      setPanoramaView(navigationButtons[0]);
    }
  };

  const openMap = (panoramaView: PuzzleSevenPanoViewType): void => {
    setPanoramaView(panoramaView);
  };

  const generateHotSpot = (hotspot: PuzzleSevenPanoViewHotspotType): ReactElement => {
    return (
      <Pannellum.Hotspot
        type="custom"
        key={hotspot.id}
        pitch={hotspot.posY}
        yaw={hotspot.posX * 0.8333}
        tooltip={createTooltip}
        tooltipArg={hotspot}
      ></Pannellum.Hotspot>
    );
  };

  const generateNavButtons = navigationButtons.map((key) => (
    <StyledNavigationButton
      isActive={panoramaView?.id === key.id}
      key={key.id}
      posX={key.posX}
      posY={key.posY}
      onClick={() => openMap(key)}
    >
      {key.buttonTitle}
    </StyledNavigationButton>
  ));

  const delay = (ms: number): Promise<void> => new Promise((res) => setTimeout(res, ms));

  const createTooltip = (
    hotSpotDiv: {
      classList: { add: (arg0: string) => void };
      appendChild: (arg0: HTMLSpanElement) => void;
      offsetWidth: number;
    },
    args: PuzzleSevenPanoViewHotspotType,
  ): void => {
    hotSpotDiv.classList.add('custom-tooltip');
    hotSpotDiv.classList.add('custom-tooltip-' + args.id);
    const span = document.createElement('span');

    span.innerHTML = `
    <input onmousedown="
    (function(){
      document.querySelectorAll('.chkhidden').forEach(element => element.checked = false);

      document.querySelectorAll('.chkhidden').forEach(element => element.parentNode.parentNode.classList.remove('active'))

      document.querySelector('.custom-tooltip-${args.id}').classList.add('active')

    })(); return false;" type="checkbox" id="dot${args.id}" class="chkhidden">

    <div class="tooltip-content ${args.bottomLayout ? 'bottomLayout' : 'topLayout'}">
      <label for="dot${args.id}" >
          <div class="close"></div>
            <div class="contentwrap">
            <div class="title">${args.title}</div>
            <div class="content">${args.text}</div>
          </div>
      </label>
    </div>
    `;

    hotSpotDiv.appendChild(span);
  };

  const resolveAndFinish = async (): Promise<void> => {
    setIsFinished(true);
    setHideSolutionButton(true);

    await delay(6000);

    setIsFinished(false);
    setHideSolutionButton(false);
    finishPuzzle();
  };

  function handleDragAndDropChange(items: PuzzleSevenType[]): void {
    setDndItems(items);
  }

  const userArray = dndItems;
  const simplifiedUserArray = userArray.map(({ id, ...rest }) => {
    return rest;
  });
  const resultArray = useContent('PuzzleData', 'puzzleCode', puzzleId);

  useEffect(() => {
    if (JSON.stringify(resultArray) === JSON.stringify(simplifiedUserArray)) {
      setIsDndSolutionCorrect(true);
    } else {
      setIsDndSolutionCorrect(false);
    }
  }, [isDndSolutionCorrect, simplifiedUserArray, resultArray]);

  return (
    <PuzzleOverlay
      hideSolutionButton={hideSolutionButton}
      backgroundImage={Background}
      puzzleId={puzzleId}
      finishPuzzle={resolveAndFinish}
      isDndPuzzleSolved={isDndSolutionCorrect}
    >
      {panoramaView === undefined ? (
        <div>
          <StyledInfoSticky backgroundImage={infoSticky}>
            {!isFinished && (
              <StyledLookAroundButton onClick={toggleLookAround}>{t('puzzle_seven.lookaround')}</StyledLookAroundButton>
            )}
          </StyledInfoSticky>
          <StyledPuzzleContainer className={isFinished ? 'finished' : ''}>
            <DragAndDropCards
              handleDragAndDropChange={handleDragAndDropChange}
              items={dndItems}
              finishPuzzle={finishPuzzle}
              puzzleId={puzzleId}
            />
            <StyledCardContainer backgroundImage={card}></StyledCardContainer>
          </StyledPuzzleContainer>
        </div>
      ) : (
        <div>
          <StyledNavigationWrapper>
            <StyledNavigationInfo>
              <StyledNavigationInfoTitle>{panoramaView.title}</StyledNavigationInfoTitle>
              {panoramaView.subtile}
            </StyledNavigationInfo>
            <StyledNavigationButtons>{generateNavButtons}</StyledNavigationButtons>
          </StyledNavigationWrapper>
          <StyledPanoramaContainer>
            <StyledLPuzzleButton onClick={toggleLookAround}>{t('puzzle_seven.to_map')}</StyledLPuzzleButton>
            <StyledDragInfoToast>
              <DragInfoToast text={t('puzzle_seven.info_text')}></DragInfoToast>
            </StyledDragInfoToast>

            <Pannellum
              type="equirectangular"
              width="100%"
              height="100%"
              image={panoramaView.panorama}
              pitch={0}
              minPitch={0}
              maxPitch={0}
              yaw={0}
              minYaw={-50}
              maxYaw={50}
              hfov={70}
              vaov={38}
              haov={100}
              maxHfov={60}
              minHfov={60}
              autoLoad
              showZoomCtrl={false}
              showFullscreenCtrl={false}
              showControls={false}
              author=""
              title=""
              orientationOnByDefault={false}
              draggable
              keyboardZoom
              mouseZoom
              hotSpotDebug={false}
              dynamicUpdate={true}
              preview=""
              previewAuthor=""
              previewTitle=""
            >
              {panoramaView?.hotspots.map((hotspot) => generateHotSpot(hotspot))}
            </Pannellum>
          </StyledPanoramaContainer>
        </div>
      )}
    </PuzzleOverlay>
  );
}

const StyledDragInfoToast = styled.div`
  width: 100%;
  height: auto;
  z-index: 2;
  text-align: center;
  position: absolute;
  bottom: 3rem;
`;

const StyledCardContainer = styled.div<{ backgroundImage: string }>`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: url('${(props) => props.backgroundImage}') center center no-repeat;
  background-size: contain;
`;

const StyledPuzzleContainer = styled.div`
  z-index: 1;
  max-width: 100%;
  margin: 0 auto;
  position: absolute;
  left: 60%;
  top: 48%;
  background: transparent;
  transform: translate(-50%, -50%);

  @keyframes rotateOut {
    0% {
      transform: rotateY(0deg);
      opacity: 1;
    }
    100% {
      transform: rotateY(90deg);
      opacity: 0;
    }
  }

  @keyframes fadeOut {
    0% {
      display: block;
    }
    99% {
      opacity: 0;
      display: block;
    }
    100% {
      opacity: 0;
      pointer-events: none;
    }
  }

  div[role='button'] {
    transform-origin: center;
    backface-visibility: hidden;
  }

  &.finished {
    .dnd {
      animation: 1s ease-out 0s 1 fadeOut forwards;
    }
    div[role='button'] {
      transform-origin: center;
    }
  }
`;

const StyledLookAroundButton = styled.button`
  background: transparent;
  border: 0.3rem solid #41caff;
  padding: 1.5rem 1rem;
  position: absolute;
  bottom: 1rem;
  width: calc(100% - 3rem);
  margin: 1.5rem;
  z-index: 1;
  cursor: pointer;
  font-weight: bold;
  &:hover {
    border: 0.4rem solid #41caff;
  }
  &:active {
    opacity: 0.5;
    transform: translate(0.15rem, 0.15rem);
  }
  &:before {
    display: inline-block;
    content: '';
    width: 2rem;
    height: 2rem;
    background-image: url('${EyeIcon}');
    background-size: contain;
    margin: 0 0.5rem 0 0rem;
    vertical-align: -0.5rem;
  }
`;

const StyledLPuzzleButton = styled.button`
  background: #ccc;
  position: absolute;
  top: 2rem;
  left: 2rem;
  z-index: 1;
  box-shadow: 0.2rem 0.2rem 0.5rem rgba(0, 0, 0, 0.2);
  background: #41caff;
  padding: 1.5rem 1rem;
  border: 0;
  width: 20rem;
  cursor: pointer;
  font-weight: bold;
  &:hover {
    box-shadow: 0.2rem 0.2rem 0.5rem rgba(0, 0, 0, 0.5);
  }
  &:active {
    transform: translate(0.15rem, 0.15rem);
    box-shadow: 0.1rem 0.1rem 0.2rem rgba(0, 0, 0, 0.5);
  }

  &:before {
    display: inline-block;
    content: '';
    width: 2rem;
    height: 2rem;
    background-image: url('${ArrowLeft}');
    background-size: contain;
    margin: 0 0.4rem 0 0rem;
    vertical-align: middle;
  }
`;

const StyledNavigationButton = styled.div<{ posX: number; posY: number; isActive: boolean }>`
  position: absolute;

  font-size: 1.5rem;
  font-weight: bold;
  top: ${(props) => props.posY}rem;
  left: ${(props) => props.posX}rem;
  background: #fff;
  width: 4rem;
  height: 4rem;
  text-align: center;
  padding-top: 1.3rem;
  cursor: pointer;
  border: 0.3rem solid #41caff;
  background: ${(props) => (props.isActive ? '#41caff' : '#fff')};
`;

const StyledNavigationButtons = styled.div`
  position: absolute;
  height: 30rem;
  width: 17rem;
  bottom: 15rem;
  background: url('${navigationBackground}') no-repeat top center;
  background-size: contain;
  background-repeat: no-repeat;
`;

const StyledNavigationInfo = styled.div`
  font-size: 2rem;
  position: absolute;
  color: #fff;
  top: 20rem;
  text-transform: uppercase;
  text-shadow: 0 0 1rem rgba(0, 0, 0, 0.4);
`;

const StyledNavigationInfoTitle = styled.div`
  display: inline-block;
  font-weight: bold;
  color: #fff;
  text-transform: uppercase;
  &:after {
    content: '|';
    padding: 1rem;
  }
`;

const StyledNavigationWrapper = styled.div`
  position: absolute;
  top: rem;
  left: 2rem;
  width: 20rem;

  height: 100%;

  z-index: 1;

  ${({ theme }) => theme.breakpoints.queries.xl} {
    left: 7rem;
  }
`;

const StyledInfoSticky = styled.div<{ backgroundImage: string }>`
  position: absolute;
  top: 27rem;
  left: 2rem;
  height: 25rem;
  width: 20rem;
  transform: translateY(-50%);
  z-index: 1;
  background: url('${(props) => props.backgroundImage}') no-repeat top center;
  ${({ theme }) => theme.breakpoints.queries.xl} {
    left: 7rem;
  }
  background-size: contain;
  background-repeat: no-repeat;
`;

const StyledPanoramaContainer = styled.div`
  width: 75%;
  height: 600px;
  max-height: 40vw;
  z-index: 1;
  max-width: 100%;
  margin: 0 auto;
  position: absolute;
  left: calc(50% + 15rem);
  top: 50%;
  transform: translate(-50%, -50%);
  .pnlm-container {
    margin: 0 auto;
    font-family: inherit;
  }

  .pnlm-about-msg {
    visibility: hidden;
    a {
      visibility: hidden;
    }
  }

  .custom-tooltip {
    width: 2rem;
    height: 2rem;
    display: block;
    font-size: 1.5rem;
    border-radius: 2rem;
    box-shadow: 0 0 0.15rem 0.15rem rgba(0, 0, 0, 0.1);

    border: 0.3rem solid rgba(255, 255, 255, 1);
    hyphens: auto;

    input:checked ~ .tooltip-content {
      display: block;
    }

    z-index: 1;

    &.active {
      z-index: 2;
    }

    &:hover {
      box-shadow: 0 0 0.25rem 0.25rem #41caff;

      transition: outline 0.1s ease-out, outline-offset 0.1s ease-out;

      &:before {
        width: 40%;
        height: 40%;
        margin: 30%;
      }
    }

    &:before {
      position: absolute;
      width: 70%;
      content: '';
      height: 70%;
      margin: 15%;
      outline: 0.3rem solid rgba(0, 0, 0, 0);
      background: #41caff;
      border-radius: 2rem;
      left: 0;
      top: 0;
    }

    input {
      opacity: 0;
      width: 200%;
      height: 200%;
      position: absolute;
      left: -50%;
      top: -50%;
      cursor: pointer;
    }

    &:hover {
    }
  }

  .tooltip-content {
    display: none;
    width: 25rem;
    background-color: #fff;
    position: absolute;
    transform: translate(-50%, 0);
    left: 0.44rem;
    bottom: 2.5rem;
    padding: 2rem 2.2rem;
    box-shadow: 0.2rem 0.2rem 0.5rem rgba(0, 0, 0, 0.2);

    .title {
      font-weight: bold;
      text-transform: uppercase;
    }

    .contentwrap {
      max-height: 13.5rem;
      overflow: auto;
      display: block;
    }

    &:after {
      content: '';
      width: 0;
      height: 0;
      left: calc(50% - 6px);
      border-left: 12px solid transparent;
      border-right: 12px solid transparent;
      position: absolute;
      bottom: -12px;
      border-top: 12px solid #fff;
    }

    .close {
      position: absolute;
      right: 0.5rem;
      top: 0.5rem;
      width: 1.7rem;
      height: 1.7rem;
      background-image: url('${Close}');
      background-size: contain;
      cursor: pointer;
    }

    &.bottomLayout {
      bottom: auto;
      top: 2.5rem;

      &:after {
        content: '';
        width: 0;
        height: 0;
        left: calc(50% - 6px);
        border-left: 12px solid transparent;
        border-right: 12px solid transparent;
        position: absolute;
        top: -12px;
        border-top: none;
        border-bottom: 12px solid #fff;
      }
    }
  }

  ${({ theme }) => theme.breakpoints.queries.xl} {
    width: 80%;
    left: calc(50% + 20rem);
    height: 600px;
  }
`;
