export enum HiddenRoutes {
  LANDING = '/',
  INTRO = '/intro',
  PUZZLEONE = '/puzzleone',
  PUZZLETWO = '/puzzletwo',
  PUZZLETHREE = '/puzzlethree',
  PUZZLEFOUR = '/puzzlefour',
  PUZZLEFIVE = '/puzzlefive',
  PUZZLESIX = '/puzzlesix',
  PUZZLESEVEN = '/puzzleseven',
  COMPLETED = '/completed',
}
