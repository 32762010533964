import ReactGA from 'react-ga4';
import { Puzzle } from '../types/Puzzle';

export class Tracking {
  private selectedLanguage = 'de';

  private activated = false;

  /**
   * @param {string} gaMeasurementID - The GA4 Measurement ID G-XXXXXXXXXX
   * @param {Object} [gtagOptions]
   */
  initializeAndActivate = (gaMeasurementID: string, gtagOptions: object = {}): void => {
    ReactGA.initialize([
      {
        trackingId: gaMeasurementID,
        gtagOptions: gtagOptions,
      },
    ]);
    this.activate();
  };

  activate = (): void => {
    this.activated = true;
  };

  deActivate = (): void => {
    this.activated = false;
  };

  pageView = (): void => {
    if (this.activated) {
      ReactGA.send('pageview');
    }
  };

  // eslint-disable-next-line @typescript-eslint/ban-types
  private event = (name: string, parameters?: {}): void => {
    if (this.activated) {
      ReactGA.event(name, parameters);
    }
  };

  urlParameters = (urlParameters: { [x: string]: string }[]): void => {
    const eventParameters = Object.assign({}, ...urlParameters);

    this.event('utm_parameters', eventParameters);
  };

  puzzleContinue = (from: Puzzle, to_puzzle_number: Puzzle): void => {
    const eventParameters = {
      ...{ selected_language: this.selectedLanguage, from: from, to_puzzle_number: to_puzzle_number },
    };

    this.event('puzzle_continued', eventParameters);
  };

  setSelectedLanguage = (language: string): void => {
    this.selectedLanguage = language.slice(0, 2);

    this.event('language_changed', { selected_language: this.selectedLanguage });
  };

  puzzleSolved = (timeStartInMS: number, timeStopInMS: number, number: Puzzle): void => {
    const timeToSolveInSeconds = (timeStopInMS - timeStartInMS) / 1000;
    const eventParameters = {
      ...{
        selected_language: this.selectedLanguage,
        time_to_solve: timeToSolveInSeconds.toFixed(0),
        puzzle_number: number,
      },
    };

    this.event('puzzle_solved', eventParameters);
  };

  puzzleIncorrectSolution = (number: Puzzle): void => {
    const eventParameters = { ...{ selected_language: this.selectedLanguage, puzzle_number: number } };

    this.event('wrong_puzzle_solution', eventParameters);
  };

  puzzleHintUse = (hintID: number, number: Puzzle): void => {
    const eventParameters = { ...{ selected_language: this.selectedLanguage, hint_id: hintID, puzzle_number: number } };

    this.event('puzzle_hint_used', eventParameters);
  };

  copyShareLink = (): void => {
    this.event('discountcode_copied', { selected_language: this.selectedLanguage });
  };

  clickNewsletterLink = (): void => {
    this.event('newsletter_link_clicked', { selected_language: this.selectedLanguage });
  };
}

export default new Tracking();
