import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import ComponentContainer from '../common/ComponentContainer';
import { Markup } from 'interweave';
import Button from '../common/Button';
import theme from '../../theme/theme';
import { HiddenRoutes } from '../../types/Routes.d';
import CopyMessage from './CopyMessage';
import { Elbphilharmonie } from '../../types/FooterLinks.d';
import { ArrowRight } from '../icons/Icons';

export default function DiscountCodeSection(): ReactElement {
  const { t } = useTranslation();

  const code = t('final_screen.discount_code.code');
  const link = Elbphilharmonie.ESCAPEROOM;

  const tempRemovalDiscount = false;

  return (
    <ComponentContainer isFinalPage>
      <StyledContentContainer>
        <StyledTextWrapper>
          <StyledLine />
          <StyledSectionTitle>
            <Markup content={t('final_screen.title')} />
          </StyledSectionTitle>

          <StyledText>
            <Markup content={t('final_screen.copy')} />
          </StyledText>

          {!tempRemovalDiscount && (
            <StyledDiscount>
              <StyledDiscountCodeTitle>{t('final_screen.discount_code.title')}</StyledDiscountCodeTitle>
              <StyledCodeCopyArea value={t('final_screen.discount_code.code')} readOnly />
              <CopyMessage textToCopy={code} hintCopy={t('final_screen.discount_code.hint')} isCode />
            </StyledDiscount>
          )}

          <StyledButtonWrapper extraMargin={tempRemovalDiscount}>
            <Button
              label={t('final_screen.button')}
              color={'primary'}
              onClick={(e) => {
                e.preventDefault();
                window.open(Elbphilharmonie.TICKETS, '_blank');
              }}
              buttonIcon={<ArrowRight />}
              iconPosition={'right'}
            />
          </StyledButtonWrapper>
          <StyledText>{t('final_screen.link.title')} </StyledText>
          <StyledLinkWrapper>
            <StyledLink href={HiddenRoutes.LANDING}>{t('final_screen.link.url')}</StyledLink>
            <CopyMessage textToCopy={link} hintCopy={t('final_screen.link.hint')} />
          </StyledLinkWrapper>
        </StyledTextWrapper>
      </StyledContentContainer>
    </ComponentContainer>
  );
}

const StyledContentContainer = styled.div`
  max-width: 100rem;
  margin: 0 auto;
`;

const StyledTextWrapper = styled.div`
  max-width: 77rem;
  margin: 0 auto;
`;

const StyledLine = styled.hr`
  width: 15rem;
  height: 0.8rem;
  background: ${theme.palette.global.black};
  padding: 0;
  margin: 0 0 3rem 0;
  border: none;
  ${({ theme }) => theme.breakpoints.queries.sm} {
    width: 25rem;
  }
`;

const StyledSectionTitle = styled.h1`
  text-transform: uppercase;
  font-weight: 900;
  font-size: 2.4rem;
  line-height: 3rem;
  margin: 0 0 1rem;
`;

const StyledText = styled.p`
  font-size: 1.8rem;
  line-height: 2.8rem;
`;

const StyledDiscount = styled.div`
  margin: 3.9rem 0 1.5rem;
  position: relative;
`;

const StyledDiscountCodeTitle = styled.h3`
  display: inline;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 2rem;
  line-height: 2.5rem;
  margin: 0 1.9rem 0 0;
  vertical-align: middle;
`;

const StyledCodeCopyArea = styled.textarea`
  resize: none;
  overflow: hidden;
  text-transform: uppercase;
  border: 0.2rem solid ${theme.palette.company.primary};
  font-family: 'DIN Pro';
  font-size: 2rem;
  line-height: 2.5rem;
  font-weight: 700;
  width: 13.3rem;
  height: 4.6rem;
  text-align: center;
  padding: 1rem;
  outline: none;
  vertical-align: middle;
  margin: 0 1.6rem 0 0;
`;

const StyledLinkWrapper = styled.div`
  margin: 1rem 0;
  position: relative;
  svg {
    position: absolute;
  }
`;

const StyledLink = styled.a`
  font-size: 1.8rem;
  line-height: 2.8rem;
  color: ${theme.palette.global.black};
  text-decoration: underline;
  padding: 0 1rem 0 0;
`;

const StyledButtonWrapper = styled.div<{ extraMargin: boolean }>`
  margin: ${({ extraMargin }) => (extraMargin ? '4rem 0' : '0 0 4rem')};
  button {
    margin: 0;
  }

  svg {
    margin: 0 0 0 0.5rem;
  }

  svg path {
    fill: ${theme.palette.global.black};
  }
`;
