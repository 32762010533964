import { ReactElement } from 'react';
import styled from 'styled-components';

type Props = {
  pageId: number;
};

export default function IntroPagination(props: Props): ReactElement {
  const { pageId } = props;

  return (
    <StyledPaginationContainer>
      <StyledProgressBar>
        <StyledFill isActive={pageId >= 1} />
      </StyledProgressBar>
      <StyledProgressBar>
        <StyledFill isActive={pageId >= 2} />
      </StyledProgressBar>
      <StyledProgressBar>
        <StyledFill isActive={pageId >= 3} />
      </StyledProgressBar>
      <StyledProgressBar>
        <StyledFill isActive={pageId >= 4} />
      </StyledProgressBar>
    </StyledPaginationContainer>
  );
}

const StyledPaginationContainer = styled.div`
  position: absolute;
  bottom: 3rem;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: space-around;

  ${({ theme }) => theme.breakpoints.queries.xl} {
    bottom: 5rem;
  }
`;

const StyledProgressBar = styled.div`
  width: 6rem;
  height: 0.5rem;
  margin: 0rem 1rem;
  background-color: white;
`;

const StyledFill = styled.div<{ isActive: boolean }>`
  width: ${({ isActive }) => (isActive ? '100%' : '0%')};
  height: 100%;
  background-color: #41cbff;
  transition: width 1s ease-in-out 0.5s;
`;
