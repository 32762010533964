import { ReactElement } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

import useBreakpoint from '../../hooks/useBreakpoint';
import Background from '../../assets/landing/keyvisual.webp';
import BackgroundMobile from '../../assets/landing/keyvisual_mobile.webp';
import BackgroundFallback from '../../assets/landing/keyvisual.png';
import BackgroundMobileFallback from '../../assets/landing/keyvisual_mobile.png';
import GradientLeft from '../../assets/landing/gradient_left.webp';
import GradientRight from '../../assets/landing/gradient_right.webp';
import GradientLeftFallback from '../../assets/landing/gradient_left.png';
import GradientRightFallback from '../../assets/landing/gradient_right.png';
import HeroTitle from '../common/HeroTitle';
import PlayAndContnueButtons from './PlayAndContinueButtons';

type Props = {
  isTopBannerOpen: boolean;
};

export default function HeroSection(props: Props): ReactElement {
  const { t } = useTranslation();
  const isSmallTablet = useBreakpoint('sm');
  const isTablet = useBreakpoint('md');
  const { isTopBannerOpen } = props;

  return (
    <>
      <Helmet>
        <link rel="preload" as="image" href={Background} />
        <link rel="preload" as="image" href={BackgroundMobile} />
      </Helmet>
      <StyledHeroContainer isTopBannerOpen={isTopBannerOpen}>
        <StyledHeroBackground>
          {isTablet && (
            <picture>
              <source srcSet={GradientLeft} type="image/webp" />
              <source srcSet={GradientLeftFallback} type="image/png" />
              <StyledGradient src={GradientLeftFallback} isLeft={true} alt="Gradient" width={'201'} loading="lazy" />
            </picture>
          )}
          <picture>
            <source srcSet={isSmallTablet ? Background : BackgroundMobile} type="image/webp" />
            <source srcSet={isSmallTablet ? BackgroundFallback : BackgroundMobileFallback} type="image/png" />
            <StyledBackgroundImage
              src={isSmallTablet ? BackgroundFallback : BackgroundMobileFallback}
              alt="Elbphilharmonie Escape Game"
              width={isSmallTablet ? '1440' : '1024'}
              loading="eager"
            />
          </picture>
          {isTablet && (
            <picture>
              <source srcSet={GradientRight} type="image/webp" />
              <source srcSet={GradientRightFallback} type="image/png" />
              <StyledGradient src={GradientRightFallback} isLeft={false} alt="Gradient" width={'201'} loading="lazy" />
            </picture>
          )}
          <StyledContentWrapper>
            <StyledContent>
              <StyledTextMobile>{t('landing_screen.subline')}</StyledTextMobile>
              <HeroTitle label={t('landing_screen.title')} />
              <StyledText>{t('landing_screen.subline')}</StyledText>
              {isTablet && (
                <StyledButtonContainer>
                  <PlayAndContnueButtons />
                </StyledButtonContainer>
              )}
            </StyledContent>
          </StyledContentWrapper>
        </StyledHeroBackground>
      </StyledHeroContainer>
    </>
  );
}

const StyledHeroContainer = styled.div<{ isTopBannerOpen: boolean }>`
  display: flex;
  position: relative;
  justify-content: center;
  margin: ${({ isTopBannerOpen }) => (isTopBannerOpen ? '4.5rem auto' : '0 auto')};
  padding: 0;
  width: 100%;
  height: auto;
  background: #dce6ee;
  ${({ theme }) => theme.breakpoints.queries.sm} {
    margin: 0 auto;
  }
`;

const StyledContentWrapper = styled.div`
  margin: 0 auto;
  padding: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 100%;
  width: 100%;
  max-width: 144rem;
`;

const StyledContent = styled.div`
  position: absolute;
  top: 20%;
  left: 4%;

  ${({ theme }) => theme.breakpoints.queries.sm} {
    top: 4%;
    left: 10%;
  }

  ${({ theme }) => theme.breakpoints.queries.lg} {
    top: 10%;
  }
`;

const StyledText = styled.p`
  display: none;
  ${({ theme }) => theme.breakpoints.queries.sm} {
    display: block;
    font-size: 1.6rem;
    line-height: 2rem;
    color: ${({ theme }) => theme.palette.global.black};
    margin: 0.5rem 0;
    font-weight: 500;
  }
`;

const StyledTextMobile = styled.p`
  font-size: 1.6rem;
  line-height: 2rem;
  color: ${({ theme }) => theme.palette.global.black};
  margin: 0.5rem 0;
  font-weight: 500;
  max-width: 16rem;
  ${({ theme }) => theme.breakpoints.queries.sm} {
    display: none;
  }
`;

const StyledButtonContainer = styled.div`
  margin: 3.2rem 0;
`;

const StyledBackgroundImage = styled.img`
  width: 100%;
  max-width: 144rem;
  height: 100%;
  object-fit: cover;
`;

const StyledGradient = styled.img<{ isLeft: boolean }>`
  position: absolute;
  left: ${({ isLeft }) => (isLeft ? '-0.1rem' : 'auto')};
  right: ${({ isLeft }) => (isLeft ? 'auto' : '-0.2rem')};
  height: 100%;
  width: auto;
`;

const StyledHeroBackground = styled.div`
  max-width: 184rem;
  position: relative;
  padding: 0;
  margin: 0;
`;
