import React, { ReactElement } from 'react';
import styled from 'styled-components';

interface Props {
  children: ReactElement;
  isFinalPage: boolean;
}

ComponentContainer.defaultProps = {
  isFinalPage: false,
};

export default function ComponentContainer(props: Props): ReactElement {
  const { children, isFinalPage } = props;
  return <StyledComponentContainer isFinalPage = {isFinalPage}>{children}</StyledComponentContainer>;
}

const StyledComponentContainer = styled.div<{ isFinalPage: boolean }>`
  margin: 4rem auto 0;
  padding: 0 2.3rem;
  width: 100%;
  max-width: 144rem;
  height: auto;
  background: ${({ theme }) => theme.palette.global.white};
  ${({ theme }) => theme.breakpoints.queries.sm} {
    margin: 5rem auto;
  }
  ${({ theme }) => theme.breakpoints.queries.md} {
    margin:  ${({ isFinalPage }) => isFinalPage ? "7rem auto 4rem" : "10rem auto 8rem"};
  }
`;
