import { ReactElement, useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';

import { Puzzle } from '../../types/Puzzle.d';
import HintSystem from './HintSystem';
import PuzzleTitle from './PuzzleTitle';
import BackgroundImage from './BackgroundImage';
import SolutionInput from './SolutionInput';
import { CheckSolution, Error } from '../icons/Icons';
import useContent from '../../hooks/useContent';
import Button from '../common/Button';
import ContentContainer from '../layout/ContentContainer';
import PuzzleOptionsContainer from './PuzzleOptionsContainer';
import tracking from '../../services/tracking';

type Props = {
  finishPuzzle(): void;
  puzzleId: Puzzle;
  isDndPuzzleSolved?: boolean;
  hideSolutionButton?: boolean;
  backgroundImage?: string;
  hideMaterialContainer?: boolean;
  stageLightsEnabled?: string[];
  setStageLightsEnabled?(value: string[]): void;
  children: React.ReactNode;
};

export default function PuzzleOverlay(props: Props): ReactElement {
  const {
    finishPuzzle,
    puzzleId,
    isDndPuzzleSolved,
    hideSolutionButton,
    backgroundImage,
    stageLightsEnabled,
    setStageLightsEnabled,
    children,
  } = props;

  const [isSolutionModalOpen, setIsSolutionModalOpen] = useState<boolean>(false);
  const [isErrorFeedbackActive, setIsErrorFeedbackActive] = useState<boolean>(false);

  const checkButtonText = useContent('PuzzleData', 'checkButton', puzzleId).toString();
  const checkButtonErrorText = useContent('PuzzleData', 'checkButtonError', puzzleId).toString();
  const isDndPuzzle = [Puzzle.FOUR, Puzzle.SIX, Puzzle.SEVEN].includes(puzzleId);

  const handleCheckButtonClick = (): void => {
    // TODO: remove the code above.
    if (isDndPuzzle) {
      if (isDndPuzzleSolved) {
        finishPuzzle();
      } else {
        setIsErrorFeedbackActive(true);
        tracking.puzzleIncorrectSolution(puzzleId);
      }
    } else {
      setIsSolutionModalOpen(true);
    }
  };

  useEffect(() => {
    if (isErrorFeedbackActive) {
      setTimeout(function () {
        setIsErrorFeedbackActive(false);
      }, 1000);
    }
  }, [isErrorFeedbackActive]);

  return (
    <>
      <StyledPuzzleOverlay>
        <ContentContainer>
          <StyledBlurContainer isBlurred={isSolutionModalOpen}>
            {children}
            {backgroundImage && <BackgroundImage backgroundImage={backgroundImage} puzzleId={puzzleId} />}
            <PuzzleTitle puzzleId={puzzleId} />
            <HintSystem puzzleId={puzzleId} />
            <PuzzleOptionsContainer puzzleId={puzzleId} />

            {!hideSolutionButton && (
              <StyledCheckButtonWrapper isError={isErrorFeedbackActive}>
                <Button
                  color={'primary'}
                  iconPosition="right"
                  label={isErrorFeedbackActive ? checkButtonErrorText : checkButtonText}
                  onClick={() => handleCheckButtonClick()}
                  buttonIcon={isErrorFeedbackActive ? <Error /> : <CheckSolution />}
                />
              </StyledCheckButtonWrapper>
            )}
          </StyledBlurContainer>
        </ContentContainer>
        {isSolutionModalOpen && (
          <SolutionInput
            puzzleId={puzzleId}
            finishPuzzle={finishPuzzle}
            stageLightsEnabled={stageLightsEnabled}
            setStageLightsEnabled={setStageLightsEnabled}
            closeModal={() => setIsSolutionModalOpen(false)}
          />
        )}
      </StyledPuzzleOverlay>
    </>
  );
}

const shake = keyframes`
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-1rem);
  }
  50% {
    transform: translateX(1rem);
  }
  75% {
    transform: translateX(-1rem);
  }
  100% {
    transform: translateX(0)
  }
`;

const StyledCheckButtonWrapper = styled.div<{ isError: boolean }>`
  position: absolute;
  right: 2rem;
  bottom: 2rem;
  z-index: 2;

  svg {
    margin: -0.1rem 0 0 0.5rem;
  }

  ${({ theme }) => theme.breakpoints.queries.xl} {
    bottom: 4rem;
    right: 7rem;
  }

  & button {
    margin: 0;
    font-size: 2rem;
    transition: all 0.3s;
    background-color: ${({ isError }) => (isError ? '#E42322' : '#41CBFF')};
    color: ${({ isError }) => (isError ? 'white' : 'black')};
    pointer-events: ${({ isError }) => (isError ? 'none' : 'all')};
    border-color: ${({ isError }) => (isError ? '#E42322' : '#41CBFF')};
    animation: ${({ isError }) => (isError ? shake : 'none')} 0.5s ease-in-out;
  }
`;

const StyledPuzzleOverlay = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  transition: all 0.3s;
`;

const StyledBlurContainer = styled.div<{ isBlurred: boolean }>`
  filter: blur(${({ isBlurred }) => (isBlurred ? '0.3' : '0')}rem);
  height: 100%;
`;
