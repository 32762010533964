import React, { ReactElement, useState } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useCopyToClipboard } from 'react-use';

import { Copy } from '../icons/Icons';
import tracking from '../../services/tracking';
type Props = {
  textToCopy: string;
  hintCopy: string;
  isCode: boolean;
};

CopyMessage.defaultProps = {
  isCode: false,
};

export default function CopyMessage(props: Props): ReactElement {
  const { textToCopy, hintCopy, isCode } = props;
  const { t } = useTranslation();
  const delay = 4000;

  const [state, copyToClipboard] = useCopyToClipboard();
  const [isVisible, setIsVisible] = useState<boolean>(false);

  const handleCopyToClipboard = (): void => {
    tracking.copyShareLink();
    copyToClipboard(textToCopy);
    setIsVisible(true);
    setTimeout(() => {
      setIsVisible(false);
    }, delay);
  };

  return (
    <>
      <StyledCopyIcon onClick={handleCopyToClipboard} $isCode={isCode} />
      <StyledCopyHint isVisible={isVisible}>{hintCopy} </StyledCopyHint>
      {state.error && (
        <StyledCopyHint isVisible={isVisible}>
          <Copy />
          {t('final_screen.discount_code.hint_error')}
        </StyledCopyHint>
      )}
    </>
  );
}

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
`;

const StyledCopyHint = styled.p<{
  isVisible: boolean;
}>`
  background-color: rgba(0, 0, 0, 0.3);
  color: ${({ theme }) => theme.palette.global.white};
  font-size: 1.6rem;
  line-height: 1.6rem;
  border-radius: 2rem;
  height: 2.3rem;
  width: 31rem;
  text-align: center;
  margin: 1rem auto;
  padding: 0.4rem;
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  animation: ${({ isVisible }) =>
    isVisible
      ? css`
          ${fadeIn} .5s forwards
        `
      : css`
          ${fadeOut} .5s forwards
        `};
`;

const StyledCopyIcon = styled(Copy)<{ $isCode: boolean }>`
  cursor: pointer;
  position: absolute;
  top: ${({ $isCode }) => ($isCode ? '1rem' : '0.2rem')};
`;
