import { ReactElement } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

type Props = {
  onClose(): void;
};

export default function Manual(props: Props): ReactElement {
  const { onClose } = props;
  const { t } = useTranslation();

  return (
    <StyledManualContainer>
      <StyledManualHeader>
        <StyledHeader>{t('manual.header')}</StyledHeader>
      </StyledManualHeader>
      <StyledTextContainer>
        <StyledTitle>{t('manual.title1')}</StyledTitle>
        <StyledDesc>{t('manual.desc1')}</StyledDesc>
        <StyledTitle>{t('manual.title2')}</StyledTitle>
        <StyledDesc>{t('manual.desc2')}</StyledDesc>
        <StyledHintContainer>
          <StyledTitle>{t('manual.hint')}</StyledTitle>
          <StyledDesc>{t('manual.hint_desc')}</StyledDesc>
        </StyledHintContainer>
        <StyledTitle>{t('manual.title3')}</StyledTitle>
        <StyledDesc>{t('manual.desc3')}</StyledDesc>
        <StyledTitle>{t('manual.title4')}</StyledTitle>
        <StyledDesc>{t('manual.desc4')}</StyledDesc>
      </StyledTextContainer>
      <StyledFadeContainer />
      <StyledCloseButton onClick={onClose} />
    </StyledManualContainer>
  );
}

const StyledManualContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
`;

const StyledManualHeader = styled.div`
  position: relative;
`;

const StyledHeader = styled.h2`
  font-size: 2.5rem;
  font-weight: 900;
  margin: 2rem 0 3rem;
  position: relative;
  text-transform: uppercase;
  pointer-events: none;

  &:before {
    content: '';
    width: 15rem;
    height: 0.8rem;
    background-color: black;
    position: absolute;
    top: -2rem;
    left: 0;
  }
`;

const StyledTextContainer = styled.div`
  padding: 2rem 5rem 4rem 0;
  overflow-y: scroll;
  position: relative;
`;

const StyledFadeContainer = styled.div`
  margin: 7.5rem 0 0 0;
  position: absolute;
  top: 0;
  left: 0;
  height: calc(100% - 7.5rem);
  width: 95%;
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0) 10%,
    rgba(255, 255, 255, 0) 95%,
    rgba(255, 255, 255, 1) 100%
  );
  pointer-events: none;
`;

const StyledTitle = styled.h3`
  font-size: 1.9rem;
  line-height: 2.5rem;
  font-weight: 700;
  text-transform: uppercase;
`;

const StyledDesc = styled.p`
  font-size: 1.8rem;
  line-height: 2.8rem;
  font-weight: 400;
  padding: 0 0 3rem;
`;

const StyledHintContainer = styled.div`
  padding: 3rem 3rem 0 3rem;
  background-color: #efefef;
  margin: 0 0 3rem;
`;

const StyledCloseButton = styled.div`
  width: 4rem;
  height: 4rem;
  position: absolute;
  top: -4rem;
  right: 1rem;
  cursor: pointer;
  z-index: 1;

  &:before {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    width: 0.2rem;
    height: 1.5rem;
    transform: translate(-50%, -50%) rotate(45deg);
    background-color: black;
    transition: transform 0.3s;
  }

  &:after {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    width: 0.2rem;
    height: 1.5rem;
    transform: translate(-50%, -50%) rotate(-45deg);
    background-color: black;
    transition: transform 0.3s;
  }
`;
