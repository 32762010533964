import React, { ReactElement, useEffect, useState } from 'react';
import styled, { keyframes, useTheme } from 'styled-components';
import { v4 as uuidV4 } from 'uuid';
import AudioPlayerWithControls from '../puzzleelements/AudioPlayerWithControls';
import 'react-h5-audio-player/lib/styles.css';

import { Puzzle } from '../../types/Puzzle.d';

import PuzzleOverlay from '../interface/PuzzleOverlay';
import Play from '../../assets/puzzle3/icons/play.svg';

import Background from '../../assets/puzzle3/background.jpg';
import PuzzleImageDe from '../../assets/puzzle3/orchesterplan_de.jpg';
import PuzzleImageEn from '../../assets/puzzle3/orchesterplan_en.jpg';
import NoSound from '../../assets/puzzle3/no_sound.svg';
import ArrowRight from '../../assets/puzzle3/button_arrow.svg';
import { getI18n, useTranslation } from 'react-i18next';
import { PuzzleThreeInstrument } from '../../types/PuzzleThreeType';
import { instrumentsArrayDe, instrumentsArrayEn } from '../../data/PuzzleThreeData';
import ContentContainer from '../layout/ContentContainer';

type Props = {
  finishPuzzle(): void;
};

export default function PuzzleThree(props: Props): ReactElement {
  const { finishPuzzle } = props;
  const puzzleId = Puzzle.THREE;
  const { t } = useTranslation();
  const [puzzleImage, setPuzzleImage] = useState<'*.jpg'>(PuzzleImageEn);
  const [instruments, setInstruments] = useState<PuzzleThreeInstrument[]>(instrumentsArrayDe);
  const [isTranscriptionOpen, setIsTranscriptionOpen] = useState<boolean>(false);
  const theme = useTheme();
  const colorPrimary = theme.palette.company.primary;

  useEffect(() => {
    switch (getI18n().language.trim().toLowerCase().slice(0, 2)) {
      case 'de':
        setPuzzleImage(PuzzleImageDe);
        setInstruments(instrumentsArrayDe);
        break;
      default:
        setPuzzleImage(PuzzleImageEn);
        setInstruments(instrumentsArrayEn);
        break;
    }
  }, [t]);

  let audio;

  const start = (index: number): void => {
    audio = new Audio(instruments[index].sound);
    audio.play();
  };

  return (
    <>
      <PuzzleOverlay puzzleId={puzzleId} backgroundImage={Background} finishPuzzle={finishPuzzle}>
        <StyledPuzzleContainer>
          <StyledAudioPlayerWrapper>
            <AudioPlayerWithControls />
          </StyledAudioPlayerWrapper>

          <StyledTranscriptionButton onClick={() => setIsTranscriptionOpen(true)}>
            {t('puzzle_three.transcription')}
          </StyledTranscriptionButton>

          <StyledInstrumentsContainer>
            {instruments.map((instrument, index) => (
              <StyledInstrument key={uuidV4()}>
                <StyledInstrumentWrap>
                  <StyledInstrumentPlayButton onClick={() => start(index)} backgroundColor={colorPrimary} />
                  <StyledInstrumentIconImage src={instrument.icon} />
                  <StyledInstrumentLetter>{instrument.letter}</StyledInstrumentLetter>
                </StyledInstrumentWrap>
                <StyledInstrumentName> {t(instrument.title)}</StyledInstrumentName>
              </StyledInstrument>
            ))}
          </StyledInstrumentsContainer>

          <StyledImageWrapper>
            <StyledImage src={puzzleImage} />
          </StyledImageWrapper>
        </StyledPuzzleContainer>
      </PuzzleOverlay>
      {isTranscriptionOpen && (
        <StyledTranscriptionOverlay>
          <StyledDarkBackground onClick={() => setIsTranscriptionOpen(false)} />
          <ContentContainer>
            <StyledTranscriptionBox>
              <StyledHeader>{t('puzzle_three.transcription')}</StyledHeader>
              <StyledTextContainer>
                <StyledTitle></StyledTitle>
                <StyledDesc>{t('puzzle_three.transcription_copy')}</StyledDesc>
              </StyledTextContainer>
              <StyledCloseButton onClick={() => setIsTranscriptionOpen(false)} />
            </StyledTranscriptionBox>
          </ContentContainer>
        </StyledTranscriptionOverlay>
      )}
    </>
  );
}

const fadeIn = keyframes`
  from { opacity: 0 }
  to { opacity: 0.3 }
`;

const fadePanInTranscription = keyframes`
  from { opacity: 0; transform: translateX(1rem); }
  to { opacity: 1; transform: translateX(0); }
`;

const StyledImageWrapper = styled.div`
  position: absolute;
  width: calc(100% - 40rem);
  left: calc(17rem + 50%);
  height: 70vh;
  transform: translate(-50%, 10%);
`;

const StyledImage = styled.img`
  max-width: 100%;
  max-height: 100%;
  transform: rotate(-4deg);
`;

const StyledPuzzleContainer = styled.div`
  width: 100%;
  z-index: 1;
  position: absolute;
  left: 50%;
  top: 10rem;
  transform: translate(-50%, 0);
`;

const StyledTranscriptionButton = styled.p`
  position: absolute;
  left: 4rem;
  top: 8.6rem;
  z-index: 1;
  color: white;
  font-size: 1.2rem;
  font-weight: 500;
  cursor: pointer;

  ${({ theme }) => theme.breakpoints.queries.lg} {
    top: 10rem;
  }

  &:before {
    content: '';
    position: absolute;
    left: -2rem;
    top: 50%;
    transform: translateY(-50%);
    background-image: url(${NoSound});
    background-size: 95%;
    background-position: center;
    background-repeat: no-repeat;
    width: 1.5rem;
    height: 1.5rem;
    z-index: 1;
  }

  &:after {
    content: '';
    position: absolute;
    right: -1.3rem;
    top: 59%;
    transform: translateY(-50%);
    background-image: url(${ArrowRight});
    background-size: 95%;
    background-position: center;
    background-repeat: no-repeat;
    width: 0.7rem;
    height: 1.5rem;
    z-index: 1;
  }

  ${({ theme }) => theme.breakpoints.queries.lg} {
    left: 9rem;
    top: 13rem;
  }
`;

const StyledInstrumentsContainer = styled.div`
  position: absolute;
  top: 23rem;
  left: 1rem;
  width: 30rem;
  transform: translateY(-50%);
  z-index: 1;

  ${({ theme }) => theme.breakpoints.queries.lg} {
    left: 6rem;
    top: 27rem;
  }
`;

const StyledInstrument = styled.div`
  margin: 1rem 0rem 0 1rem;
  width: 12rem;
  border-radius: 1rem;
  cursor: pointer;
  display: inline-block;
  background: #fff;
  padding: 0.4rem 1rem 0.4rem 1rem;
`;

const StyledInstrumentWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 4rem;
  text-align: center;
`;

const StyledInstrumentPlayButton = styled.div<{ backgroundColor: string }>`
  background: ${(props) => props.backgroundColor};
  border-radius: 2rem;
  text-align: center;
  color: #fff;
  display: inline-block;
  width: 3rem;
  height: 3rem;
  position: relative;
  box-shadow: 0.3rem 0.3rem 0.5rem rgba(0, 0, 0, 0.4);
  transition: background-color 0.5s, transform 1s linear, box-shadow 0.75s linear, box-shadow 0.75s linear;
  transform: translate(0rem, 0rem);

  &:hover {
    background-color: #01b9ff;
  }

  &:active {
    opacity: 0.8;
    box-shadow: 0rem 0rem 0.5rem rgba(0, 0, 0, 0.4);
    transform: translate(0.3rem, 0.3rem);
    transition: transform 0s linear, box-shadow 0s linear, opacity 0s linear;
  }

  &:before {
    display: inline-block;
    content: '';
    width: 100%;
    height: 100%;
    background: url('${Play}') no-repeat center center;
  }
`;

const StyledInstrumentIconImage = styled.img`
  flex-grow: 1;
  margin: 0 0.5rem;
  max-height: 3.5rem;
`;

const StyledInstrumentLetter = styled.div`
  font-size: 2.5rem;
  font-weight: 900;
  width: 15%;
  text-align: right;
`;

const StyledAudioPlayerWrapper = styled.div`
  position: absolute;
  top: 5rem;
  left: 2rem;
  width: 20rem;
  transform: translateY(-50%);
  z-index: 1;

  ${({ theme }) => theme.breakpoints.queries.lg} {
    left: 7rem;
    top: 9rem;
  }
`;

const StyledTranscriptionOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 10;
`;

const StyledDarkBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0;
  animation: ${fadeIn} 0.3s ease-in-out forwards;
`;

const StyledTranscriptionBox = styled.div`
  position: absolute;
  width: 65rem;
  left: 30rem;
  top: 12rem;
  background-color: white;
  padding: 5rem 3rem;
  transform: translateX(1rem);
  opacity: 0;
  animation: ${fadePanInTranscription} 0.3s ease-in-out 0.3s forwards;

  &:before {
    content: '';
    width: 1.6rem;
    height: 1.6rem;
    transform: translateX(-50%) rotate(45deg);
    position: absolute;
    background-color: white;
    left: 0;
    top: 3rem;
  }

  ${({ theme }) => theme.breakpoints.queries.xl} {
    left: 35rem;
    top: 15rem;
    width: 85rem;
  }
`;

const StyledHeader = styled.h2`
  font-size: 2.5rem;
  font-weight: 900;
  margin: 2rem 0 3rem;
  position: relative;
  text-transform: uppercase;
  pointer-events: none;

  &:before {
    content: '';
    width: 15rem;
    height: 0.8rem;
    background-color: black;
    position: absolute;
    top: -2rem;
    left: 0;
  }
`;

const StyledTextContainer = styled.div`
  padding: 2rem 5rem 4rem 0;
  position: relative;
`;

const StyledTitle = styled.h3`
  font-size: 1.9rem;
  line-height: 2.5rem;
  font-weight: 700;
  text-transform: uppercase;
`;

const StyledDesc = styled.p`
  font-size: 1.8rem;
  line-height: 2.8rem;
  font-weight: 400;
  padding: 0 0 3rem;
`;

const StyledCloseButton = styled.div`
  width: 4rem;
  height: 4rem;
  position: absolute;
  top: 1rem;
  right: 1rem;
  cursor: pointer;
  z-index: 1;

  &:before {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    width: 0.2rem;
    height: 1.5rem;
    transform: translate(-50%, -50%) rotate(45deg);
    background-color: black;
    transition: transform 0.3s;
  }

  &:after {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    width: 0.2rem;
    height: 1.5rem;
    transform: translate(-50%, -50%) rotate(-45deg);
    background-color: black;
    transition: transform 0.3s;
  }
`;

const StyledInstrumentName = styled.p`
  text-align: center;
  padding-left: 1rem;
`;
