import React from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { HiddenRoutes } from '../../types/Routes.d';

import Body from './Body';

type Props = {
  children: React.ReactNode;
};

export default function Layout(props: Props): React.ReactElement {
  const { children } = props;
  const { pathname } = useLocation();

  let isLanding: boolean;
  if (pathname === HiddenRoutes.LANDING || pathname === HiddenRoutes.COMPLETED) {
    isLanding = true;
  } else isLanding = false;

  return (
    <StyledLayoutContainer isLanding={isLanding}>
      <Body>{children}</Body>
    </StyledLayoutContainer>
  );
}

const StyledLayoutContainer = styled.div<{ isLanding: boolean }>`
  display: flex;
  flex-direction: column;
  height: ${({ isLanding }) => (isLanding ? 'none' : '100vh')};
`;
