import React, { forwardRef, useEffect, useState } from 'react';
import theme from '../../theme/theme';
import styled from 'styled-components';

import { VolumeUp } from '../icons/Icons';
import GalleryPictureModal from './GalleryPictureModal';

interface Props {
  isDragging: boolean;
  isTransparent: boolean;
  url: string;
  audio?: string;
  index: number | string;
  totalColumnsNumber: number;
  style?: React.CSSProperties;
}

export const GalleryPicture = forwardRef<HTMLDivElement, Props>(
  ({ url, index, audio, isTransparent, isDragging, style, totalColumnsNumber, ...props }, ref) => {
    const [isLensViewOpen, setIsLensViewOpen] = useState(false);

    const openLensView = (): void => {
      setIsLensViewOpen(true);
    };

    const closeLensView = (): void => {
      setIsLensViewOpen(false);
    };

    useEffect(() => {
      if (isLensViewOpen) {
        document.body.style.overflow = 'hidden';
        document.body.style.touchAction = 'none';
      } else {
        document.body.style.overflow = 'auto';
        document.body.style.touchAction = '';
      }
    }, [isLensViewOpen]);

    return (
      <>
        <StyledLens
          onClick={() => {
            openLensView();
          }}
          $isTransparent={isTransparent}
        />
        {isLensViewOpen && <GalleryPictureModal url={url} hide={closeLensView} audio={audio} />}
        <StyledDragAndDropImage
          ref={ref}
          isTransparent={isTransparent}
          isDragging={isDragging}
          url={url}
          style={style}
          {...props}
        ></StyledDragAndDropImage>
      </>
    );
  },
);

GalleryPicture.displayName = 'Gallery Picture';

GalleryPicture.defaultProps = {
  audio: '',
};

const StyledDragAndDropImage = styled.div<{ isDragging: boolean; isTransparent: boolean; url: string }>`
  width: 13rem;
  height: 16.5rem;
  opacity: ${({ isDragging }) => (isDragging ? 0 : 1)};
  transform-origin: 0 0;
  transform: ${({ isTransparent }) => (isTransparent ? 'rotate(-4deg)' : '')};
  background-image: ${({ isDragging, url }) => (isDragging ? `` : `url("${url}")`)};
  background-size: contain;
  background-position: center;
  background-color: transparent;
  background-repeat: no-repeat;
  border: ${({ isTransparent }) => (isTransparent ? `0.4rem ${theme.palette.company.primary} solid` : 'none')};
  color: ${theme.palette.company.primary};
  ${({ theme }) => theme.breakpoints.queries.lg} {
    width: 16.9rem;
    height: 21.5rem;
  }
`;

const StyledLens = styled(VolumeUp)<{ isTransparent: boolean }>`
  z-index: 5;
  top: 23rem;
  left: 0;
  position: relative;
  opacity: ${({ $isTransparent }) => ($isTransparent ? 0 : 1)};
  fill: ${({ theme }) => theme.palette.company.primary};
  transition: fill 0.3s ease-in;
  filter: drop-shadow(1px 1px 6px rgba(0, 0, 0, 0.45));
  :hover {
    fill: ${({ theme }) => theme.palette.company.hover.primary};
  }
  ${({ theme }) => theme.breakpoints.queries.lg} {
    top: 28rem;
  }
`;
