import React, { ReactElement } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import ImageMagnifier from './ImageMagnifier';

type Props = {
  url: string;
  audio?: string;
  hide: () => void;
};

export default function GalleryPictureModal({ hide, audio, url }: Props): ReactElement {
  return ReactDOM.createPortal(
    <StyledModalOverlay>
      <StyledModalWrapper aria-modal aria-hidden tabIndex={-1} role="dialog">
        <StyledLensView>
          <StyledCloseButton onClick={hide} />
          <ImageMagnifier src={url} audio={audio} />
        </StyledLensView>
      </StyledModalWrapper>
    </StyledModalOverlay>,
    document.body,
  );
}

const StyledModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  opacity: 1;
  margin: 0 auto;
  overscroll-behavior: none;
  background-color: #000000cf;
`;
const StyledModalWrapper = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  outline: 0;
  background-color: transparent;
`;

const StyledLensView = styled.div`
  width: 100%;
  height: 100%;
  max-height: 51rem;
  max-width: 39rem;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  ${({ theme }) => theme.breakpoints.queries.lg} {
    width: 50rem;
    height: 64rem;
  }
`;
const StyledCloseButton = styled.div`
  position: absolute;
  right: -6.8rem;
  top: 0;
  width: 4rem;
  height: 4rem;
  cursor: pointer;
  z-index: 1;

  ${({ theme }) => theme.breakpoints.queries.lg} {
    right: -12rem;
    top: -7rem;
  }

  @media (max-height: 660px) {
    right: -6.8rem;
    top: 0;
  }

  &:before {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    width: 0.4rem;
    height: 4rem;
    transform: translate(-50%, -50%) rotate(45deg);
    background-color: ${({ theme }) => theme.palette.global.white};
    transition: transform 0.3s;
  }

  &:after {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    width: 0.4rem;
    height: 4rem;
    transform: translate(-50%, -50%) rotate(-45deg);
    background-color: ${({ theme }) => theme.palette.global.white};
    transition: transform 0.3s;
  }
`;
