import { ReactElement, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

import Background from '../../assets/puzzle5/background.jpg';
import NotebookDE from '../../assets/puzzle5/notebook_book_front_de.png';
import NotebookEN from '../../assets/puzzle5/notebook_book_front_en.png';
import CabelBackground from '../../assets/puzzle5/cabel/background_cabel.jpg';

import { ArrowLeft, Lens } from '../icons/Icons';
import NoteBookSlider from '../puzzleelements/NoteBookSlider';
import { Puzzle } from '../../types/Puzzle.d';
import PuzzleOverlay from '../interface/PuzzleOverlay';
import { FrequencyWaves } from '../puzzleelements/FrequencyWaves';
import { LedMatrix } from '../puzzleelements/LedMatrix';
import Button from '../common/Button';

type Props = {
  finishPuzzle(): void;
};

export default function PuzzleFive(props: Props): ReactElement {
  const { finishPuzzle } = props;
  const { t } = useTranslation();
  const puzzleId = Puzzle.FIVE;

  const [isViewOpen, setIsViewOpen] = useState<boolean>(false);
  const [viewContentId, setViewContentId] = useState<0 | 1 | 2 | 3>(0);
  const [stageLightsEnabled, setStageLightsEnabled] = useState<string[]>([]);
  const locale = i18next.resolvedLanguage.trim().toLowerCase().slice(0, 2);

  const openView = (viewContentId: 0 | 1 | 2 | 3): void => {
    setIsViewOpen(true);
    setViewContentId(viewContentId);
  };

  const closeView = (): void => {
    setIsViewOpen(false);
  };

  const [bookImage, setBookImage] = useState(NotebookDE);

  useEffect(() => {}, [stageLightsEnabled]);

  useEffect(() => {
    switch (locale) {
      case 'de':
        setBookImage(NotebookDE);
        break;
      default:
        setBookImage(NotebookEN);
        break;
    }
  }, [locale]);

  return (
    <PuzzleOverlay
      puzzleId={puzzleId}
      stageLightsEnabled={stageLightsEnabled}
      setStageLightsEnabled={setStageLightsEnabled}
      finishPuzzle={finishPuzzle}
      backgroundImage={Background}
    >
      <StyledBookWrapper>
        <img src={bookImage} alt="Book" />
        <StyledSwitchButton x={33} y={55} onClick={() => openView(3)} />
      </StyledBookWrapper>
      <StyledSwitchButton x={52} y={32} onClick={() => openView(0)} />
      <StyledSwitchButton x={3} y={64} onClick={() => openView(1)} />
      <StyledSwitchButton x={25} y={32} onClick={() => openView(2)} />

      {isViewOpen && (
        <>
          {viewContentId !== 3 && (
            <StyledView>
              <StyledBackButtonWrapper>
                <Button
                  onClick={() => closeView()}
                  label={t('puzzle_five.back_button')}
                  color="primary"
                  buttonIcon={<ArrowLeft />}
                  iconPosition="left"
                />
              </StyledBackButtonWrapper>
              {viewContentId == 1 && <StyledViewImage src={CabelBackground} />}
              {viewContentId == 2 && <LedMatrix />}
              {viewContentId == 0 && <FrequencyWaves />}
            </StyledView>
          )}
          {viewContentId == 3 && (
            <StyledNotebookView>
              <StyledDarkCover />
              <StyledCloseButton onClick={() => closeView()} />
              <NoteBookSlider />
            </StyledNotebookView>
          )}
        </>
      )}
    </PuzzleOverlay>
  );
}

const StyledSwitchButton = styled(Lens)<{ x: number; y: number }>`
  position: absolute;
  top: ${({ y }) => `${y}%`};
  left: ${({ x }) => `${x}%`};
  cursor: pointer;
  z-index: 2;
  fill: ${({ theme }) => theme.palette.company.primary};
  transition: fill 0.3s ease-in;
  filter: drop-shadow(1px 1px 6px rgba(0, 0, 0, 0.45));
  :hover {
    fill: ${({ theme }) => theme.palette.company.hover.primary};
  }
`;

const StyledCloseButton = styled.div`
  position: absolute;
  top: 4rem;
  right: 4rem;
  width: 4rem;
  height: 4rem;
  cursor: pointer;
  z-index: 5;

  &:before {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    width: 0.4rem;
    height: 4rem;
    transform: translate(-50%, -50%) rotate(45deg);
    background-color: ${({ theme }) => theme.palette.global.white};
    transition: transform 0.3s;
  }

  &:after {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    width: 0.4rem;
    height: 4rem;
    transform: translate(-50%, -50%) rotate(-45deg);
    background-color: ${({ theme }) => theme.palette.global.white};
    transition: transform 0.3s;
  }
`;

const StyledDarkCover = styled.div`
  width: 300vw;
  height: 300vh;
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(-50%, -50%);
  background-color: black;
  opacity: 0.8;
`;

const StyledView = styled.div`
  width: 100%;
  height: 100%;
  background-color: transparent;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
  background-color: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(0.25rem);
`;

const StyledViewImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

const StyledBookWrapper = styled.div`
  position: absolute;
  top: 27rem;
  left: 1rem;
  width: 20rem;
  transform: translateY(-50%);
  z-index: 3;

  ${({ theme }) => theme.breakpoints.queries.xl} {
    left: 5rem;
  }
`;

const StyledNotebookView = styled.div`
  width: 100%;
  height: 100%;
  background-color: transparent;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 8;
  backdrop-filter: blur(0.25rem);
`;

const StyledBackButtonWrapper = styled.div`
  position: absolute;
  top: 13rem;
  left: 2rem;
  cursor: pointer;
  z-index: 5;
  color: ${({ theme }) => theme.palette.global.white};
  ${({ theme }) => theme.breakpoints.queries.xl} {
    left: 7rem;
    top: 15rem;
  }
  svg {
    width: 2.4rem;
    height: 2.4rem;
    margin: 0;
  }
  button {
    padding: 2.1rem;
  }
  span {
    font-size: 1.4rem;
  }
`;
