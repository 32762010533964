import { ReactElement } from 'react';
import styled, { keyframes } from 'styled-components';

type Props = {
  backgroundImage: string;
};

export default function IntroBackground(props: Props): ReactElement {
  const { backgroundImage } = props;
  return (
    <StyledBackgroundContainer>
      <StyledBackground src={backgroundImage} />
      <StyledGradients />
    </StyledBackgroundContainer>
  );
}

const slightMovement = keyframes`
  from { transform: translateX(-52%) }
  to { transform: translateX(-48%) }
`;

const StyledBackgroundContainer = styled.div`
  position: absolute;
  overflow: hidden;
  height: 100%;
  width: 100%;
`;

const StyledBackground = styled.img`
  height: 150%;
  position: absolute;
  top: 0;
  left: 50%;
  z-index: -1;
  animation: ${slightMovement} 15s ease-in-out infinite alternate;
`;

const StyledGradients = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: linear-gradient(90deg, #5d8ab1 0%, #5d8ab100 10%, #5d8ab100 90%, #5d8ab1 100%),
    linear-gradient(0deg, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0) 20%, rgba(0, 0, 0, 0) 80%, rgba(0, 0, 0, 0.7) 100%);
`;
