import React, { forwardRef } from 'react';
import theme from '../../theme/theme';
import styled from 'styled-components';

interface Props {
  isDragging: boolean;
  isTransparent: boolean;
  url: string;
  index: number | string;
  totalColumnsNumber: number;
  style?: React.CSSProperties;
}

export const SightsCard = forwardRef<HTMLDivElement, Props>(
  ({ url, index, isTransparent, isDragging, style, totalColumnsNumber, ...props }, ref) => {
    let color;
    const getBackgroundColor = (isDragging: boolean): string => {
      if (isDragging === true) {
        color = '#01B9FF';
      } else color = theme.palette.company.primary;
      return color;
    };

    return (
      <StyledDragAndDropImage
        ref={ref}
        isTransparent={isTransparent}
        isDragging={isDragging}
        url={url}
        style={style}
        color={getBackgroundColor(isTransparent)}
        {...props}
      >
        <StyledSightIcon src={url} />
      </StyledDragAndDropImage>
    );
  },
);

SightsCard.displayName = 'Sights Image';

const StyledDragAndDropImage = styled.div<{ isDragging: boolean; isTransparent: boolean; url: string; color: string }>`
  width: 9rem;
  height: 9rem;
  opacity: ${({ isDragging }) => (isDragging ? 0 : 1)};
  transform-origin: 0 0;
  transform: ${({ isTransparent }) => (isTransparent ? 'rotate(-10deg)' : '')};
  background-size: cover;
  background-position: center;
  background: ${({ url, color }) => (url ? color : `transparent`)};
  box-shadow: ${({ url }) => (url ? `0px 0px 20px rgba(0, 0, 0, 0.25);` : `none`)};
  display: flex;
  justify-content: center;
  justify-items: center;
  align-items: center;
  align-content: center;
  z-index: 5;

  ${({ theme }) => theme.breakpoints.queries.lg} {
    width: 12rem;
    height: 12rem;
  }
`;

const StyledSightIcon = styled.img`
  width: 5.6rem;
  position: relative;
  vertical-align: center;
  ${({ theme }) => theme.breakpoints.queries.lg} {
    width: 7.6rem;
  }
`;
