import DE_Page1 from '../../src/assets/puzzle5/notebook_page1_de.png';
import DE_Page2 from '../../src/assets/puzzle5/notebook_page2_de_en.png';
import DE_Page3 from '../../src/assets/puzzle5/notebook_page3_de.png';

import EN_Page1 from '../../src/assets/puzzle5/notebook_page1_en.png';
import EN_Page2 from '../../src/assets/puzzle5/notebook_page2_de_en.png';
import EN_Page3 from '../../src/assets/puzzle5/notebook_page3_en.png';


export const NoteBookDataDe: { index: number; image: string }[] = [
  {
    index: 1,
    image: DE_Page1,
  },
  {
    index: 2,
    image: DE_Page2,
  },
  {
    index: 3,
    image: DE_Page3,
  },
];

export const NoteBookDataEn: { index: number; image: string }[] = [
  {
    index: 1,
    image: EN_Page1,
  },
  {
    index: 2,
    image: EN_Page2,
  },
  {
    index: 3,
    image: EN_Page3,
  },
];
