/* eslint-disable react-hooks/exhaustive-deps */
import { ReactElement, useEffect, useRef, useState } from 'react';
import styled, { keyframes } from 'styled-components';

type Props = {
  messageArray: string[];
  isIntermission: boolean;
  setButton?(): void;
};

export default function PhoneMessages(props: Props): ReactElement {
  const { messageArray, isIntermission, setButton } = props;

  const smartphoneBottomElement = useRef<HTMLDivElement>(null);
  const smartphoneScollingContainer = useRef<HTMLDivElement>(null);
  const [showTypingAnimation, setShowTypingAnimation] = useState<boolean>(isIntermission);
  const [displayedMessages, setDisplayedMessages] = useState<string[]>([]);

  const messageHistory = messageArray
    .slice(0, messageArray.length - 1)
    .map((key, index) => <StyledMessage key={index}>{key}</StyledMessage>);

  const latestMessage = (
    <StyledLastMessageWrapper>
      <StyledMessage>{messageArray[messageArray.length - 1]}</StyledMessage>
    </StyledLastMessageWrapper>
  );

  const scrollToBottom = (): void => {
    if (smartphoneScollingContainer.current && smartphoneBottomElement.current) {
      smartphoneScollingContainer.current.scrollTo({
        top: smartphoneBottomElement.current.offsetTop,
        behavior: 'smooth',
      });
    }
  };

  const showTypingInHintSystem = (): void => {
    scrollToBottom();
    setShowTypingAnimation(true);
    setTimeout(function () {
      setShowTypingAnimation(false);
      setTimeout(function () {
        scrollToBottom();
      }, 300);
    }, 2000);
  };

  useEffect(() => {
    if (isIntermission) {
      for (let i = 0; i < messageArray.length; i++) {
        setTimeout(function () {
          setDisplayedMessages((displayedMessages) => [...displayedMessages, messageArray[i]]);
          setTimeout(function () {
            scrollToBottom();
          }, 300);
          if (i == messageArray.length - 1) {
            setShowTypingAnimation(false);
            if (setButton != undefined) {
              setButton();
            }
          }
        }, 5500 * i);
      }
    }
  }, []);

  useEffect(() => {
    if (!isIntermission) {
      showTypingInHintSystem();
    }
  }, [messageArray]);

  return (
    <>
      <StyledMessagesContainer isIntermission={isIntermission} ref={smartphoneScollingContainer}>
        {isIntermission && (
          <>
            {displayedMessages.map((key, index) => (
              <StyledMessage key={index}>{key}</StyledMessage>
            ))}
            {showTypingAnimation && (
              <StyledTypingBox>
                <StyledTypingDot />
                <StyledTypingDot />
                <StyledTypingDot />
              </StyledTypingBox>
            )}
          </>
        )}

        {!isIntermission && (
          <>
            {messageHistory}
            {showTypingAnimation ? (
              <StyledTypingBox>
                <StyledTypingDot />
                <StyledTypingDot />
                <StyledTypingDot />
              </StyledTypingBox>
            ) : (
              latestMessage
            )}
          </>
        )}
        <StyledLastElement isSmall={isIntermission} ref={smartphoneBottomElement} />
      </StyledMessagesContainer>
      <StyledFadeOverlay />
    </>
  );
}

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

const typing = keyframes`
  0% {
    opacity: 0;
  }
  60% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

const StyledMessagesContainer = styled.div<{ isIntermission: boolean }>`
  position: relative;
  width: 100%;
  height: 29rem;
  padding: ${({ isIntermission }) => (isIntermission ? '2rem 3rem 0rem' : '2rem 4rem 0rem')};
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  top: 9rem;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const StyledMessage = styled.div`
  width: 100%;
  padding: 1.4rem 1.2rem;
  background: #eff1f2;
  margin: 0 0 1rem;
  font-size: 1.4rem;
  line-height: 2rem;
  position: relative;

  &:before {
    content: '';
    width: 1rem;
    height: 1rem;
    transform: translateX(-50%) rotate(45deg);
    position: absolute;
    background-color: #eff1f2;
    left: 0rem;
    bottom: 1rem;
  }
`;

const StyledTypingBox = styled.div`
  padding: 1.6rem;
  background: #eff1f2;
  width: 6rem;
  display: flex;
  justify-content: space-between;
`;

const StyledLastMessageWrapper = styled.div`
  opacity: 0;
  animation: ${fadeIn} 0.2s linear 0.5s forwards;
`;

const StyledLastElement = styled.div<{ isSmall: boolean }>`
  width: 100%;
  height: 1rem;
  margin-top: ${({ isSmall }) => (isSmall ? '3rem' : '9rem')};
`;

const StyledFadeOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 80%;
  height: 29rem;
  top: 9rem;
  left: 50%;
  transform: translateX(-50%);
  pointer-events: none;
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0) 2%,
    rgba(255, 255, 255, 0) 90%,
    rgba(255, 255, 255, 1) 100%
  );
`;

const StyledTypingDot = styled.div`
  background-color: #232323;
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
  display: inline-block;
  animation: ${typing} 1.5s ease-in-out infinite;

  &:nth-child(0) {
    animation-delay: 0;
  }
  &:nth-child(1) {
    animation-delay: 0.4s;
  }
  &:nth-child(2) {
    animation-delay: 0.8s;
  }
`;
