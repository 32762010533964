import React, { ReactElement } from 'react';
import styled from 'styled-components';

type Props = {
  children: React.ReactNode;
  columns: number;
};

export function DragAndDropGuestsGrid(props: Props): ReactElement {
  const { children, columns } = props;
  return <StyledGrid columns={columns}>{children}</StyledGrid>;
}

const StyledGrid = styled.div<{ columns: number }>`
  display: grid;
  grid-template-columns: 16% 9% 15% 10% 14% 10% 15% 10%;
  grid-template-rows: repeat(2, 1fr);
  grid-gap: 0;
  justify-items: center;
  position: relative;
  top: 0;
  left: 0;
  width: 80%;
  margin: 0 auto;

  div {
    border-radius: 50%;
    padding: 0;
    margin: -0.15rem;
  }

  div:nth-child(n + ${({ columns }) => columns + 1}) {
    background-color: #bcb8b81a;
    border: 0.3rem white solid;
    margin: 1rem 0;
  }

  div:nth-child(-n + ${({ columns }) => columns}) {
    div {
      position: absolute;
    }
  }

  div:nth-child(1) {
    div {
      right: 95%;
    }
  }

  div:nth-child(2) {
    div {
      right: 79%;
    }
  }

  div:nth-child(3) {
    div {
      right: 62%;
    }
  }

  div:nth-child(4) {
    div {
      right: 46%;
    }
  }

  div:nth-child(5) {
    div {
      right: 30%;
    }
  }

  div:nth-child(6) {
    div {
      right: 14%;
    }
  }

  div:nth-child(7) {
    div {
      right: -3%;
    }
  }

  div:nth-child(8) {
    div {
      display: none;
    }
  }
`;
