export enum SocialLinks {
  FACEBOOK = 'https://www.facebook.com/elbphilharmonie.hamburg',
  INSTAGRAM = 'https://www.instagram.com/elbphilharmonie/',
  TWITTER = 'https://twitter.com/elbphilharmonie',
  TIKTOK = 'https://www.tiktok.com/@elbphilharmoniehamburg',
  YOUTUBE = 'https://www.youtube.com/user/ElbphilharmonieHH',
  SPOTIFY = 'https://open.spotify.com/user/elbphilharmonie',
}

export enum Partners {
  SAP = 'https://www.sap.com/germany/index.html',
  JULIUS = 'https://www.juliusbaer.com/de/ueber-uns/unser-unternehmen',
  KUEHNE = 'https://www.kuehne-stiftung.org/',
  TELEKOM = 'https://www.telekom.com/',
  PORSCHE = 'https://www.porsche.com/',
  ROLEX = 'https://www.rolex.com/',
}

export enum Elbphilharmonie {
  HOME = "https://www.elbphilharmonie.de/",
  ESCAPEROOM = "https://escaperoom.elbphilharmonie.de/",
  TICKETS = "https://elphi.me/escape-game-concerts/",
  NEWSLETTER = "https://www.elbphilharmonie.de/de/newsletter",
  TEAM = "https://www.elbphilharmonie.de/de/mediathek/tag/team",
}