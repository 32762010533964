/* eslint-disable react-hooks/exhaustive-deps */
import { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import styled from 'styled-components';
import ContentContainer from '../components/layout/ContentContainer';

import Background1 from '../assets/intro/background_page1.jpg';
import Background2 from '../assets/intro/background_page2-4.jpg';
import NewspaperDE from '../assets/intro/Intro_Zeitung_DE.png';
import NewspaperEN from '../assets/intro/Intro_Zeitung_EN.png';
import IntroTitleElement from '../components/interface/IntroTitleElement';
import IntroPagination from '../components/interface/IntroPagination';
import Button from '../components/common/Button';
import { ArrowRight } from '../components/icons/Icons';
import PuzzleOptionsContainer from '../components/interface/PuzzleOptionsContainer';
import IntroBackground from '../components/interface/IntroBackground';
import i18next from 'i18next';
import StorySmartphone from '../components/interface/StorySmartphone';
import StoryMessages from '../components/interface/StoryMessages';

export default function IntroScreen(): ReactElement {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [page, setPage] = useState<number>(1);
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(false);
  const isFirstPage = page == 1;

  const handleContinueClick = (): void => {
    if (page == 4) {
      navigate('/puzzleone');
    } else {
      setPage(page + 1);
      setIsButtonDisabled(true);
    }
    if (page == 1) {
      setIsButtonDisabled(true);
    }
  };

  const handleBackClick = (): void => {
    setPage(1);
    setIsButtonDisabled(false);
  };

  const buttonText = (): string => {
    switch (page) {
      case 1:
        return t('intro_screen.buttontext1');
      case 2:
        return t('intro_screen.buttontext2');
      case 3:
        return t('intro_screen.buttontext3');
      case 4:
        return t('intro_screen.buttontext4');
      default:
        return t('intro_screen.buttontext1');
    }
  };

  useEffect(() => {
    setPage(1);
  }, [i18next.language]);

  return (
    <ContentContainer key={i18next.language}>
      <IntroBackground backgroundImage={!isFirstPage ? Background2 : Background1} />

      {isFirstPage && <StyledNewspaper src={i18next.language.slice(0, 2) == 'de' ? NewspaperDE : NewspaperEN} />}

      {!isFirstPage && (
        <StorySmartphone
          messageComponent={<StoryMessages currentPage={page} setButton={() => setIsButtonDisabled(false)} />}
          isIntro
        />
      )}

      <StyledContinueContainer>
        <Button
          onClick={() => handleContinueClick()}
          label={buttonText()}
          color="primary"
          buttonIcon={<ArrowRight />}
          iconPosition={'right'}
          disabled={isButtonDisabled}
        />
      </StyledContinueContainer>
      {!isFirstPage && (
        <StyledBackContainer>
          <Button
            onClick={() => handleBackClick()}
            label={t('intro_screen.back')}
            color="secondary"
            buttonIcon={<ArrowRight />}
            iconPosition={'left'}
          />
        </StyledBackContainer>
      )}
      <PuzzleOptionsContainer puzzleId={0} />
      <IntroPagination pageId={page} />
      <IntroTitleElement headline={!isFirstPage ? t('intro_screen.headline2') : t('intro_screen.headline')} />
    </ContentContainer>
  );
}

const StyledNewspaper = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-5deg);
  width: 80rem;
`;

const StyledContinueContainer = styled.div`
  position: absolute;
  right: 2rem;
  bottom: 2rem;

  ${({ theme }) => theme.breakpoints.queries.xl} {
    bottom: 5rem;
    right: 7rem;
  }

  svg path {
    fill: black;
  }
`;

const StyledBackContainer = styled.div`
  position: absolute;
  left: 2rem;
  bottom: 2rem;

  ${({ theme }) => theme.breakpoints.queries.xl} {
    bottom: 5rem;
    left: 7rem;
  }

  * {
    color: #41cbff;
  }

  svg path {
    fill: #41cbff;
  }

  svg {
    transform: rotate(180deg);
  }
`;
