import { ReactElement } from 'react';
import styled, { keyframes } from 'styled-components';

type Props = {
  isFilled: boolean;
  isCurrent: boolean;
  posY: number;
};

export default function ProgressBar(props: Props): ReactElement {
  const { isFilled, isCurrent, posY } = props;

  return (
    <StyledProgressBar posY={posY}>
      <StyledProgress isFilled={isFilled} isCurrent={isCurrent} />
    </StyledProgressBar>
  );
}

const fillBar = keyframes`
  from { width: 0% }
  to { width: 100% }
`;

const StyledProgressBar = styled.div<{ posY: number }>`
  transform: translateY(${({ posY }) => posY}rem);
  height: 0.2rem;
  width: 5.7rem;
  background-color: white;
  position: relative;
`;

const StyledProgress = styled.div<{ isFilled: boolean; isCurrent: boolean }>`
  position: absolute;
  top: -0.1rem;
  left: 0;
  height: 200%;
  width: ${({ isFilled }) => (isFilled ? '100%' : '0')};
  background-color: #41cbff;
  animation: ${({ isCurrent }) => (isCurrent ? fillBar : 'none')} 1s linear 0.5s forwards;
`;
