import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import i18n from '../../i18n.js';

import Sprite from '../../assets/landing/Sprite.svg';
import { PartnerLogos, SocialIcons } from '../../data/FooterData';
declare global {
  interface Window {
    elbphilharmonie: {
      consent: {
        editSettings: () => void;
      };
    };
  }
}

export default function Footer(): ReactElement {
  const { t } = useTranslation();

  const getSocialIcons = SocialIcons.map((icon) => (
    <StyledSocialIcon
      key={uuidv4()}
      href={icon.link}
      target="_blank"
      position={icon.position}
      width={icon.width}
      height={icon.height}
      aria-label="Social Icon"
    />
  ));

  const getPartnerLogos = PartnerLogos.map((partner) => (
    <a key={uuidv4()} href={partner.link} target="_blank" rel="noreferrer" aria-label="Partner">
      <StyledLogoImage src={partner.image} alt="Partner Logo" width={'100'} height={'70'} />
    </a>
  ));

  const openConsent = (): void => {
    window.elbphilharmonie.consent.editSettings();
  };

  return (
    <>
      <StyledFooterLinksSection>
        <StyledFooterLinks>
          <StyledLink
            href={`https://www.elbphilharmonie.de/${i18n.resolvedLanguage}/contact-elbphilharmonie`}
            target="_blank"
            aria-label="Elbphilharmonie Contact"
          >
            {t('landing_screen.contact_link')}
          </StyledLink>
          <StyledLink
            href={`https://www.elbphilharmonie.de/${i18n.resolvedLanguage}/datenschutzerklaerung`}
            target="_blank"
            aria-label="Elbphilharmonie Privacy"
          >
            {t('landing_screen.privacy_link')}
          </StyledLink>
          <StyledLink
            href={`https://www.elbphilharmonie.de/${i18n.resolvedLanguage}/impressum`}
            target="_blank"
            aria-label="Elbphilharmonie Imprint"
          >
            {t('landing_screen.imprint_link')}
          </StyledLink>
          <StyledLink href="#" onClick={openConsent} aria-label="Elbphilharmonie Cookies">
            {t('landing_screen.cookies_link')}
          </StyledLink>
        </StyledFooterLinks>
        <StyledSocialIcons>{getSocialIcons}</StyledSocialIcons>
      </StyledFooterLinksSection>
      <StyledPartnerLogos>{getPartnerLogos}</StyledPartnerLogos>
    </>
  );
}

const StyledFooterLinksSection = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 0;
  justify-content: space-between;
  width: 90%;
  max-width: 131rem;
  border-bottom: 0.2rem solid #d5d5d5;

  ${({ theme }) => theme.breakpoints.queries.sm} {
    padding: 4rem 0;
    flex-direction: row;
    margin: 0 2rem auto;
  }
`;

const StyledFooterLinks = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  left: 0;
  border-bottom: 0.2rem solid #d5d5d5;
  padding: 4rem 0;
  ${({ theme }) => theme.breakpoints.queries.sm} {
    flex-direction: row;
    border: none;
    padding: 0;
  }
`;

const StyledLink = styled.a`
  display: flex;
  position: relative;
  margin: 1.2rem auto;
  font-size: 1.6rem;
  line-height: 2rem;
  font-weight: 700;
  color: ${({ theme }) => theme.palette.global.black};
  ${({ theme }) => theme.breakpoints.queries.sm} {
    margin: 0 3rem 0 0;
  }
  ${({ theme }) => theme.breakpoints.queries.md} {
    margin: 0 6.7rem 0 0;
  }
`;

const StyledSocialIcons = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  max-width: 30rem;
  margin: 3rem auto;
  justify-items: center;
  align-items: center;
  ${({ theme }) => theme.breakpoints.queries.sm} {
    display: flex;
    position: relative;
    max-width: initial;
    right: 0;
    margin: 0;
  }
`;

const StyledSocialIcon = styled.a<{ position: string; width: number; height: number }>`
  content: '';
  text-indent: 1000%;
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;
  background: url('${Sprite}') no-repeat;
  background-position: ${({ position }) => position};
  background-size: 270px 238px;
  width: ${({ width }) => `${width}rem`};
  height: ${({ height }) => `${height}rem`};
  margin: 2rem;

  ${({ theme }) => theme.breakpoints.queries.sm} {
    margin: 0 0 0 2rem;
  }
  ${({ theme }) => theme.breakpoints.queries.md} {
    margin: 0 0 0 3rem;
  }
  ${({ theme }) => theme.breakpoints.queries.lg} {
    margin: 0 0 0 4rem;
  }
`;

const StyledPartnerLogos = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  justify-items: center;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 5rem 0 5rem;
  width: 90%;
  max-width: 131rem;

  a:last-child {
    grid-column: 1 / span 2;
  }

  ${({ theme }) => theme.breakpoints.queries.sm} {
    display: flex;
    flex-direction: row;
    margin: 5rem 0 8rem;
  }
`;

const StyledLogoImage = styled.img`
  width: auto;
  height: 7rem;
  padding: 1rem;

  ${({ theme }) => theme.breakpoints.queries.sm} {
    padding: 0;
    height: 5rem;
  }

  ${({ theme }) => theme.breakpoints.queries.md} {
    height: 6rem;
  }
`;
