import { Markup } from 'interweave';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css, keyframes } from 'styled-components';
import useContent from '../../hooks/useContent';
import { Puzzle } from '../../types/Puzzle.d';

type Props = {
  puzzleId: Puzzle;
  isPuzzleFinished: boolean;
};

export default function IntermissionTitleElement(props: Props): ReactElement {
  const { puzzleId, isPuzzleFinished } = props;
  const { t } = useTranslation();

  const puzzleTitle = useContent('PuzzleData', 'puzzleTitle', puzzleId);
  const puzzleSubTitle = useContent('PuzzleData', 'puzzleSubTitle', puzzleId);

  return (
    <StyledPuzzleTitleContainer isPuzzleFinished={isPuzzleFinished}>
      <StyledTask isPuzzleFinished={isPuzzleFinished}>
        {t('puzzle_screen.task')}
        {puzzleId}
      </StyledTask>
      <StyledPuzzleTitle isPuzzleFinished={isPuzzleFinished}>
        {isPuzzleFinished ? t('intermission_screen.finish') : puzzleTitle}
      </StyledPuzzleTitle>
      <StyledPuzzleSubTitle isPuzzleFinished={isPuzzleFinished}>
        <Markup content={t(puzzleSubTitle)} />
      </StyledPuzzleSubTitle>
    </StyledPuzzleTitleContainer>
  );
}

const moveDown = keyframes`
  from { transform: translateY(-100%); opacity: 0 }
  to { transform: translateY(0); opacity: 1 }
`;

const fadeInOut = keyframes`
  0% { opacity: 0 }
  10% { opacity: 1 }
  90% { opacity: 1 }
  100% { opacity: 0 }
`;

const introAnimation = keyframes`
  0% { transform: translate(-50%, 120%) scale(1.6); left: 50%; opacity: 0 }
  25% { transform: translate(-50%, 120%) scale(1.6); left: 50%; opacity: 1 }
  85% { transform: translate(-50%, 120%) scale(1.6); left: 50%; opacity: 1 }
  100% { transform: translate(0) scale(1); left: 7rem; opacity: 1 }
`;

const titleAnimation = css`
  ${introAnimation} 12s ease-in-out 0s forwards;
`;

const StyledPuzzleTitleContainer = styled.div<{ isPuzzleFinished: boolean }>`
  position: absolute;
  top: 2rem;
  left: ${({ isPuzzleFinished }) => (isPuzzleFinished ? '2rem' : '50%')};
  z-index: 5;
  transform: ${({ isPuzzleFinished }) => (isPuzzleFinished ? 'translate(0)' : 'translate(-50%, 120%) scale(1.6)')};
  opacity: ${({ isPuzzleFinished }) => (isPuzzleFinished ? '1' : '0')};
  animation: ${({ isPuzzleFinished }) => (isPuzzleFinished ? 'none' : titleAnimation)};

  ${({ theme }) => theme.breakpoints.queries.xl} {
    top: 4rem;
    left: ${({ isPuzzleFinished }) => (isPuzzleFinished ? '7rem' : '50%')};
  }
`;

const StyledTask = styled.h3<{ isPuzzleFinished: boolean }>`
  display: inline-block;
  margin: 0 1.5rem 0 0;
  color: white;
  font-size: 1.6rem;
  transform: translateY(-100%);
  opacity: 0;
  animation: ${moveDown} 0.5s ease-in-out ${({ isPuzzleFinished }) => (isPuzzleFinished ? '0s' : '12s')} forwards;
`;

const StyledPuzzleTitle = styled.h2<{ isPuzzleFinished: boolean }>`
  font-size: 3rem;
  font-weight: 900;
  color: white;
  margin: 3.5rem 0 0;
  text-transform: uppercase;
  position: relative;

  &:after {
    content: '';
    top: -2rem;
    left: 0;
    height: 0.7rem;
    width: 15rem;
    position: absolute;
    background-color: white;
    transform: translateY(-100%);
    opacity: 0;
    animation: ${moveDown} 0.5s ease-in-out ${({ isPuzzleFinished }) => (isPuzzleFinished ? '0s' : '12s')} forwards;
  }
`;

const StyledPuzzleSubTitle = styled.p<{ isPuzzleFinished: boolean }>`
  display: ${({ isPuzzleFinished }) => (isPuzzleFinished ? 'none' : 'block')};
  position: absolute;
  bottom: -3.5rem;
  left: 50%;
  transform: translateX(-50%);
  color: white;
  font-size: 1.6rem;
  white-space: nowrap;
  opacity: 0;
  animation: ${fadeInOut} 9s ease-in-out 1s forwards;
`;
