import Card2 from '../assets/puzzle7/cards/card2.svg';
import Card3 from '../assets/puzzle7/cards/card3.svg';
import Card4 from '../assets/puzzle7/cards/card4.svg';
import Card5 from '../assets/puzzle7/cards/card5.svg';
import Card6 from '../assets/puzzle7/cards/card6.svg';
import Card7 from '../assets/puzzle7/cards/card7.svg';
import Card8 from '../assets/puzzle7/cards/card8.svg';

import Pano1 from '../assets/puzzle7/A.jpg';
import Pano2 from '../assets/puzzle7/B.jpg';
import Pano3 from '../assets/puzzle7/C.jpg';
import Pano4 from '../assets/puzzle7/D.jpg';
import Pano5 from '../assets/puzzle7/E.jpg';
import Pano6 from '../assets/puzzle7/F.jpg';
import Pano7 from '../assets/puzzle7/G.jpg';

import { PuzzleSevenType, PuzzleSevenPanoViewType } from '../types/PuzzleSevenType';
import { useTranslation } from 'react-i18next';

export const PuzzleSevenData: PuzzleSevenType[] = [
  { id: '1', url: Card6 },
  { id: '2', url: '' },
  { id: '3', url: '' },
  { id: '4', url: '' },
  { id: '5', url: '' },
  { id: '6', url: Card5 },
  { id: '7', url: '' },
  { id: '8', url: '' },
  { id: '9', url: '' },
  { id: '10', url: Card3 },
  { id: '11', url: Card4 },
  { id: '12', url: '' },
  { id: '13', url: '' },
  { id: '14', url: '' },
  { id: '15', url: Card7 },
  { id: '16', url: '' },
  { id: '17', url: '' },
  { id: '18', url: Card8 },
  { id: '19', url: Card2 },
  { id: '20', url: '' },
];

export const PuzzleSevenPanoViewData = (): PuzzleSevenPanoViewType[] => {
  const { t } = useTranslation();

  return [
    {
      id: 0,
      title: t('puzzle_seven.direction_button_all.title'),
      subtile: t('puzzle_seven.direction_button_all.subtitle'),
      buttonTitle: 'N/W',
      posX: -1,
      posY: -1,
      panorama: Pano4,
      hotspots: [
        {
          id: 0,
          title: t('puzzle_seven.hotspots.nw.0.title'),
          text: t('puzzle_seven.hotspots.nw.0.text'),
          posX: -47,
          posY: 10,
          bottomLayout: true,
        },
        {
          id: 1,
          title: t('puzzle_seven.hotspots.nw.1.title'),
          text: t('puzzle_seven.hotspots.nw.1.text'),
          posX: -47,
          posY: -7,
          bottomLayout: false,
        },
        {
          id: 2,
          title: t('puzzle_seven.hotspots.nw.2.title'),
          text: t('puzzle_seven.hotspots.nw.2.text'),
          posX: -22.8,
          posY: -2.5,
          bottomLayout: false,
        },
        {
          id: 3,
          title: t('puzzle_seven.hotspots.nw.3.title'),
          text: t('puzzle_seven.hotspots.nw.3.text'),
          posX: -10.4,
          posY: -2,
          bottomLayout: true,
        },
        {
          id: 4,
          title: t('puzzle_seven.hotspots.nw.4.title'),
          text: t('puzzle_seven.hotspots.nw.4.text'),
          posX: 2.45,
          posY: 12,
          bottomLayout: true,
        },
        {
          id: 5,
          title: t('puzzle_seven.hotspots.nw.5.title'),
          text: t('puzzle_seven.hotspots.nw.5.text'),
          posX: 26,
          posY: -4.5,
          bottomLayout: false,
        },
      ],
    },
    {
      id: 1,
      title: t('puzzle_seven.direction_button_all.title'),
      subtile: t('puzzle_seven.direction_button_all.subtitle'),
      buttonTitle: 'N/O',
      posX: 14,
      posY: -1,
      panorama: Pano5,
      hotspots: [
        {
          id: 0,
          title: t('puzzle_seven.hotspots.no.0.title'),
          text: t('puzzle_seven.hotspots.no.0.text'),
          posX: -51.2,
          posY: 10,
          bottomLayout: true,
        },
        {
          id: 1,
          title: t('puzzle_seven.hotspots.no.1.title'),
          text: t('puzzle_seven.hotspots.no.1.text'),
          posX: -45,
          posY: 12,
          bottomLayout: true,
        },
        {
          id: 2,
          title: t('puzzle_seven.hotspots.no.2.title'),
          text: t('puzzle_seven.hotspots.no.2.text'),
          posX: -30.555,
          posY: 13,
          bottomLayout: true,
        },
        {
          id: 3,
          title: t('puzzle_seven.hotspots.no.3.title'),
          text: t('puzzle_seven.hotspots.no.3.text'),
          posX: -16.375,
          posY: 12,
          bottomLayout: true,
        },
        {
          id: 4,
          title: t('puzzle_seven.hotspots.no.4.title'),
          text: t('puzzle_seven.hotspots.no.4.text'),
          posX: -8.685,
          posY: 14,
          bottomLayout: true,
        },
        {
          id: 5,
          title: t('puzzle_seven.hotspots.no.5.title'),
          text: t('puzzle_seven.hotspots.no.5.text'),
          posX: 13,
          posY: 6,
          bottomLayout: true,
        },
        {
          id: 6,
          title: t('puzzle_seven.hotspots.no.6.title'),
          text: t('puzzle_seven.hotspots.no.6.text'),
          posX: 22,
          posY: 9,
          bottomLayout: true,
        },
        {
          id: 7,
          title: t('puzzle_seven.hotspots.no.7.title'),
          text: t('puzzle_seven.hotspots.no.7.text'),
          posX: 37.2,
          posY: -5,
          bottomLayout: false,
        },
        {
          id: 8,
          title: t('puzzle_seven.hotspots.no.8.title'),
          text: t('puzzle_seven.hotspots.no.8.text'),
          posX: 47,
          posY: 3,
          bottomLayout: false,
        },
        {
          id: 9,
          title: t('puzzle_seven.hotspots.no.9.title'),
          text: t('puzzle_seven.hotspots.no.9.text'),
          posX: 50.75,
          posY: -9,
          bottomLayout: false,
        },
      ],
    },
    {
      id: 2,
      title: t('puzzle_seven.direction_button_all.title'),
      subtile: t('puzzle_seven.direction_button_all.subtitle'),
      buttonTitle: 'W',
      posX: -1,
      posY: 6.5,
      panorama: Pano3,
      hotspots: [
        {
          id: 0,
          title: t('puzzle_seven.hotspots.w.0.title'),
          text: t('puzzle_seven.hotspots.w.0.text'),
          posX: -49,
          posY: 7,
          bottomLayout: true,
        },
        {
          id: 1,
          title: t('puzzle_seven.hotspots.w.1.title'),
          text: t('puzzle_seven.hotspots.w.1.text'),
          posX: -44,
          posY: 9,
          bottomLayout: true,
        },
        {
          id: 2,
          title: t('puzzle_seven.hotspots.w.2.title'),
          text: t('puzzle_seven.hotspots.w.2.text'),
          posX: -22,
          posY: 9,
          bottomLayout: true,
        },
        {
          id: 3,
          title: t('puzzle_seven.hotspots.w.3.title'),
          text: t('puzzle_seven.hotspots.w.3.text'),
          posX: -10.5,
          posY: 4,
          bottomLayout: true,
        },
        {
          id: 4,
          title: t('puzzle_seven.hotspots.w.4.title'),
          text: t('puzzle_seven.hotspots.w.4.text'),

          posX: -16.3,
          posY: 11,
          bottomLayout: true,
        },
        {
          id: 5,
          title: t('puzzle_seven.hotspots.w.5.title'),
          text: t('puzzle_seven.hotspots.w.5.text'),
          posX: -5.5,
          posY: 9,
          bottomLayout: true,
        },
        {
          id: 6,
          title: t('puzzle_seven.hotspots.w.6.title'),
          text: t('puzzle_seven.hotspots.w.6.text'),
          posX: -3,
          posY: 7.5,
          bottomLayout: true,
        },
        {
          id: 7,
          title: t('puzzle_seven.hotspots.w.7.title'),
          text: t('puzzle_seven.hotspots.w.7.text'),
          posX: 1,
          posY: 10,
          bottomLayout: true,
        },
        {
          id: 8,
          title: t('puzzle_seven.hotspots.w.8.title'),
          text: t('puzzle_seven.hotspots.w.8.text'),
          posX: 1,
          posY: 12,
          bottomLayout: true,
        },
        {
          id: 9,
          title: t('puzzle_seven.hotspots.w.9.title'),
          text: t('puzzle_seven.hotspots.w.9.text'),
          posX: 2.65,
          posY: 6,
          bottomLayout: true,
        },
        {
          id: 10,
          title: t('puzzle_seven.hotspots.w.10.title'),
          text: t('puzzle_seven.hotspots.w.10.text'),
          posX: 2.65,
          posY: -12,
          bottomLayout: false,
        },
        {
          id: 11,
          title: t('puzzle_seven.hotspots.w.11.title'),
          text: t('puzzle_seven.hotspots.w.11.text'),
          posX: 8.5,
          posY: 12,
          bottomLayout: true,
        },
        {
          id: 12,
          title: t('puzzle_seven.hotspots.w.12.title'),
          text: t('puzzle_seven.hotspots.w.12.text'),
          posX: 13,
          posY: 5,
          bottomLayout: true,
        },
        {
          id: 13,
          title: t('puzzle_seven.hotspots.w.13.title'),
          text: t('puzzle_seven.hotspots.w.13.text'),
          posX: 17,
          posY: 13,
          bottomLayout: true,
        },
        {
          id: 14,
          title: t('puzzle_seven.hotspots.w.14.title'),
          text: t('puzzle_seven.hotspots.w.14.text'),
          posX: 19.75,
          posY: 6,
          bottomLayout: true,
        },
        {
          id: 15,
          title: t('puzzle_seven.hotspots.w.15.title'),
          text: t('puzzle_seven.hotspots.w.15.text'),
          posX: 42,
          posY: 13,
          bottomLayout: true,
        },
      ],
    },
    {
      id: 3,
      title: t('puzzle_seven.direction_button_all.title'),
      subtile: t('puzzle_seven.direction_button_all.subtitle'),
      buttonTitle: 'O',
      posX: 14,
      posY: 6.5,
      panorama: Pano6,
      hotspots: [
        {
          id: 0,
          title: t('puzzle_seven.hotspots.o.0.title'),
          text: t('puzzle_seven.hotspots.o.0.text'),
          posX: -43,
          posY: 14,
          bottomLayout: true,
        },
        {
          id: 1,
          title: t('puzzle_seven.hotspots.o.1.title'),
          text: t('puzzle_seven.hotspots.o.1.text'),
          posX: -27,
          posY: 15.5,
          bottomLayout: true,
        },
        {
          id: 2,
          title: t('puzzle_seven.hotspots.o.2.title'),
          text: t('puzzle_seven.hotspots.o.2.text'),
          posX: -25,
          posY: -12,
          bottomLayout: false,
        },
        {
          id: 3,
          title: t('puzzle_seven.hotspots.o.3.title'),
          text: t('puzzle_seven.hotspots.o.3.text'),
          posX: -16,
          posY: -5,
          bottomLayout: false,
        },
        {
          id: 4,
          title: t('puzzle_seven.hotspots.o.4.title'),
          text: t('puzzle_seven.hotspots.o.4.text'),
          posX: 3,
          posY: 14,
          bottomLayout: true,
        },
/*         {
          id: 5,
          title: t('puzzle_seven.hotspots.o.5.title'),
          text: t('puzzle_seven.hotspots.o.5.text'),
          posX: 5,
          posY: -5.5,
          bottomLayout: false,
        },  */
        {
          id: 5,
          title: t('puzzle_seven.hotspots.o.5.title'),
          text: t('puzzle_seven.hotspots.o.5.text'),
          posX: 12,
          posY: 14,
          bottomLayout: true,
        },
        {
          id: 6,
          title: t('puzzle_seven.hotspots.o.6.title'),
          text: t('puzzle_seven.hotspots.o.6.text'),
          posX: 17,
          posY: -12,
          bottomLayout: false,
        },
      ],
    },
    {
      id: 4,
      title: t('puzzle_seven.direction_button_all.title'),
      subtile: t('puzzle_seven.direction_button_all.subtitle'),
      buttonTitle: 'S/W',
      posX: -1,
      posY: 14,
      panorama: Pano2,
      hotspots: [
        {
          id: 0,
          title: t('puzzle_seven.hotspots.sw.0.title'),
          text: t('puzzle_seven.hotspots.sw.0.text'),
          posX: -50,
          posY: 5.5,
          bottomLayout: true,
        },
        {
          id: 1,
          title: t('puzzle_seven.hotspots.sw.1.title'),
          text: t('puzzle_seven.hotspots.sw.1.text'),
          posX: -37,
          posY: 5,
          bottomLayout: true,
        },
        {
          id: 2,
          title: t('puzzle_seven.hotspots.sw.2.title'),
          text: t('puzzle_seven.hotspots.sw.2.text'),
          posX: -23,
          posY: 5.5,
          bottomLayout: true,
        },
        {
          id: 3,
          title: t('puzzle_seven.hotspots.sw.3.title'),
          text: t('puzzle_seven.hotspots.sw.3.text'),
          posX: -1,
          posY: 7,
          bottomLayout: true,
        },
        {
          id: 4,
          title: t('puzzle_seven.hotspots.sw.4.title'),
          text: t('puzzle_seven.hotspots.sw.4.text'),
          posX: 15,
          posY: -2,
          bottomLayout: false,
        },
        {
          id: 5,
          title: t('puzzle_seven.hotspots.sw.5.title'),
          text: t('puzzle_seven.hotspots.sw.5.text'),
          posX: 28,
          posY: -1.5,
          bottomLayout: false,
        },
        {
          id: 6,
          title: t('puzzle_seven.hotspots.sw.6.title'),
          text: t('puzzle_seven.hotspots.sw.6.text'),
          posX: 33,
          posY: -0.5,
          bottomLayout: true,
        },
        {
          id: 7,
          title: t('puzzle_seven.hotspots.sw.7.title'),
          text: t('puzzle_seven.hotspots.sw.7.text'),
          posX: 45,
          posY: -4,
          bottomLayout: false,
        },
        {
          id: 8,
          title: t('puzzle_seven.hotspots.sw.8.title'),
          text: t('puzzle_seven.hotspots.sw.8.text'),
          posX: 52,
          posY: 2.5,
          bottomLayout: true,
        },
      ],
    },
    {
      id: 5,
      title: t('puzzle_seven.direction_button_all.title'),
      subtile: t('puzzle_seven.direction_button_all.subtitle'),
      buttonTitle: 'S',
      posX: 6.5,
      posY: 14,
      panorama: Pano1,
      hotspots: [
        {
          id: 0,
          title: t('puzzle_seven.hotspots.s.0.title'),
          text: t('puzzle_seven.hotspots.s.0.text'),
          posX: -52,
          posY: -5,
          bottomLayout: false,
        },
        {
          id: 1,
          title: t('puzzle_seven.hotspots.s.1.title'),
          text: t('puzzle_seven.hotspots.s.1.text'),
          posX: -47.5,
          posY: 1,
          bottomLayout: true,
        },
        {
          id: 2,
          title: t('puzzle_seven.hotspots.s.2.title'),
          text: t('puzzle_seven.hotspots.s.2.text'),
          posX: -38,
          posY: 0.7,
          bottomLayout: true,
        },
        {
          id: 3,
          title: t('puzzle_seven.hotspots.s.3.title'),
          text: t('puzzle_seven.hotspots.s.3.text'),
          posX: -17,
          posY: -2,
          bottomLayout: false,
        },
        {
          id: 4,
          title: t('puzzle_seven.hotspots.s.4.title'),
          text: t('puzzle_seven.hotspots.s.4.text'),
          posX: -14,
          posY: 0.1,
          bottomLayout: false,
        },
        {
          id: 5,
          title: t('puzzle_seven.hotspots.s.5.title'),
          text: t('puzzle_seven.hotspots.s.5.text'),
          posX: 2,
          posY: -1.5,
          bottomLayout: false,
        },
        {
          id: 6,
          title: t('puzzle_seven.hotspots.s.6.title'),
          text: t('puzzle_seven.hotspots.s.6.text'),
          posX: 8.5,
          posY: 0.5,
          bottomLayout: false,
        },
        {
          id: 7,
          title: t('puzzle_seven.hotspots.s.7.title'),
          text: t('puzzle_seven.hotspots.s.7.text'),
          posX: 52,
          posY: 2,
          bottomLayout: false,
        },
        {
          id: 8,
          title: t('puzzle_seven.hotspots.s.8.title'),
          text: t('puzzle_seven.hotspots.s.8.text'),
          posX: 52.5,
          posY: -9,
          bottomLayout: false,
        },
      ],
    },
    {
      id: 6,
      title: t('puzzle_seven.direction_button_all.title'),
      subtile: t('puzzle_seven.direction_button_all.subtitle'),
      buttonTitle: 'S/O',
      posX: 14,
      posY: 14,
      panorama: Pano7,
      hotspots: [
        {
          id: 0,
          title: t('puzzle_seven.hotspots.so.0.title'),
          text: t('puzzle_seven.hotspots.so.0.text'),
          posX: -43,
          posY: 4,
          bottomLayout: true,
        },
        {
          id: 1,
          title: t('puzzle_seven.hotspots.so.1.title'),
          text: t('puzzle_seven.hotspots.so.1.text'),
          posX: -27,
          posY: -6,
          bottomLayout: false,
        },
        {
          id: 2,
          title: t('puzzle_seven.hotspots.so.2.title'),
          text: t('puzzle_seven.hotspots.so.2.text'),
          posX: -25,
          posY: 4.5,
          bottomLayout: true,
        },
        {
          id: 3,
          title: t('puzzle_seven.hotspots.so.3.title'),
          text: t('puzzle_seven.hotspots.so.3.text'),
          posX: -20,
          posY: 3,
          bottomLayout: true,
        },
        {
          id: 4,
          title: t('puzzle_seven.hotspots.so.4.title'),
          text: t('puzzle_seven.hotspots.so.4.text'),
          posX: -17,
          posY: -12,
          bottomLayout: false,
        },
        {
          id: 5,
          title: t('puzzle_seven.hotspots.so.5.title'),
          text: t('puzzle_seven.hotspots.so.5.text'),
          posX: 5,
          posY: 2.5,
          bottomLayout: false,
        },
        {
          id: 6,
          title: t('puzzle_seven.hotspots.so.6.title'),
          text: t('puzzle_seven.hotspots.so.6.text'),
          posX: 12.5,
          posY: 3,
          bottomLayout: true,
        },
        {
          id: 7,
          title: t('puzzle_seven.hotspots.so.7.title'),
          text: t('puzzle_seven.hotspots.so.7.text'),
          posX: 34,
          posY: 3.3,
          bottomLayout: true,
        },
        {
          id: 8,
          title: t('puzzle_seven.hotspots.so.8.title'),
          text: t('puzzle_seven.hotspots.so.8.text'),
          posX: 40,
          posY: 5,
          bottomLayout: true,
        },
        {
          id: 9,
          title: t('puzzle_seven.hotspots.so.9.title'),
          text: t('puzzle_seven.hotspots.so.9.text'),
          posX: 43,
          posY: 6,
          bottomLayout: true,
        },
      ],
    },
  ];
};
