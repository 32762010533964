import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

import ComponentContainer from '../common/ComponentContainer';
import { Eye, Friends, Puzzle, Ticket } from '../icons/Icons';
import { Markup } from 'interweave';

export default function IconsSection(): ReactElement {
  const { t } = useTranslation();

  const iconElements = [
    {
      title: t('landing_screen.icon1.title'),
      copy: t('landing_screen.icon1.copy'),
      icon: <Puzzle />,
    },
    {
      title: t('landing_screen.icon2.title'),
      copy: t('landing_screen.icon2.copy'),
      icon: <Eye />,
    },
    {
      title: t('landing_screen.icon3.title'),
      copy: t('landing_screen.icon3.copy'),
      icon: <Friends />,
    },
    {
      title: t('landing_screen.icon4.title'),
      copy: t('landing_screen.icon4.copy'),
      icon: <Ticket />,
    },
  ];

  const getIcons = iconElements.map((iconElement) => {
    return (
      <StyledIconColumn key={uuidv4()}>
        {iconElement.icon}
        <StyledIconTitle> {iconElement.title}</StyledIconTitle>
        <StyledIconText>{iconElement.copy}</StyledIconText>
      </StyledIconColumn>
    );
  });

  return (
    <ComponentContainer>
      <StyledContentContainer>
        <StyledTextWrapper>
          <StyledLine />
          <StyledSectionTitle>
            <Markup content={t('landing_screen.icon_section_title')} />
          </StyledSectionTitle>
          <StyledText>{t('landing_screen.icon_section_copy')}</StyledText>
        </StyledTextWrapper>
        <StyledIconsContainer>{getIcons}</StyledIconsContainer>
      </StyledContentContainer>
    </ComponentContainer>
  );
}

const StyledContentContainer = styled.div`
  max-width: 115rem;
  margin: 0 auto;
`;

const StyledTextWrapper = styled.div`
  max-width: 77rem;
  margin: 0 auto;
`;

const StyledLine = styled.hr`
  width: 15rem;
  height: 0.8rem;
  background: ${({ theme }) => theme.palette.global.black};
  padding: 0;
  margin: 0 0 3rem 0;
  border: none;
  ${({ theme }) => theme.breakpoints.queries.sm} {
    width: 25rem;
  }
`;

const StyledSectionTitle = styled.h1`
  text-transform: uppercase;
  font-weight: 900;
  font-size: 2.4rem;
  line-height: 3rem;
  margin: 0 0 4.6rem;
`;

const StyledText = styled.p`
  font-size: 1.8rem;
  line-height: 2.8rem;
`;

const StyledIconsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-content: flex-start;
  text-align: center;
  margin: 6rem 1rem 0;
  ${({ theme }) => theme.breakpoints.queries.md} {
    flex-direction: row;
    margin: 8rem 1rem;
  }
`;

const StyledIconColumn = styled.div`
  width: 100%;
  padding: 0 0 6rem;
  ${({ theme }) => theme.breakpoints.queries.md} {
    width: 25%;
    padding: 0;
  }
`;

const StyledIconTitle = styled.h3`
  text-transform: uppercase;
  margin: 1rem;
  font-size: 2rem;
  line-height: 2.5rem;
  font-weight: 700;
`;

const StyledIconText = styled.p`
  font-size: 1.8rem;
  line-height: 2.8rem;
  max-width: 24.8rem;
  margin: 0 auto;
  ${({ theme }) => theme.breakpoints.queries.md} {
    max-width: 22rem;
  }
`;
