import { ReactElement } from 'react';
import styled from 'styled-components';
import useContent from '../../hooks/useContent';
import { Puzzle } from '../../types/Puzzle.d';
import ContentContainer from '../layout/ContentContainer';

type Props = {
  backgroundImage: string;
  puzzleId: Puzzle;
};

export default function BackgroundImage(props: Props): ReactElement {
  const { backgroundImage, puzzleId } = props;

  const puzzleColor = useContent('PuzzleData', 'puzzleColor', puzzleId).toString();

  return (
    <ContentContainer>
      <StyledBackgroundImageContainer>
        <StyledBackgroundImage src={backgroundImage}></StyledBackgroundImage>
        <StyledGradients color={puzzleColor} />
      </StyledBackgroundImageContainer>
    </ContentContainer>
  );
}

const StyledBackgroundImageContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  text-align: center;
  overflow: hidden;
`;

const StyledBackgroundImage = styled.img`
  height: 100%;
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
`;

const StyledGradients = styled.div<{ color: string }>`
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: linear-gradient(
      90deg,
      ${({ color }) => color} 0%,
      ${({ color }) => color}00 10%,
      ${({ color }) => color}00 90%,
      ${({ color }) => color} 100%
    ),
    linear-gradient(0deg, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0) 20%, rgba(0, 0, 0, 0) 80%, rgba(0, 0, 0, 0.7) 100%);
`;
