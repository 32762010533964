/* eslint-disable react-hooks/exhaustive-deps */
import { ReactElement, useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import i18next from 'i18next';
import useContent from '../../hooks/useContent';
import { Puzzle } from '../../types/Puzzle';
import PhoneMessages from './PhoneMessages';
import Smartphone from '../../assets/Smartphone.svg';
import SmartphoneIcons from '../../assets/Smartphone_Icons.svg';
import NuriaPfP from '../../assets/nuria.png';
import { SpeechBubble, Error, Check, CheckSolution } from '../icons/Icons';
import { useTranslation } from 'react-i18next';
import tracking from '../../services/tracking';

type Props = {
  puzzleId: Puzzle;
};

export default function HintSystem(props: Props): ReactElement {
  const { puzzleId } = props;

  const { t } = useTranslation();

  const [isSmartphoneExtended, setIsSmartphoneExtended] = useState<boolean>(false);
  const [showGetHintOverlay, setShowGetHintOverlay] = useState<boolean>(false);

  const [displayedMessages, setDisplayedMessages] = useState<string[]>([]);
  const [hintIndex, setHintIndex] = useState<number>(0);

  const intermissionStartMessages = useContent('IntermissionData', 'introMessages', puzzleId) as string[];
  const puzzleHints = useContent('PuzzleData', 'puzzleHints', puzzleId);
  const taskSteps = useContent('PuzzleData', 'taskSteps', puzzleId);

  const onLastHint = hintIndex === puzzleHints.length - 1;
  const areHintsUsedUp = hintIndex === puzzleHints.length;

  const callNextHint = (): void => {
    tracking.puzzleHintUse(hintIndex, puzzleId);
    setShowGetHintOverlay(false);
    if (hintIndex < puzzleHints.length) {
      const currentHint = puzzleHints[hintIndex];
      setDisplayedMessages([...displayedMessages, currentHint]);
      setHintIndex(hintIndex + 1);
    }
  };

  const closeHintSystem = (): void => {
    setIsSmartphoneExtended(false);
    setShowGetHintOverlay(false);
  };

  const taskItems =
    Array.isArray(taskSteps) &&
    taskSteps.map((key, index) => (
      <StyledTaskItem key={index} isExtended={isSmartphoneExtended}>
        {key}
      </StyledTaskItem>
    ));

  useEffect(() => {
    setDisplayedMessages(displayedMessages.concat(intermissionStartMessages));
  }, []);

  useEffect(() => {
    setIsSmartphoneExtended(false);
    setDisplayedMessages(intermissionStartMessages);
    setHintIndex(0);
  }, [i18next.language]);

  return (
    <>
      <StyledDarkBackground isExtended={isSmartphoneExtended} onClick={() => setIsSmartphoneExtended(false)} />
      <StyledCutOff>
        <StyledHintContainer isExtended={isSmartphoneExtended} key={i18next.language}>
          <StyledTaskDescContainer isExtended={isSmartphoneExtended}>
            <StyledTaskTitle isExtended={isSmartphoneExtended}>{t('hint_system.task')}</StyledTaskTitle>
            <StyledTaskItemsContainer>{taskItems}</StyledTaskItemsContainer>
            <StyledHintButton
              isHidden={areHintsUsedUp}
              isExtended={isSmartphoneExtended}
              onClick={() => setShowGetHintOverlay(true)}
            >
              {onLastHint ? <StyledCheckSolutionIcon /> : <StyledSpeechBubbleIcon />}
              {onLastHint ? <p>{t('hint_system.get_solution')}</p> : <p>{t('hint_system.get_hint')}</p>}
            </StyledHintButton>

            <StyledHintOverlay isVisible={showGetHintOverlay}>
              <StyledHintTitle>
                {hintIndex}
                {t('hint_system.hint_nuria')}
              </StyledHintTitle>
              <StyledHintDescription>{t('hint_system.hint_nuria_description')}</StyledHintDescription>
              <StyledYesButton onClick={callNextHint}>
                <StyledCheckIcon />
                <p>{t('hint_system.yes')}</p>
              </StyledYesButton>
              <StyledNoButton onClick={() => setShowGetHintOverlay(false)}>
                <StyledErrorIcon />
                <p>{t('hint_system.no')}</p>
              </StyledNoButton>
            </StyledHintOverlay>
          </StyledTaskDescContainer>
          <StyledSmartphone onClick={() => setIsSmartphoneExtended(true)} bg={Smartphone}>
            <StyledFadeInContainer isExtended={isSmartphoneExtended}>
              <PhoneMessages isIntermission={false} messageArray={displayedMessages} />
            </StyledFadeInContainer>
          </StyledSmartphone>
          <StyledCloseButton onClick={closeHintSystem}>
            <p>{t('hint_system.close')}</p>
          </StyledCloseButton>
          <StyledProfilePicture isExtended={isSmartphoneExtended} src={NuriaPfP} />
          <StyledName isExtended={isSmartphoneExtended}>{t('hint_system.nuria')}</StyledName>
          <StyledPhoneIcons isExtended={isSmartphoneExtended} src={SmartphoneIcons} />
        </StyledHintContainer>
      </StyledCutOff>
    </>
  );
}

const extendTaskDescContainer = keyframes`
  from {
    width: 0;
    opacity: 0;
  }

  to {
    width: 32rem;
    opacity: 1;
  }
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

const StyledHintContainer = styled.div<{ isExtended: boolean }>`
  position: absolute;
  left: 7rem;
  bottom: ${({ isExtended }) => (isExtended ? '-18rem' : '-22rem')};
  width: ${({ isExtended }) => (isExtended ? '31.6rem' : '15rem')};
  height: ${({ isExtended }) => (isExtended ? '63rem' : '35rem')};
  transform: rotate(${({ isExtended }) => (isExtended ? '0deg' : '-10deg')});
  transition: all 0.3s ease-in-out;
  cursor: ${({ isExtended }) => (isExtended ? 'default' : 'pointer')};
  z-index: 8;
  pointer-events: all;

  &:hover {
    bottom: ${({ isExtended }) => (isExtended ? '-18rem' : '-20rem')};
  }
`;

const StyledCutOff = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  z-index: 5;
  pointer-events: none;
`;

const StyledDarkBackground = styled.div<{ isExtended: boolean }>`
  position: fixed;
  top: -100vh;
  left: -100vw;
  width: 300vw;
  height: 300vh;
  background-color: black;
  opacity: ${({ isExtended }) => (isExtended ? '0.8' : '0')};
  pointer-events: ${({ isExtended }) => (isExtended ? 'all' : 'none')};
  transition: opacity 0.5s ease-in-out;
  z-index: 5;
`;

const StyledSmartphone = styled.div<{ bg: string }>`
  width: 100%;
  height: 100%;
  background-image: url('${({ bg }) => bg}');
  background-size: contain;
  z-index: 6;
`;

const StyledCloseButton = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 40rem;
  cursor: pointer;
  padding: 0.3rem 1rem;
  border-radius: 1rem;
  text-transform: uppercase;
  background-color: transparent;
  transition: all 0.3s;
  font-size: 1.3rem;

  & p {
    margin: 0 0 0 1.5rem;
  }

  &:before {
    content: '';
    position: absolute;
    left: 1.3rem;
    top: 0.3rem;
    width: 0.1rem;
    height: 1.3rem;
    transform: rotate(45deg);
    background-color: black;
    transition: transform 0.3s;
  }

  &:after {
    content: '';
    position: absolute;
    left: 1.3rem;
    top: 0.3rem;
    width: 0.1rem;
    height: 1.3rem;
    transform: rotate(-45deg);
    background-color: black;
    transition: transform 0.3s;
  }

  &:hover {
    background-color: #eff1f2;

    &:before {
      transform: rotate(135deg);
    }

    &:after {
      transform: rotate(45deg);
    }
  }
`;

const StyledTaskDescContainer = styled.div<{ isExtended: boolean }>`
  position: absolute;
  top: 4rem;
  left: 99%;
  animation: ${extendTaskDescContainer} 0.5s ease-in-out 0.3s forwards;
  display: ${({ isExtended }) => (isExtended ? 'block' : 'none')};
  width: 0;
  opacity: 0;
  background-color: #01b9ff33;
  transition: width 0.5s ease-in-out 0.5s;
  overflow: hidden;
`;

const StyledTaskTitle = styled.h3<{ isExtended: boolean }>`
  display: ${({ isExtended }) => (isExtended ? 'block' : 'none')};
  animation: ${fadeIn} 0.5s ease-in-out 1s forwards;
  opacity: 0;
  font-size: 1.8rem;
  color: white;
  text-transform: uppercase;
  padding: 0.4rem 0 1.7rem;
  position: relative;
  font-weight: 600;
  width: 30rem;
  margin: 4rem 5rem 0;

  &:before {
    content: '';
    width: 5.4rem;
    height: 0.5rem;
    background-color: white;
    position: absolute;
    top: -0.8rem;
    left: 0;
  }
`;

const StyledTaskItemsContainer = styled.div`
  min-height: 10rem;
`;

const StyledHintTitle = styled.h3`
  font-size: 1.8rem;
  color: black;
  text-transform: uppercase;
  padding: 0.4rem 0 3rem;
  position: relative;
  font-weight: 600;
  width: 30rem;
  margin: 4rem 5rem 0;

  &:before {
    content: '';
    width: 5.4rem;
    height: 0.5rem;
    background-color: black;
    position: absolute;
    top: -0.8rem;
    left: 0;
  }
`;

const StyledHintDescription = styled.p`
  margin: 0 5rem 4rem;
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 1.8rem;
`;

const StyledTaskItem = styled.p<{ isExtended: boolean }>`
  display: ${({ isExtended }) => (isExtended ? 'block' : 'none')};
  animation: ${fadeIn} 0.5s ease-in-out 1s forwards;
  opacity: 0;
  font-size: 1.4rem;
  color: white;
  padding: 0 0 1rem;
  position: relative;
  margin: 0 0 0 1.5rem;
  font-weight: 300;
  line-height: 1.8rem;
  width: 23rem;
  margin: 0 5rem;

  &:before {
    content: '>';
    position: absolute;
    left: -1.5rem;
    color: white;
    font-size: 1.4rem;
    top: -0.1rem;
  }
`;

const StyledHintButton = styled.div<{ isExtended: boolean; isHidden: boolean }>`
  display: ${({ isExtended }) => (isExtended ? 'block' : 'none')};
  animation: ${fadeIn} 0.5s ease-in-out 1.5s forwards;
  opacity: 0;
  padding: 1rem;
  border: 0.2rem solid #41cbff;
  font-weight: 600;
  font-size: 1.4rem;
  color: #41cbff;
  cursor: pointer;
  margin: 1rem 5rem 4rem;
  display: inline-flex;
  align-items: center;
  transition: background-color 0.3s;
  visibility: ${({ isHidden }) => (isHidden ? 'hidden' : 'visible')};

  &:hover {
    background-color: #01b9ff33;
  }
`;

const StyledYesButton = styled.div`
  padding: 1rem;
  border: 0.2rem solid white;
  background-color: white;
  font-weight: 600;
  font-size: 1.4rem;
  color: black;
  cursor: pointer;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 4rem 0 5rem;
`;

const StyledNoButton = styled.div`
  padding: 1rem;
  border: 0.2rem solid black;
  font-weight: 600;
  font-size: 1.4rem;
  color: black;
  cursor: pointer;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledCheckSolutionIcon = styled(CheckSolution)`
  width: 1.5rem;
  height: 1.5rem;
  object-fit: cover;
  margin: 0 1rem 0 0;

  & path {
    fill: #41cbff;
    stroke: #41cbff;
  }
`;

const StyledSpeechBubbleIcon = styled(SpeechBubble)`
  width: 1.5rem;
  height: 1.5rem;
  object-fit: cover;
  margin: 0 1rem 0 0;
`;

const StyledErrorIcon = styled(Error)`
  width: 1.2rem;
  height: 1.2rem;
  object-fit: cover;
  margin: 0 0.5rem 0 0;

  & path {
    fill: black;
  }
`;

const StyledCheckIcon = styled(Check)`
  width: 1.2rem;
  height: 1.2rem;
  object-fit: cover;
  margin: 0 0.5rem 0 0;
`;

const StyledFadeInContainer = styled.div<{ isExtended: boolean }>`
  opacity: ${({ isExtended }) => (isExtended ? '1' : '0')};
  visibility: ${({ isExtended }) => (isExtended ? 'visible' : 'hidden')};
  transition: opacity 0.6s 0.3s;
`;

const StyledProfilePicture = styled.img<{ isExtended: boolean }>`
  position: absolute;
  left: ${({ isExtended }) => (isExtended ? '20%' : '50%')};
  transform: translateX(-50%);
  top: ${({ isExtended }) => (isExtended ? '4rem' : '3rem')};
  width: 4rem;
  border-radius: 50%;
  pointer-events: none;
  transition: all 0.3s;
`;

const StyledName = styled.h3<{ isExtended: boolean }>`
  position: absolute;
  left: ${({ isExtended }) => (isExtended ? '37%' : '50%')};
  transform: translateX(-50%);
  top: ${({ isExtended }) => (isExtended ? '5rem' : '8rem')};
  font-size: 1.8rem;
  font-weight: 900;
  pointer-events: none;
  transition: all 0.3s;
`;

const StyledPhoneIcons = styled.img<{ isExtended: boolean }>`
  position: absolute;
  right: 4.3rem;
  top: 2.7rem;
  width: 5.4rem;
  opacity: ${({ isExtended }) => (isExtended ? '1' : '0')};
  transition: opacity 0.3s;
`;

const StyledHintOverlay = styled.div<{ isVisible: boolean }>`
  width: 100%;
  height: 100%;
  background-color: #41cbff;
  position: absolute;
  top: 0;
  left: 0;
  opacity: ${({ isVisible }) => (isVisible ? '1' : '0')};
  pointer-events: ${({ isVisible }) => (isVisible ? 'all' : 'none')};
  transition: opacity 0.3s;
`;
