import { ReactElement } from 'react';
import styled, { useTheme } from 'styled-components';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import Music from '../../assets/puzzle3/orchesterprobe.mp3';

import { ReactComponent as Play } from '../../assets/puzzle3/icons/Play2.svg';
import { ReactComponent as Pause } from '../../assets/puzzle3/icons/pause.svg';
import { useTranslation } from 'react-i18next';

export default function AudioPlayerWithControls(): ReactElement {
  const theme = useTheme();
  const colorPrimary = theme.palette.company.primary;
  const { t } = useTranslation();

  return (
    <StyledAudioPlayerContainer color={colorPrimary}>
      <StyledAudioPlayerTitle>{t('puzzle_three.audio_file')}</StyledAudioPlayerTitle>
      <AudioPlayer
        customIcons={{ play: <Play />, pause: <Pause /> }}
        src={Music}
        autoPlay={false}
        volume={0.5}
        showSkipControls={false}
        showJumpControls={false}
        layout={'horizontal-reverse'}
      />
      <StyledAudioPlayerInfo color={colorPrimary}></StyledAudioPlayerInfo>
    </StyledAudioPlayerContainer>
  );
}

const StyledAudioPlayerTitle = styled.div`
  font-size: 1.2rem;
  font-weight: 500;
  margin: 0 0 0.3rem;
`;

const StyledAudioPlayerInfo = styled.div<{ color: string }>`
  padding: 0.5rem 1.9rem;
  position: absolute;
  right: 0;
  bottom: 0rem;
  font-size: 1.2rem;
  font-weight: 600;
  color: ${(props) => props.color};
`;

const StyledAudioPlayerContainer = styled.div<{ color: string }>`
  position: relative;
  background: #fff;
  width: 25rem;
  height: 5.8rem;
  box-shadow: 1rem 1rem 1rem rgba(0, 0, 0, 0.3);
  padding: 1rem;

  .rhap_container {
    position: absolute;
    border: 0;
    width: 100%;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgb(255, 255, 255);
    display: flex;
    justify-items: center;
    padding: 0.25rem 0.75rem;
    box-shadow: none;
  }

  .rhap_controls-section {
    flex: initial;
  }

  .rhap_main {
    height: 2rem;
  }

  .rhap_time {
    color: ${(props) => props.color};
    font-size: 1.2rem;
    margin: 0.2rem 0.3rem 0.3rem 0.1rem;
    font-weight: 500;
  }

  .rhap_controls-section .rhap_volume-controls {
    display: none;
  }

  .rhap_progress-container {
    margin: 0 1rem 0 0.3rem;
  }

  .rhap_progress-bar {
    border-radius: 0rem;
  }

  .rhap_main-controls-button {
    font-size: 1rem;
  }

  .rhap_play-pause-button {
    width: 1rem;
  }

  .rhap_progress-indicator {
    width: 1rem;
    height: 1rem;
    top: -0.3rem;
    margin-left: -0.3rem;
    background: ${(props) => props.color};
  }

  .rhap_controls-section .rhap_main-controls button[aria-label='Play'] svg path {
    fill: ${(props) => props.color};
  }

  .rhap_controls-section .rhap_main-controls button[aria-label='Pause'] svg path {
    fill: ${(props) => props.color};
  }
  .rhap_controls-section .rhap_repeat-button {
    display: none;
  }
  .rhap_controls-section .rhap_volume-button {
    display: none;
  }
  .rhap_progress-bar {
    height: 0.4rem;
  }
  .rhap_progress-filled {
    background-color: ${(props) => props.color};
    border-radius: 0rem;
  }
  .rhap_download-progress {
    background-color: #01b9ff4d;
    border-radius: 0rem;
  }
  .rhap_time.rhap_total-time {
    display: none;
  }
  .rhap_horizontal-reverse .rhap_controls-section {
    margin-right: 0rem;
  }
`;
