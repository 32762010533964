import React, { ReactElement } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import styled from 'styled-components';

import { GuestImage } from './GuestImage';
import { GalleryPicture } from './GalleryPicture';
import { Puzzle } from '../../types/Puzzle.d';
import { SightsCard } from './SightsCard';

type Props = {
  id: string | number;
  url: string;
  audio: string;
  totalColumnsNumber: number;
  puzzleId: Puzzle;
};

DragAndDropSortableItem.defaultProps = {
  audio: '',
};

export function DragAndDropSortableItem(props: Props): ReactElement {
  const { id, url, audio, totalColumnsNumber, puzzleId } = props;
  const { attributes, listeners, setNodeRef, transform, isDragging, transition } = useSortable({ id: props.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const getSortbaleItem = (puzzleId: Puzzle): ReactElement => {
    switch (puzzleId) {
      case Puzzle.FOUR:
        return (
          <GalleryPicture
            isTransparent={false}
            isDragging={isDragging}
            index={id}
            ref={setNodeRef}
            style={style}
            url={url}
            audio={audio}
            totalColumnsNumber={totalColumnsNumber}
            {...attributes}
            {...listeners}
          />
        );
      case Puzzle.SIX:
        return (
          <GuestImage
            isTransparent={false}
            isDragging={isDragging}
            index={id}
            ref={setNodeRef}
            style={style}
            url={url}
            totalColumnsNumber={totalColumnsNumber}
            {...attributes}
            {...listeners}
          />
        );
      default:
        return (
          <SightsCard
            isTransparent={false}
            isDragging={isDragging}
            index={id}
            ref={setNodeRef}
            style={style}
            url={url}
            totalColumnsNumber={totalColumnsNumber}
            {...attributes}
            {...listeners}
          />
        );
    }
  };

  return <StyledImageContainer url={url}>{getSortbaleItem(puzzleId)}</StyledImageContainer>;
}

const StyledImageContainer = styled.div<{ url: string }>`
  background-color: transparent;
  text-align: center;
  font-size: 3rem;
  color: white;
  cursor: pointer;
  pointer-events: ${({ url }) => (url == '' ? 'none ' : 'auto')};
`;
