import { ReactElement, useState } from 'react';
import styled from 'styled-components';
import Lottie from 'lottie-react';
import { v4 as uuidv4 } from 'uuid';

import Off from '../../assets/puzzle5/frequency/Frequenz_01_Aus_1024.json';
import Flat from '../../assets/puzzle5/frequency/Frequenz_02_Flach_1024.json';
import Sinus from '../../assets/puzzle5/frequency/Frequenz_03_Sinus_1024.json';
import ShortLong from '../../assets/puzzle5/frequency/Frequenz_04_KurzKurzLang_1024.json';
import Random from '../../assets/puzzle5/frequency/Frequenz_05_Random_1024.json';

import Button1 from '../../assets/puzzle5/frequency/Frequenz01.png';
import Button2 from '../../assets/puzzle5/frequency/Frequenz02.png';
import Button3 from '../../assets/puzzle5/frequency/Frequenz03.png';
import Button4 from '../../assets/puzzle5/frequency/Frequenz04.png';
import Button5 from '../../assets/puzzle5/frequency/Frequenz05.png';

import FrequencyBackground from '../../assets/puzzle5/frequency/background_frequency.jpg';
import useBreakpoint from '../../hooks/useBreakpoint';

export function FrequencyWaves(): ReactElement {
  const [animation, setAnimation] = useState<object>(Off);
  const [activeButton, setActiveButton] = useState<number>(0);
  const isDesktop = useBreakpoint('lg');

  const buttons = [
    {
      index: 0,
      src: Button1,
      left: 0,
      top: -22,
      leftTablet: 0,
      topTablet: -19,
    },
    {
      index: 1,
      src: Button2,
      left: 0,
      top: -13.5,
      leftTablet: 0,
      topTablet: -12.3,
    },
    {
      index: 2,
      src: Button3,
      top: -5.2,
      left: 0.8,
      topTablet: -4.9,
      leftTablet: 0.6,
    },
    {
      index: 3,
      src: Button4,
      top: 3.5,
      left: 1,
      topTablet: 3.2,
      leftTablet: 1,
    },
    {
      index: 4,
      src: Button5,
      top: 11.5,
      left: 2,
      topTablet: 10.4,
      leftTablet: 2,
    },
  ];

  const getActiveButton = buttons.map((button) => {
    return (
      <StyledButtonWrapper key={uuidv4()} isActiveButtonVisible={activeButton === button.index}>
        <StyledButtonImage
          src={button.src}
          left={button.left}
          top={button.top}
          topTablet={button.topTablet}
          leftTablet={button.leftTablet}
          alt="Button Image"
        />
      </StyledButtonWrapper>
    );
  });

  const getAnimation = (index: number | undefined): void => {
    switch (index) {
      case 0:
        setActiveButton(0);
        return setAnimation(Off);
      case 1:
        setActiveButton(1);
        return setAnimation(Flat);
      case 2:
        setActiveButton(2);
        return setAnimation(Sinus);
      case 3:
        setActiveButton(3);
        return setAnimation(ShortLong);
      case 4:
        setActiveButton(4);
        return setAnimation(Random);
      default:
        setActiveButton(0);
        return setAnimation(Off);
    }
  };

  return (
    <>
      <StyledContainer>
        <StyledBackgroundImage src={FrequencyBackground} useMap={'#map'} />
        <map name="map" id="map">
          <StyledArea
            shape="circle"
            alt=""
            title=""
            coords={isDesktop ? '1116,204,22' : '991,183,19'}
            onClick={() => getAnimation(0)}
          />
          <StyledArea
            shape="circle"
            alt=""
            title=""
            coords={isDesktop ? '1120,286,21' : '995,253,18'}
            onClick={() => getAnimation(1)}
          />
          <StyledArea
            shape="circle"
            alt=""
            title=""
            coords={isDesktop ? '1125,370,21' : '999,329,18'}
            onClick={() => getAnimation(2)}
          />
          <StyledArea
            shape="circle"
            alt=""
            title=""
            coords={isDesktop ? '1131,456,20' : '1005,406,19'}
            onClick={() => getAnimation(3)}
          />
          <StyledArea
            shape="circle"
            alt=""
            title=""
            coords={isDesktop ? '1135,543,22' : '1011,482,19'}
            onClick={() => getAnimation(4)}
          />
        </map>
        <StyledLottie animationData={animation} loop={true} />
        {getActiveButton}
      </StyledContainer>
    </>
  );
}

const StyledContainer = styled.div`
  position: absolute;
  width: 128rem;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  ${({ theme }) => theme.breakpoints.queries.lg} {
    width: 144rem;
  }
`;

const StyledLottie = styled(Lottie)`
  position: absolute;
  z-index: 3;
  width: 71rem;
  height: auto;
  top: 50%;
  left: 47.7%;
  transform: translate(-50%, -50%);
  ${({ theme }) => theme.breakpoints.queries.lg} {
    width: 80rem;
  }
`;

const StyledArea = styled.area`
  cursor: pointer;
`;

const StyledButtonWrapper = styled.div<{ isActiveButtonVisible: boolean }>`
  display: ${({ isActiveButtonVisible }) => (isActiveButtonVisible ? 'block' : 'none')};
  position: absolute;
  z-index: 3;
  top: 0%;
  left: 74.7%;
`;

const StyledButtonImage = styled.img<{ top: number; left: number; topTablet: number; leftTablet: number }>`
  position: absolute;
  width: 15rem;
  top: ${({ topTablet }) => `${topTablet}rem`};
  left: ${({ leftTablet }) => `${leftTablet}rem`};
  ${({ theme }) => theme.breakpoints.queries.lg} {
    top: ${({ top }) => `${top}rem`};
    left: ${({ left }) => `${left}rem`};
    width: 17rem;
  }
`;

const StyledBackgroundImage = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
`;
