/* eslint-disable react-hooks/exhaustive-deps */
import i18next from 'i18next';
import React, { ReactElement, useEffect } from 'react';
import tracking from '../services/tracking';
import util from '../services/util';

declare const window: Window &
  typeof globalThis & {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    elbphilharmonie: any;
  };

export default function ElphiConsentProvider(): ReactElement {
  const urlParams = new URLSearchParams(window.location.search);

  function initTrackingEvents(): void {
    const parameterArray: { [x: string]: string }[] = [];
    urlParams.forEach((key, value) => parameterArray.push({ [value]: key }));

    tracking.initializeAndActivate(util.loadEnvVarString('REACT_APP_GA_MEASUREMENT_ID'));
    tracking.pageView();

    if (parameterArray.length !== 0) {
      tracking.urlParameters(parameterArray);
    }
  }

  useEffect(() => {
    if (!window.elbphilharmonie) {
      const script = document.createElement('script');
      script.async = true;
      script.src =
        'https://' +
        util.loadEnvVarString('REACT_APP_ELBPHILHARMONIE_COOKIE_CONSENT_HOST') +
        `/ep/consent-${i18next.resolvedLanguage}.js`;

      document.body.appendChild(script);

      script.onload = function () {
        const api = window.elbphilharmonie.consent;

        if (api.getConsent('google_analytics')) {
          // Einwilligung wurde bereits erklaert
          initTrackingEvents();
        }
        api.onEvent('consentChanged', function () {
          // Noch keine Entscheidung oder diese wurde
          // geaendert. Wird asynchron aufgerufen,
          // sobald Entscheidung getroffen wurde.
          if (api.getConsent('google_analytics')) {
            initTrackingEvents();
          } else {
            tracking.deActivate();
          }
        });
      };
    }
  }, []);

  return <></>;
}
