import { ReactElement, useEffect, useState } from 'react';
import styled from 'styled-components';
import { DragOverlay, DragEndEvent, DragStartEvent } from '@dnd-kit/core';
import { arrayMove, SortableContext, rectSortingStrategy } from '@dnd-kit/sortable';
import { v4 as uuidv4 } from 'uuid';

import BookSlider from '../puzzleelements/BookSlider';
import PuzzleOverlay from '../interface/PuzzleOverlay';
import Background from '../../assets/puzzle4/background.jpg';
import useContent from '../../hooks/useContent';
import getImageUrl from '../../services/getImageUrl';
import DragAndDropContext from '../puzzleelements/DragAndDropContext';
import { DragAndDropSortableItem } from '../puzzleelements/DragAndDropSortableItem';
import { GalleryPicture } from '../puzzleelements/GalleryPicture';
import { PuzzleFourData } from '../../data/PuzzleFourData';
import { Puzzle } from '../../types/Puzzle.d';

type Props = {
  finishPuzzle(): void;
};

export default function PuzzleFour(props: Props): ReactElement {
  const { finishPuzzle } = props;
  const puzzleId = Puzzle.FOUR;
  const totalColumnsNumber = 6;

  const [isDndSolutionCorrect, setIsDndSolutionCorrect] = useState<boolean>(false);
  const [items, setItems] = useState(PuzzleFourData);
  const [activeId, setActiveId] = useState<string | number | null>(null);

  const userArray = items;
  const simplifiedUserArray = userArray.map(({ url, audio, ...rest }) => {
    return rest;
  });
  const resultArray = useContent('PuzzleData', 'puzzleCode', puzzleId);

  useEffect(() => {
    if (JSON.stringify(resultArray) === JSON.stringify(simplifiedUserArray.slice(0, totalColumnsNumber))) {
      setIsDndSolutionCorrect(true);
    } else {
      setIsDndSolutionCorrect(false);
    }
  }, [isDndSolutionCorrect, simplifiedUserArray, resultArray]);

  function handleDragStart(event: DragStartEvent): void {
    setActiveId(event.active.id);
  }

  function handleDragEnd(event: DragEndEvent): void {
    const { active, over } = event;
    if (over !== null) {
      if (active.id !== over.id) {
        setItems((items) => {
          const oldIndex = items.findIndex(({ id }) => id === active.id);
          const newIndex = items.findIndex(({ id }) => id === over.id);
          return arrayMove(items, oldIndex, newIndex);
        });
      }
    }
    setActiveId(null);
  }

  function handleDragCancel(): void {
    setActiveId(null);
  }

  return (
    <PuzzleOverlay
      puzzleId={puzzleId}
      isDndPuzzleSolved={isDndSolutionCorrect}
      finishPuzzle={finishPuzzle}
      backgroundImage={Background}
    >
      <StyledPuzzleContainer>
        <DragAndDropContext
          onDragStart={handleDragStart}
          onDragEnd={handleDragEnd}
          onDragCancel={handleDragCancel}
          puzzleId={puzzleId}
        >
          <>
            <SortableContext items={items.map(({ id }) => id)} strategy={rectSortingStrategy}>
              <StyledPictureGrid columns={totalColumnsNumber}>
                {items.map(({ id, url, audio }) => (
                  <DragAndDropSortableItem
                    key={uuidv4()}
                    id={id}
                    url={url}
                    audio={audio}
                    totalColumnsNumber={totalColumnsNumber}
                    puzzleId={puzzleId}
                  />
                ))}
              </StyledPictureGrid>
            </SortableContext>
            <DragOverlay
              adjustScale={true}
              dropAnimation={{
                duration: 300,
                easing: 'ease-out',
              }}
            >
              {activeId && (
                <GalleryPicture
                  url={getImageUrl(items, activeId)}
                  index={activeId}
                  totalColumnsNumber={totalColumnsNumber}
                  isTransparent
                  isDragging={false}
                />
              )}
            </DragOverlay>
          </>
        </DragAndDropContext>
      </StyledPuzzleContainer>
      <BookSlider variant={puzzleId} />
    </PuzzleOverlay>
  );
}

const StyledPuzzleContainer = styled.div`
  width: 100%;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  text-align: center;
`;

const StyledPictureGrid = styled.div<{ columns: number }>`
  top: 20rem;
  position: relative;
  display: grid;
  max-width: 90rem;
  margin: 0 auto;
  grid-template-columns: ${({ columns }) => `repeat(${columns}, 1fr)`};
  grid-template-rows: repeat(1, 1fr);
  justify-items: center;
  grid-gap: 1.3rem;
  width: 100%;
  z-index: 3;
  @media (max-height: 800px) {
    top: 10rem;
  }
  ${({ theme }) => theme.breakpoints.queries.lg} {
    max-width: 115rem;
  }
`;
