import { useEffect, useState } from 'react'
import theme from '../theme/theme'

type Breakpoint = 'xs' | 'sm' | 'md' | 'lg' | 'xl'

/**
 * It returns a boolean value that represents whether or not the current window width is greater than
 * or equal to the breakpoint value
 * @param {Breakpoint} breakpoint - Breakpoint
 * @returns A boolean or undefined.
 */
export default function useBreakpoint(
  breakpoint: Breakpoint
): boolean | undefined {
  const { breakpoints } = theme.breakpoints

  const [isGreaterThanBreakpoint, setIsGreaterThanBreakpoint] = useState<
    boolean | undefined
  >(undefined)

  useEffect(() => {
    function handleResize(): void {
      if (
        window.innerWidth >= breakpoints[breakpoint] !==
        isGreaterThanBreakpoint
      ) {
        setIsGreaterThanBreakpoint(window.innerWidth >= breakpoints[breakpoint])
      }
    }

    window.addEventListener('resize', handleResize)
    handleResize()
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [isGreaterThanBreakpoint, breakpoints, breakpoint])

  return isGreaterThanBreakpoint
}

