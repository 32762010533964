import { ReactElement, useEffect, useState } from 'react';
import styled from 'styled-components';
import useBreakpoint from '../../hooks/useBreakpoint';

type Props = {
  src: string;
  audio?: string;
  zoomLevel: number;
};

ImageMagnifier.defaultProps = {
  zoomLevel: 1.5,
  audio: '',
};

export default function ImageMagnifier(props: Props): ReactElement {
  const { src, zoomLevel, audio } = props;
  const [[x, y], setXY] = useState<[number, number]>([0, 0]);
  const [[imgWidth, imgHeight], setImageSize] = useState<[number, number]>([0, 0]);
  const [showMagnifier, setShowMagnifier] = useState(false);
  const [magnifierWidth, setMagnifierWidth] = useState<number>(0);
  const [isMusicPlaying, setIsMusicPlaying] = useState<boolean>(false);

  const isDesktop = useBreakpoint('lg');
  const magnifierWidthDesktop = 257;
  const magnifierWidthTablet = 200;

  useEffect(() => {
    if (isDesktop) {
      setMagnifierWidth(magnifierWidthDesktop);
    } else setMagnifierWidth(magnifierWidthTablet);
  }, [isDesktop]);

  useEffect(() => {
    setIsMusicPlaying(true);
  }, [isMusicPlaying]);

  return (
    <StyledImageWrapper>
      <audio autoPlay={isMusicPlaying ? true : false}>
        <source src={audio} type="audio/mp3" />
      </audio>
      <StyledImage
        src={src}
        onMouseEnter={(e) => {
          const element = e.currentTarget;
          const { width, height } = element.getBoundingClientRect();
          setImageSize([width, height]);
          setShowMagnifier(true);
        }}
        onMouseMove={(e) => {
          const element = e.currentTarget;
          const { top, left } = element.getBoundingClientRect();
          const x = e.pageX - left - window.pageXOffset;
          const y = e.pageY - top - window.pageYOffset;
          setXY([x, y]);
        }}
        onTouchMove={(e) => {
          const element = e.currentTarget;
          const { top, left } = element.getBoundingClientRect();
          const x = e.touches[0].pageX - left - window.pageXOffset;
          const y = e.touches[0].pageY - top - window.pageYOffset;
          setXY([x, y]);
        }}
        onMouseLeave={() => {
          setShowMagnifier(false);
        }}
        alt="Image Lens"
      />
      <StyledLens
        style={{
          display: showMagnifier ? '' : 'none',
          height: `${magnifierWidth}px`,
          width: `${magnifierWidth}px`,
          top: `${y - magnifierWidth / 2}px`,
          left: `${x - magnifierWidth / 2}px`,
          backgroundImage: `url('${src}')`,
          backgroundSize: `${imgWidth * zoomLevel}px ${imgHeight * zoomLevel}px`,
          backgroundPositionX: `${-x * zoomLevel + magnifierWidth / 2}px`,
          backgroundPositionY: `${-y * zoomLevel + magnifierWidth / 2}px`,
        }}
      />
    </StyledImageWrapper>
  );
}

const StyledImageWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
`;

const StyledImage = styled.img`
  width: 39rem;
  height: 51rem;
  ${({ theme }) => theme.breakpoints.queries.lg} {
    width: 50rem;
    height: 64rem;
  }
  @media (max-height: 660px) {
    width: 39rem;
    height: 51rem;
  }
`;

const StyledLens = styled.div`
  position: absolute;
  pointer-events: none;
  opacity: 1;
  border-radius: 50%;
  background-repeat: no-repeat;
`;
