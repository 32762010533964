import { useTranslation } from 'react-i18next';

export const IntermissionData = (): object[] => {
  const { t } = useTranslation();

  return [
    {
      //Puzzle 1
      introMessages: [t('puzzle_one.intermission_start_msg1'), t('puzzle_one.intermission_start_msg2')],
      finishMessages: [t('puzzle_one.intermission_finish_msg1')],
    },
    {
      //Puzzle 2
      introMessages: [
        t('puzzle_two.intermission_start_msg1'),
        t('puzzle_two.intermission_start_msg2'),
        t('puzzle_two.intermission_start_msg3'),
      ],
      finishMessages: [t('puzzle_two.intermission_finish_msg1')],
    },
    {
      //Puzzle 3
      introMessages: [
        t('puzzle_three.intermission_start_msg1'),
        t('puzzle_three.intermission_start_msg2'),
        t('puzzle_three.intermission_start_msg3'),
      ],
      finishMessages: [t('puzzle_three.intermission_finish_msg1')],
    },
    {
      //Puzzle 4
      introMessages: [
        t('puzzle_four.intermission_start_msg1'),
        t('puzzle_four.intermission_start_msg2'),
        t('puzzle_four.intermission_start_msg3'),
        t('puzzle_four.intermission_start_msg4'),
      ],
      finishMessages: [t('puzzle_four.intermission_finish_msg1')],
    },
    {
      //Puzzle 5
      introMessages: [
        t('puzzle_five.intermission_start_msg1'),
        t('puzzle_five.intermission_start_msg2'),
        t('puzzle_five.intermission_start_msg3'),
      ],
      finishMessages: [t('puzzle_five.intermission_finish_msg1')],
    },
    {
      //Puzzle 6
      introMessages: [t('puzzle_six.intermission_start_msg1'), t('puzzle_six.intermission_start_msg2')],
      finishMessages: [t('puzzle_six.intermission_finish_msg1'), t('puzzle_six.intermission_finish_msg2')],
    },
    {
      //Puzzle 7
      introMessages: [
        t('puzzle_seven.intermission_start_msg1'),
        t('puzzle_seven.intermission_start_msg2'),
        t('puzzle_seven.intermission_start_msg3'),
        t('puzzle_seven.intermission_start_msg4'),
        t('puzzle_seven.intermission_start_msg5'),
      ],
      finishMessages: [t('puzzle_seven.intermission_finish_msg1'), t('puzzle_seven.intermission_finish_msg2')],
    },
  ];
};
