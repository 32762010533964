/**
 * Loads an environment variable as a string.
 *
 * @param envVarName The env var name.
 * @param fallback An optional fallback value that is returned when the env var is not found.
 * @returns The env vars value or the fallback.
 * @throws An error if the env var can't be found and no fallback was provided.
 */
 function loadEnvVarString(envVarName: string, fallback?: string): string {
    const value = process.env[envVarName];
    if (!value) {
      if (fallback !== undefined) {
        return fallback;
      }
      throw Error(`Environment variable ${envVarName} not set.`);
    }
    return value;
  }
   
  const Util = {
    loadEnvVarString,
  };

  export default Util;
