import DE_Page1 from '../../src/assets/puzzle4/composers/Kuenstlerhandbuch_01_DE.png';
import DE_Page2 from '../../src/assets/puzzle4/composers/Kuenstlerhandbuch_02_DE.png';
import DE_Page3 from '../../src/assets/puzzle4/composers/Kuenstlerhandbuch_03_DE.png';
import DE_Page4 from '../../src/assets/puzzle4/composers/Kuenstlerhandbuch_04_DE.png';
import EN_Page1 from '../../src/assets/puzzle4/composers/Kuenstlerhandbuch_01_EN.png';
import EN_Page2 from '../../src/assets/puzzle4/composers/Kuenstlerhandbuch_02_EN.png';
import EN_Page3 from '../../src/assets/puzzle4/composers/Kuenstlerhandbuch_03_EN.png';
import EN_Page4 from '../../src/assets/puzzle4/composers/Kuenstlerhandbuch_04_EN.png';

export const ComposerBookDataDe: { index: number; image: string }[] = [
  {
    index: 1,
    image: DE_Page1,
  },
  {
    index: 2,
    image: DE_Page2,
  },
  {
    index: 3,
    image: DE_Page3,
  },
  {
    index: 4,
    image: DE_Page4,
  },
];

export const ComposerBookDataEn: { index: number; image: string }[] = [
  {
    index: 1,
    image: EN_Page1,
  },
  {
    index: 2,
    image: EN_Page2,
  },
  {
    index: 3,
    image: EN_Page3,
  },
  {
    index: 4,
    image: EN_Page4,
  },
];
