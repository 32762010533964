import { ReactElement, useEffect, useState } from 'react';
import styled from 'styled-components';
import { DragOverlay, DragEndEvent, DragStartEvent } from '@dnd-kit/core';
import { SortableContext, rectSwappingStrategy, arraySwap } from '@dnd-kit/sortable';
import { v4 as uuidv4 } from 'uuid';

import useContent from '../../hooks/useContent';
import getImageUrl from '../../services/getImageUrl';
import DragAndDropContext from '../puzzleelements/DragAndDropContext';
import { DragAndDropSortableItem } from '../puzzleelements/DragAndDropSortableItem';
import { Puzzle } from '../../types/Puzzle.d';
import { CardsGrid } from './CardsGrid';
import { SightsCard } from './SightsCard';
import CompassImage from '../../assets/puzzle7/cards/Compass.png';
import Card1 from '../../assets/puzzle7/cards/card1.jpeg';
import { PuzzleSevenType } from '../../types/PuzzleSevenType';

type Props = {
  handleDragAndDropChange: (data: PuzzleSevenType[]) => void;
  finishPuzzle(): void;
  puzzleId: Puzzle;
  items: PuzzleSevenType[];
};

export default function DragAndDropCards(props: Props): ReactElement {
  const { puzzleId } = props;

  const totalColumnsNumber = 5;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isDndSolutionCorrect, setIsDndSolutionCorrect] = useState(false);
  const [items, setItems] = useState(props.items);
  const [activeId, setActiveId] = useState<string | number | null>(null);

  const userArray = items;
  const simplifiedUserArray = userArray.map(({ id, ...rest }) => {
    return rest;
  });
  const resultArray = useContent('PuzzleData', 'puzzleCode', puzzleId);

  useEffect(() => {
    props.handleDragAndDropChange(items);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDndSolutionCorrect, simplifiedUserArray, resultArray]);

  function handleDragStart(event: DragStartEvent): void {
    setActiveId(event.active.id);
  }

  function handleDragEnd(event: DragEndEvent): void {
    const { active, over } = event;

    if (over !== null) {
      if (active.id !== over.id) {
        setItems((items) => {
          const oldIndex = items.findIndex(({ id }) => id === active.id);
          const newIndex = items.findIndex(({ id }) => id === over.id);
          return arraySwap(items, oldIndex, newIndex);
        });
      }
    }

    setActiveId(null);
  }

  function handleDragCancel(): void {
    setActiveId(null);
  }

  function renderPlaceholderElements(): ReactElement {
    return (
      <StyledPlaceholderGrid columns={totalColumnsNumber}>
        {items.map(() => (
          <StyledPlaceholderElement key={uuidv4()}>
            <div></div>
          </StyledPlaceholderElement>
        ))}
      </StyledPlaceholderGrid>
    );
  }

  return (
    <StyledPuzzleContainer className="dnd">
      <StyledCompassBackground src={CompassImage} alt="Compass image" />
      <DragAndDropContext onDragStart={handleDragStart} onDragEnd={handleDragEnd} onDragCancel={handleDragCancel}>
        <>
          {renderPlaceholderElements()}
          <CardsGrid columns={totalColumnsNumber}>
            <SortableContext items={items.map(({ id }) => id)} strategy={rectSwappingStrategy}>
              {items.map(({ id, url }) => (
                <DragAndDropSortableItem
                  key={uuidv4()}
                  id={id}
                  url={url}
                  totalColumnsNumber={totalColumnsNumber}
                  puzzleId={puzzleId}
                />
              ))}
            </SortableContext>
          </CardsGrid>
          <DragOverlay
            adjustScale
            dropAnimation={{
              duration: 300,
              easing: 'ease-in-out',
            }}
          >
            {activeId && (
              <SightsCard
                url={getImageUrl(items, activeId)}
                index={activeId}
                totalColumnsNumber={totalColumnsNumber}
                isTransparent
                isDragging={false}
              />
            )}
          </DragOverlay>
        </>
      </DragAndDropContext>
    </StyledPuzzleContainer>
  );
}

const StyledPuzzleContainer = styled.div`
  position: relative;
  width: 100%;
  margin: 0 auto;
  z-index: 2;
`;

const StyledPlaceholderGrid = styled.div<{ columns: number }>`
  display: grid;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 40rem;
  height: 40rem;
  margin: 0 auto;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(4, 1fr);
  grid-gap: 1rem;

  ${({ theme }) => theme.breakpoints.queries.lg} {
    width: 50rem;
    height: 50rem;
  }

  div {
    background-color: #d9f5ff;
    position: relative;
  }

  div:nth-child(1) {
    top: 20%;
    left: -80%;
  }

  div:nth-child(2),
  div:nth-child(4) {
    top: 40%;
    left: -55%;
  }

  div:nth-child(3) {
    background-image: url('${Card1}');
    background-size: cover;
    background-color: ${({ theme }) => theme.palette.company.primary};
    top: 40%;
    left: -55%;
  }

  div:nth-child(6) {
    top: 25%;
    left: -80%;
  }

  div:nth-child(7),
  div:nth-child(9) {
    top: 40%;
    left: -55%;
  }

  div:nth-child(10) {
    top: 0%;
    right: 50%;
  }

  div:nth-child(11) {
    top: 30%;
    left: -80%;
  }
  div:nth-child(12),
  div:nth-child(13),
  div:nth-child(14) {
    top: 40%;
    left: -55%;
  }
  div:nth-child(15) {
    top: 0%;
    right: 50%;
  }
  div:nth-child(18) {
    top: 50%;
    left: -140%;
  }
  div:nth-child(19) {
    top: 50%;
    left: -120%;
  }

  div:nth-child(5),
  div:nth-child(8),
  div:nth-child(16),
  div:nth-child(17),
  div:nth-child(20) {
    opacity: 0;
  }
`;

const StyledPlaceholderElement = styled.div`
  height: 9rem;
  width: 9rem;
  z-index: 3;
  padding: 0;

  ${({ theme }) => theme.breakpoints.queries.lg} {
    width: 12rem;
    height: 12rem;
  }
`;

const StyledCompassBackground = styled.img`
  position: absolute;
  width: 100%;
  max-width: 47rem;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 4;
`;
