import { ReactElement, useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { motion, AnimatePresence } from 'framer-motion';
import useContent from '../../hooks/useContent';

import { Puzzle } from '../../types/Puzzle.d';
import Button from '../common/Button';

import Questionmarks from '../../assets/questionmarks.png';
import ContentContainer from '../layout/ContentContainer';
import tracking from '../../services/tracking';
import LightsImage from '../../assets/puzzle5/solution_check_lights.jpg';
import { getBrowserName } from '../../services/getBrowserName';

type Props = {
  puzzleId: Puzzle;
  stageLightsEnabled?: string[];
  setStageLightsEnabled?(value: string[]): void;
  finishPuzzle(): void;
  closeModal(): void;
};

export default function SolutionInput(props: Props): ReactElement {
  const { puzzleId, stageLightsEnabled, setStageLightsEnabled, finishPuzzle, closeModal } = props;
  const { t } = useTranslation();

  const [solutionInputValue, setSolutionInputValue] = useState<string>('');
  const [solutionLetterInput, setSolutionLetterInput] = useState<string>('');
  const [solutionNumberInput, setSolutionNumberInput] = useState<string>('');
  const [isErrorFeedbackActive, setIsErrorFeedbackActive] = useState<boolean>(false);
  const [activeMessage, setActiveMessage] = useState<string>(' ');

  const puzzleCode = useContent('PuzzleData', 'puzzleCode', puzzleId);
  const puzzleCode2 = useContent('PuzzleData', 'puzzleCode2', puzzleId);
  const puzzleSolutionTitle = useContent('PuzzleData', 'puzzleSolutionTitle', puzzleId);

  const puzzleFiveCodeArray = Array.isArray(puzzleCode) ? puzzleCode : [];
  const isLightOneActivated = stageLightsEnabled && stageLightsEnabled.includes(puzzleFiveCodeArray[0]) ? true : false;
  const isLightTwoActivated = stageLightsEnabled && stageLightsEnabled.includes(puzzleFiveCodeArray[1]) ? true : false;
  const isLightThreeActivated =
    stageLightsEnabled && stageLightsEnabled.includes(puzzleFiveCodeArray[2]) ? true : false;
  let brightness = 20;
  const codesLeft = stageLightsEnabled && puzzleFiveCodeArray.length - stageLightsEnabled.length - 1;

  const delay = (ms: number): Promise<void> => new Promise((res) => setTimeout(res, ms));

  const setPuzzleInputSolution = (value: string, pos: number): void => {
    let chars;
    if (puzzleId === Puzzle.ONE || puzzleId === Puzzle.THREE) {
      chars = solutionInputValue.split(',');
    } else chars = solutionInputValue.split('');

    for (let i = 0; i < 4; i++) {
      chars[i] = chars[i] ?? '';
    }

    const nextInput = document.querySelector('input[tabindex="' + (pos + 2) + '"]') as HTMLInputElement;
    const prevInput = document.querySelector('input[tabindex="' + pos + '"]') as HTMLInputElement;

    if (nextInput && value != '') {
      nextInput.focus();
    }

    if (prevInput && value == '') {
      prevInput.focus();
    }

    chars[pos] = value;
    if (puzzleId === Puzzle.ONE) {
      setSolutionInputValue(chars.join(','));
    } else setSolutionInputValue(chars.join('').toLocaleUpperCase());

    if (puzzleId === Puzzle.TWO && pos === 0) {
      setSolutionLetterInput(value);
    }

    if (puzzleId === Puzzle.TWO && pos === 1) {
      setSolutionNumberInput(value);
    }

    if (puzzleId === Puzzle.THREE) {
      setSolutionInputValue(chars.join(','));
    }
  };

  const triggerErrorFeedback = (): void => {
    setIsErrorFeedbackActive(true);
    tracking.puzzleIncorrectSolution(puzzleId);
  };

  const [isPuzzleFiveComplete, setIsPuzzleFiveComplete] = useState(false);

  const resolveAndFinish = async (): Promise<void> => {
    setIsPuzzleFiveComplete(true);
    await delay(2000);

    finishPuzzle();
  };

  const getLightsImageBrightness = (): number => {
    if (stageLightsEnabled?.length === 1) {
      brightness = 30;
    } else if (stageLightsEnabled?.length === 2) {
      brightness = 60;
    } else {
      brightness = 20;
    }
    if (isPuzzleFiveComplete) {
      brightness = 100;
    }
    return brightness;
  };

  const checkSolution = (): void => {
    switch (puzzleId) {
      case Puzzle.ONE:
        if (puzzleCode === solutionInputValue.replace(/,/g, '').toLowerCase()) {
          finishPuzzle();
        } else {
          triggerErrorFeedback();
        }
        return;
      case Puzzle.TWO:
        if (puzzleCode === solutionLetterInput.toUpperCase() + solutionNumberInput) {
          finishPuzzle();
        } else {
          triggerErrorFeedback();
        }
        return;
      case Puzzle.THREE:
        if (
          puzzleCode === solutionInputValue.trim().replace(/,/g, '').toLowerCase() ||
          puzzleCode2 === solutionInputValue.trim().replace(/,/g, '').toLowerCase()
        ) {
          finishPuzzle();
          return;
        } else {
          triggerErrorFeedback();
        }
        return;
      case Puzzle.FIVE:
        setSolutionInputValue('');
        if (stageLightsEnabled && stageLightsEnabled.find((e) => e === solutionInputValue.toLowerCase())) {
          setActiveMessage(t('puzzle_five.solution_input_copy_code_exists'));
          return;
        }
        setActiveMessage(t('puzzle_five.solution_input_copy_error'));
        triggerErrorFeedback();
        if (puzzleFiveCodeArray.find((e) => e === solutionInputValue.toLowerCase())) {
          setActiveMessage(
            t('puzzle_five.solution_input_copy_code_success') +
              `${codesLeft} ${t('puzzle_five.solution_input_copy_count_end')}`,
          );
          setIsErrorFeedbackActive(false);
          if (stageLightsEnabled?.length === 2) {
            setActiveMessage(t('puzzle_five.solution_input_copy_success'));
            setIsPuzzleFiveComplete(true);
            resolveAndFinish();
            return;
          } else {
            if (setStageLightsEnabled && stageLightsEnabled) {
              setStageLightsEnabled([...stageLightsEnabled, solutionInputValue.toLocaleLowerCase()]);
              return;
            }
          }
        }
    }
  };

  useEffect(() => {
    if (isErrorFeedbackActive) {
      setTimeout(function () {
        setIsErrorFeedbackActive(false);
      }, 1000);
    }
  }, [isErrorFeedbackActive]);

  useEffect(() => {
    if (activeMessage) {
      setTimeout(function () {
        setActiveMessage('');
      }, 2000);
    }
  }, [activeMessage]);

  return (
    <>
      <StyledDarkBackground />
      <ContentContainer>
        <StyledSolutionPopup>
          <StyledQuestionmarks src={Questionmarks} />
          <StyledSolutionInputContainer variant={puzzleId}>
            <StyledSolutionTitle variant={puzzleId} browserName={getBrowserName()}>
              <span>{puzzleSolutionTitle}</span>
            </StyledSolutionTitle>

            {puzzleId === Puzzle.ONE && (
              <StyledInputRow
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    checkSolution();
                  }
                }}
                variant={puzzleId}
                isPuzzleFiveComplete={isPuzzleFiveComplete}
              >
                <StyledPuzzleOneAndFiveInputWrapper label="">
                  <StyledPuzzleOneInput
                    autoFocus
                    value={solutionInputValue.split(',')[0] ?? ''}
                    tabIndex={1}
                    onFocus={(e) => {
                      e.target.setSelectionRange(0, 1);
                    }}
                    onChange={(e) => {
                      setPuzzleInputSolution(e.target.value, 0);
                    }}
                    maxLength={1}
                  />
                </StyledPuzzleOneAndFiveInputWrapper>

                <StyledPuzzleOneAndFiveInputWrapper label="">
                  <StyledPuzzleOneInput
                    value={solutionInputValue.split(',')[1] ?? ''}
                    tabIndex={2}
                    onChange={(e) => {
                      setPuzzleInputSolution(e.target.value, 1);
                    }}
                    onFocus={(e) => {
                      e.target.setSelectionRange(0, 1);
                    }}
                    maxLength={1}
                  />
                </StyledPuzzleOneAndFiveInputWrapper>
                <StyledPuzzleOneAndFiveInputWrapper label="">
                  <StyledPuzzleOneInput
                    value={solutionInputValue.split(',')[2] ?? ''}
                    tabIndex={3}
                    onChange={(e) => {
                      setPuzzleInputSolution(e.target.value, 2);
                    }}
                    onFocus={(e) => {
                      e.target.setSelectionRange(0, 1);
                    }}
                    maxLength={1}
                  />
                </StyledPuzzleOneAndFiveInputWrapper>
                <StyledPuzzleOneAndFiveInputWrapper label="">
                  <StyledPuzzleOneInput
                    value={solutionInputValue.split(',')[3] ?? ''}
                    tabIndex={4}
                    onChange={(e) => {
                      setPuzzleInputSolution(e.target.value, 3);
                    }}
                    onFocus={(e) => {
                      e.target.setSelectionRange(0, 1);
                    }}
                    maxLength={1}
                  />
                </StyledPuzzleOneAndFiveInputWrapper>

                <StyledSolutionInputButtonContainer isError={isErrorFeedbackActive} variant={puzzleId}>
                  <Button
                    onClick={() => checkSolution()}
                    label={isErrorFeedbackActive ? t('solution_input.error_message') : t('solution_input.check')}
                    color={'primary'}
                  />
                </StyledSolutionInputButtonContainer>
              </StyledInputRow>
            )}

            {puzzleId === Puzzle.TWO && (
              <StyledInputRow
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    checkSolution();
                  }
                }}
              >
                <StyledInputWrapper label={t('puzzle_two.letter')}>
                  <StyledPuzzleTwoInput
                    autoFocus
                    value={solutionLetterInput}
                    tabIndex={1}
                    onChange={(e) => setPuzzleInputSolution(e.target.value, 0)}
                    maxLength={1}
                  />
                </StyledInputWrapper>

                <StyledInputWrapper label={t('puzzle_two.number')}>
                  <StyledPuzzleTwoInput
                    value={solutionNumberInput}
                    tabIndex={2}
                    onChange={(e) => setPuzzleInputSolution(e.target.value, 1)}
                    maxLength={1}
                  />
                </StyledInputWrapper>
                <StyledSolutionInputButtonContainer isError={isErrorFeedbackActive} variant={puzzleId}>
                  <Button
                    onClick={() => checkSolution()}
                    label={isErrorFeedbackActive ? t('solution_input.error_message') : t('solution_input.check')}
                    color={'primary'}
                  />
                </StyledSolutionInputButtonContainer>
              </StyledInputRow>
            )}

            {puzzleId === Puzzle.THREE && (
              <StyledInputRow
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    checkSolution();
                  }
                }}
                variant={puzzleId}
                isPuzzleFiveComplete={isPuzzleFiveComplete}
              >
                <StyledPuzzleOneAndFiveInputWrapper label="">
                  <StyledPuzzleOneInput
                    autoFocus
                    value={solutionInputValue.split(',')[0] ?? ''}
                    tabIndex={1}
                    onFocus={(e) => {
                      e.target.setSelectionRange(0, 1);
                    }}
                    onChange={(e) => {
                      setPuzzleInputSolution(e.target.value, 0);
                    }}
                    maxLength={1}
                  />
                </StyledPuzzleOneAndFiveInputWrapper>

                <StyledPuzzleOneAndFiveInputWrapper label="">
                  <StyledPuzzleOneInput
                    value={solutionInputValue.split(',')[1] ?? ''}
                    tabIndex={2}
                    onChange={(e) => {
                      setPuzzleInputSolution(e.target.value, 1);
                    }}
                    onFocus={(e) => {
                      e.target.setSelectionRange(0, 1);
                    }}
                    maxLength={1}
                  />
                </StyledPuzzleOneAndFiveInputWrapper>
                <StyledPuzzleOneAndFiveInputWrapper label="">
                  <StyledPuzzleOneInput
                    value={solutionInputValue.split(',')[2] ?? ''}
                    tabIndex={3}
                    onChange={(e) => {
                      setPuzzleInputSolution(e.target.value, 2);
                    }}
                    onFocus={(e) => {
                      e.target.setSelectionRange(0, 1);
                    }}
                    maxLength={1}
                  />
                </StyledPuzzleOneAndFiveInputWrapper>
                <StyledPuzzleOneAndFiveInputWrapper label="">
                  <StyledPuzzleOneInput
                    value={solutionInputValue.split(',')[3] ?? ''}
                    tabIndex={4}
                    onChange={(e) => {
                      setPuzzleInputSolution(e.target.value, 3);
                    }}
                    onFocus={(e) => {
                      e.target.setSelectionRange(0, 1);
                    }}
                    maxLength={1}
                  />
                </StyledPuzzleOneAndFiveInputWrapper>
                <StyledPuzzleOneAndFiveInputWrapper label="">
                  <StyledPuzzleOneInput
                    value={solutionInputValue.split(',')[4] ?? ''}
                    tabIndex={5}
                    onChange={(e) => {
                      setPuzzleInputSolution(e.target.value, 4);
                    }}
                    onFocus={(e) => {
                      e.target.setSelectionRange(0, 1);
                    }}
                    maxLength={1}
                  />
                </StyledPuzzleOneAndFiveInputWrapper>
                <StyledPuzzleOneAndFiveInputWrapper label="">
                  <StyledPuzzleOneInput
                    value={solutionInputValue.split(',')[5] ?? ''}
                    tabIndex={6}
                    onChange={(e) => {
                      setPuzzleInputSolution(e.target.value, 5);
                    }}
                    onFocus={(e) => {
                      e.target.setSelectionRange(0, 1);
                    }}
                    maxLength={1}
                  />
                </StyledPuzzleOneAndFiveInputWrapper>

                <StyledSolutionInputButtonContainer isError={isErrorFeedbackActive} variant={puzzleId}>
                  <Button
                    onClick={() => checkSolution()}
                    label={isErrorFeedbackActive ? t('solution_input.error_message') : t('solution_input.check')}
                    color={'primary'}
                  />
                </StyledSolutionInputButtonContainer>
              </StyledInputRow>
            )}

            {puzzleId === Puzzle.FIVE && (
              <StyledPuzzleFiveInputWrapper>
                <AnimatePresence>
                  <motion.div
                    key={brightness}
                    initial={{ opacity: 0, position: 'relative' }}
                    animate={{ opacity: 1, position: 'relative' }}
                    exit={{ opacity: 0, position: 'absolute' }}
                  >
                    <StyledLightsImage src={LightsImage} alt="Concert Lights" brightness={getLightsImageBrightness()} />
                  </motion.div>
                </AnimatePresence>

                <StyledSolutionContainer>
                  <StyledLightsContainer>
                    <StyledLightEntry isSolved={isLightOneActivated || isPuzzleFiveComplete}>
                      {t('puzzle_five.light1')}
                    </StyledLightEntry>
                    <StyledLightEntry isSolved={isLightTwoActivated || isPuzzleFiveComplete}>
                      {t('puzzle_five.light2')}
                    </StyledLightEntry>
                    <StyledLightEntry isSolved={isLightThreeActivated || isPuzzleFiveComplete}>
                      {t('puzzle_five.light3')}
                    </StyledLightEntry>
                  </StyledLightsContainer>
                  <StyledInputMessage>{activeMessage}</StyledInputMessage>
                  <StyledInputRow
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        checkSolution();
                      }
                    }}
                    variant={puzzleId}
                    isPuzzleFiveComplete={isPuzzleFiveComplete}
                  >
                    <StyledPuzzleOneAndFiveInputWrapper label="">
                      <StyledPuzzleOneInput
                        autoFocus
                        value={solutionInputValue.split('')[0] ?? ''}
                        tabIndex={1}
                        onFocus={(e) => {
                          e.target.setSelectionRange(0, 1);
                        }}
                        onChange={(e) => {
                          setPuzzleInputSolution(e.target.value, 0);
                        }}
                        maxLength={1}
                      />
                    </StyledPuzzleOneAndFiveInputWrapper>

                    <StyledPuzzleOneAndFiveInputWrapper label="">
                      <StyledPuzzleOneInput
                        value={solutionInputValue.split('')[1] ?? ''}
                        tabIndex={2}
                        onChange={(e) => {
                          setPuzzleInputSolution(e.target.value, 1);
                        }}
                        onFocus={(e) => {
                          e.target.setSelectionRange(0, 1);
                        }}
                        maxLength={1}
                      />
                    </StyledPuzzleOneAndFiveInputWrapper>
                    <StyledPuzzleOneAndFiveInputWrapper label="">
                      <StyledPuzzleOneInput
                        value={solutionInputValue.split('')[2] ?? ''}
                        tabIndex={3}
                        onChange={(e) => {
                          setPuzzleInputSolution(e.target.value, 2);
                        }}
                        onFocus={(e) => {
                          e.target.setSelectionRange(0, 1);
                        }}
                        maxLength={1}
                      />
                    </StyledPuzzleOneAndFiveInputWrapper>
                    <StyledPuzzleOneAndFiveInputWrapper label="">
                      <StyledPuzzleOneInput
                        value={solutionInputValue.split('')[3] ?? ''}
                        tabIndex={4}
                        onChange={(e) => {
                          setPuzzleInputSolution(e.target.value, 3);
                        }}
                        onFocus={(e) => {
                          e.target.setSelectionRange(0, 1);
                        }}
                        maxLength={1}
                      />
                    </StyledPuzzleOneAndFiveInputWrapper>
                  </StyledInputRow>
                  <StyledSolutionInputButtonContainer
                    isError={isErrorFeedbackActive}
                    variant={puzzleId}
                    isPuzzleFiveComplete={isPuzzleFiveComplete}
                  >
                    <Button
                      onClick={() => checkSolution()}
                      label={
                        isErrorFeedbackActive
                          ? t('puzzle_five.solution_input_error')
                          : t('puzzle_five.solution_input_button')
                      }
                      color={'primary'}
                    />
                  </StyledSolutionInputButtonContainer>
                </StyledSolutionContainer>
              </StyledPuzzleFiveInputWrapper>
            )}
          </StyledSolutionInputContainer>
          <StyledCloseButton
            onClick={() => closeModal()}
            isPuzzleFiveComplete={isPuzzleFiveComplete}
            variant={puzzleId}
          />
        </StyledSolutionPopup>
      </ContentContainer>
    </>
  );
}

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

const shake = keyframes`
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-1rem);
  }
  50% {
    transform: translateX(1rem);
  }
  75% {
    transform: translateX(-1rem);
  }
  100% {
    transform: translateX(0)
  }
`;

const panFadeIn = keyframes`
  from {
    transform: translate(-5rem, -50%);
    opacity: 0;
  }

  to {
    transform: translate(0, -50%);
    opacity: 1;
  }
`;

const StyledDarkBackground = styled.div`
  background-color: rgba(0, 0, 0, 0.8);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`;

const StyledSolutionPopup = styled.div`
  position: fixed;
  z-index: 10;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  pointer-events: all;
  animation: ${fadeIn} 0.3s ease-in-out forwards;
`;

const StyledSolutionInputContainer = styled.div<{ variant: Puzzle }>`
  position: absolute;
  z-index: 2;
  left: ${({ variant }) => (variant === Puzzle.FIVE ? '10rem' : '20rem')};
  top: 50%;
  transform: translate(-5rem, -50%);
  opacity: 0;
  animation: ${panFadeIn} 0.3s ease-in-out 0.6s forwards;
`;

const StyledCloseButton = styled.div<{ isPuzzleFiveComplete: boolean; variant: Puzzle }>`
  position: absolute;
  top: 4rem;
  right: 4rem;
  width: 4rem;
  height: 4rem;
  cursor: pointer;
  opacity: ${({ variant, isPuzzleFiveComplete }) => (variant === Puzzle.FIVE && isPuzzleFiveComplete ? 0 : 1)};
  transition: opacity 0.3s ease-in;
  pointer-events: ${({ variant, isPuzzleFiveComplete }) =>
    variant === Puzzle.FIVE && isPuzzleFiveComplete ? 'none' : 'auto'};

  &:before {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    width: 0.4rem;
    height: 4rem;
    transform: translate(-50%, -50%) rotate(45deg);
    background-color: ${({ theme }) => theme.palette.global.white};
    transition: transform 0.3s;
  }

  &:after {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    width: 0.4rem;
    height: 4rem;
    transform: translate(-50%, -50%) rotate(-45deg);
    background-color: ${({ theme }) => theme.palette.global.white};
    transition: transform 0.3s;
  }
`;

const StyledSolutionTitle = styled.h2<{ variant: Puzzle; browserName: 'firefox' | 'other' }>`
  font-size: ${({ variant }) => (variant === Puzzle.FIVE ? '4rem' : '6rem')};
  text-transform: uppercase;
  font-weight: 900;
  line-height: ${({ variant }) => (variant === Puzzle.FIVE ? '6rem' : '8rem')};
  color: black;
  margin: ${({ variant }) => (variant === Puzzle.FIVE ? '0 0 2.1rem' : '0 0 5rem')};
  width: ${({ variant }) => (variant === Puzzle.FIVE ? '74rem' : '85rem')};

  & span {
    background-color: ${({ theme }) => theme.palette.global.white};
    box-shadow: ${({ browserName }) =>
      browserName === 'firefox' ? '1rem 0 0 white, 0 0 0 white' : '1rem 0 0 white, -1rem 0 0 white'};
  }
`;

const StyledPuzzleOneInput = styled.input`
  width: 8.5rem;
  height: 7rem;
  text-align: center;
  font-size: 4rem;
  font-weight: 900;
  outline-color: #41cbff;
`;

const StyledPuzzleTwoInput = styled.input`
  width: 8.5rem;
  height: 7rem;
  text-align: center;
  font-size: 4rem;
  font-weight: 900;
  text-transform: uppercase;
  outline-color: #41cbff;
`;

const StyledPuzzleOneAndFiveInputWrapper = styled.div<{ label: string }>`
  margin: 0 2rem 0 0;
  position: relative;

  &:after {
    content: '';
    bottom: 1rem;
    left: 50%;
    transform: translateX(-50%);
    width: 4rem;
    height: 0.4rem;
    background-color: black;
    position: absolute;
    display: block;
  }
`;

const StyledInputWrapper = styled.div<{ label: string }>`
  margin: 0 9rem 0 0;
  position: relative;

  &:before {
    content: '${({ label }) => label}';
    position: absolute;
    color: ${({ theme }) => theme.palette.global.white};
    bottom: -3rem;
    left: 0;
    display: block;
    font-size: 1.8rem;
  }

  &:after {
    content: '';
    bottom: 1rem;
    left: 50%;
    transform: translateX(-50%);
    width: 4rem;
    height: 0.4rem;
    background-color: black;
    position: absolute;
    display: block;
  }
`;

const StyledInputRow = styled.div<{ isPuzzleFiveComplete?: boolean; variant?: Puzzle }>`
  display: flex;
  opacity: ${({ variant, isPuzzleFiveComplete }) => (variant === Puzzle.FIVE && isPuzzleFiveComplete ? 0 : 1)};
  transition: opacity 0.3s ease-in;
  outline-color: #41cbff;
`;

const StyledSolutionInputButtonContainer = styled.div<{
  isError: boolean;
  variant: Puzzle;
  isPuzzleFiveComplete?: boolean;
}>`
  opacity: ${({ variant, isPuzzleFiveComplete }) => (variant === Puzzle.FIVE && isPuzzleFiveComplete ? 0 : 1)};
  transition: opacity 0.3s ease-in;
  button {
    height: 7rem;
    margin: ${({ variant }) => (variant === Puzzle.FIVE ? '3.1rem 0' : '0')};
    font-size: 2rem;
    text-transform: uppercase;
    transition: all 0.3s;
    background-color: ${({ isError }) => (isError ? '#E42322' : '#41CBFF')};
    color: ${({ isError }) => (isError ? 'white' : 'black')};
    pointer-events: ${({ isError }) => (isError ? 'none' : 'all')};
    border-color: ${({ isError }) => (isError ? '#E42322' : '#41CBFF')};
    animation: ${({ isError }) => (isError ? shake : 'none')} 0.5s ease-in-out;
  }
`;

const StyledLightsContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 0 0 1rem;
  justify-content: flex-start;
  align-items: flex-start;
  width: 35rem;
  padding: 2rem 0;
  ${({ theme }) => theme.breakpoints.queries.lg} {
    padding: 4rem 0;
  }
`;

const StyledLightEntry = styled.p<{ isSolved: boolean }>`
  margin: 1rem 2rem;
  padding: 0 2rem;
  position: relative;
  color: ${({ theme }) => theme.palette.global.white};
  font-size: 1.6rem;
  line-height: 2rem;
  font-weight: 900;

  ${({ theme }) => theme.breakpoints.queries.lg} {
    font-size: 2.5rem;
    line-height: 2rem;
    text-transform: uppercase;
  }
  &:before {
    content: '';
    width: 1.6rem;
    height: 1.6rem;
    background-color: ${({ isSolved, theme }) =>
      isSolved ? theme.palette.system.success : theme.palette.system.error};
    border-radius: 50%;
    position: absolute;
    left: -1.5rem;
    top: 50%;
    transform: translateY(-50%);
  }
`;

const StyledQuestionmarks = styled.img`
  left: 5rem;
  top: 50%;
  transform: translate(-5rem, -50%);
  opacity: 0;
  animation: ${panFadeIn} 0.6s ease-in-out 0.3s forwards;
  width: 100rem;
  margin: -15rem 0 0 0;
  position: absolute;
`;

const StyledPuzzleFiveInputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const StyledLightsImage = styled.img<{ brightness: number }>`
  width: 40rem;
  filter: ${({ brightness }) => `brightness(${brightness}%)`};
  margin: 4rem 5rem 0 0;
  ${({ theme }) => theme.breakpoints.queries.lg} {
    width: 50rem;
  }
`;

const StyledSolutionContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledInputMessage = styled.p`
  display: block;
  height: 1rem;
  margin: 2rem 0;
  color: ${({ theme }) => theme.palette.global.white};
  font-size: 1.4rem;
  line-height: 1.8rem;
`;
