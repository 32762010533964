import React, { ReactElement, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { HiddenRoutes } from '../../types/Routes.d';
import Button from '../common/Button';
import KeywordInput from '../interface/KeywordInput';
import { ArrowRight } from '../icons/Icons';

export default function PlayAndContnueButtons(): ReactElement {
  const { t } = useTranslation();
  const [isKeywordInputOpen, setIsKeywordInputOpen] = useState<boolean>(false);

  return (
    <>
      <StyledButtonContainer>
        <Link to={HiddenRoutes.INTRO}>
          <StyledButtonWrapper>
            <Button
              label={t('landing_screen.button')}
              color={'primary'}
              iconPosition="right"
              buttonIcon={<ArrowRight />}
            />
          </StyledButtonWrapper>
        </Link>
        {/*
        <StyledContinueLink onClick={() => setIsKeywordInputOpen(true)}>
          {t('landing_screen.continue_link')} <ArrowRight />
        </StyledContinueLink>
        */}
      </StyledButtonContainer>
      {isKeywordInputOpen && (
        <>
          <StyledCloseOverlay onClick={() => setIsKeywordInputOpen(false)} />
          <StyledKeywordModal>
            <KeywordInput puzzleId={0} onClose={() => setIsKeywordInputOpen(false)} />
          </StyledKeywordModal>
        </>
      )}
    </>
  );
}

const StyledButtonContainer = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
`;

const StyledButtonWrapper = styled.div`
  position: relative;

  & > button {
    padding: 2.5rem 2.6rem;
  }
  svg {
    width: 1.5rem;
    height: 1.5rem;
    margin: 0;
  }
`;

const fadeIn = keyframes`
  from { opacity: 0 }
  to { opacity: 0.7 }
`;

const fadePanIn = keyframes`
  from { opacity: 0; transform: translate(-50%, -45%); }
  to { opacity: 1; transform: translate(-50%, -50%); }
`;

const StyledKeywordModal = styled.div`
  position: fixed;
  left: 50%;
  transform: translate(-50%, -45%);
  bottom: 10%;
  width: 38rem;
  background-color: ${({ theme }) => theme.palette.global.white};
  z-index: 6;
  pointer-events: all;
  padding: 2rem;
  opacity: 0;
  animation: ${fadePanIn} 0.3s ease-in-out 0.3s forwards;
`;

const StyledCloseOverlay = styled.div`
  position: fixed;
  height: 300vh;
  width: 300vw;
  top: 0;
  left: -100vw;
  pointer-events: all;
  z-index: 5;
  opacity: 0;
  background-color: ${({ theme }) => theme.palette.global.black};
  animation: ${fadeIn} 0.3s ease-in-out forwards;
`;
