import { ReactElement, useEffect } from 'react';
import { MemoryRouter, Routes, Route } from 'react-router-dom';
import styled, { ThemeProvider } from 'styled-components';
import useWindowDimensions from './hooks/useWindowDimensions';
import i18next from 'i18next';
import './i18n.js';
import tracking from './services/tracking';

import theme from './theme/theme';
import GlobalStyles from './theme/GlobalStyles';
import Layout from './components/layout/Layout';

import LandingScreen from './screens/LandingScreen';
import IntroScreen from './screens/IntroScreen';
import StageScreen from './screens/StageScreen';
import FinalScreen from './screens/FinalScreen';

import { Puzzle } from './types/Puzzle.d';
import { HiddenRoutes } from './types/Routes.d';

import De from './localization/De';
import En from './localization/En';
import MobileWarnScreen from './screens/MobileWarnScreen';
import ElphiConsentProvider from './provider/ElphiConsentProvider';

i18next.init({
  debug: true,
  resources: {
    de: {
      translation: De,
    },
    en: {
      translation: En,
    },
  },
});

i18next.on('languageChanged', (lng) => tracking.setSelectedLanguage(lng));
i18next.on('languageChanged', (lng) => document.documentElement.setAttribute('lang', lng));

export default function App(): ReactElement {
  const { isSmartphone } = useWindowDimensions();

  useEffect(() => {
    document.documentElement.setAttribute('lang', i18next.language.slice(0, 2));
  }, []);

  return (
    <>
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        <ElphiConsentProvider />

        <StyledContentContainer>
          <MemoryRouter>
            <Layout>
              <Routes>
                <Route path={HiddenRoutes.LANDING} element={<LandingScreen />} />
                <Route path={HiddenRoutes.INTRO} element={isSmartphone ? <MobileWarnScreen /> : <IntroScreen />} />
                <Route
                  path={HiddenRoutes.PUZZLEONE}
                  element={isSmartphone ? <MobileWarnScreen /> : <StageScreen puzzleId={Puzzle.ONE} />}
                />
                <Route
                  path={HiddenRoutes.PUZZLETWO}
                  element={isSmartphone ? <MobileWarnScreen /> : <StageScreen puzzleId={Puzzle.TWO} />}
                />
                <Route
                  path={HiddenRoutes.PUZZLETHREE}
                  element={isSmartphone ? <MobileWarnScreen /> : <StageScreen puzzleId={Puzzle.THREE} />}
                />
                <Route
                  path={HiddenRoutes.PUZZLEFOUR}
                  element={isSmartphone ? <MobileWarnScreen /> : <StageScreen puzzleId={Puzzle.FOUR} />}
                />
                <Route
                  path={HiddenRoutes.PUZZLEFIVE}
                  element={isSmartphone ? <MobileWarnScreen /> : <StageScreen puzzleId={Puzzle.FIVE} />}
                />
                <Route
                  path={HiddenRoutes.PUZZLESIX}
                  element={isSmartphone ? <MobileWarnScreen /> : <StageScreen puzzleId={Puzzle.SIX} />}
                />
                <Route
                  path={HiddenRoutes.PUZZLESEVEN}
                  element={isSmartphone ? <MobileWarnScreen /> : <StageScreen puzzleId={Puzzle.SEVEN} />}
                />
                <Route path={HiddenRoutes.COMPLETED} element={isSmartphone ? <MobileWarnScreen /> : <FinalScreen />} />
              </Routes>
            </Layout>
          </MemoryRouter>
        </StyledContentContainer>
      </ThemeProvider>
    </>
  );
}

const StyledContentContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;
