import { useTranslation } from 'react-i18next';

export const LandingData = (): object[] => {
  const { t } = useTranslation();

  return [
    {
      elphiTitle: t('landing_screen.title'),
      elphiSubTitle: 'Noch ein cooler Teststring',
    },
  ];
};
