import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import useContent from '../../hooks/useContent';
import { Puzzle } from '../../types/Puzzle.d';

type Props = {
  puzzleId: Puzzle;
};

export default function PuzzleTitle(props: Props): ReactElement {
  const { puzzleId } = props;
  const { t } = useTranslation();

  const puzzleTitle = useContent('PuzzleData', 'puzzleTitle', puzzleId);
  const puzzleKeyword = useContent('PuzzleData', 'puzzleTitleKeyword', puzzleId);

  return (
    <StyledPuzzleTitleContainer>
      <StyledTask>
        {t('puzzle_screen.task')}
        {puzzleId}
      </StyledTask>
      <StyledKeywordContainer>
        {t('puzzle_screen.keyword')}
        {t(puzzleKeyword)}
      </StyledKeywordContainer>
      <StyledPuzzleTitle>{puzzleTitle}</StyledPuzzleTitle>
    </StyledPuzzleTitleContainer>
  );
}

const StyledPuzzleTitleContainer = styled.div`
  position: absolute;
  top: 2rem;
  left: 2rem;
  z-index: 3;

  ${({ theme }) => theme.breakpoints.queries.xl} {
    top: 4rem;
    left: 7rem;
  }
`;

const StyledTask = styled.h3`
  display: inline-block;
  margin: 0 1.5rem 0 0;
  color: white;
  font-size: 1.6rem;
`;

const StyledKeywordContainer = styled.div`
  padding: 0.4rem 1.5rem;
  display: inline-block;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 2rem;
  color: white;
  font-size: 1.6rem;
`;

const StyledPuzzleTitle = styled.h2`
  font-size: 3rem;
  font-weight: 900;
  color: white;
  margin: 3.5rem 0 0;
  text-transform: uppercase;
  position: relative;

  &:after {
    content: '';
    top: -2rem;
    left: 0;
    height: 0.7rem;
    width: 15rem;
    position: absolute;
    background-color: white;
  }
`;
