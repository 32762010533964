import React, { ReactElement } from 'react';
import styled, { useTheme } from 'styled-components';

type Props = {
  label: string;
  color: 'primary' | 'secondary';
  disabled?: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  iconPosition?: 'left' | 'right';
  buttonIcon?: ReactElement;
};

Button.defaultProps = {
  disabled: false,
  onClick: undefined,
  iconPosition: 'right',
  buttonIcon: <></>,
};

export default function Button(props: Props): ReactElement {
  const { label, color, disabled, iconPosition, onClick, buttonIcon } = props;
  const buttonLabel = label;

  const theme = useTheme();
  const isIconRight = iconPosition === 'right';

  const colorBlack = theme.palette.global.black;

  const colorPrimary = theme.palette.company.primary;
  const colorPrimaryHover = theme.palette.company.hover.primary;
  const colorSecondary = '#00000000';

  const getButtonText = (): ReactElement => (
    <div>{buttonLabel !== '' && <StyledButtonTextWrapper>{buttonLabel}</StyledButtonTextWrapper>}</div>
  );

  const getButtonLabel = (): ReactElement => (
    <StyledLabelWrapper>
      {!isIconRight && <>{buttonIcon}</>}
      {getButtonText()}
      {isIconRight && <>{buttonIcon}</>}
    </StyledLabelWrapper>
  );

  const getCustomButton = (): ReactElement => {
    switch (color) {
      case 'primary':
        return (
          <PrimaryButton
            color={colorPrimary}
            textColor={colorBlack}
            hoverColor={colorPrimaryHover}
            disabled={disabled}
            onClick={onClick}
          >
            {getButtonLabel()}
          </PrimaryButton>
        );
      case 'secondary':
        return (
          <OutlinedButton
            color={colorSecondary}
            textColor={colorBlack}
            hoverColor={colorPrimaryHover}
            disabled={disabled}
            onClick={onClick}
          >
            {getButtonLabel()}
          </OutlinedButton>
        );
      default:
        return (
          <PrimaryButton
            color={colorPrimary}
            textColor={colorBlack}
            hoverColor={colorPrimaryHover}
            disabled={disabled}
            onClick={onClick}
          >
            {getButtonLabel()}
          </PrimaryButton>
        );
    }
  };

  return <>{getCustomButton()}</>;
}

const StyledButton = styled('button')`
  all: unset;
  display: flex;
  justify-content: center;
  justify-items: center;
  align-content: center;
  align-items: center;
  flex-shrink: 0;
  box-sizing: border-box;
  border: 0;
  cursor: pointer;
  padding: 1.6rem 2.4rem;
  margin: 0.8rem auto;
  width: fit-content;
  height: 4.4rem;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);

  &:disabled {
    pointer-events: none;
    svg {
      fill: ${({ theme }) => theme.palette.company.disabled};
    }
  }

  svg {
    width: 1.4rem;
    height: 1.4rem;
    margin: 0 0.5rem 0 0;
    transition: transform 0.3s;
    fill: ${({ theme }) => theme.palette.global.black};
  }
`;

const PrimaryButton = styled(StyledButton)<{ color: string; hoverColor: string; textColor?: string }>`
  color: ${({ textColor }) => textColor};
  background-color: ${({ color }) => color};
  border: 0.2rem solid ${({ color }) => color};
  transition: background-color 0.3s, border-color 0.3s;

  &:hover {
    background-color: ${({ hoverColor }) => hoverColor};
    border: 0.2rem solid ${({ hoverColor }) => hoverColor};
  }

  &:disabled {
    pointer-events: none;
    color: #555;
    background-color: grey;
    border-color: grey;

    svg {
      path {
        fill: #555;
      }
    }
  }
`;

const OutlinedButton = styled(StyledButton)<{ color: string; hoverColor: string; textColor?: string }>`
  color: ${({ textColor }) => textColor};
  background-color: ${({ color }) => color};
  border: 0.2rem solid ${({ theme }) => theme.palette.company.primary};
  transition: border-color 0.3s;

  &:hover {
    border: 0.2rem solid ${({ hoverColor }) => hoverColor};
  }

  &:disabled {
    pointer-events: none;
    color: ${({ theme }) => theme.palette.company.disabled};
  }
`;
const StyledLabelWrapper = styled.span`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 1.6rem;
  line-height: 1.6rem;
  font-weight: 600;
`;

const StyledButtonTextWrapper = styled.span`
  margin: 0.5rem;
`;
