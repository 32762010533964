import { ReactElement, useEffect, useState } from 'react';
import { Pannellum } from 'pannellum-react';
import styled from 'styled-components';
import { useTranslation, getI18n } from 'react-i18next';
import panoramaImageDe from '../../assets/puzzle1/lastenaufzug-DE-fin.jpg';
import panoramaImageEn from '../../assets/puzzle1/lastenaufzug-EN-fin.jpg';
import PuzzleOverlay from '../interface/PuzzleOverlay';
import { Puzzle } from '../../types/Puzzle.d';
import Background from '../../assets/puzzle1/background.jpg';
import DragInfoToast from '../puzzleelements/DragInfoToast';

type Props = {
  finishPuzzle(): void;
};

export default function PuzzleOne(props: Props): ReactElement {
  const { finishPuzzle } = props;
  const puzzleId = Puzzle.ONE;

  const [panoramaImage, setPanoramaImage] = useState<'*.jpg'>(panoramaImageEn);

  const { t } = useTranslation();

  useEffect(() => {
    switch (getI18n().language.trim().toLowerCase().slice(0, 2)) {
      case 'de':
        setPanoramaImage(panoramaImageDe);
        break;
      default:
        setPanoramaImage(panoramaImageEn);
        break;
    }
  }, [t]);

  return (
    <PuzzleOverlay puzzleId={puzzleId} finishPuzzle={finishPuzzle} backgroundImage={Background}>
      <StyledPanoramaContainer>
        <StyledDragInfoToast>
          <DragInfoToast text={t('puzzle_one.info_text')}></DragInfoToast>
        </StyledDragInfoToast>
        <Pannellum
          width="90%"
          height="100%"
          image={panoramaImage}
          pitch={0}
          yaw={200}
          hfov={100}
          maxHfov={100}
          minHfov={30}
          autoLoad
          author=""
          title=""
          orientationOnByDefault={false}
          draggable
          keyboardZoom
          mouseZoom
          preview=""
          previewAuthor=""
          previewTitle=""
          showControls
          showFullscreenCtrl
          showZoomCtrl
          hotspotDebug={false}
        ></Pannellum>
      </StyledPanoramaContainer>
    </PuzzleOverlay>
  );
}

const StyledDragInfoToast = styled.div`
  width: 100%;
  height: auto;
  z-index: 2;
  text-align: center;
  position: absolute;
  bottom: 3rem;
`;

const StyledPanoramaContainer = styled.div`
  width: 100%;
  height: 900px;
  max-height: calc(100% - 25rem);
  min-height: 50rem;
  z-index: 1;
  max-width: 100%;
  margin: 0 auto;
  position: absolute;
  left: 50%;
  top: 15rem;
  transform: translate(-50%, 0);
  .pnlm-container {
    margin: 0 auto;
  }

  .pnlm-about-msg {
    visibility: hidden;
    a {
      visibility: hidden;
    }
  }

  @media (max-height: 670px) {
    min-height: 45rem;
    top: 12rem;
  }

  ${({ theme }) => theme.breakpoints.queries.xl} {
    width: 90%;
    height: 900px;
    top: 15rem;
    max-height: calc(100% - 22rem);
  }
`;
