import { ReactElement, useEffect, useState } from 'react';
import styled from 'styled-components';
import { DragOverlay, DragEndEvent, DragStartEvent } from '@dnd-kit/core';
import { SortableContext, rectSwappingStrategy, arraySwap } from '@dnd-kit/sortable';
import { v4 as uuidv4 } from 'uuid';

import useContent from '../../hooks/useContent';
import PuzzleOverlay from '../interface/PuzzleOverlay';
import Background from '../../assets/puzzle6/background.jpg';
import BookSlider from '../puzzleelements/BookSlider';
import getImageUrl from '../../services/getImageUrl';
import DragAndDropContext from '../puzzleelements/DragAndDropContext';
import { DragAndDropGuestsGrid } from '../puzzleelements/DragAndDropGuestsGrid';
import { GuestImage } from '../puzzleelements/GuestImage';
import { DragAndDropSortableItem } from '../puzzleelements/DragAndDropSortableItem';
import { PuzzleSixData } from '../../data/PuzzleSixData';
import { Puzzle } from '../../types/Puzzle.d';

type Props = {
  finishPuzzle(): void;
};

export default function PuzzleSix(props: Props): ReactElement {
  const { finishPuzzle } = props;
  const puzzleId = Puzzle.SIX;

  const totalColumnsNumber = 8;

  const [isDndSolutionCorrect, setIsDndSolutionCorrect] = useState(false);
  const [items, setItems] = useState(PuzzleSixData);
  const [activeId, setActiveId] = useState<string | number | null>(null);
  const puzzleColor = useContent('PuzzleData', 'puzzleColor', puzzleId).toString();

  const userArray = items;
  const simplifiedUserArray = userArray.map(({ id, ...rest }) => {
    return rest;
  });
  const resultArray = useContent('PuzzleData', 'puzzleCode', puzzleId);

  useEffect(() => {
    if (
      JSON.stringify(resultArray) ===
      JSON.stringify(simplifiedUserArray.slice(totalColumnsNumber, totalColumnsNumber * 2))
    ) {
      setIsDndSolutionCorrect(true);
    } else {
      setIsDndSolutionCorrect(false);
    }
  }, [isDndSolutionCorrect, simplifiedUserArray, resultArray]);

  function renderPlaceholderElements(): ReactElement {
    return (
      <StyledPlaceholderGrid columns={totalColumnsNumber}>
        {items.map(() => (
          <StyledPlaceholderElement key={uuidv4()} />
        ))}
      </StyledPlaceholderGrid>
    );
  }

  function handleDragStart(event: DragStartEvent): void {
    setActiveId(event.active.id);
  }

  function handleDragEnd(event: DragEndEvent): void {
    const { active, over } = event;

    if (over !== null) {
      if (active.id !== over.id) {
        setItems((items) => {
          const oldIndex = items.findIndex(({ id }) => id === active.id);
          const newIndex = items.findIndex(({ id }) => id === over.id);
          return arraySwap(items, oldIndex, newIndex);
        });
      }
    }
    setActiveId(null);
  }

  function handleDragCancel(): void {
    setActiveId(null);
  }

  return (
    <PuzzleOverlay puzzleId={puzzleId} isDndPuzzleSolved={isDndSolutionCorrect} finishPuzzle={finishPuzzle}>
      <StyledBackground src={Background} />
      <StyledGradients color={puzzleColor} />
      <StyledPuzzleContainer>
        {renderPlaceholderElements()}
        <DragAndDropContext onDragStart={handleDragStart} onDragEnd={handleDragEnd} onDragCancel={handleDragCancel}>
          <>
            <DragAndDropGuestsGrid columns={totalColumnsNumber}>
              <SortableContext items={items.map(({ id }) => id)} strategy={rectSwappingStrategy}>
                {items.map(({ id, url }) => (
                  <DragAndDropSortableItem
                    key={uuidv4()}
                    id={id}
                    url={url}
                    totalColumnsNumber={totalColumnsNumber}
                    puzzleId={puzzleId}
                  />
                ))}
              </SortableContext>
            </DragAndDropGuestsGrid>
            <DragOverlay
              adjustScale
              dropAnimation={{
                duration: 300,
                easing: 'ease-in-out',
              }}
            >
              {activeId && (
                <GuestImage
                  url={getImageUrl(items, activeId)}
                  index={activeId}
                  totalColumnsNumber={totalColumnsNumber}
                  isTransparent
                  isDragging={false}
                />
              )}
            </DragOverlay>
          </>
        </DragAndDropContext>
      </StyledPuzzleContainer>
      <BookSlider variant={puzzleId} />
    </PuzzleOverlay>
  );
}

const StyledPuzzleContainer = styled.div`
  position: absolute;
  width: 100%;
  margin: 0 auto;
  z-index: 2;

  ${({ theme }) => theme.breakpoints.queries.md} {
    top: 25rem;
  }

  ${({ theme }) => theme.breakpoints.queries.lg} {
    top: 30rem;
  } 

  @media only screen and (min-width: 1281px) and (max-height: 750px) {
    top: 16rem;
  }
  @media only screen and (max-width: 1280px) and (max-height: 750px) {
    top: 20rem;
  }
`;

const StyledPlaceholderGrid = styled.div<{ columns: number }>`
  display: grid;
  position: relative;
  left: 0;
  width: 80%;
  margin: 0 auto;

  div {
    border-radius: 50%;
    background-color: #bcb8b81a;
    position: absolute;
    padding: 0;
    margin: -0.3rem;
  }

  div:nth-child(-n + ${({ columns }) => columns}) {
    background-color: #bcb8b81a;
  }

  div:nth-child(1) {
    right: 95%;
  }

  div:nth-child(2) {
    right: 79%;
  }

  div:nth-child(3) {
    right: 62%;
  }

  div:nth-child(4) {
    right: 46%;
  }

  div:nth-child(5) {
    right: 30%;
  }

  div:nth-child(6) {
    right: 14%;
  }

  div:nth-child(7) {
    right: -3%;
  }

  div:nth-child(8) {
    right: -105%;
    display: hidden;
  }

  div:nth-child(n + 8) {
    display: none;
  }
`;

const StyledPlaceholderElement = styled.div`
  opacity: 1;
  height: 7rem;
  width: 7rem;
  background-size: cover;
  background-position: center center;
  background-color: transparent;
  border-radius: 50%;
  border: none;

  ${({ theme }) => theme.breakpoints.queries.lg} {
    width: 10rem;
    height: 10rem;
  }
`;

const StyledBackground = styled.img`
  position: absolute;
  width: 100%;
`;

const StyledGradients = styled.div<{ color: string }>`
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: linear-gradient(
      90deg,
      ${({ color }) => color} 0%,
      ${({ color }) => color}00 10%,
      ${({ color }) => color}00 90%,
      ${({ color }) => color} 100%
    ),
    linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.6012780112044818) 0%,
      rgba(0, 0, 0, 0) 20%,
      rgba(0, 0, 0, 0) 80%,
      rgba(0, 0, 0, 0.5984768907563025) 100%
    );
`;
