import { ReactElement } from 'react';
import styled from 'styled-components';

type Props = {
  image: string;
};

export default function BookPage(props: Props): ReactElement {
  const { image } = props;

  return (
    <StyledBookPage>
      <StyledPagePhoto src={image} alt="Book page" />
    </StyledBookPage>
  );
}

const StyledBookPage = styled.div`
  width: 100%;
  padding: 2.8rem 0 0;
`;

const StyledPagePhoto = styled.img`
  width: 100%;
  height: auto;
  margin: 0;
`;
