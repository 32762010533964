/* eslint-disable max-len */
const De = {
  landing_screen: {
    top_banner: 'Das Online Escape Game der Elbphilharmonie® ist leider nicht auf dem Smartphone spielbar.',
    title: 'Rette <br/> das Konzert',
    subline: 'Das digitale Elbphilharmonie® Escape Game',
    button: 'Jetzt kostenlos spielen',
    continue_link: '...oder Spiel fortfahren',
    icon_section_title: 'Starte jetzt ins Abenteuer und werde Teil des Elbphilharmonie-Teams!',
    icon_section_copy:
      'Das große Konzert am heutigen Abend ist in Gefahr – nur Du kannst es retten! Schaffst Du es, alle Aufgaben zu lösen?',
    icon1: {
      title: 'Stell dich der Herausforderung!',
      copy: 'Knifflige Rätsel führen Dich zum Ziel.',
    },
    icon2: {
      title: 'Geh Backstage!',
      copy: 'Entdecke die Elbphilharmonie, wie Du sie noch nie gesehen hast.',
    },
    icon3: {
      title: 'Lade Freund:innen ein!',
      copy: 'Spiel alleine oder im Team.',
    },
    icon4: {
      title: 'Komm vorbei!',
      copy: 'Sichere Dir zur Belohnung 20% Rabatt auf Konzerttickets.',
    },
    image1: {
      title: 'Die Story',
      copy: 'Ein beschädigtes Instrument, ein Künstler, der sich verirrt hat, nicht funktionierende Technik – und das wenige Stunden vor Konzertbeginn! Kannst Du der Projektleiterin Nuria helfen, das Konzert zu retten?',
    },
    image2: {
      title: 'So kannst Du spielen',
      copy: 'Du brauchst einen Laptop oder ein ausreichend großes Tablet sowie eine Internetverbindung. Auf Smartphones ist das Spielen leider nicht möglich.',
      icon1_copy: 'ca. 45 Minuten',
      icon2_copy: 'Allein oder gemeinsam spielbar',
      icon3_copy: 'auf Englisch & Deutsch',
    },
    nomobile_copy:
      'Das Online Escape Game ist leider nicht auf dem Smartphone spielbar. Bitte wechsel auf ein größeres Endgerät.',
    anchor_link: 'Nach oben',
    contact_link: 'Kontakt',
    privacy_link: 'Datenschutz',
    imprint_link: 'Impressum',
    cookies_link: 'Cookies',
  },
  intro_screen: {
    headline: 'Das Konzert',
    headline2: 'Vor der Elbphilharmonie',
    subheadline: 'Der Einstieg',
    buttontext1: 'Weiter',
    buttontext2: '»Klar, worum geht’s?«',
    buttontext3: '»Alles klar, ich gebe mein Bestes!«',
    buttontext4: '»Kann losgehen!«',
    back: 'Zurück',
    nuria_desc: 'Projektleiterin für die Elbphilharmonie',
    message1:
      'Hey! Das Orchester ist gerade gelandet. Leider wurde Paganonos Geige bei der Sicherheitskontrolle beschädigt. Eine Katastrophe!',
    message2: 'Ohne die Geige kann das Konzert nicht stattfinden. Ich muss los, um sie reparieren zu lassen! ',
    message3: 'Ich brauche Unterstützung. Kannst Du mir helfen?',
    message4: 'Du musst die letzten Aufgaben im Haus erledigen, damit das Konzert stattfinden kann.',
    message5: 'Es ist das wichtigste Konzert des Jahres, nichts darf schiefgehen!',
    message6:
      'Aber keine Sorge, ich weiß, Du bekommst das hin. Ich überlasse Dir meine Unterlagen, und über das Smartphone bleiben wir in Kontakt!',
    message7: 'Vielen Dank, damit rettest Du den Abend!',
    message8:
      'Lass uns keine Zeit verlieren. Geh zu den Künstlergarderoben im 11. Stock. Nimm dafür den Lastenaufzug. Bist Du bereit?',
    usermessage1: "Klar, worum geht's?",
    usermessage2: 'Alles klar, ich gebe mein Bestes!',
    usermessage3: 'Kann losgehen!',
  },
  puzzle_screen: {
    keyword: 'Stichwort: #',
    task: 'Aufgabe ',
    switch: 'Aufgabe wechseln',
    manual: 'Anleitung',
  },
  mobile_warn_screen: {
    title: 'Zu kleines Browserfenster',
    copy: 'Dieses Spiel ist für größere Bildschirme ausgelegt. Um zu spielen, öffne es bitte auf Deinem Tablet oder Desktop und achte darauf, dass das Browserfenster maximiert ist (mindestens 1024 Pixel).',
    button: 'Zurück zur Startseite',
  },
  intermission_screen: {
    progress: 'Fortschritt',
    finish: 'Aufgabe abgeschlossen!',
    button_start: 'Los geht’s',
    button_finish: 'Weiter',
  },
  final_screen: {
    hero: {
      title: 'Herzlichen Glückwunsch',
      copy: 'Du hast es geschafft!',
      replay_button: 'Video nochmal abspielen',
    },
    title: 'Lust auf live? Komm vorbei!',
    copy: 'Zur Belohnung schenkt Dir die Elbphilharmonie einen <strong>20%-Rabatt</strong> auf ausgewählte Konzertticktes in der laufenden Saison. Von Klassik bis Jazz, World und Pop – hier ist für jeden Geschmack etwas dabei. Gib im Warenkorb einfach den Ermäßigungscode an und sichere Dir Deine Tickets (einlösbar bis zum 30.04.2024)! ',
    copy_nodiscount: 'Von Klassik bis Jazz, World und Pop – hier ist für jeden Geschmack etwas dabei.',
    discount_code: {
      title: 'Dein Rabattcode:',
      code: 'PAGANONO',
      hint: 'Code in die Zwischenablage kopiert',
      hint_error: 'Error',
    },
    link: {
      url: 'escapegame.elbphilharmonie.de',
      title: 'Empfiehl Deinen Freund:innen das digitale Escape Game der Elbphilharmonie',
      hint: 'Link in die Zwischenablage kopiert',
    },
    image1: {
      title: 'Du magst keine klassische Musik?',
      copy: 'Kein Problem! Hier gibt es viel mehr als nur Klassik: Jazz, World, Pop und Eletronik entdecken!',
    },
    image2: {
      title: 'Für 12 Euro in die Elbphilharmonie',
      copy: 'Elbphilharmonie U30: Attraktive Ermäßigungen für junge Leute.',
    },
    newsletter_button: 'Mehr erfahren',
    team_button: 'Zum Programm',
    mediathek_link: 'https://www.elbphilharmonie.de/de/programm/JA/WELT/PRS/ELEK/',
    u30_link: 'https://www.elbphilharmonie.de/de/u30',
    button: 'Konzerte entdecken',
    subcopy:
      'Hier steht das Kleingedruckte. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.',
  },
  puzzle_one: {
    puzzle_title: 'DER LASTENAUFZUG',
    puzzle_title_keyword: 'violine',
    puzzle_subtitle: 'Wer hoch hinaus will, sollte den Fahrstuhl nehmen',
    info_text: 'Ziehen oder wischen zum Umsehen, scrollen zum Vergrößern',
    note_title: 'Hinweiszettel',
    check_button: 'Code prüfen',
    puzzleSolution_title: 'Wie lautet der Aufzug-Code?',
    intermission_start_msg1:
      'Ah, Mist … Jetzt habe ich in der Eile ganz vergessen, Dir die Zugangskarte zu geben, die Du für den Aufzug brauchst. 😓',
    intermission_start_msg2:
      'Es gibt aber ein Codewort, mit dem Du den Fahrstuhl bedienen kannst. Schau Dich mal um. Da sollten irgendwo Hinweise sein, die Dir helfen, das Codewort zu finden!',
    intermission_finish_msg1: 'Super, Du hast den richtigen Code gefunden!',
    hint1:
      'Schau Dich per 360°-Ansicht im Fahrstuhl um. Vielleicht fallen Dir ja vier Wörter auf, die Dir bei der Entschlüsselung des Codeworts helfen.',
    hint2:
      'Von jedem der farblich hervorgehobenen Wörter benötigst Du einen bestimmten Buchstaben. Die einzelnen Buchstaben ergeben dann zusammen das Codewort.',
    hint3:
      'Gesucht wird jeweils der vierte Buchstabe der farblich markierten Wörter: A, U, R und F. Wenn Du sie anhand der Farben sortierst, erhältst Du das Codewort »RAUF«. Gib es in das Eingabefenster ein.',
    task_step1: 'Sieh Dich im Lastenaufzug um',
    task_step2: 'Entschlüssle den Code, um nach oben zu fahren',
  },
  puzzle_two: {
    puzzle_title: 'Der Orchesterplan',
    puzzle_title_keyword: 'cello',
    puzzle_subtitle: 'Das rote »X« markiert den Plan',
    check_button: 'Code prüfen',
    letter: 'Buchstabe',
    number: 'Zahl',
    back: 'Zurück zum Raumplan',
    solution_title: 'Wo befindet sich Nurias Fach?',
    floor_name: '11. Stock',
    room_name: '| Raum ',
    intermission_start_msg1:
      'Ich hab eben eine Nachricht von den Techniker:innen aus dem Großen Saal bekommen. Sie brauchen den Plan für den Orchesteraufbau.',
    intermission_start_msg2:
      'Für solche Informationen habe ich ein kleines Fach, dort müsste auch eine Kopie des Plans sein … Kannst Du ihn holen?',
    intermission_start_msg3:
      'Oh, ich bin an der Reihe und muss kurz das Handy aus der Hand legen. Auf meinem Notizzettel siehst Du, in welchem Raum sich das Fach befindet. Ich melde mich gleich wieder.',
    intermission_finish_msg1:
      'Glückwunsch, Du hast den Orchesterplan gefunden! Nun kann die Bühne ausgestattet werden.',
    hint1: 'Betrachte die unterschiedlichen Räume, indem Du auf die blauen Flächen klickst. Fällt Dir dort etwas auf?',
    hint2: 'Gesucht wird eine Koordinate. Vergleiche die Objekte auf dem Hinweis mit denen in den Fotos.',
    hint3:
      'Auf dem Lageplan des elften Stocks kannst Du die Räume miteinander verbinden, so dass sich ein Kreuz ergibt. Dort, wo sich die Linien überlappen, ist Nurias Fach. Gesucht ist die Koordinate H4.',
    task_step1: 'Suche Nurias Fach, um den Orchesterplan zu finden',
  },
  puzzle_three: {
    puzzle_title: 'Der Orchesteraufbau',
    puzzle_title_keyword: 'mundharmonika',
    puzzle_subtitle: 'Wer sitzt hier falsch?',
    check_button: 'Aufgabe lösen',
    solution: 'Lösung',
    audio_file: 'Orchesterprobe.mp3',
    transcription: 'Transkription',
    transcription_copy:
      'Das Audio wird abgespielt: Wir hören, wie das Orchester im Großen Saal die Instrumente stimmt. Der Dirigent klopft mit dem Taktstock, um auf den Beginn der Probe aufmerksam zu machen. Der Reihe nach spielen die folgenden Instrumente: Harfe, Violine, Klavier, Violine, Fagott, Querflöte, Pauke. Nach einer kurzen Pause fahren die folgenden Instrumente fort: Harfe, Posaune, Violine, Pauke, Fagott, Violine, Klavier, Querflöte.',
    solution_title: 'Hast Du alle falsch platzierten Instrumente erkannt? Gib das Lösungswort ein.',
    solution_input: 'Code prüfen',
    intermission_start_msg1:
      'Eine Kollegin hat mir eben aus dem Saal geschrieben. Die Probe hat begonnen, scheinbar gibt es aber Probleme mit der Orchesteraufstellung.',
    intermission_start_msg2: 'Sie hat ein paar Aufnahmen geschickt, irgendwas stimmt da wirklich nicht ... 🙄',
    intermission_start_msg3:
      'Kannst Du mal in die Aufnahme aus dem Großen Saal reinhören und feststellen, welche Instrumente sich nicht an die abgebildete Reihenfolge halten? So können wir herausfinden, wer falsch sitzt.',
    intermission_finish_msg1: 'Gute Arbeit, weiter so! Jetzt sitzen alle am richtigen Platz. 💪',
    hint1:
      'Verfolge den Orchesterplan, während Du Dir die Probe anhörst. Hörst Du in der Aufnahme ein anderes Instrument als im Orchesterplan vorgesehen? Der äußere Kreis spielt in einer anderen Richtung als der innere, vom Dirigenten aus beginnend.',
    hint2:
      'Die Instrumente in der Aufnahme spielen wie folgt: Harfe, Violine, Klavier, Violine, Fagott, Querflöte, Pauke. Pause. Harfe, Posaune, Violine, Pauke, Fagott, Violine, Klavier, Querflöte.',
    hint3: 'Notierst Du Dir alle Buchstaben der falsch spielenden Instrumente, erhältst Du das Lösungswort »FLOETE«.',
    task_step1:
      'Hör Dir die Orchesterprobe an und vergleiche sie mit dem Plan, um herauszufinden, welche Instrumente in der Aufnahme falsch spielen.',
    task_step2: 'Achte auf die Reihenfolge der Instrumente',
  },
  puzzle_four: {
    puzzle_title: 'DIE SOLISTENGARDEROBE',
    puzzle_title_keyword: 'altsaxophon',
    puzzle_subtitle: 'Chronologisch unterwegs im 12. Stock',
    check_button: 'Reihenfolge prüfen',
    check_button_error: 'Leider falsch. Probiere es nochmal.',
    intermission_start_msg1:
      'Bevor Paganono ankommt, sollten wir noch seine Garderobe einrichten. Die Jazzband von der Probe heute Morgen sollte jetzt draußen sein.',
    intermission_start_msg2:
      'Ich habe für Paganono extra ein paar Bilder bestellt, die schon in der Garderobe bereit liegen.',
    intermission_start_msg3:
      'Die Bilder sollen chronologisch nach Veröffentlichungsdatum aufgehängt werden. Schau sie Dir genau an: Was könnten sie darstellen?',
    intermission_start_msg4: 'Ich habe ein kleines Handbuch in meinen Unterlagen, das Du zu Rate ziehen kannst. 🤓',
    intermission_finish_msg1: 'Super! Ich hoffe, Paganono wird sich freuen. 😊',
    hint1:
      'Die Bilder stehen für klassische Musikstücke, klicke sie an, um sie näher zu untersuchen. Doch nicht alle Infos verstecken sich in den Bildern und dem Handbuch – Du kannst auch auf eigene Faust recherchieren.',
    hint2:
      'Sortiere die Musikstücke nach Veröffentlichungsdatum. Zum Teil musst Du die Namen der Stücke oder ihre Künstler anhand der Bilder und mit einer Internet-Suche herausfinden. Dann findest Du auch die Daten der Veröffentlichung, oder kannst sie aus Nurias Handbuch ziehen',
    hint3:
      'Die richtige Reihenfolge lautet: Für Elise (1810 – achte auf das Bild der Frau), Hochzeitsmarsch (1842), die Oper AIDA (1871), Morgenstimmung (1875), Schwanensee (1895), Hummelflug (ca. 1900 – hier versteckt sich eine kleine Hummel in einem der Bilder)',
    task_step1: 'Sortiere die 6 dargestellten Werke nach Veröffentlichungsdatum',
  },
  puzzle_five: {
    puzzle_title: 'Die Beleuchtung',
    puzzle_title_keyword: 'synthesizer',
    puzzle_subtitle: 'Die Technik ruft: »Es werde Licht!«',
    check_button: 'Code prüfen',
    solution_title: 'Gib nacheinander die entsprechenden Codes ein, um die richtige Beleuchtung zu aktivieren.',
    solution_input_button: 'Scheinwerfer einschalten',
    solution_input_error: 'Beleuchtungscode falsch',
    solution_input_copy_error: 'Beleuchtungscode ungültig. Bitte versuche es mit einem anderen Code.',
    solution_input_copy_code_exists: 'Diese Beleuchtung ist bereits aktiviert.',
    solution_input_copy_code_success: 'Beleuchtungscode aktiviert. ',
    solution_input_copy_success: 'Alle Beleuchtungen erfolgreich aktiviert.',
    solution_input_copy_count_start: 'Noch ',
    solution_input_copy_count_end: 'Beleuchtung(en) verbleibend.',
    light1: 'Spot 1',
    light2: 'Floorlight 2',
    light3: 'Backlight',
    back_button: 'zurück zur Übersicht',
    intermission_start_msg1: 'Jetzt ist es fast soweit! 🤗 Wir müssen noch die Beleuchtung checken.',
    intermission_start_msg2:
      'Jeder Scheinwerfer kann mit einem Code eingeschaltet werden. Für das heutige Konzert werden Spot 1, Floorlight 2 und Backlight benötigt.',
    intermission_start_msg3:
      'Du kannst die drei Scheinwerfer in beliebiger Reihenfolge einschalten. Auf dem Pult liegt eine Anleitung bereit, doch vielleicht sind nicht alle Codes vollständig ...',
    intermission_finish_msg1: 'Es werde Licht! Gut gemacht, jetzt ist die Bühne bereit. 🥳',
    hint1:
      'Überall sind Hinweise versteckt, mit denen Du Buchstaben und Zahlen ermitteln kannst. Denk daran, dass Du drei Codes für drei Scheinwerfer benötigst. Die Reihenfolge ist dabei egal.',
    hint2:
      'Wenn sich etwas bewegt, achte genau darauf, wie es sich bewegt. Womöglich kannst Du dort Zahlen erkennen, die farblich zu den Codes passen?',
    hint3:
      'Du musst diese drei Codes in beliebiger Reihenfolge eingeben: Spot 1: 2287 Floorlight 2: UAI4 Backlight: 1UO6',
    task_step1:
      'Vervollständige die drei benötigten Codes aus der Anleitung und schalte die Scheinwerfer in beliebiger Reihenfogle ein.',
  },
  puzzle_six: {
    puzzle_title: 'Die Gäste',
    puzzle_title_keyword: 'drumset',
    puzzle_subtitle: 'Großer Saal – viele Wünsche',
    check_button: 'Reihenfolge prüfen',
    check_button_error: 'Leider falsch. Probiere es nochmal.',
    clipboard: {
      title: 'Gästeliste von Paganono',
      guest1: {
        name: 'Tarek',
        text: 'Dürfte meine Frau möglichst mittig sitzen? Sie ist die mit dem roten Blazer. Ich bin mit jedem Platz zufrieden.',
      },
      guest2: {
        name: 'Hannah',
        text: 'Ich möchte neben meinem Mann sitzen.',
      },
      guest3: {
        name: 'Delia',
        text: 'Neben mir soll ein Platz frei bleiben, damit ich meine Tasche abstellen kann. Ich liebe es, mitten im Spektakel zu sein, deshalb möchte ich möglichst mittig sitzen.',
      },
      guest4: {
        name: 'Kim',
        text: 'Ich möchte so nah wie möglich am Rand sitzen.',
      },
      guest5: {
        name: 'Elif',
        text: 'Ich möchte nicht ganz am Rand sein, neben mir soll aber nur eine weitere Person sitzen.',
      },
      guest6: {
        name: 'Judy',
        text: 'Darf ich rechts neben meinem Vater sitzen? Er trägt eine Brille und hat sehr kurze Haare.',
      },
      guest7: {
        name: 'Thomas',
        text: 'Ich möchte neben möglichst wenigen anderen Gästen Paganonos sitzen.',
      },
    },
    intermission_start_msg1:
      'Ich schätze, Paganonos geladene Gäste treffen jeden Augenblick ein - sie benötigen noch ihre Tickets!',
    intermission_start_msg2: 'Achte bitte darauf, die Gäste so zu platzieren, dass alle zufrieden sind. 🙏',
    intermission_finish_msg1: 'Das war nicht leicht, aber Du hast alle Gäste glücklich gemacht! 😊',
    intermission_finish_msg2: 'Danke für Deine Unterstützung! Das Konzert ist zum Greifen nahe ...',
    hint1: 'Thomas ist der Vater von Judy.',
    hint2: 'Zwischen Elif und Delia bleibt ein Platz frei.',
    hint3: 'Platziere die Personen so: Kim, Elif, freier Platz, Delia, Hannah, Tarek, Judy, Thomas.',
    task_step1: 'Platziere die Gäste nach ihren Wünschen',
  },
  puzzle_seven: {
    puzzle_title: 'Der Standort',
    puzzle_title_keyword: 'kontrabass',
    puzzle_subtitle: 'Panorama-Perspektiven auf der Plaza',
    info_text: 'Navigiere dich nach rechts und links, um mehr zu entdecken!',
    check_button: 'Anordnung prüfen',
    check_button_error: 'Leider falsch. Probiere es nochmal.',
    to_map: 'zurück zur Karte',
    lookaround: 'Umschauen',
    direction_button_all: {
      title: '8. STOCK',
      subtitle: 'PLAZA',
    },
    intermission_start_msg1:
      'Gute Nachrichten: Die Geige ist wieder heil und ich bin auf dem Weg zurück in die Elbphilharmonie 😊',
    intermission_start_msg2:
      'Aber Paganono hat eben angerufen – er ist auf der Plaza und findet den richtigen Fahrstuhl nicht!',
    intermission_start_msg3:
      'Ich bin nicht sicher, wo er genau steht. Kannst Du das herausfinden und ihn lotsen? Er sagt, er sieht einen Turm und die vier Masten der Peking.',
    intermission_start_msg4:
      'Ich habe versucht, Dir zur Orientierung die Umgebungskarte zu schicken, aber sie ist zu groß. Deswegen schicke ich sie Dir in mehreren Teilen.',
    intermission_start_msg5:
      'Schau Dir die Umgebung an und setze die Karte zusammen. So können wir klären, wo Paganono gerade ist.',
    intermission_finish_msg1:
      'Alles klar! Paganono sieht das Segelfrachtschiff Peking und den Wilhelmsburger Wasserturm. Er befindet sich also im Südosten der Plaza!',
    intermission_finish_msg2:
      'Sehr gut! Dann kann ich ihm sagen, wie er zum Fahrstuhl kommt. Wenn er oben ankommt, kann er sich direkt in seiner Garderobe umziehen und muss dann fürs zweite Stück auf die Bühne. Es kann losgehen! 🤩',
    hint1: 'Schaue Dir die unterschiedlichen Panoramen genau an. Erkennst Du die Sehenswürdigkeiten woanders wieder?',
    hint2:
      'In jeder Himmelsrichtung befindet sich eine Sehenswürdigkeit, die auf den Karten des Plans als Symbol wiederzufinden ist. Bei manchen Sehenswürdigkeiten musst Du Dir die Informationen genau anschauen und etwas um die Ecke denken.',
    hint3:
      'Die Symbolkarten müssen wie folgt angelegt werden (von Norden ausgehend im Uhrzeigersinn): Uhrenturm, Fischbrötchen, Segelboot, Wasserturm, Löwe (König der Löwen Musical), Schwan (Cap San Diego), Fernsehturm.',
    hotspots: {
      no: [
        {
          title: 'HAMBURGER RATHAUS',
          text: 'Das Rathaus der Freien und Hansestadt Hamburg ist der prunkvolle Sitz der »Regierung eines selbständigen Staates« (A. Lichtwark). In dem 1886 bis 1897 im Stil der Neorenaissance erbauten Haus mit dem 112 Meter hohen Turm logieren die Landesregierung (Hamburger Senat) und das Landesparlament (Hamburgische Bürgerschaft).',
        },
        {
          title: 'MAHNMAL ST. NICOLAI',
          text: 'Die ehemalige Hauptkirche St. Nicolai wurde im Zweiten Weltkrieg zerstört und erinnert uns nun als umsorgte Ruine an die Opfer des NS-Regimes. Der Turm ist der fünfthöchste Kirchturm der Welt.',
        },
        {
          title: 'HAUPTKIRCHE ST. PETRI',
          text: 'Mitten in der Hamburger Innenstadt liegt diese weitere Hauptkirche. Nach etwas mühsamen 544 Treppenstufen gelangt man bei 123 Metern auf die derzeit höchste Aussichtsplattform der Stadt.',
        },
        {
          title: 'HAUPTKIRCHE ST. JACOBI',
          text: 'Schon im 14. Jahrhundert begann der Bau. Die Kirche diente 1769 als erster Blitzableiter Deutschlands und während der napoleonischen Besatzungszeit (1806–1814) auch mal als Pferdestall.',
        },
        {
          title: 'HAUPTKIRCHE ST. KATHARINEN',
          text: 'Die goldene Krone auf dem Turm soll gerüchteweise aus dem Schatz des geköpften Piraten Klaus Störtebeker stammen. Hier entwickelte Gotthold Ephraim Lessing bei einem Streitgespräch mit dem Hauptpastor Goeze die Idee zu seinem letzten und bekann&shy;testen&shy; Werk »Nathan der Weise«.',
        },
        {
          title: 'SPEICHERSTADT',
          text: 'Die historischen Backsteingebäude mit den vielen grünen Dächern wurden seit 1883 auf tausenden Eichenpfählen gebaut. Das größte Lagerhaus-Ensemble der Welt ist seit 2015 UNESCO-Welterbe. In der Speicherstadt finden sich neben den ansässigen Betrieben auch das Miniatur Wunderland, Museen, eine Kaffeerösterei oder das Hamburg Dungeon.',
        },
        {
          title: 'SPIEGEL-HOCHHAUS',
          text: 'Im September 2011 zog der Spiegel-Verlag in das neue Gebäude ein, und die wohlbekannte orange-rote Spiegel-Kantine zog in das Museum für Kunst und Gewerbe um.',
        },
        {
          title: 'SANDTORHAFEN',
          text: 'Der Sandtorkai mit den drei restaurierten Stückgutkränen wurde in den 1860er Jahren gebaut und war Hamburgs erstes künstlich geschaffenes Hafenbecken. Zwischen 20 und 50 Schiffe liegen in dem Traditionsschiffhafen.',
        },
        {
          title: 'MAGELLAN-TERRASSEN',
          text: 'Die stufenförmigen Terrassen bilden einen architektonisch anspruchsvoll gestalteten Freiraum am östlichen Ende des Sandtorhafens.',
        },
        {
          title: 'KRAN IM SANDTORHAFEN',
          text: 'Die drei restaurierten Kräne am südlichen Kaiufer erinnern an die ursprüngliche Funktion dieses Hafens: Mit Hilfe von »Brownschen Dampfkranen« konnten die Schiffe direkt in die Kaischuppen, auf Eisenbahnwaggons oder Pferdefuhrwerke gelöscht bzw. von diesen beladen werden – das war revolutionär zur damaligen Zeit. Gefertigt wurden diese Kräne übrigens auf Kampnagel – mittlerweile selbst ein angesagter Hamburger Kulturort.',
        },
      ],

      o: [
        {
          title: 'DEICHTORHALLEN',
          text: 'Ganz ursprünglich stand hier der Berliner Bahnhof, der nach dem Bau des Hauptbahnhofs ab 1906 jedoch dem Großmarkt weichen musste. In den beiden ehemaligen Markthallen, erbaut 1911 bis 1914, befinden sich (versteckt hinter dem Spiegel-Hochhaus) die Halle für aktuelle Kunst und das Haus der Photographie.',
        },
        {
          title: 'GROSSMARKT-HALLE',
          text: 'Die geschwungenen Dächer der ca. 220 Meter langen drei Großmarkthallen bieten aus&shy;schließlich gewerblichen Käufern an Werktagen von 2 (!) bis 9 Uhr Obst und Gemüse an. Einmal jährlich darf die Öffentlichkeit in die Hallen hineinschnuppern: Beim »Food Market Hamburg« präsentieren Spitzenköche ihre kulinarischen Meisterleistungen.',
        },
        {
          title: 'BESUCHERZENTRUM DER ELBPHILHARMONIE',
          text: 'Plaza-Tickets, Souvenirs und Antworten auf Fragen rund um die Elbphilharmonie gibt es hier. (Konzerttickets gibt es schräg gegenüber in der Konzertkasse oder online.)',
        },
        {
          title: 'DISCOVERY DOCK',
          text: 'Virtuell, interaktiv und ganz ohne Barkasse erkunden (nicht nur) Technikbegeisterte den Hamburger Hafen, der sich hier als multimedialer Abenteuerspielplatz präsentiert.',
        },
        {
          title: 'KÜHNE LOGISTICS UNIVERSITY',
          text: 'Die von der Kühne Stiftung getragene KLU ist eine private Hochschule. Schwerpunktmäßig wird in den Bereichen Logistik, Supply Chain Management sowie Management gelehrt und geforscht.',
        },
        {
          title: 'HAFENCITY UNIVERSITÄT',
          text: 'Diese Universität für Baukunst und Metropolenentwicklung wurde 2014 an ihrem namensgebenden Ort eröffnet und gehört zu den stadtentwicklungspolitischen »Leuchtturmprojekten«. Etwa 1.500 Studierende lernen auf dem ca. 4.000 m² großen Grundstück mit zwei öffentlich zugänglichen Wasserfronten sowie einer weitläufigen Uferpromenade. Seit 2012 verbindet die neue Linie U4 die HCU über die Haltestelle HafenCity Universität.',
        },
        {
          title: 'CARLS',
          text: 'Brasserie und Restaurant – das CARLS ist einer der ältesten Nachbarn der Elbphilharmonie.',
        },
      ],

      so: [
        {
          title: 'UNILEVER-GEBÄUDE UND MARCO-POLO-TOWER',
          text: 'Ein Hingucker ist das Ensemble mit dem 60 Meter hohen Marco-Polo-Tower und dem Unilever-Gebäude. Letzteres zeichnet sich durch seine besondere Nachhaltigkeit aus und wurde dafür mit dem Umweltzeichen der HafenCity zertifiziert.',
        },
        {
          title: 'STRANDKAI',
          text: 'Die lange Zunge des Strandkais wird an den vorderen 90 Metern unbebaut bleiben. Auf dem größten Teil der Fläche entstehen Wohnungen und Bürogebäude.',
        },
        {
          title: 'GRASBROOK',
          text: 'Dieser Stadtteil war mal für die Austragung der Olympischen Spiele 2024 oder 2028 vorgesehen – woraus aufgrund eines abschlägigen Votums der Hamburgerinnen und Hamburger nichts wurde. In den kommenden 20 Jahren entsteht hier nun ein Wohn-, Arbeits- und Freizeit-Quartier entlang des Elbufers.',
        },
        {
          title: 'MOLDAUHAFEN',
          text: 'Das rund 30.000 m² große Gebiet stellte eine Besonderheit im Hamburger Hafen dar: Es wurde 1919 im Rahmen des Versailler Vertrages an die Tschechoslowakei verpachtet. Der nachfolgend ausgehandelte Pachtvertrag gilt noch bis 2028.',
        },
        {
          title: 'ANLEGER ELBPHILHARMONIE',
          text: 'Seit Ende 2012 fährt als Teil des öffentlichen Nahverkehrs die HADAG-Fähre, Linie 72, von den Landungsbrücken zur Elbphilharmonie.',
        },
        {
          title: 'SEGELSCHIFFHAFEN',
          text: 'Dieses Hafenbecken heißt Segelschiffhafen.',
        },
        {
          title: 'PEGELMESSER',
          text: 'Der Wasserstandsanzeiger auf dem Amerikahöft stammt aus den 1950er Jahren und befindet sich auf der Denkmalliste der Hansestadt. Auf seinem uhrenähnlichen Ziffernblatt zeigt er den Pegelstand der Elbe an.',
        },
        {
          title: 'HANSAHAFEN',
          text: 'Dieses Hafenbecken heißt Hansahafen.',
        },
        {
          title: '50ER SCHUPPEN UND HAFENMUSEUM HAMBURG',
          text: 'Wie es im Hafen aussah, bevor der Container (in den 1960er Jahren) eingeführt wurde, ist hier im Hansahafen hautnah erlebbar. An diesem letzten und geschützten Kaiensemble der Kaiserzeit wird von April bis Oktober historisches Hafenleben gezeigt.',
        },
        {
          title: 'DIE PEKING',
          text: 'Das 1911 von der Reederei Laeisz erbaute Segelfrachtschiff PEKING ist 2017 nach über 40 Jahren recht marode aus New York zurückgekehrt und liegt seit September 2020 nun fast fertig saniert hier im Hansahafen. Seine wohl allerletzte Reise wird es innerhalb Hamburgs antreten, um als schillerndes Leitobjekt dem in Planung befindlichen Deutschen Hafenmuseum Strahlkraft zu verleihen.',
        },
      ],

      s: [
        {
          title: 'ANLEGER ARNINGSTRASSE UND EINFAHRT STEINWERDER HAFEN',
          text: 'Der Anleger wird von der HADAG-Fähre 72 angefahren, die auch bei der Elbphilharmonie anlegt.',
        },
        {
          title: 'ENERGIEBUNKER WILHELMSBURG',
          text: 'Der ehemalige Flakbunker ist jetzt ein Kraftwerk für erneuerbare Energien. Mit der 360°-Aussichtsplattform und dem Café auf dem Dach liegt er inmitten der Elbinsel Wilhelmsburg.',
        },
        {
          title: 'WILHELMSBURGER WASSERTURM',
          text: 'Der Wasserturm Groß Sand sicherte von 1911 bis 1956 die Wasserversorgung im Stadtteil; mitt&shy;lerweile kann man in dem Kulturdenkmal wohnen.',
        },
        {
          title: 'WASSERSCHUTZPOLIZEI-SCHULE',
          text: 'Seit 1945 gibt es hier diesen Ausbildungsort für alle Wasserschutzpolizist:innen Deutschlands – nur Thüringen ist nicht beteiligt, denn dort gibt es keine Wasserschutzpolizei.',
        },
        {
          title: 'INDUSTRIEDENKMAL RETHESPEICHER',
          text: 'In Wilhelmsburg steht dieser imposante Getreidespeicher – ein großer Komplex, dessen Gebäudeteile aus verschiedenen Jahrzehnten des vergangenen Jahrhunderts stammen.',
        },
        {
          title: 'KOHLEKRAFTWERK MOORBURG',
          text: 'Ab 2007 gebaut, 2015 in den Betrieb gestartet und 2020 wieder vom Netz genommen – das umstrittene ehemalige Kohlekraftwerk wird nun zukünftig einen wichtigen Beitrag zur CO2-Einsparung leisten. Geplant ist hier eine Elektrolyse-Anlage zur Produktion von grünem Wasserstoff. Die bestehende Infrastruktur soll überdies für weitere innovative Technologien zur Energieerzeugung genutzt werden.',
        },
        {
          title: 'HARBURGER BERGE',
          text: 'Der höchste »Berg« Hamburgs heißt Hasselbrack und bildet mit 116,2 Metern über Normalnull die Spitze des Hamburger Höhenzuges – also den höchsten natürlichen Punkt Hamburgs.',
        },
        {
          title: 'KÖHLBRANDBRÜCKE',
          text: 'Die Schrägseilbrücke mit den markanten 88 Stahlseilen ist mit gut 3.600 Metern Gesamtlänge die zweitlängste Straßenbrücke Deutschlands. Bald ist die Brücke aber absehbar so marode, dass es eine neue Querung geben muss: Voraussichtlich ab 2034 wird ein Tunnel die Brücke ersetzen.',
        },
        {
          title: 'EINFAHRT REIHERSTIEG',
          text: 'Der Reiherstieg verbindet als Querachse durch den Hafen die Norderelbe mit der Süderelbe.',
        },
      ],

      sw: [
        {
          title: 'HAMBURG CRUISE CENTER STEINWERDER',
          text: 'Das neueste der drei Hamburger Kreuzfahrt-Terminals kann bis zu 8.000 Passagiere pro Anlauf von Schiffen mit bis zu 400 Metern Länge abfertigen. In Corona-bedingt Kreuzfahrt-ärmeren Zeiten wurde das große Gelände mitten im Hafen kurzerhand zu einem kulturellen Ort: mit Autokino, Konzerten und Comedy.',
        },
        {
          title: 'KÖHLBRANDBRÜCKE',
          text: 'Die Schrägseilbrücke mit den markanten 88 Stahlseilen ist mit gut 3.600 Metern Gesamtlänge die zweitlängste Straßenbrücke Deutschlands. Bald ist die Brücke aber absehbar so marode, dass es eine neue Querung geben muss: Voraussichtlich ab 2034 wird ein Tunnel die Brücke ersetzen.',
        },
        {
          title: 'TERMINAL TOLLERORT',
          text: 'Das HHLA-Containerterminal Tollerort hat eine Kailänge von 1.240 Metern und kann Schiffe mit bis zu 400 Metern Länge löschen. Und das ist nur das kleinste der Terminals. HHLA heißt übrigens »Hamburger Hafen und Logistik AG«, es ist also die Firma, die dafür sorgt, dass in Hamburg alle Güter im Hafen reibungslos bewegt werden können.',
        },
        {
          title: 'TERMINAL BURCHARDKAI',
          text: 'Das HHLA-Containerterminal Burchardkai ist der größte und älteste Umschlagplatz hier im Hafen – schon seit 1968 werden an dieser Stelle Container abgefertigt. (1961 gab es die ersten genormten Container.)',
        },
        {
          title: 'STAGE THEATER AN DER ELBE',
          text: 'Am Südufer der Norderelbe, auf der Elbinsel Steinwerder, liegen die beiden Theater der Stage Entertainment. Bis zu 1.850 Gäste können in dem silbrig glänzenden Gebäude mit der 12 Meter hohen Glasfassade hochkarätige Musical-Kunst erleben.',
        },
        {
          title: 'MUSICALBOULEVARD',
          text: 'Der Boulevard liegt zwischen den beiden Musical-Theatern. Man kann dort drinnen und draußen speisen und den Blick nach Norden über Stadt und Elbphilharmonie schweifen lassen.',
        },
        {
          title: 'STAGE THEATER IM HAFEN',
          text: 'In dem markanten gelben Zelt der Stage Entertainment wird bereits seit 2001 der Publikumsmagnet »König der Löwen« gespielt.',
        },
        {
          title: 'ANLEGER THEATER IM HAFEN',
          text: 'Mit der HADAG-Fähre 73 kann man sich von den Landungsbrücken zu den Musical-Theatern schippern lassen. Musical-Gäste nutzen den kostenlosen Schiff-Shuttle.',
        },
        {
          title: 'BLOHM + VOSS HAUPTGEBÄUDE',
          text: 'Von 1911 stammt das Hauptverwaltungsgebäude des 1877 gegründeten Unternehmens.',
        },
      ],

      w: [
        {
          title: 'SCHIFFSWERFT BLOHM + VOSS',
          text: 'Auffällig sind die Docks 10 und 11 direkt auf der Elbe sowie das Trockendock Elbe 17, das als eines der größten Trockendocks Europas gilt.',
        },
        {
          title: 'ALTONAER FISCHAUKTIONSHALLE UND FISCHMARKT',
          text: '1894 erbaut und seit 1989 unter Denkmalschutz: Die ehemalige Auktionshalle ist heute ein beliebter Veranstaltungsort, beispielsweise für den Frühschoppen nach dem frühen Shoppen auf dem Fischmarkt. Der Fischmarkt besteht seit über 300 Jahren – eine Hamburger Institution für Frühaufsteher und Nachtschwärmer gleichermaßen.',
        },
        {
          title: 'PARK FICTION IM ANTONIPARK',
          text: 'Ein »Fliegender Teppich« unter farbenfrohen stählernen Palmen und weitere kreative Hingucker – das Kunstprojekt ist eine von Bürgern erkämpfte grüne Oase im betonierten Großstadt-Trubel.',
        },
        {
          title: 'CAP SAN DIEGO',
          text: 'Denkmal, Hochseilgarten, Hotel, Ausstellungs- und Veranstaltungsort und immer noch seetüchtig: Die CAP SAN DIEGO fuhr einst als Stückgutfrachter über die Meere. Der »Weiße Schwan« an der Überseebrücke ist eines der unübersehbaren Hamburger Wahrzeichen und zeugt von moderner maritimer Tradition in Hamburg.',
        },
        {
          title: 'ST. TRINITATIS ALTONA',
          text: 'Direkt neben dem Fischmarkt steht diese ev.-luth. Kirche. Dass die Monogramme zweier dän&shy;ischer Könige namens Christian (V. und VI.) deren Sandportale schmücken, ist darauf zurückzuführen, dass Altona vor gut 130 Jahren noch dänisch regiert wurde.',
        },
        {
          title: 'ALTER ELBTUNNEL (NORDEINGANG)',
          text: 'Der 1911 erbaute, 426 Meter lange St. Pauli-Elbtunnel mit den schön gefliesten und mit Steinzeug-Reliefs verzierten zwei Röhren wird zurzeit aufwendig restauriert. Daher steht er aktuell mit nur je einer Röhre ausschließlich Fußgängern und Radfahrern kostenfrei zur Verfügung.',
        },
        {
          title: 'LANDUNGSBRÜCKEN',
          text: 'Die schwimmenden, ca. 700 Meter langen Pontons gibt es seit 1839. Markant sind die zwei runden Türme mit den grünen Kuppeln sowie der fast sakral anmutende Pegelturm, der alle halbe Stunde per Schiffsglocke die Uhrzeit kündet und auch den Stand der Tide anzeigt.',
        },
        {
          title: 'BERNHARD-NOCHT-INSTITUT FÜR TROPENMEDIZIN',
          text: '1900 wurde das »Institut für Schiffs- und Tropenkrankheiten« gegründet. Es war zunächst eine Dienststelle der Hamburger Gesundheitsbehörde. Tropentypische Erkrankungen und neue Infektionskrankheiten werden hier erforscht – und natürlich wurden hier auch Erkenntnisse über das Corona-Virus gewonnen.',
        },
        {
          title: 'EMPIRE RIVERSIDE HOTEL',
          text: 'Der britische Stararchitekt David Chipperfield führte bei diesem Gebäude die Feder auf dem Reißbrett. Das Design-Hotel mit der Bar in 90 Metern Höhe bietet einen Ausblick, der fast so schön ist wie der Blick von der Elbphilharmonie.',
        },
        {
          title: 'RICKMER RICKMERS',
          text: 'Der Stapellauf des dreimastigen Großseglers fand 1896 in Bremen statt: Wind, Dampf und Diesel trieben ihn an. Die Rickmer Rickmers liegt fest am Fiete-Schmidt-Anleger und bietet als schwimmendes Museum Informationen zum Schiff und zur Schifffahrt an.',
        },
        {
          title: 'ANLEGER SANDTORHÖFT',
          text: 'Von hier hat man einen ganz unmittelbaren Blick und eine besondere Perspektive auf den Hafen sowie auf die Westspitze der Elbphilharmonie.',
        },
        {
          title: 'ASTRA-TURM',
          text: 'Von hier stammte das legendäre Hamburger Bier. Bis 2006 stand an dieser Stelle das Astra-Bürogebäude in Biertulpen-Form. Eine leichte Einschnürung an der Fassade des neuen Gebäudes stellt einen vagen Bezug zu dem kultigen Gebäude von damals her.',
        },
        {
          title: 'PROMENADE UND HOCHWASSERSCHUTZANLAGE',
          text: 'Die von Zaha Hadid Architects entworfene Anlage verbindet seit Mai 2019 über eine Länge von 625 Metern die St. Pauli-Landungsbrücken mit dem Baumwall.',
        },
        {
          title: 'ATLANTIK-HAUS',
          text: 'Dieses Gewerbe- und Bürogebäude auf St. Pauli ist so platziert, dass sich die Mieter ihre Adresse unter drei verschiedenen Möglichkeiten aussuchen können.',
        },
        {
          title: 'RUNDBUNKER',
          text: 'Der ehemalige runde Hochbunker am Vorsetzen wurde 1940 als sog. »Zombeck-Turm« erbaut. Er war für 600 Personen ausgelegt, tatsächlich suchten dort aber bis zu 1000 Menschen Schutz vor den Bomben des Zweiten Weltkriegs.',
        },
        {
          title: 'TANZENDE TÜRME',
          text: 'Die weithin sichtbaren zwei Hochhäuser am Eingang der Reeperbahn beherbergen den Mojo Club, das Radio Reeperbahn, das höchste Restaurant Hamburgs sowie Büros.',
        },
      ],

      nw: [
        {
          title: 'HAUPTKIRCHE ST. MICHAELIS (»MICHEL«)',
          text: 'Der »Michel« ist eine der fünf Hauptkirchen Hamburgs und das Hamburger Wahrzeichen schlechthin. Nach 452 Stufen wird man in 82 Metern Höhe mit einem grandiosen Blick über die Stadt – und auf die Elbphilharmonie – belohnt.',
        },
        {
          title: 'KRAMERAMTSSTUBEN',
          text: 'Am Fuße des »Michels« liegen die historischen Reihenhäuser, die ab 1620 erbaut wurden. Zwanzig Wohnungen dienten ab 1676 als Altersversorgung für die Witwen der Hamburger Kleinhändler, deren recht wohlhabende Zunft »Krameramt« hieß.',
        },
        {
          title: 'WASSERTURM SCHANZENPARK',
          text: 'Von 1910 bis 1961 diente der mit 60 Metern ehemals höchste Wasserturm Europas seiner ursprünglichen Funktion. Nun ist er ein Hotel.',
        },
        {
          title: 'HANSEATISCHES OBERLANDESGERICHT',
          text: 'Gemeinsam mit dem Ziviljustizgebäude und dem Strafjustizgebäude bildet dieses stattliche Haus von 1912 das Justizforum am Sievekingplatz. Der Architekt, Georg Kallmorgen, war der Vater von Werner Kallmorgen, dem Architekten des Kaispeichers A, auf dessen Dach Sie gerade stehen.',
        },
        {
          title: 'FERNSEHTURM',
          text: 'Der 280 Meter hohe Heinrich-Hertz-Turm, auch »Tele-Michel« genannt, wurde 1968 eröffnet und ist das höchste Gebäude Hamburgs. Ab Ende 2023 soll die gemächlich rotierende Plattform in 130 Metern Höhe zur Aussicht, für Events und mit gastronomischem Angebot wieder eröffnet werden.',
        },
        {
          title: 'LAEISZHALLE HAMBURG',
          text: 'Versteckt hinter Häusern befindet sich die »ältere Schwester« der Elbphilharmonie. Als größtes und modernstes Konzerthaus Deutschlands wurde sie 1908 fest&shy;lich&shy; eingeweiht. Elbphilharmonie&shy; und&shy; Laeiszhalle werden unter einer Generalintendanz geführt.',
        },
      ],
    },
    task_step1: 'Erkunde die Umgebung der Elbphilharmonie und ergänze den Plan, um Paganonos Position zu bestimmen',
  },
  solution_input: {
    check: 'Check',
    error_message: 'Code falsch!',
  },
  keyword_input_modal: {
    description:
      'Hierfür benötigst Du das Stichwort der Aufgabe, zu der Du wechseln möchtest. Du findest es oben links im Spielbild, neben der Kapitelnummer.',
    button_text: 'Wechseln',
    button_text_error: 'Stichwort falsch',
    placeholder: 'Stichwort eingeben',
  },
  hint_system: {
    close: 'Schliessen',
    task: 'Die Aufgabe',
    get_hint: 'Tipp erhalten',
    get_solution: 'Lösung anzeigen',
    yes: 'Ja, bitte',
    no: 'Nein',
    nuria: 'Nuria',
    hint_nuria: ' / 2 Tipps erhalten',
    hint_nuria_description:
      'Zu jeder Aufgabe kannst Du zwei Tipps oder sogar die Lösung von Nuria bekommen. Möchtest Du einen Tipp erhalten?',
  },
  manual: {
    header: 'Was du wissen solltest',
    title1: '»Rette das Konzert!«',
    desc1: 'Bevor es losgeht, ein paar Infos zum Spiel.',
    title2: 'So geht’s',
    desc2:
      'Das Spiel findet im Browser statt. Du solltest Stift und Zettel zur Hand haben und der Ton Deines Geräts sollte eingeschaltet sein. Für Hörbeeinträchtigte gibt es eine transkribierte Version der Hörrätsel.',
    hint: 'Tipp:',
    hint_desc:
      'Für manche Rätsel könntest Du mehr Infos brauchen als das Spiel zur Verfügung stellt. Wenn Du nicht weiter weißt, darfst Du deshalb auch außerhalb des Spiels nach Lösungen suchen (z. B. im Internet oder in Büchern).',
    title3: 'Zu einer Aufgabe springen oder das Spiel fortsetzen',
    desc3:
      'Jede Aufgabe ist mit einem Stichwort versehen, das mit einer Raute beginnt (z. B. #violine). Die Stichwörter ermöglichen es, direkt zu einem bestimmten Rätsel zu springen oder nach einer Pause wieder ins Spiel einzusteigen. Notiere Dir bei einer Unterbrechung das aktuelle Stichwort, Du findest es oben links im Spielbildschirm. Willst Du das Spiel wieder aufnehmen, klicke im Spiel auf »Aufgabe wechseln«, und gib das Stichwort ein. Die Stichwörter sind nicht rätselrelevant.',
    title4: 'Gemeinsam spielen',
    desc4:
      'Du kannst das Escape Game auch gemeinsam mit Freund:innen spielen. Schließt Euch dafür über ein Konferenz-Tool (z. B. Skype, Zoom oder Discord) zusammen. Nach Spielstart könnt Ihr durch das Abgleichen der Stichwörter alle Aufgaben gemeinsam lösen.',
  },
};

export default De;
