import { ReactElement } from 'react';
import { v4 as uuidv4 } from 'uuid';

import BookPage from './BookPage';

type Props = {
  currentPage: number;
  bookImage: {index: number; image: string}[];
};

export default function Book(props: Props): ReactElement {
  const { currentPage, bookImage } = props;
  
  const getBook = bookImage.map((page) => {
    return (
      <div key={uuidv4()}>
        {page.index === currentPage + 1 && <BookPage image={page.image} />}
      </div>
    );
  });

  return <>{currentPage >= 0 && getBook}</>;
}
