import { ReactElement, useEffect, useState } from 'react';
import styled from 'styled-components';

import IntermissionScene from '../components/scenes/IntermissionScene';
import PuzzleScene from '../components/scenes/PuzzleScene';
import tracking from '../services/tracking';

import { Puzzle } from '../types/Puzzle.d';

type Props = {
  puzzleId: Puzzle;
};

export default function StageScreen(props: Props): ReactElement {
  const { puzzleId } = props;

  const [isPuzzleShown, setIsPuzzleShown] = useState<boolean>(false);
  const [isPuzzleFinished, setIsPuzzleFinished] = useState<boolean>(false);
  const [puzzleStartTime, setPuzzleStartTime] = useState<number>(0);

  const startPuzzle = (): void => {
    setIsPuzzleShown(true);
    setPuzzleStartTime(Date.now());
  };

  const finishPuzzle = (): void => {
    setIsPuzzleFinished(true);
    setIsPuzzleShown(false);

    const puzzleFinishTime = Date.now();
    tracking.puzzleSolved(puzzleStartTime, puzzleFinishTime, puzzleId);
  };

  useEffect(() => {
    setIsPuzzleShown(false);
  }, [puzzleId]);

  return (
    <>
      <StyledBlurContainer isBlurred={!isPuzzleShown}>
        <PuzzleScene puzzleId={puzzleId} finishPuzzle={finishPuzzle} />
      </StyledBlurContainer>
      {!isPuzzleShown && (
        <IntermissionScene
          puzzleId={puzzleId}
          isPuzzleFinished={isPuzzleFinished}
          startPuzzle={() => startPuzzle()}
          resetIntermission={() => setIsPuzzleFinished(false)}
        />
      )}
    </>
  );
}

const StyledBlurContainer = styled.div<{ isBlurred: boolean }>`
  filter: blur(${({ isBlurred }) => (isBlurred ? '0.3' : '0')}rem);
  height: 100%;
`;
