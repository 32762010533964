import { ReactElement, useRef, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import Navbar from '../components/landingpage/Navbar';
import ContentContainer from '../components/layout/ContentContainer';
import HeroSection from '../components/landingpage/HeroSection';
import IconsSection from '../components/landingpage/IconsSection';
import ImagesSection from '../components/common/ImagesSection';
import Footer from '../components/landingpage/Footer';
import { ArrowUp, Close } from '../components/icons/Icons';
import useBreakpoint from '../hooks/useBreakpoint';
import NoMobileSection from '../components/landingpage/NoMobileSection';
import Image1 from '../assets/landing/landing_image_1.webp';
import Image1Fallback from '../assets/landing/landing_image_1.png';
import Image2 from '../assets/landing/landing_image_2.webp';
import Image2Fallback from '../assets/landing/landing_image_2.png';

export default function StartScreen(): ReactElement {
  const { t } = useTranslation();
  const isSmallTablet = useBreakpoint('sm');
  const isTablet = useBreakpoint('md');
  const topRef = useRef<HTMLDivElement | null>(null);

  const [isTopBannerOpen, setTopBannerOpen] = useState(true);

  const executeScroll = (): void => {
    if (topRef.current) {
      topRef.current.scrollIntoView();
    }
  };

  const handleClose = (): void => {
    setTopBannerOpen(false);
  };

  return (
    <ContentContainer>
      {isSmallTablet ? null : (
        <StyledTopBanner isTopBannerOpen={isTopBannerOpen}>
          <StyledClose onClick={handleClose} />
          <StyledTopBannerText>{t('landing_screen.top_banner')}</StyledTopBannerText>
        </StyledTopBanner>
      )}
      <div ref={topRef} />
      <Navbar isSmallTablet={isSmallTablet} isTopBannerOpen={isTopBannerOpen} />
      <HeroSection isTopBannerOpen={isTopBannerOpen} />
      <IconsSection />
      {isTablet ? null : <NoMobileSection />}
      <ImagesSection
        isTablet={isTablet}
        imageOneSrc={Image1}
        imageTwoSrc={Image2}
        imageOneFallbackSrc={Image1Fallback}
        imageTwoFallbackSrc={Image2Fallback}
        titleOne={t('landing_screen.image1.title')}
        titleTwo={t('landing_screen.image2.title')}
        copyOne={t('landing_screen.image1.copy')}
        copyTwo={t('landing_screen.image2.copy')}
      />
      <StyledFooterContainer>
        <StyledTopAnchorLinkWrapper>
          <StyledTopAnchorLink onClick={executeScroll}>
            {t('landing_screen.anchor_link')} <StyledArrowUp />
          </StyledTopAnchorLink>
        </StyledTopAnchorLinkWrapper>
        <Footer />
      </StyledFooterContainer>
    </ContentContainer>
  );
}

const StyledFooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
  margin: 0 auto;
  padding: 0;
  width: 100%;
  background: #efefef;
  align-items: center;
`;

const StyledTopAnchorLinkWrapper = styled.div`
  position: relative;
  width: 90%;
  max-width: 131rem;
  margin: 0 2rem 0;
  padding: 3.6rem 0;
  border-bottom: 0.2rem solid #d5d5d5;
  background: #efefef;
  text-align: center;
`;

const StyledTopAnchorLink = styled.button`
  position: relative;
  right: 2rem;
  font-family: 'DIN Pro';
  font-size: 1.6rem;
  font-weight: 700;
  cursor: pointer;
  border: none;
  padding: 0;
  transition: color 0.2s ease-in;
  color: ${({ theme }) => theme.palette.global.black};
  &:hover {
    color: #555;
    svg {
      margin: -0.4rem 0 0;
    }
  }
`;

const StyledArrowUp = styled(ArrowUp)`
  position: absolute;
  left: 110%;
  top: -0.2rem;
  transition: margin 0.2s ease-in;
`;

const StyledTopBanner = styled.div<{ isTopBannerOpen: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  position: fixed;
  width: 100vw;
  height: ${({ isTopBannerOpen }) => (isTopBannerOpen ? '4.5rem' : '0')};
  opacity: ${({ isTopBannerOpen }) => (isTopBannerOpen ? 1 : 0)};
  background-color: #f5f5f5;
  transform: ${({ isTopBannerOpen }) => (isTopBannerOpen ? `translateY(0)` : `translateY(-100%)`)};
  transition: transform 0.3s ease;
  z-index: 5;
`;

const StyledTopBannerText = styled.p`
  font-size: 1.2rem;
  line-height: 1.6rem;
  padding: 2rem 1rem;
`;

const StyledClose = styled(Close)`
  width: 2rem;
  margin: 1rem;
  cursor: pointer;
`;
