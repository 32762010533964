import React, { ReactElement, useRef } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import Video from '../../assets/finalpage/FinalVideo2.mp4';
import theme from '../../theme/theme';
import { Replay } from '../icons/Icons';
import GradientLeft from '../../assets/finalpage/gradient_final_left.png';
import GradientRight from '../../assets/finalpage/gradient_final_right.png';
import { getBrowserName } from '../../services/getBrowserName';

type Props = {
  isFadingIn: boolean;
};
export default function VideoSection(props: Props): ReactElement {
  const { t } = useTranslation();
  const { isFadingIn } = props;
  const videoRef = useRef<HTMLVideoElement | null>(null);

  const handleReplayClick = (): void => {
    if (videoRef.current !== null) {
      videoRef.current.currentTime = 0.1;
      videoRef.current.play();
    }
  };

  return (
    <StyledHeroContainer>
      <StyledHeroBackground>
        <StyledGradient src={GradientLeft} isLeft={true} />
        <StyledBackgroundVideo autoPlay playsInline ref={videoRef}>
          <source src={Video} type="video/mp4" />
        </StyledBackgroundVideo>
        <StyledGradient src={GradientRight} isLeft={false} />
        <StyledContentWrapper>
          <StyledContent isFadingIn={isFadingIn}>
            <StyledHeroTitle browserName={getBrowserName()}>
              <h2>
                <span>{t('final_screen.hero.title')}</span>
              </h2>
            </StyledHeroTitle>
            <StyledText browserName={getBrowserName()}>
              <span>{t('final_screen.hero.copy')}</span>
            </StyledText>
          </StyledContent>
        </StyledContentWrapper>
        <StyledReplayButton isFadingIn={isFadingIn} onClick={handleReplayClick}>
          <Replay /> {t('final_screen.hero.replay_button')}
        </StyledReplayButton>
      </StyledHeroBackground>
    </StyledHeroContainer>
  );
}

const StyledHeroContainer = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  margin: 0 auto;
  padding: 0;
  width: auto;
  height: 100vh;
  background-color: #282828;
`;

const StyledContentWrapper = styled.div`
  margin: 0 auto;
  padding: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 100%;
  width: 100%;
  max-width: 144rem;
`;

const StyledContent = styled.div<{ isFadingIn: boolean }>`
  position: absolute;
  top: 10%;
  left: 3.7%;
  opacity: ${({ isFadingIn }) => (isFadingIn ? 1 : 0)};
  transition: opacity 0.3s ease-in 3s;
`;

const StyledHeroTitle = styled.p<{ browserName: 'firefox' | 'other' }>`
  font-size: 6rem;
  line-height: 8rem;
  text-transform: uppercase;
  font-weight: 900;
  color: ${({ theme }) => theme.palette.global.black};
  max-width: 90rem;
  margin: 0;
  ${({ theme }) => theme.breakpoints.queries.sm} {
    font-size: 6rem;
    line-height: 9rem;
  }

  span {
    background-color: ${({ theme }) => theme.palette.global.white};
    box-shadow: ${({ browserName }) =>
      browserName === 'firefox' ? '1rem 0 0 white, 0 0 0 white' : '1rem 0 0 white, -1rem 0 0 white'};
  }
`;

const StyledText = styled.p<{ browserName: 'firefox' | 'other' }>`
  display: block;
  font-size: 2rem;
  line-height: 3rem;
  color: ${({ theme }) => theme.palette.global.black};
  margin: 1rem 0 2rem;
  font-weight: 900;
  text-transform: uppercase;
  span {
    background-color: ${({ theme }) => theme.palette.global.white};
    margin: 0;
    padding: 0;
    box-shadow: ${({ browserName }) =>
      browserName === 'firefox' ? '1rem 0 0 white, 0 0 0 white' : '1rem 0 0 white, -1rem 0 0 white'};
  }
`;

const StyledBackgroundVideo = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const StyledHeroBackground = styled.div`
  max-width: 184rem;
  position: relative;
  padding: 0;
  margin: 0;
`;

const StyledReplayButton = styled.div<{ isFadingIn: boolean }>`
  background-color: transparent;
  color: ${theme.palette.global.white};
  position: absolute;
  top: 92%;
  left: 5%;
  cursor: pointer;
  font-size: 1.6rem;
  line-height: 2.8rem;
  opacity: ${({ isFadingIn }) => (isFadingIn ? 1 : 0)};
  transition: opacity 0.3s ease-in 5s;
  svg {
    margin: 0 1rem 0 0;
  }
`;

const StyledGradient = styled.img<{ isLeft: boolean }>`
  position: absolute;
  left: ${({ isLeft }) => (isLeft ? '-0.1rem' : 'auto')};
  right: ${({ isLeft }) => (isLeft ? 'auto' : '-0.2rem')};
  height: 100%;
`;
