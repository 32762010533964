import { MutableRefObject, ReactElement, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import Navbar from '../components/landingpage/Navbar';
import ContentContainer from '../components/layout/ContentContainer';
import Footer from '../components/landingpage/Footer';
import { ArrowUp, ScrollArrowDown } from '../components/icons/Icons';
import useBreakpoint from '../hooks/useBreakpoint';
import DiscountCodeSection from '../components/finalpage/DiscountCodeSection';
import VideoSection from '../components/finalpage/VideoSection';
import ImagesSection from '../components/common/ImagesSection';
import Image1 from '../assets/finalpage/image3.webp';
import Image1Fallback from '../assets/finalpage/image3.png';
import Image2 from '../assets/finalpage/image4.webp';
import Image2Fallback from '../assets/finalpage/image4.png';

export default function StartScreen(): ReactElement {
  const { t } = useTranslation();
  const isSmallTablet = useBreakpoint('sm');
  const isTablet = useBreakpoint('md');
  const topRef = useRef<HTMLDivElement | null>(null);
  const discountRef = useRef<HTMLDivElement | null>(null);
  const [isFadingIn, setIsFadingIn] = useState<boolean>(false);

  const executeScroll = (currentRef: MutableRefObject<HTMLDivElement | null>): void => {
    if (currentRef.current !== null) {
      currentRef.current.scrollIntoView();
    }
  };

  useEffect(() => {
    setIsFadingIn(true);
  }, [isFadingIn]);

  return (
    <ContentContainer>
      <div ref={topRef} />
      <Navbar isSmallTablet={isSmallTablet} isTopBannerOpen={false} isFinalPage={true} isFadingIn={isFadingIn} />
      <StyledHeroSection>
        <VideoSection isFadingIn={isFadingIn} />
        <StyledArrowDownButton onClick={() => executeScroll(discountRef)} isFadingIn={isFadingIn}>
          <ScrollArrowDown />
        </StyledArrowDownButton>
      </StyledHeroSection>
      <div ref={discountRef} />
      <DiscountCodeSection />
      <ImagesSection
        isFinalPage={true}
        isTablet={isTablet}
        imageOneFallbackSrc={Image1Fallback}
        imageOneSrc={Image1}
        imageTwoFallbackSrc={Image2Fallback}
        imageTwoSrc={Image2}
        copyOne={t('final_screen.image1.copy')}
        copyTwo={t('final_screen.image2.copy')}
        titleOne={t('final_screen.image1.title')}
        titleTwo={t('final_screen.image2.title')}
      />
      <StyledFooterContainer>
        <StyledTopAnchorLinkWrapper>
          <StyledTopAnchorLink onClick={() => executeScroll(topRef)}>
            {t('landing_screen.anchor_link')} <StyledArrowUp />
          </StyledTopAnchorLink>
        </StyledTopAnchorLinkWrapper>
        <Footer />
      </StyledFooterContainer>
    </ContentContainer>
  );
}

const StyledFooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
  margin: 0 auto;
  padding: 0;
  width: 100%;
  background: #efefef;
  align-items: center;
`;

const StyledTopAnchorLinkWrapper = styled.div`
  position: relative;
  width: 90%;
  max-width: 131rem;
  margin: 0 2rem 0;
  padding: 3.6rem 0;
  border-bottom: 0.2rem solid #d5d5d5;
  background: #efefef;
  text-align: center;
`;

const StyledTopAnchorLink = styled.button`
  position: relative;
  right: 2rem;
  font-family: 'DIN Pro';
  font-size: 1.6rem;
  font-weight: 700;
  cursor: pointer;
  border: none;
  padding: 0;
  transition: color 0.2s ease-in;
  color: ${({ theme }) => theme.palette.global.black};
  &:hover {
    color: #555;
    svg {
      margin: -0.4rem 0 0;
    }
  }
`;

const StyledArrowUp = styled(ArrowUp)`
  position: absolute;
  left: 110%;
  top: -0.2rem;
  transition: margin 0.2s ease-in;
`;

const StyledHeroSection = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const StyledArrowDownButton = styled.div<{ isFadingIn: boolean }>`
  position: absolute;
  width: 6rem;
  height: 6rem;
  background-color: ${({ theme }) => theme.palette.company.primary};
  top: 90%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 6;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  opacity: ${({ isFadingIn }) => (isFadingIn ? 1 : 0)};
  transition: opacity 0.3s ease-in 5s;
  :hover {
    background-color: ${({ theme }) => theme.palette.company.hover.primary};
    svg {
      margin: 0.3rem 0 0;
    }
  }
  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: margin 0.2s ease-in-out;
  }
`;
