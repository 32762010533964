import Picture1 from '../assets/puzzle4/pictures/Picture1.jpg';
import Picture2 from '../assets/puzzle4/pictures/Picture2.jpg';
import Picture3 from '../assets/puzzle4/pictures/Picture3.jpg';
import Picture4  from '../assets/puzzle4/pictures/Picture4.jpg';
import Picture5  from '../assets/puzzle4/pictures/Picture5.jpg';
import Picture6  from '../assets/puzzle4/pictures/Picture6.jpg';

import Music1 from '../assets/puzzle4/audio/Music1.mp3';
import Music2 from '../assets/puzzle4/audio/Music2.mp3';
import Music3 from '../assets/puzzle4/audio/Music3.mp3';
import Music4 from '../assets/puzzle4/audio/Music4.mp3';
import Music5  from '../assets/puzzle4/audio/Music5.mp3';
import Music6 from '../assets/puzzle4/audio/Music6.mp3';
import { PuzzleFourType } from '../types/PuzzleFourType';

export const PuzzleFourData: PuzzleFourType[] = [
  { id: '1', url: Picture1, audio: Music1},
  { id: '2', url: Picture2, audio: Music2},
  { id: '3', url: Picture3, audio: Music3 },
  { id: '4', url: Picture4, audio: Music4 },
  { id: '5', url: Picture5, audio: Music5 },
  { id: '6', url: Picture6, audio: Music6 },
];
