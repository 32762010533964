import { ReactElement } from 'react';
import {
  DndContext,
  DragEndEvent,
  DragStartEvent,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
  pointerWithin,
  closestCenter,
} from '@dnd-kit/core';
import { snapCenterToCursor } from '@dnd-kit/modifiers';
import { Puzzle } from '../../types/Puzzle.d';

type Props = {
  children: ReactElement;
  onDragStart: (event: DragStartEvent) => void;
  onDragEnd: (event: DragEndEvent) => void;
  onDragCancel: () => void;
  puzzleId?: Puzzle;
};

export default function DragAndDropContext(props: Props): ReactElement {
  const { children, onDragStart, onDragEnd, onDragCancel, puzzleId } = props;
  const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));
  return (
    <DndContext
      sensors={sensors}
      collisionDetection={puzzleId === Puzzle.SIX ? pointerWithin : closestCenter}
      onDragStart={onDragStart}
      onDragEnd={onDragEnd}
      onDragCancel={onDragCancel}
      modifiers={[snapCenterToCursor]}
    >
      {children}
    </DndContext>
  );
}
