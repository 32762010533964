/* eslint-disable max-len */
const En = {
  landing_screen: {
    top_banner: 'This escape game experience is not designed for mobile devices.',
    title: 'Save <br/>  the Concert',
    subline: 'The Elbphilharmonie® Online Escape Game',
    button: 'Play for free now',
    continue_link: '...or resume game',
    icon_section_title: 'Start the adventure now and become a member of the Elbphilharmonie team!',
    icon_section_copy:
      'Tonight’s big concert is in danger – and only you can save it! Can you solve all the problems in time?',
    icon1: {
      title: 'Step up to the challenge!',
      copy: 'Tricky puzzles will lead you to the goal',
    },
    icon2: {
      title: 'Go backstage!',
      copy: 'Discover the Elbphilharmonie as you’ve never seen it before.',
    },
    icon3: {
      title: 'Invite your friends!',
      copy: 'Play alone or as a team.',
    },
    icon4: {
      title: 'Come visit!',
      copy: 'Get 20% off concert tickets as a reward.',
    },
    image1: {
      title: 'The Story',
      copy: 'A damaged instrument, an artist who has gone astray, technology that refuses to work – all that just a few hours before the concert is due to start! Can you help Nuria, the project leader, to save the concert?',
    },
    image2: {
      title: 'How to play',
      copy: 'You’ll need a laptop or a tablet of a sufficient size, as well as an internet connection. Unfortunately, it’s not possible to play on smartphones.',
      icon1_copy: 'Approx. 45 minutes',
      icon2_copy: 'Play alone or with others',
      icon3_copy: 'In English or German',
    },
    nomobile_copy:
      'This escape game experience is not designed for mobile devices. Please consider using the desktop site to enjoy this adventure.',
    anchor_link: 'To the top',
    contact_link: 'Contact',
    privacy_link: 'Privacy Policy',
    imprint_link: 'Imprint',
    cookies_link: 'Cookies',
  },
  intro_screen: {
    headline: 'The concert',
    headline2: 'In front of the Elbphilharmonie',
    subheadline: 'Getting started',
    buttontext1: 'Next',
    buttontext2: '»Sure, how can I help?«',
    buttontext3: '»OK, I’ll do my best!«',
    buttontext4: '»Ready to go!«',
    back: 'Back',
    nuria_desc: 'Project leader at the Elbphilharmonie',
    message1:
      'Hey! The orchestra has just landed. Unfortunately, Paganono’s violin was damaged during the security checks. What a nightmare!',
    message2: 'The concert can’t go ahead without the violin. I have to go now to get it repaired!',
    message3: 'I need some help. Can you step in?',
    message4: 'You have to finish the final tasks in the venue so that the concert can go ahead.',
    message5: 'It’s the most important concert of the year – it’s vital that nothing goes wrong!',
    message6:
      'But don’t worry, I know you’ll manage. I’ll leave my documents with you and we can stay in touch via smartphone!',
    message7: 'Thank you, you’re really saving the evening!',
    message8:
      'We don’t have a second to lose. Head to the artists’ dressing rooms on the 11th floor. Take the goods elevator. Are you ready?',
    usermessage1: 'Sure, how can I help?',
    usermessage2: "OK, I'll do my best!",
    usermessage3: 'Ready to go!',
  },
  puzzle_screen: {
    keyword: 'Keyword: #',
    task: 'Task ',
    switch: 'Switch tasks',
    manual: 'Instructions',
  },
  mobile_warn_screen: {
    title: 'Browser window is too small',
    copy: 'This game is designed for larger screens. To play the game, please open it on your tablet or desktop, and make sure the browser window is maximised (minimum 1024 pixels).',
    button: 'Back to the start page',
  },
  intermission_screen: {
    progress: 'Progress',
    finish: 'Task completed!',
    button_start: 'Here we go!',
    button_finish: 'Continue',
  },
  final_screen: {
    hero: {
      title: 'Congratulations!',
      copy: 'You did it!',
      replay_button: 'Replay',
    },
    title: 'Love live music? Come visit!',
    copy: 'As a reward, the Elbphilharmonie is giving you a <strong>20% discount</strong> on selected concert tickets in the current season. From classical music to jazz, world music to pop – there is something for everyone here. Simply enter the redeem voucher in your shopping basket and secure your tickets (redeemable until 30.04.2024)!',
    copy_nodiscount: 'From classical music to jazz, world music to pop – there is something for everyone here.',
    discount_code: {
      title: 'Your discount code:',
      code: 'PAGANONO',
      hint: 'Code was copied to clipboard',
      hint_error: 'Error',
    },
    link: {
      url: 'escapegame.elbphilharmonie.de',
      title: 'Recommend the Elbphilharmonie’s Online Escape Game to your friends:',
      hint: 'Link was copied to clipboard',
    },
    image1: {
      title: "Don't like classical music?",
      copy: 'No problem! Here you’ll find far more than just classical: discover Jazz, World, Pop & Electronic Concerts!',
    },
    image2: {
      title: 'Elbphilharmonie tickets for 12 euros',
      copy: 'Elbphilharmonie U30: attractive discounts for young people.',
    },
    newsletter_button: 'Find out more',
    team_button: 'View programme',
    mediathek_link: 'https://www.elbphilharmonie.de/en/whats-on/JA/WELT/PRS/ELEK/',
    u30_link: 'https://www.elbphilharmonie.de/en/u30',
    button: 'Discover concerts',
    subcopy:
      'Hier steht das Kleingedruckte. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.',
  },
  puzzle_one: {
    puzzle_title: 'THE SERVICE ELEVATOR',
    puzzle_title_keyword: 'violin',
    puzzle_subtitle: "Let's get on to another level",
    info_text: 'Drag or swipe to look around, scroll to enlarge',
    note_title: 'Hint note',
    check_button: 'Check code',
    puzzleSolution_title: 'What is the elevator code?',
    intermission_start_msg1:
      'Damn! In all the hurry I forgot to give you the access card you’ll need for the elevator. 😓',
    intermission_start_msg2:
      'But there is also a code that allows you to use the elevator. Have a look around. There should be clues to help you find the code!',
    intermission_finish_msg1: 'Great, you’ve found the right code!',
    hint1:
      'Use the 360° view to look around in the elevator. You may notice four words that will help you decrypt the codeword.',
    hint2:
      'You’ll need to take a certain letter from each one of the colour-highlighted words. Those letters will give you the codeword.',
    hint3:
      'The letters you’re looking for are A, U, R and F. If you rearrange them, you get the codeword »RAUF«. Enter this in the input window.',
    task_step1: 'Have a look around in the service elevator',
    task_step2: 'Decrypt the code to go up',
  },
  puzzle_two: {
    puzzle_title: 'The Orchestra plan',
    puzzle_title_keyword: 'cello',
    puzzle_subtitle: 'The red »X« marks the plan.',
    check_button: 'Check code',
    letter: 'Letter',
    number: 'Number',
    back: 'Back to the floor plan',
    solution_title: 'Where is Nuria’s compartment?',
    floor_name: '11. Floor ',
    room_name: '| Room ',
    intermission_start_msg1:
      'I’ve just received a message from the Technical department in the Grand Hall. They need the plan for setting up the orchestra.',
    intermission_start_msg2:
      'I have a small compartment for this kind of information - there must be a copy of the plan there ... Can you get it?',
    intermission_start_msg3:
      'Oh, I’m up next so I’ll have to put the phone down for a second. Look in my notepad to see in which room the compartment is. I’ll be in touch again as soon as I can.',
    intermission_finish_msg1: 'Congratulations, you have found the orchestra plan! The stage can now be set up.',
    hint1: 'Examine the various rooms by clicking on the blue areas. Do you notice anything important?',
    hint2: 'You’re looking for coordinates. Compare the objects in the clue with those in the photos.',
    hint3:
      'On the site plan of the eleventh floor you can connect the rooms together, giving you a cross. At that point, where the lines overlap, is Nuria’s compartment. The coordinate you’re looking for is H4.',
    task_step1: 'Search Nuria’s compartment to find the orchestra plan',
  },
  puzzle_three: {
    puzzle_title: 'THE ORCHESTRA SET-UP',
    puzzle_title_keyword: 'mouthorgan',
    puzzle_subtitle: 'Something is not in tune',
    check_button: 'Solution',
    audio_file: 'OrchestraRehearsal.mp3',
    transcription: 'Transcription',
    transcription_copy:
      'The audio is played back: we hear the orchestra musicians tuning their instruments in the Grand Hall. The conductor taps his baton to get the rehearsal started. The following instruments play in turn: harp, violin, piano, violin, bassoon, flute, timpani. After a short break, the following instruments continue: harp, trombone, violin, timpani, bassoon, violin, piano, flute.',
    solution: 'Solution',
    solution_title: 'Did you recognise all the instruments that were positioned incorrectly? Enter the solution word. ',
    solution_input: 'Check code',
    intermission_start_msg1:
      'A colleague from the concert hall has just written to me. The rehearsal has just started, but there seems to be a problem with the orchestra set-up.',
    intermission_start_msg2: 'She’s sent a couple of recordings - something really isn’t right ... 🙄',
    intermission_start_msg3:
      "Can you listen to the recording from the Grand Hall and work out which instruments aren't sticking to the order shown? That will let us work out who is sitting in the wrong place.",
    intermission_finish_msg1: 'Great work, keep it up! Now everyone is sitting in the right place. 💪',
    hint1:
      'Follow the orchestra plan while you listen to the rehearsal. In the recording, do you hear a different instrument to the one indicated in the orchestra plan? The outer circle is playing in a different direction to the inner circle, starting from the conductor.',
    hint2:
      'The instruments in the recording are playing as follows: harp, violin, piano, violin, bassoon, flute, timpani. Break. Harp, trombone, violin, timpani, bassoon, violin, piano, flute.',
    hint3:
      'If you note all the letters of the instruments that are playing incorrectly, you get the solution word »SONATA«.',
    task_step1: 'Listen to the orchestra rehearsing and compare that with the plan',
    task_step2: 'Pay attention to the order of the instruments',
  },
  puzzle_four: {
    puzzle_title: 'THE SOLOIST’S DRESSING ROOM',
    puzzle_title_keyword: 'altosaxophone',
    puzzle_subtitle: 'Sorting time on the 12<sup>th</sup> floor',
    check_button: 'Check the order',
    check_button_error: 'Wrong, unfortunately. Please try again.',
    intermission_start_msg1:
      'We need to get Paganono’s dressing room ready before he arrives. The jazz band from the rehearsal this morning should be gone by now.',
    intermission_start_msg2:
      'I’ve ordered a few pictures especially for Paganono and these are already in the dressing room.',
    intermission_start_msg3:
      'The pictures should be hung up in chronological order based on publication date. Take a good look: what do the pictures show? ',
    intermission_start_msg4: 'I have a small handbook in my documents that you can consult. 🤓',
    intermission_finish_msg1: 'Outstanding! I hope Paganono will be pleased. 😊',
    hint1:
      'The pictures represent works of classical music. Click on them to have a closer look. But not all the information is hidden in the pictures and the handbook – feel free to take the initiative and conduct your own research.',
    hint2:
      'Sort the musical works by date of publication. In some cases, you’ll need to find out the names of the works or the artist on the basis of the image, and with an internet search. You’ll then also discover the date of publication, or you’ll find it in Nuria’s handbook.',
    hint3:
      'The correct order is: Für Elise (1810 – note the picture of the woman), Wedding March (1842), the opera Aida (1871), Morning Mood (1875), Swan Lake (1895), Flight of the Bumblebee (ca. 1900 – a little bumblebee is hidden in one of the pictures)',
    task_step1: 'Sort the 6 depicted works by publication date',
  },
  puzzle_five: {
    puzzle_title: 'The lights',
    puzzle_title_keyword: 'synthesizer',
    puzzle_subtitle: 'Please illuminate the Grand Hall',
    check_button: 'Check code',
    solution_title: 'Enter the codes one after the other to activate the correct lights.',
    solution_input_button: 'Switch on the light',
    solution_input_error: 'Incorrect lighting code',
    solution_input_copy_error: 'Incorrect lighting code. Please try once more.',
    solution_input_copy_code_exists: 'This light is already activated.',
    solution_input_copy_code_success: 'The light activated. ',
    solution_input_copy_success: 'All the lights activated.',
    solution_input_copy_count_start: '',
    solution_input_copy_count_end: 'light(s) left.',
    light1: 'Spot 1',
    light2: 'Floorlight 2',
    light3: 'Backlight',
    intermission_start_msg1: 'It’s almost time! 🤗 We still need to check the lighting.',
    intermission_start_msg2:
      'Each spotlight can be switched on with a code. Spot 1, Floorlight 2 and Backlight are needed for tonight’s concert.',
    intermission_start_msg3:
      'You can switch on the three spotlights in any order. You’ll find instructions on the conductor’s podium, but it may be that not all the codes are complete ...',
    intermission_finish_msg1: 'Let there be light! Well done, the stage is now ready. 🥳',
    hint1:
      'Clues are hidden everywhere, and you can use these to determine letters and numbers. Remember that you’ll need three codes for three lights. The order is not important.',
    hint2:
      'If something moves, pay attention to how it moves. You might be able to identify numbers there that are a colour match for the codes?',
    hint3: 'You must enter these three codes in any order: Spot 1: 2287 Floorlight 2: UAI4 Backlight: 1UO6',
    back_button: 'back to overview',
    task_step1: 'Complete the three required codes from the instructions and switch on the spotlights in any order.',
  },
  puzzle_six: {
    puzzle_title: 'The guests',
    puzzle_title_keyword: 'drumset',
    puzzle_subtitle: 'Grand Hall – Many requests',
    check_button: 'Check order',
    check_button_error: 'Wrong, unfortunately. Please try again.',
    clipboard: {
      title: 'The Paganono guest list',
      guest1: {
        name: 'Tarek',
        text: 'Could my wife sit as close as possible to the centre? She is the one with the red blazer. I’m happy with any seat.',
      },
      guest2: {
        name: 'Hannah',
        text: 'I would like to sit next to my husband.',
      },
      guest3: {
        name: 'Delia',
        text: 'There needs to be an empty seat next to me so that I can put my bag down. I love to be right in the middle of the action so please put me as close as possible to the centre.',
      },
      guest4: {
        name: 'Kim',
        text: 'I would like to sit as close as possible to the end.',
      },
      guest5: {
        name: 'Elif',
        text: 'I don’t want to sit right at the end, but there should only be one other person sitting next to me.',
      },
      guest6: {
        name: 'Judy',
        text: 'Can I sit to the right next to my father? He wears glasses and has very short hair.',
      },
      guest7: {
        name: 'Thomas',
        text: 'I would like to sit next to as few as possible of Paganono’s other guests.',
      },
    },
    intermission_start_msg1:
      'I reckon Paganono’s invited guests will be arriving any minute - and they still need their tickets!',
    intermission_start_msg2: 'Please seat the guests in a way that ensures they’re all happy. 🙏',
    intermission_finish_msg1: 'That wasn’t easy but you’ve made all the guests happy! 😊',
    intermission_finish_msg2: 'Thank you for your support! The concert is now within reach ...',
    hint1: 'Thomas is Judy’s father.',
    hint2: 'There is an empty seat between Elif and Delia.',
    hint3: 'Seat the guests like this: Kim, Elif, empty seat, Delia, Hannah, Tarek, Judy, Thomas.',
    task_step1: 'Seat the guests based on their wishes',
  },
  puzzle_seven: {
    puzzle_title: 'THE LOCATION',
    puzzle_title_keyword: 'double bass',
    puzzle_subtitle: 'Gain insights by looking outside',
    info_text: 'Navigate to the right and left to discover more!',
    check_button: 'Check arrangement',
    to_map: 'Back to the map',
    lookaround: 'Look around',
    check_button_error: 'Wrong, unfortunately. Please try again.',
    direction_button_all: {
      title: '8TH FLOOR',
      subtitle: 'PLAZA',
    },
    hotspots: {
      no: [
        {
          title: 'HAMBURG CITY HALL',
          text: 'The City Hall of the Free and Hanseatic City of Hamburg is the magnificent seat of the »government of an independent state« (A. Lichtwark). The building, which was constructed between 1886 and 1897 in the Neo-Renaissance style, has a 112-metre-high tower, and houses the State Government (Hamburger Senat) and the State Parliament (Hamburgische Bürgerschaft).',
        },
        {
          title: 'ST. NICOLAI CHURCH MEMORIAL',
          text: 'The former main church of St. Nicolai was destroyed in the Second World War and now, as a cared-for ruin, is a memorial dedicated to the victims of the Nazi regime. The tower is the fifth-highest church tower in the world.',
        },
        {
          title: 'ST. PETRI CHURCH',
          text: 'This church is another of Hamburg’s main churches, and it is located right in the heart of the city centre. After struggling up the 544 steps, you reach what is currently the city’s highest viewing platform, at a height of 123 metres.',
        },
        {
          title: 'ST. JACOBI CHURCH',
          text: 'Construction began in the 14th century. In 1769, the church served as Germany’s first lightning conductor, and was used to stable horses during the Napoleonic occupation (1806–1814).',
        },
        {
          title: 'ST. KATHARINEN CHURCH',
          text: 'Rumour has it that the golden crown on the tower comes from the treasure of the beheaded pirate Klaus Störtebeker. This is where Gotthold Ephraim Lessing, in an argument with Senior Pastor Goeze, developed the idea for his last and best-known work »Nathan the Wise«.',
        },
        {
          title: 'SPEICHERSTADT (WAREHOUSE DISTRICT)',
          text: 'The historic brick buildings with the green roofs were built on thousands of oak pilings, with construction starting in 1883. This is the biggest warehouse district in the world, and it was named a UNESCO World Heritage Site in 2015. In the Speicherstadt you will find lots of local shops and businesses, as well as the Miniatur Wunderland, museums, a coffee roastery and the Hamburg Dungeon.',
        },
        {
          title: 'SPIEGEL HEADQUARTERS',
          text: 'In September 2011, the Spiegel publishing house moved into a new building, and the famous red-and-orange Spiegel canteen was moved to the Museum für Kunst und Gewerbe.',
        },
        {
          title: 'SANDTORHAFEN',
          text: 'The Sandtor dock, with the three restored cargo cranes, was built in the 1860s and was Hamburg’s first artificial harbour basin. Between 20 and 50 boats and ships are moored in the »traditional ship harbour«.',
        },
        {
          title: 'THE MAGELLAN TERRACES',
          text: 'The cascading terraces form an architecturally sophisticated open space at the eastern end of the Sandtorhafen.',
        },
        {
          title: 'SANDTORHAFEN CRANE',
          text: 'The three restored cranes on the southern shore of the dock remind us of the original function of this harbour. With the aid of the Brown steam cranes , the ships were able to load and unload their cargo directly into and out of the quay sheds, rail wagons or horse-drawn carts, which was revolutionary at that time. Incidentally, these cranes were manufactured in Kampnagel – which is now also a popular cultural site in Hamburg.',
        },
      ],

      o: [
        {
          title: 'DEICHTORHALLEN',
          text: 'Originally, this was the site of the Berliner Bahnhof (Berlin Line Station), but that made way for the market hall after Hamburg’s Main Station opened in 1906. The two former market halls, built between 1911 and 1914 (and hidden away behind the Spiegel Headquarters), now house the Hall for Contemporary Art and the House of Photography.',
        },
        {
          title: 'GROSSMARKT-HALLEN (WHOLESALE MARKET',
          text: 'Under the wave-like roof that covers the 220-metre length of the three wholesale market halls, commercial buyers can purchase fruit and vegetables from 2 am (!) until 9 am every weekday. The doors are opened to the general public once a year, when top chefs present their culinary masterpieces at the »Food Market Hamburg«.',
        },
        {
          title: 'THE ELBPHILHARMONIE VISITOR CENTER',
          text: 'Here you can get Plaza tickets, souvenirs and answers to questions on everything to do with the Elbphilharmonie. (You can buy concert tickets in the ticket shop diagonally opposite or online.)',
        },
        {
          title: 'DISCOVERY DOCK',
          text: 'Virtual, interactive and with no need for a boat: everyone, not just technophiles, can explore the Port of Hamburg, presented here as a multimedia adventure playground.',
        },
        {
          title: 'KÜHNE LOGISTICS UNIVERSITY',
          text: 'The KLU is a private university financed by the Kühne Foundation. Teaching and research focus on logistics, management and supply chain management.',
        },
        {
          title: 'HAFENCITY UNIVERSITY',
          text: 'This university for architecture and urban planning moved to the district from which it takes its name in 2014. It is one of the »Lighthouse Projects« of the urban development policy. Around 1,500 students study on the 4,000m² site, which boasts two waterfronts and an extensive waterside promenade accessible to the public. Since 2012, the new U4 line has been linking the HCU via the HafenCity Universität stop.',
        },
        {
          title: 'CARLS',
          text: 'Brasserie and restaurant – CARLS is one of the Elbphilharmonie’s oldest neighbours.',
        },
      ],

      so: [
        {
          title: 'UNILEVER BUILDING AND THE MARCO POLO TOWER',
          text: 'The ensemble with the 60-metre-high Marco Polo Tower and the Unilever Building is a real eye-catcher. The latter boasts extremely sustainable architecture, for which it has been awarded the HafenCity Environmental Certificate.',
        },
        {
          title: 'STRANDKAI',
          text: 'The last 90 metres on the tip of the long tongue of the Strandkai will remain undeveloped. Apartments and office buildings are being constructed on most of the rest of the area.',
        },
        {
          title: 'GRASBROOK',
          text: 'This city quarter was earmarked as a potential venue for the 2024 or 2028 Olympic Games – but nothing came of those plans due to a negative vote by the Hamburg public. Instead, over the next 20 years, a residential, work and leisure quarter will be developed here along the bank of the river Elbe.',
        },
        {
          title: 'MOLDAUHAFEN',
          text: 'The 30,000m² area represents something of a peculiarity in the Port of Hamburg: it was leased to Czechoslovakia in 1919 under the terms of the Treaty of Versailles. The lease contract subsequently negotiated runs until 2028.',
        },
        {
          title: 'ELBPHILHARMONIE LANDING PIER',
          text: 'Since late 2012, the HADAG 72 ferry route, as part of the local public transport network, has been providing a link between the Landungsbrücken and the Elbphilharmonie.',
        },
        {
          title: 'SEGELSCHIFFHAFEN',
          text: 'This harbour is called Segelschiffhafen (sail ship harbour).',
        },
        {
          title: 'WATER LEVEL GAUGE (»PEGELMESSER«)',
          text: 'The Water Level Gauge on the quay known as »Amerikahöft« dates back to the 1950s and is listed as an historic monument. It has a clock-like dial that shows the water level of the River Elbe.',
        },
        {
          title: 'HANSAHAFEN',
          text: 'This harbour is called Hansahafen.',
        },
        {
          title: 'THE SERIES 50 SHEDS AND HAFENMUSEUM HAMBURG',
          text: 'Here in the Hansahafen you can see how the port looked before the introduction of the standardised container (in the 1960s). In this last (now protected) ensemble of quay buildings to be constructed during the period of the German Empire, history is brought back to life from April to October each year.',
        },
        {
          title: 'THE PEKING',
          text: 'The sailing cargo ship PEKING, which was built by the Laeisz shipping company in 1911, returned from New York in terrible condition in 2017 after 40 years. She is now almost fully restored and has been docked here in the Hansahafen since September 2020. The ship will set off on a final journey within Hamburg as a spectacular main exhibit for the German Port Museum, which is currently in the planning phase.',
        },
      ],

      s: [
        {
          title: 'THE ARNINGSTRASSE LANDING PIER AND THE MOUTH OF STEINWERDER HARBOU',
          text: 'The landing pier is served by the HADAG 72 ferry, which also stops at the Elbphilharmonie.',
        },
        {
          title: 'WILHELMSBURG ENERGY BUNKER',
          text: 'The former flak bunker is now a renewable energy power station. With a 360° viewing platform and a rooftop cafe, the Energy Bunker is located on the island of Wilhelmsburg on the Elbe river.',
        },
        {
          title: 'WILHELMSBURG WATER TOWER',
          text: 'The Groß Sand Water Tower supplied water for the Wilhelmsburg city district from 1911 until 1956; the cultural monument has since been converted into apartments.',
        },
        {
          title: 'WATER POLICE SCHOOL',
          text: 'This has been a training centre for police officers working on waterways all over Germany since 1945 – with the exception of the German state of Thuringia where there are no water police in service.',
        },
        {
          title: 'THE RETHESPEICHER INDUSTRIAL MONUMENT',
          text: 'This imposing-looking granary is located in the Wilhelmsburg neighbourhood. It is a vast complex with different building parts dating from various decades of the last century.',
        },
        {
          title: 'MOORBURG COAL POWER STATION',
          text: 'Construction started in 2007, it was put into operation in 2015, then shut down again in 2020: in the future, the controversial former coal-fired power station will make an important contribution to the reduction of CO2 emissions. The plan is to use the site for an electrolysis plant to produce green hydrogen. The existing infrastructure will also be used for other innovative energy-production technologies.',
        },
        {
          title: 'THE HARBURG HILLS',
          text: 'At 116.2 metres above ground level, Hasselbrack is Hamburg’s highest »mountain« and the highest summit in the Harburg Hills – and thereby also the highest natural point in Hamburg.',
        },
        {
          title: 'KÖHLBRAND BRIDGE',
          text: 'At a total length of 3,600 meters, the cable-stayed bridge with its distinctive structure of 88 steel cables is Germany’s second-longest road bridge. However, it is expected to be in such a poor state by 2030 that a new crossing will be required. It is anticipated that a tunnel will replace the bridge from 2034.',
        },
        {
          title: 'MOUTH OF THE REIHERSTIEG',
          text: 'The Reiherstieg is an anabranch through the port, connecting the Northern and Southern Elbe rivers.',
        },
      ],

      sw: [
        {
          title: 'HAMBURG CRUISE CENTER STEINWERDER',
          text: 'The newest of Hamburg’s three cruise terminals can handle ships up to 400 meters in length and carrying up to 8,000 passengers. With fewer cruises taking place as a result of the coronavirus crisis, the large area in the middle of the port was quickly turned into a cultural site: with concerts, comedy and a drive-in cinema.',
        },
        {
          title: 'KÖHLBRAND BRIDGE',
          text: 'At a total length of 3,600 meters, the cable-stayed bridge with its distinctive structure of 88 steel cables is Germany’s second-longest road bridge. However, it is expected to be in such a poor state by 2030 that a new crossing will be required. It is anticipated that a tunnel will replace the bridge from 2034.',
        },
        {
          title: 'THE TOLLERORT TERMINAL',
          text: 'The HHLA Container Terminal Tollerort has a quay of 1,240 metres in length and can accommodate ships of up to 400 metres in length. And that is the smallest of all terminals... HHLA is short for »Hamburger Hafen und Logistik AG« and is a logistics company that ensures that all freight is moved smoothly to, from and within the Port of Hamburg.',
        },
        {
          title: 'THE BURCHARDKAI TERMINAL',
          text: 'The HHLA Container Terminal Burchardkai is the biggest and oldest transhipment centre here in the port – containers have been handled here since 1968. (The first standardised containers date from 1961.)',
        },
        {
          title: 'STAGE THEATER AN DER ELBE',
          text: 'The two Stage Entertainment theatres lie on the southern bank of the Northern Elbe river, on the Elbe island of Steinwerder. The »Pretty Woman« musical has been running in the silvery building since October 2019.',
        },
        {
          title: 'MUSICAL BOULEVARD',
          text: 'The Boulevard lies between the two musical theatres. You can eat indoors and outdoors here, and enjoy views to the north of the city and of the Elbphilharmonie.',
        },
        {
          title: 'STAGE THEATER IM HAFEN',
          text: 'The ever-popular »Lion King« has been running in Stage Entertainment’s striking yellow tent since 2001.',
        },
        {
          title: 'THEATER IM HAFEN LANDING PIER',
          text: 'You can sail from the Landungsbrücken (landing stages) to the musical theatres on the HADAG 73 ferry. Those going to see a musical can use the free Schiff-Shuttle.',
        },
        {
          title: 'BLOHM + VOSS MAIN BUILDING',
          text: 'The company was founded in 1877 and its main administration building dates back to 1911.',
        },
      ],

      w: [
        {
          title: 'BLOHM + VOSS SHIPYARD',
          text: 'Particularly striking are Docks 10 and 11 directly on the Elbe river, as is Dry Dock Elbe 17, which is one of Europe’s biggest dry docks.',
        },
        {
          title: 'THE ALTONA FISH AUCTION HALL AND FISH MARKET',
          text: 'Built in 1894 and a protected building since 1989: today, the former auction market is a popular event venue – and perfect for a morning drink after some early-morning shopping at the fish market. The fish market with its over 300-year history is a Hamburg institution for early risers and night owls alike.',
        },
        {
          title: 'PARK FICTION IN ANTONIPARK',
          text: 'A »magic carpet« among brightly coloured steel palm trees and other creative eye-catchers – the art project is a green oasis that was hard won by local citizens in the middle of the concrete hustle and bustle of the city.',
        },
        {
          title: 'CAP SAN DIEGO',
          text: 'The CAP SAN DIEGO once sailed the oceans as a general cargo vessel: it is now a monument, high-rope course, hotel, exhibition centre and event venue. And it’s still seaworthy! The »White Swan« at the Überseebrücke is one of the unmissable Hamburg landmarks, and bears witness to the city’s modern maritime tradition.',
        },
        {
          title: 'ST. TRINITATIS CHURCH IN ALTONA',
          text: 'This Lutheran church stands just next to Hamburg’s famous fish market. The monograms of two Danish kings, both Christian (the 5th and 6th), adorn its sandstone portals – a reminder that the Altona district was, in fact, still under Danish rule up until a good 130 years ago.',
        },
        {
          title: 'OLD ELBE TUNNEL (NORTH ENTRANCE)',
          text: 'The 426-metre-long "St. Pauli-Elbtunnel" was built in 1911. Its two tubes are decorated with beautiful tiles and stoneware reliefs. The tunnel is currently undergoing extensive restoration and only one tube is open at any given time, and that for pedestrians and cyclists only at no charge.',
        },
        {
          title: 'LANDUNGSBRÜCKEN (LANDING STAGES)',
          text: 'The 700-metre-long floating pontoons have been here since 1839. Particularly striking are the two round towers with the green domes and the almost church-like Pegelturm (Water Level Tower), which announces the time on a ship’s bell every half hour and shows the current stage of the tide.',
        },
        {
          title: 'BERNHARD NOCHT INSTITUTE FOR TROPICAL MEDICINE',
          text: 'This »Institute for Maritime and Tropical Diseases« was founded in 1900. Initially it was a department of the Hamburg Health Authority. Research is carried out here into diseases typically found in the tropics and new infectious diseases. New insights into the coronavirus were also discovered here.',
        },
        {
          title: 'EMPIRE RIVERSIDE HOTEL',
          text: 'The acclaimed British architect David Chipperfield was the mastermind behind the design of this striking building. Soaring 90 metres high, the views from the designer hotel’s top-floor bar are almost as stunning as those from the Elbphilharmonie.',
        },
        {
          title: 'RICKMER RICKMERS',
          text: 'The three-masted barque was launched in Bremen in 1896, and was powered by wind, steam and diesel. The Rickmer Rickmers is permanently moored at the Fiete Schmidt Landing Pier and the floating museum it houses provides information about the ship and shipping more generally.',
        },
        {
          title: 'SANDTORHÖFT LANDING PIER',
          text: 'From here you have a direct view and a special perspective of the port and of the western point of the Elbphilharmonie.',
        },
        {
          title: 'ASTRA-TOWER',
          text: 'The home of Hamburg’s legendary beer. This was the site, up until 2006, of the Astra office building with its famous beer glass shape. The façade of the new building is still slightly slimmer at its base, in subtle reference to the iconic design of the former tower.',
        },
        {
          title: 'PROMENADE AND FLOOD PROTECTION STRUCTURE',
          text: 'The 625-metre-long structure designed by Zaha Hadid Architects was completed in May 2019, and connects the St. Pauli Landing Stages with the Baumwall.',
        },
        {
          title: 'ATLANTIK-HAUS',
          text: 'This commercial and office building in St. Pauli is positioned along three different street elevations, giving tenants three addresses to choose from.',
        },
        {
          title: 'RUNDBUNKER',
          text: 'The round-shaped former high bunker on Vorsetzen was built in 1940 in the »Zombeck Turm« style. It was designed for 600 people, but up to 1,000 people would actually seek shelter in it during Second World War bombing raids.',
        },
        {
          title: 'DANCING TOWERS',
          text: 'The two widely visible high-rises at one end of the Reeperbahn house the Mojo Club, Radio Reeperbahn, Hamburg’s highest restaurant and offices.',
        },
      ],

      nw: [
        {
          title: 'ST. MICHAELIS CHURCH (»MICHEL«)',
          text: 'One of Hamburg’s five main churches and a quintessential Hamburg landmark. If you climb the 452 steps up to a height of 82 metres, you are rewarded with a stunning view of the city – and of the Elbphilharmonie.',
        },
        {
          title: 'KRAMERAMTSSTUBEN (GROCERS FLATS)',
          text: 'These historic row houses at the foot of the »Michel« were built from 1620. Twenty flats located in the buildings here were erected by the wealthy guild »Krameramt« (Grocers’ Institute) from 1676 and served as residences for grocers’ widows.',
        },
        {
          title: 'SCHANZENPARK WATER TOWER',
          text: 'At a height of 60 metres, this was once Europe’s highest water tower – it served its original function from 1910 to 1961. It is now a hotel.',
        },
        {
          title: 'HIGHER REGIONAL COURT OF THE FREE AND HANSEATIC CITY OF HAMBURG',
          text: 'Together with the Civil Justice Building and the Criminal Justice Building, this imposing 1912 courthouse forms the Justice Forum on Sievekingplatz. The architect, Georg Kallmorgen, was the father of Werner Kallmorgen, the architect of Kaispeicher A, on whose roof you are now standing.',
        },
        {
          title: 'TV TOWER',
          text: 'The 280-meter-high Heinrich Hertz Tower, also known as Tele-Michel, was opened in 1968, and is the highest building in Hamburg. From late 2023, the slowly rotating platform 130 meters above the earth will open again for events and offering cuisine with a spectacular view.',
        },
        {
          title: 'LAEISZHALLE HAMBURG',
          text: 'The Elbphilharmonie’s »older sister« is hidden away behind buildings. When it first opened its doors in 1908, it was Germany’s biggest, most modern concert hall. The Elbphilharmonie and the Laeiszhalle operate under one general and artistic director. Concert Tickets',
        },
      ],
    },
    intermission_start_msg1: 'Good news: The violin has been repaired and I’m on my way back to the Elbphilharmonie 😊',
    intermission_start_msg2: 'Paganono has just called – he’s on the Plaza and can’t find the right elevator!',
    intermission_start_msg3:
      'I’m not sure where exactly he is. Can you find out and guide him? He says he can see a tower and the four masts of the Peking.',
    intermission_start_msg4:
      'I’ve tried to send you a map of the area to help with orientation but it’s too big. So I’ll send it to you in several parts.',
    intermission_start_msg5:
      'Have a look at your surroundings and put the map together. Then we can work out where Paganono is.',
    intermission_finish_msg1:
      'OK! Paganono can see the sailing cargo ship Peking and the Wilhelmsburg Water Tower. So he must be on the south-eastern side of the Plaza!',
    intermission_finish_msg2:
      'Very good! We can now tell him how he can reach the elevator. Then, when he gets up there, he can get changed straight away in his dressing room and go on stage for the second piece. We can get started! 🤩 ',
    hint1: 'Take a close look at the various panoramas. Do you recognise the sights somewhere else?',
    hint2:
      'In each point of the compass there is a landmark that can be found as a symbol on the map of the plan. With some of the landmarks you will have to look carefully at the information and think outside the box.',
    hint3:
      'The symbol maps must be arranged as follows (clockwise starting from north): Clock Tower, Fish Sandwich, Sailing Boat, Water Tower, Lion (Lion King Musical), Swan (Cap San Diego), TV Tower.',
    task_step1:
      'Explore the surroundings of the Elbphilharmonie and complete the map in order to determine where Paganono is',
  },
  solution_input: {
    check: 'Check',
    error_message: 'Wrong Code!',
  },
  keyword_input_modal: {
    description:
      'To do this you need the keyword of the task you wish to switch to. You’ll find it in the top left of the screen, next to the chapter number.',
    button_text: 'Switch',
    button_text_error: 'Wrong keyword',
    placeholder: 'Enter keyword',
  },
  hint_system: {
    close: 'Close',
    task: 'The Task',
    get_hint: 'Get a clue',
    get_solution: 'Show solution',
    yes: 'Yes, please',
    no: 'No',
    nuria: 'Nuria',
    hint_nuria: ' / 2 clues received',
    hint_nuria_description:
      'For each task, you can get two clues or even the solution from Nuria. Would you like a clue?',
  },
  manual: {
    header: 'What you need to know',
    title1: '»Save the Concert!«',
    desc1: "Before you start, here's some information about the game.",
    title2: 'Here’s how it works',
    desc2:
      'The game is played in the browser. You should have a pen and paper handy and make sure the sound is on on your device. A transcribed version of the audio puzzles is available for people with hearing impairments.',
    hint: 'Tip:',
    hint_desc:
      'For some puzzles, you may need more information than is provided by the game. So if you don’t know how to get any further, you can also look for solutions outside the game (e.g. on the internet or in books).',
    title3: 'Jump to a task or continue game',
    desc3:
      'Each task features a keyword starting with a hash (e.g. #violin). The keywords allow you to jump directly to a certain puzzle or to restart the game where you left off. If you take a break, make a note of the current keyword: you’ll find it at the top left of the screen. If you want to resume playing, click on »Switch tasks« and enter the keyword. The keywords are not relevant for the puzzles.',
    title4: 'Play with friends',
    desc4:
      'You can also play the game with friends. To do that, get together via a conference tool (e.g. Skype, Zoom or Discord). Once you start the game, you can all solve the tasks together by synchronising the keywords.',
  },
};

export default En;
