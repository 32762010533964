import React, { ReactElement } from 'react';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

interface Language {
  nativeName: 'DE' | 'EN';
}

type Props = {
  isWhite: boolean;
};

const langs: { [key: string]: Language } = {
  de: { nativeName: 'DE' },
  en: { nativeName: 'EN' },
};

LanguageToggle.defaultProps = {
  isWhite: false,
};

export default function LanguageToggle(props: Props): ReactElement {
  const { i18n } = useTranslation();
  const { isWhite } = props;
  return (
    <StyledLanguageToggle>
      {Object.keys(langs).map((lng) => (
        <StyledLanguageButton
          key={uuidv4()}
          style={{
            fontWeight: i18n.resolvedLanguage === lng ? '900' : 'normal',
          }}
          isWhite={isWhite}
          hasBorder={langs[lng].nativeName === 'DE'}
          type="submit"
          onClick={() => i18n.changeLanguage(lng)}
          variant={langs[lng].nativeName}
        >
          {langs[lng].nativeName}
        </StyledLanguageButton>
      ))}
    </StyledLanguageToggle>
  );
}

const StyledLanguageToggle = styled.div`
  position: relative;
  margin: 0 0 0 0.2rem;
  z-index: 2;
`;

const StyledLanguageButton = styled.button<{ isWhite: boolean; hasBorder: boolean; variant: 'DE' | 'EN' }>`
  border: none;
  display: inline-block;
  font-family: 'DIN Pro', sans-serif;
  background: transparent;
  cursor: pointer;
  padding: 0;
  margin: 0;
  font-size: 1.6rem;
  line-height: 2.1rem;
  text-align: ${({ variant }) => (variant === 'DE' ? 'left' : 'right')};
  min-width: ${({ variant }) => (variant === 'DE' ? '3.4rem' : '3.2rem')};
  text-shadow: ${({ isWhite }) => (isWhite ? '0rem 0rem 1rem #00000088' : '0rem 0rem 0rem #000')};
  color: ${({ theme, isWhite }) => (isWhite ? theme.palette.global.white : theme.palette.global.black)};
  border-right: ${({ theme, hasBorder, isWhite }) =>
    hasBorder
      ? isWhite
        ? `0.2rem solid  ${theme.palette.global.white}`
        : `0.2rem solid  ${theme.palette.global.black}`
      : 'none'};
`;
