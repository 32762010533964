import { Partners, SocialLinks } from '../types/FooterLinks.d';
import LogoSap from '../assets/landing/logo_sap.png';
import LogoJulius from '../assets/landing/logo_julius.png';
import LogoKuehne from '../assets/landing/logo_kuehne.png';
import LogoRolex from '../assets/landing/logo_rolex.png';
import LogoPorsche from '../assets/landing/logo_porsche.png';

export const SocialIcons: { name: string; link: SocialLinks; position: string; width: number; height: number }[] = [
  {
    name: 'facebook',
    link: SocialLinks.FACEBOOK,
    position: '62.9465830866% 69.2009164446%',
    width: 1.5,
    height: 3,
  },
  {
    name: 'youtube',
    link: SocialLinks.YOUTUBE,
    position: '37.3723112698% 26.2586019558%',
    width: 3.4,
    height: 2.3,
  },
  {
    name: 'instagram',
    link: SocialLinks.INSTAGRAM,
    position: '12.7228954992% 86.4203297987%',
    width: 3,
    height: 3,
  },
  {
    name: 'twitter',
    link: SocialLinks.TWITTER,
    position: '99.6% 47.4885844749%',
    width: 2.5,
    height: 2.5,
  },
  {
    name: 'tiktok',
    link: SocialLinks.TIKTOK,
    position: '89.6% 0',
    width: 2.5,
    height: 2.8,
  },
  {
    name: 'spotify',
    link: SocialLinks.SPOTIFY,
    position: '54.268342782% 69.6732887675%',
    width: 3.2,
    height: 3.2,
  },
];

export const PartnerLogos: { name: string; image: string; link: Partners }[] = [
  {
    name: 'sap',
    image: LogoSap,
    link: Partners.SAP,
  },
  {
    name: 'kuehne',
    image: LogoKuehne,
    link: Partners.KUEHNE,
  },
  {
    name: 'juliusbaer',
    image: LogoJulius,
    link: Partners.JULIUS,
  },
  {
    name: 'porsche',
    image: LogoPorsche,
    link: Partners.PORSCHE,
  },
  {
    name: 'rolex',
    image: LogoRolex,
    link: Partners.ROLEX,
  },
];
