import React, { forwardRef } from 'react';
import theme from '../../theme/theme';
import styled from 'styled-components';

interface Props {
  isDragging: boolean;
  isTransparent: boolean;
  url: string;
  index: number | string;
  totalColumnsNumber: number;
  style?: React.CSSProperties;
}

export const GuestImage = forwardRef<HTMLDivElement, Props>(
  ({ url, index, isTransparent, isDragging, style, totalColumnsNumber, ...props }, ref) => {
    return (
      <StyledDragAndDropImage
        ref={ref}
        isTransparent={isTransparent}
        isDragging={isDragging}
        url={url}
        style={style}
        {...props}
      />
    );
  },
);

GuestImage.displayName = 'Guest Image';

const StyledDragAndDropImage = styled.div<{
  isDragging: boolean;
  isTransparent: boolean;
  url: string;
}>`
  width: 7rem;
  height: 7rem;
  opacity: ${({ isDragging }) => (isDragging ? 0 : 1)};
  transform-origin: 0 0;
  background-image: ${({ isDragging, url }) => (isDragging ? `` : `url("${url}")`)};
  background-size: cover;
  background-position: center;
  background-color: transparent;
  border-radius: 50%;
  box-shadow: ${({ isTransparent }) =>
    isTransparent ? ` inset 0 0 0 0.2rem ${theme.palette.company.hover.primary}` : 'none'};
  ${({ theme }) => theme.breakpoints.queries.lg} {
    width: 10rem;
    height: 10rem;
  }
  transition: 0.3s box-shadow;
  :hover {
    filter:  drop-shadow(0 0 0.6rem ${theme.palette.company.hover.primary}) brightness(1.05);
    -webkit-filter:  drop-shadow(0 0 0.6rem ${theme.palette.company.hover.primary}) brightness(1.05);
    //box-shadow: inset 0 0 0 0.3rem ${theme.palette.company.hover.primary};

  }
`;
