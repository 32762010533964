import { ReactElement } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { GuestsClipboardData } from '../../data/GuestsClipboardData';
import GuestsClipboardElement from './GuestsClipboardElement';

type Props = {
  isFirstPage: boolean;
  currentPage: number;
};

export default function GuestsClipboard(props: Props): ReactElement {
  const { isFirstPage, currentPage } = props;
  const { t } = useTranslation();

  return (
    <StyledGuestsClipboard>
      {isFirstPage && (
        <StyledGuestListTitle>
          <h3>{t('puzzle_six.clipboard.title')}</h3>
        </StyledGuestListTitle>
      )}
      {currentPage > 0 &&
        GuestsClipboardData.map((page, index) => {
          return <div key={index}>{page.index === currentPage && <GuestsClipboardElement guests={page.guests} />}</div>;
        })}
    </StyledGuestsClipboard>
  );
}

const StyledGuestsClipboard = styled.div``;

const StyledGuestListTitle = styled.div`
  max-width: 14rem;
  font-size: 1.9rem;
  line-height: 2.5rem;
  position: relative;
  text-transform: uppercase;
  font-weight: 700;
  text-align: left;
  margin: 0 4.8rem;
  padding: 8.5rem 0 1.1rem 0;
  &::after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    width: 7rem;
    left: 0;
    border-top: 0.4rem solid black;
  }
`;