import React, { ReactElement } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { HiddenRoutes } from '../../types/Routes.d';

type Props = {
  mobilePadding?: string;
  desktopPadding?: string;
  mobileMaxWidth?: string;
  desktopMaxWidth?: string;
  zIndex?: string;
  children: React.ReactNode;
};

ContentContainer.defaultProps = {
  mobilePadding: '0 2.5rem',
  desktopPadding: '0',
  mobileMaxWidth: undefined,
  desktopMaxWidth: undefined,
  zIndex: 'auto',
};

//#TODO adjust according to design (design not ready yet)

/**
 * Section container which contains the max width values for the different breakpoints
 * @param children - The children of the container
 * @returns The container with the max width values
 */
export default function ContentContainer(props: Props): ReactElement {
  const { children, zIndex } = props;
  const { pathname } = useLocation();

  let isLanding: boolean;

  if (pathname === HiddenRoutes.LANDING || pathname === HiddenRoutes.COMPLETED) {
    isLanding = true;
  } else isLanding = false;

  return (
    <StyledContainer isLanding={isLanding} zIndex={zIndex as string}>
      {children}
    </StyledContainer>
  );
}

const StyledContainer = styled.div<{ isLanding: boolean; zIndex: string }>`
  width: 100%;
  height: ${({ isLanding }) => (isLanding ? 'initial' : '100vh')};
  max-width: ${({ isLanding }) => (isLanding ? 'initial' : '144rem')};
  max-height: ${({ isLanding }) => (isLanding ? 'initial' : '108rem')};
  top: ${({ isLanding }) => (isLanding ? '0' : '50%')};
  left: ${({ isLanding }) => (isLanding ? '0' : '50%')};
  position: ${({ isLanding }) => (isLanding ? 'relative' : 'absolute')};
  transform: ${({ isLanding }) => (isLanding ? 'none' : 'translate(-50%, -50%)')};
  z-index: ${({ zIndex }) => zIndex};
`;
